import { t } from "i18next";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import colors from "../../Assets/Colors/Colors.json";
import font from "../../Assets/Fonts/Fonts.json";
import fontSize from "../../Assets/Fonts/FontsSizes.json";
import Images from "../../Data/Images/Images";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import { ITransitTransport } from "../../Interfaces/ITransitTransport";
import { IsMobile } from "../../Services/DeviceService";
import i18n from "../../Services/i18n";
import { StyleMode } from "../../Utils/consts";
import { GetDistanceFromLatLonInKm } from "../../Utils/helpers";
import useDeviceMode from "../../Utils/useWindowDimensions";
import locationIcon from "./images/location.png";
import TransitLineAccordion from "./TransitLineAccordion";

const TransitStation: React.FC<ITransitTransport> = (props) => {
  const {
    stationId,
    stationName,
    latitude,
    longitude,
    transitLines,
    locationDescriptionHe,
    stationRailId,
    stations,
    typeOfPage,
  } = props;

  const [_distance, _setDistance] = useState(0);

  const deviceMode = useDeviceMode();

  const stationDetails = stations.filter(
    (station) => station.stationId === stationRailId
  );

  useEffect(() => {
    const distanceFromStation =
      latitude &&
      longitude &&
      GetDistanceFromLatLonInKm(
        stationDetails[0].location.latitude,
        stationDetails[0].location.lontitude,
        latitude,
        longitude
      ) * 1000;

    if (distanceFromStation && distanceFromStation < 1000) {
      _setDistance(distanceFromStation);
    }
  }, []);

  const handleClick = () => {
    IsMobile()
      ? (window.location.href = `geo:0,0?q=${latitude},${longitude}(${"stationName"} ${t(
          `stationNavigation`
        )} ${stationName})`)
      : window.open(
          `https://www.google.com/maps/dir/'${stationDetails[0].location.latitude},${stationDetails[0].location.lontitude}'/'${latitude},${longitude}'/`,
          "_blank"
        );
  };

  return (
    <div
      style={{
        ...styles.container,
        width: deviceMode === StyleMode.desktop ? "100%" : "90%",
      }}
    >
      <div style={styles.header}>
        <div style={styles.headerDetails}>
          <div
            style={{
              ...styles.title,
              color:
                typeOfPage === "stationInfo" ? colors.darkBlue : colors.black,
            }}
          >
            {stationName}
          </div>
          <div style={styles.stationNumberAndNavigation} onClick={handleClick}>
            <div style={styles.stationNumber}>
              {t("stationNumber")} {stationId}
            </div>
            <div style={styles.seperator}></div>
            <div style={styles.stationNavigation}>
              <div>
                <img src={locationIcon} alt="bus" width="8.5" height="11" />
              </div>
              <span>{t("NavigationStation")}</span>
              <img
                style={{
                  rotate: i18n.dir() === "ltr" ? "270deg" : "90deg",

                  color: colors.black,
                  fontFamily: font.FbReformaMedium,
                }}
                src={Images.arrowDown}
                alt="arrow"
              />
            </div>
          </div>
        </div>
        {
          <div
            style={{
              ...styles.distance,
              color:
                typeOfPage === "stationInfo" ? colors.darkBlue : colors.black,
            }}
          >
            {_distance?.toFixed()} {t("meter")}
          </div>
        }
      </div>
      <div style={styles.linesContainer}>
        <TransitLineAccordion
          transitLines={transitLines}
          locationDescriptionHe={locationDescriptionHe}
          typeOfPage={typeOfPage}
        />
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "40.625rem",
    width: "100%",
    minWidth: "25rem",
    gap: "0.5rem",
    boxShadow:
      "rgba(0, 0, 0, 0.2) 0px 0px 1px 0px, rgba(0, 0, 0, 0.19) 0px 0px 15px 0px",
    borderRadius: "0.625rem",
    position: "relative",
  },

  header: {
    background: colors.ghostWhite,
    display: "flex",
    justifyContent: "space-between",
    padding: "1.5rem",
    borderRadius: "0.625rem",
  },

  distance: {
    display: "flex",
    alignItems: "center",
    color: colors.darkBlue,
    fontFamily: font.FbReformaMedium,

    whiteSpace: "pre",
  },

  headerDetails: {
    display: "flex",
    flexDirection: "column",
    gap: ".5rem",
  },

  stationNumberAndNavigation: {
    display: "flex",
    gap: ".5rem",
    fontSize: fontSize.Text,
    fontFamily: font.FbReformaMedium,
    cursor: "pointer",
    color: colors.black,
  },

  seperator: {
    width: "0.0063125rem",
    border: "0.01875rem solid grey",
  },
  stationNavigation: {
    display: "flex",
    gap: ".5rem",
    color: colors.darkBlue,
    cursor: "pointer",
  },
  title: {
    color: colors.darkBlue,
    fontFamily: font.FbReformaMedium,

    fontSize: fontSize.Paragraph,
    letterSpacing: "0.51px",
    lineHeight: "20px",
  },
};

export default TransitStation;
