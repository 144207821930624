import React from "react";
import { IArticleWithMedia } from "../../../../Interfaces/IMagazinePage";
import { StyleSheet } from "../../../../Interfaces/IStyleSheet";
import ArticleMedia from "../Components/ArticleMedia";
import ArticleTitleAndText from "../Components/ArticleTitleAndText";

const SelectedArticleGalleryItem: React.FC<
  IArticleWithMedia & { videoStyles?: React.CSSProperties }
> = (props) => {
  const { containerStyles, videoStyles } = props;
  return (
    <div style={styles.container} className="article-media">
      <div style={styles.imageContainer}>
        <ArticleMedia
          {...props}
          isBottomContainerVisible={false}
          containerStyles={containerStyles}
          videoStyles={videoStyles}
        />
      </div>
      <ArticleTitleAndText {...props} title={props.titleAndDescription.title} />
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
    // justifyContent: "center",
  },
  imageContainer: {
    position: "relative",
    borderRadius: "0.5rem",
    overflow: "hidden",
  },
};

export default SelectedArticleGalleryItem;
