import { ISectionWithContactCards } from "../../../Interfaces/ISection";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import { StyleMode } from "../../../Utils/consts";
import useDeviceMode from "../../../Utils/useWindowDimensions";
import ContactCard from "../../UmbracoElements/ContactCard/ContactCard";
import Section from "../Section";

const SectionWithContactCards: React.FC<ISectionWithContactCards> = (props) => {
  const { contactCards, anchor } = props;
  const deviceMode = useDeviceMode();
  return (
    <>
      <Section {...props} />
      <div
        anchor-id={anchor}
        style={{
          ...styles.contactCardsContainer,
          justifyContent: deviceMode !== StyleMode.desktop ? "center" : "",
          flexWrap: deviceMode !== StyleMode.desktop ? "wrap" : "unset",
          gap: deviceMode === StyleMode.desktop ? "6.5rem" : "2.5rem",
        }}
      >
        {contactCards.map((contactCard, index) => (
          <ContactCard {...contactCard} key={index} />
        ))}
      </div>
    </>
  );
};

const styles: StyleSheet = {
  contactCardsContainer: {
    display: "flex",
    marginTop: "-8.5rem",
    position: "relative",
    zIndex: 1,
  },
};

export default SectionWithContactCards;
