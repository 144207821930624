import { t } from "i18next";
import { useRef, useState, useEffect } from "react";
import { IDatePickerForm } from "../../../Interfaces/IForm";
import DatePicker from "../../DatePicker/DatePicker";
import colors from "../../../Assets/Colors/Colors.json";
import fonts from "../../../Assets/Fonts/Fonts.json";
import { FormatDateYYYYMMDD } from "../../../Services/DateService";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import useDeviceMode from "../../../Utils/useWindowDimensions";
import { StyleMode } from "../../../Utils/consts";
import DatePickerMobileContainer from "../../DatePicker/DatePickerMobileContainer";
import fontSize from "../../../Assets/Fonts/FontsSizes.json";
import { IsMobile } from "../../../Services/DeviceService";

const DatePickerForm: React.FC<IDatePickerForm> = (props) => {
  const {
    icon,
    placeholder,
    date,
    onDateSelected,
    minDate,
    noMinDate = true,
    maxDate,
    isButton = false,
    onClick,
  } = props;

  const [_isDateVisible, _setIsDateVisible] = useState(false);
  const [_label, _setLabel] = useState(FormatDateYYYYMMDD(date));
  const [_datePickerMobileKey, _setDatePickerMobileKey] = useState(0);
  const datePickerRef = useRef<HTMLDivElement>(null);
  const datePickerCapsuleRef = useRef<HTMLDivElement>(null);
  const deviceMode = useDeviceMode();

  const handleDatePickerSelect = (date: Date) => {
    _setIsDateVisible(false);
    _setLabel(FormatDateYYYYMMDD(date));
    onDateSelected(date);
  };

  useEffect(() => {
    _setLabel(FormatDateYYYYMMDD(date));
  }, [date]);

  const checkMouseClickOutside = (e: any) => {
    if (datePickerRef.current && !datePickerRef.current.contains(e.target)) {
      _setIsDateVisible(false);
    } else {
      _setIsDateVisible(true);
    }
  };

  useEffect(() => {
    if (deviceMode === StyleMode.desktop) {
      document.addEventListener("mousedown", checkMouseClickOutside);
      return () => {
        document.removeEventListener("mousedown", checkMouseClickOutside);
      };
    }
  }, []);

  return (
    <div
      tabIndex={!IsMobile() ? 0 : -1}
      ref={datePickerRef}
      role={"button"}
      aria-label={`${t("TimeCapsuleButton", {
        fulldate: _label,
      })}`}
      style={{
        ...styles.container,
        width: deviceMode === StyleMode.desktop ? "11.6875rem" : "100%",
        border: `0.0625rem solid ${
          _isDateVisible ? colors.darkBlue : colors.pastelGray
        }`,
      }}
      onClick={() => {
        isButton && onClick && onClick();
        const closeButton = document.getElementById(
          "datePickerMobileModalClose"
        );
        if (
          deviceMode !== StyleMode.desktop &&
          !document.activeElement?.contains(closeButton)
        ) {
          _setIsDateVisible(true);
        }
      }}
    >
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
        }}
      ></div>
      <img src={icon && icon}></img>
      <div
        style={{
          ...styles.value,
          marginInlineStart: "1rem",
        }}
      >
        {placeholder
          ? placeholder
          : _label
          ? _label.split("-").reverse().join(".")
          : placeholder}
      </div>
      {_isDateVisible && (
        <div style={styles.datePickerContainer} ref={datePickerCapsuleRef}>
          <DatePicker
            onChange={handleDatePickerSelect}
            selectedDate={_label ? _label : FormatDateYYYYMMDD(new Date())}
            minDate={minDate ? minDate : new Date()}
            noMinDate
            showMonthDropdown
            showYearDropdown
            showDisabledMonthNavigation
            maxDate={maxDate}
          />
        </div>
      )}
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "18.6rem",
    width: "100%",
    height: "2.5rem",
    borderRadius: "1.25rem",
    gap: ".3rem",
    padding: "0 .5rem",
    position: "relative",
    cursor: "pointer",
  },
  value: {
    width: "80%",
    fontSize: fontSize.Text,
    color: colors.pasterlGrayDark,
    letterSpacing: "0.043125rem",
    fontFamily: fonts.FbReformaRegular,
  },
  icon: {
    backgroundRepeat: "no-repeat",
  },
  datePickerContainer: {
    position: "absolute",
    backgroundColor: colors.whiteTransparent,
    zIndex: "1",
    marginInlineStart: "10rem",
    marginInlineEnd: "10rem",
    top: "3rem",
    border: `.1rem solid ${colors.whiteSmoke}`,
    borderRadius: ".7rem",
  },
};

export default DatePickerForm;
