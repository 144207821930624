import { ISection } from "../../Interfaces/ISection";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import colors from "../../Assets/Colors/Colors.json";
import fonts from "../../Assets/Fonts/Fonts.json";
import fontSize from "../../Assets/Fonts/FontsSizes.json";
import { StyleMode } from "../../Utils/consts";
import useDeviceMode from "../../Utils/useWindowDimensions";
import TitleSplitted from "../UmbracoElements/TitleSplitted/TitleSplitted";

const Section: React.FC<ISection> = (props) => {
  const {
    firstTitle,
    secondTitle,
    titlesColor,
    body,
    backgroundColor,
    image,
    anchor,
  } = props;

  const deviceMode = useDeviceMode();
  return (
    <div
      anchor-id={anchor}
      style={{
        ...styles.container,
        backgroundColor: `#${backgroundColor}`,
        justifyContent:
          deviceMode !== StyleMode.desktop ? "start" : "space-evenly",
      }}
    >
      <TitleSplitted
        firstTitle={firstTitle}
        secondTitle={secondTitle}
        customH1Styles={{
          color: `#${titlesColor}`,
          padding: deviceMode !== StyleMode.desktop ? "0 2rem" : "",
          maxWidth: "",
          alignItems: "",
          width: "",
        }}
        customFirstTitleStyles={{
          fontFamily: fonts.FbReformaLight,
          fontSize: "2.7rem",
        }}
        customSecondTitleStyles={{
          fontFamily: fonts.FbReformaMedium,
        }}
        isMobileOneLine={false}
      />
      <div
        style={{
          ...styles.backgroundImage,
          backgroundImage: `url(${image._url})`,
          top: deviceMode === StyleMode.desktop ? 0 : "",
          left: deviceMode === StyleMode.desktop ? 0 : "-10rem",
          bottom: deviceMode !== StyleMode.desktop ? "-10rem" : "unset",
        }}
      ></div>
      <p
        style={{
          ...styles.paragraph,
          padding: deviceMode !== StyleMode.desktop ? "0 2rem" : "",
        }}
        dangerouslySetInnerHTML={{ __html: body }}
      ></p>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    display: "flex",
    justifyContent: "space-around",
    width: "100%",
    minHeight: "25.75rem",
    alignItems: "center",
    position: "relative",
    flexWrap: "wrap",
  },
  title: {
    fontSize: fontSize.PageTitle,
    lineHeight: "3rem",
    color: colors.darkBlue,
    zIndex: 1,
    position: "relative",
  },
  backgroundImage: {
    position: "absolute",
    left: 0,
    width: "100%",
    height: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
  },
  paragraph: {
    maxWidth: "48.4rem",
    width: "100%",
    fontSize: fontSize.Paragraph,
    lineHeight: "1.75rem",
    zIndex: 1,
  },
};

export default Section;
