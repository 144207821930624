import axios from "axios";
import Store from "../Redux/Store/Store";
import {
  setErrorStatus,
  setErrorStatusUpdateClientNewTenders,
} from "../Auctions/src/Redux/Actions/Auctions/AuctionsActions";
import Config from "../Configuration/server";
import { IAuctionsSystemAuctions } from "../Auctions/src/Interfaces_tenders/IAuctionsSystemDetails";
import {
  IAuctionsSystemExemptionNotices,
  IAuctionsSystemSingleSupplier,
} from "../Interfaces/IAuctionsSystemDetails";

export const headers = {
  "Ocp-Apim-Subscription-Key": Config["OCP_APIM_SUBSCRIPTION_KEY"]!,
  "Access-Control-Allow-Origin": "*",
  "Content-Type": "application/json",
};

const COMMON = `${Config.BASE_URL}${Config.COMMON}`;

export const GetTypes = async () => {
  try {
    const res = await axios(`${COMMON}Tenders/GetTenderTypes`, {
      method: "GET",
      headers,
    });
    return res.data;
  } catch (error) {
    Store.dispatch(setErrorStatus(true));
  }
};

export const GetMeetings = async () => {
  try {
    const res = await axios(`${COMMON}Tenders/GetMeetings`, {
      method: "GET",

      headers,
    });
    return res.data;
  } catch (error) {
    Store.dispatch(setErrorStatus(true));
  }
};
type ITYPE = 1 | 2 | 3 | 10; // 1 = OPEN, 2 =  CLOSE, 3 = Results, 10 = GetALL
export const GetAuctionsByType = async (type: ITYPE) => {
  try {
    const res = await axios(`${COMMON}Tenders/GetTendersByType?type=${type}`, {
      method: "GET",

      headers,
    });
    const result = res.data.result;
    if (type === 3) {
      const filterAuction: IAuctionsSystemAuctions[] = res.data.result;
      filterAuction.map((item) => {
        if (item.winningSupplier !== ``) result.push(item);
      });
    }
    return result;
  } catch (error) {
    Store.dispatch(setErrorStatus(true));
  }
};

export const GetSingleSupplier = async () => {
  try {
    const res = await axios(`${COMMON}Tenders/GetSingleSupplier`, {
      method: "GET",

      headers,
    });

    const now = new Date();
    const twelveMonthsAgo = new Date();
    twelveMonthsAgo.setFullYear(now.getFullYear() - 1);

    const filteredItems = res.data.result.filter(
      (item: IAuctionsSystemSingleSupplier) => {
        const itemDate = new Date(item.decisionDate);
        return itemDate >= twelveMonthsAgo && itemDate <= now;
      }
    );

    return filteredItems;
  } catch (error) {
    Store.dispatch(setErrorStatus(true));
  }
};

export const GetExemptionNotices = async () => {
  try {
    const res = await axios(`${COMMON}Tenders/GetExemptionNotices`, {
      method: "GET",

      headers,
    });

    const now = new Date();
    const twelveMonthsAgo = new Date();
    twelveMonthsAgo.setFullYear(now.getFullYear() - 1);

    const filteredItems = res.data.result.filter(
      (item: IAuctionsSystemExemptionNotices) => {
        const itemDate = new Date(item.publishDate);
        return itemDate >= twelveMonthsAgo && itemDate <= now;
      }
    );

    return filteredItems;
  } catch (error) {
    Store.dispatch(setErrorStatus(true));
  }
};

export interface IPostEmailList {
  mails: string;
  name: string;
  tendersType?: boolean;
  exempId?: string;
  tenderId?: string;
  singleId?: string;
  planningAndRatesType?: boolean;
  exemptionNoticesType?: boolean;
  singleSupplierType?: boolean;
  domain?: number;
  typeOfTender?: number;
  page?: number;
  step?: number;
}
export const PostEmailList = async (data: IPostEmailList) => {
  try {
    const res = await axios(`${COMMON}Tenders/UpdateMailingList`, {
      method: "POST",
      headers,
      data: data,
    });
    return res;
  } catch (error) {
    Store.dispatch(setErrorStatusUpdateClientNewTenders(true));
    return Promise.reject(error);
  }
};

export const RemoveEmailList = async (mailAdress: string) => {
  try {
    const res = await axios(
      `${COMMON}Tenders/RemoveSingleMailFromList?mail=${mailAdress}`,
      {
        method: "PUT",
        headers,
      }
    );

    return res;
  } catch (error) {
    Store.dispatch(setErrorStatus(true));
    return Promise.reject(error);
  }
};

export const GetMailingList = async () => {
  try {
    const res = await axios(`${COMMON}Tenders/GetMailingLists`, {
      method: "GET",

      headers,
    });
    return res.data.result;
  } catch (error) {
    Store.dispatch(setErrorStatus(true));
  }
};
