import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IModal, IModalBottom } from "../../Interfaces/IModal";

export interface ModalState {
  modal: IModal;
  modalBottom:IModalBottom
}

const initialState: ModalState = {
  modal: {
    modalStyle: {},
    closeButtonType: "external",
    isOverlayClick: true,
    isYesNoModal: false,
    childrenComponent: {},
    position: undefined,
    maxWidth:"76rem",
    visible: false,
    innerWidth:"100%",
    onClose: undefined,
    elementFocusAfterClose: undefined
  },
  modalBottom: {
    data:{},
    visible: false,
    onClose: undefined,
  },
};

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    openModalBottom: (state, action: PayloadAction<IModalBottom>) => {
      state.modalBottom = action.payload;
    },
    openModal: (state, action: PayloadAction<IModal>) => {
      state.modal = action.payload;
    },
    closeModal: (state, action: PayloadAction<IModal>) => {
      state.modal = action.payload;
    },
  },
});

export const { openModal ,openModalBottom} = modalSlice.actions;

export default modalSlice.reducer;
