import { ICookiesSettingsModal } from "../../../../Interfaces/ICookiesModal";
import { StyleSheet } from "../../../../Interfaces/IStyleSheet";
import colors from "../../../../Assets/Colors/Colors.json";
import fonts from "../../../../Assets/Fonts/Fonts.json";
import Images from "../../../../Data/Images/Images";
import i18n from "../../../../Services/i18n";
import { t } from "i18next";
import { Fragment, useRef } from "react";
import { useState, useEffect } from "react";
import Config from "../../../../Configuration/server";
import fontSize from "../../../../Assets/Fonts/FontsSizes.json";
import { IsMobile } from "../../../../Services/DeviceService";

const CookiesSettingsModalMobile: React.FC<ICookiesSettingsModal> = (props) => {
  const { onClose, onButtonClick, cookies } = props;
  const [_cookiesToggleValue, _setCookiesToggleValue] = useState(false);
  const [_googleAnalyticsValue, _setGoogleAnalyticsValue] = useState(true);
  const settingsModalRef = useRef<HTMLImageElement>(null);
  const closeButtonRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    const cookieString = document.cookie;
    const cookieName = `${Config.BASE_LOCALSTORAGE_NAME}_Cookies_Approve`;
    const cookie = cookieString
      .split("; ")
      .find((cookie) => cookie.includes(cookieName));
    if (cookie) {
      const result = cookie.split("=")[1];
      if (result === "true") {
        _setCookiesToggleValue(true);
      } else if (result === "false") {
        _setCookiesToggleValue(false);
      }
    } else {
      _setCookiesToggleValue(false);
    }

    const googleAnalyticsCookieName = `${Config.BASE_LOCALSTORAGE_NAME}_Google_Analytics_Enabled`;
    const googleAnalytics = cookieString
      .split("; ")
      .find((cookie) => cookie.includes(googleAnalyticsCookieName));
    if (googleAnalytics) {
      const result = googleAnalytics.split("=")[1];
      if (result === "true") {
        _setGoogleAnalyticsValue(true);
      } else if (result === "false") {
        _setGoogleAnalyticsValue(false);
      }
    } else {
      _setGoogleAnalyticsValue(true);
    }
  }, []);

  const handleConfirm = () => {
    onButtonClick({
      cookiesToggleValue: _cookiesToggleValue,
      googleAnalyticsValue: _googleAnalyticsValue,
    });
  };
  return (
    <Fragment>
      <div style={styles.modalOverflow}>
        <div
          style={styles.modalCard}
          role="dialog"
          aria-modal="true"
          tabIndex={!IsMobile() ? 0 : -1}
          ref={settingsModalRef}
        >
          <img
            src={Images.closeButton}
            ref={closeButtonRef}
            id={"cookiesSettingsClose"}
            role={"button"}
            aria-label={t("Close")}
            tabIndex={!IsMobile() ? 0 : -1}
            style={{
              ...styles.closeIcon,
              left: i18n.dir() === "rtl" ? ".25rem" : "",
              right: i18n.dir() === "ltr" ? ".25rem" : "",
            }}
            onClick={() => {
              onClose();
            }}
            onKeyPressCapture={() => {
              onClose();
            }}
          ></img>
          <div style={styles.modalImageCircle}>
            <img
              src={Images.cookie}
              style={{
                ...styles.modalImage,
              }}
              alt={``}
            />
          </div>
          <div style={styles.modalBody}>
            <div style={styles.modalInfoContainer}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={styles.information}>
                  <h1 style={styles.title}>{cookies?.settingsTitle}</h1>
                  <div style={styles.settings}>
                    <div style={styles.settingsRow}>
                      <div>{cookies?.settingsEnableCookies}</div>
                      <label className="switch">
                        <input
                          tabIndex={!IsMobile() ? 0 : -1}
                          type="checkbox"
                          checked={_cookiesToggleValue}
                          onChange={(e) => {
                            _setCookiesToggleValue(e.target.checked);
                          }}
                        />
                        <span className="slider round" />
                      </label>
                    </div>
                    <div style={styles.settingsRow}>
                      <div>{cookies?.settingsEnableGoogleAnalytics}</div>
                      <label className="switch">
                        <input
                          tabIndex={!IsMobile() ? 0 : -1}
                          type="checkbox"
                          checked={_googleAnalyticsValue}
                          onChange={(e) => {
                            _setGoogleAnalyticsValue(e.target.checked);
                          }}
                        />
                        <span className="slider round" />
                      </label>
                    </div>
                  </div>
                  <div style={styles.buttons}>
                    <button style={styles.acceptButton} onClick={handleConfirm}>
                      {t("Confirm")}
                    </button>
                    <button
                      style={styles.refuseButton}
                      onClick={() => onClose()}
                      onKeyDown={(e) => {
                        if (e.key === "Tab") {
                          if (closeButtonRef.current) {
                            e.stopPropagation();
                            e.preventDefault();
                            closeButtonRef.current.focus();
                          }
                        }
                      }}
                    >
                      {t("Cancel")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const styles: StyleSheet = {
  container: {
    display: "flex",
    flexWrap: "wrap",
    gap: "4rem",
    width: "80%",
    maxWidth: "76rem",
  },
  modalOverflow: {
    display: "flex",
    justifyContent: "center",
    top: "0",
    position: "fixed",
    width: "100%",
    height: "100%",
    backgroundColor: colors.modal,
    zIndex: "9999999999",
  },
  modalCard: {
    display: "flex",
    justifyContent: "center",
    position: "absolute",
    width: "80%",
    backgroundColor: colors.white,
    borderRadius: "13px 13px 288px 288px / 13px 13px 43px 43px",
    top: "6rem"
  },
  buttons: {
    display: "flex",
    gap: "2rem",
    justifyContent: "center"
  },
  information: {
    fontFamily: fonts.FbReformaRegular,
    fontSize: fontSize.Paragraph,
    lineHeight: "1.75rem",
    letterSpacing: "0.03875rem",
    color: colors.darkBlue,
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
  },
  acceptButton: {
    borderRadius: "1rem",
    backgroundColor: colors.darkBlue,
    border: ".1rem solid transparent",
    color: colors.white,
    fontSize: fontSize.Paragraph,
    minWidth: "10rem",
    cursor: "pointer",
    fontFamily: fonts.FbReformaMedium,
  },
  refuseButton: {
    borderRadius: "1rem",
    backgroundColor: colors.white,
    border: `.1rem solid ${colors.darkBlue}`,
    color: colors.darkBlue,
    fontSize: fontSize.Paragraph,
    minWidth: "10rem",
    cursor: "pointer",
    fontFamily: fonts.FbReformaMedium,
  },
  modalImageCircle: {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "5rem",
    height: "5rem",
    backgroundColor: colors.white,
    top: "1rem",
    borderRadius: "50%",
    transform: "translateY(-3.5rem)",
    boxShadow: "-1px 1px 33px 1px rgb(64 60 67 / 40%)",
  },
  modalImage: {
    position: "absolute",
    maxWidth: "50%",
  },
  modalBody: {
    // position: "absolute",
    top: "10%",
    maxHeight: "80%",
    overflow: "auto",
    display: "flex",
    padding: "5rem .5rem",
    flexDirection: "column",
  },
  modalInfoContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  closeIcon: {
    position: "absolute",
    top: "0",
    cursor: "pointer",
  },
  link: {
    color: colors.oldLavender,
    cursor: "pointer",
    textDecoration: "underline",
  },
  settings: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  settingsRow: {
    display: "flex",
    gap: "1.5rem",
    justifyContent: "space-between",
    fontSize: fontSize.FooterItem,
  },
  title: {
    fontFamily: fonts.FbReformaMedium,
    fontSize: fontSize.H1,
    letterSpacing: "0.045rem",
  },
};
export default CookiesSettingsModalMobile;
