import { IArticleWithMedia } from "../../../../Interfaces/IMagazinePage";
import { StyleMode } from "../../../../Utils/consts";
import useDeviceMode from "../../../../Utils/useWindowDimensions";

const ArticleVideo: React.FC<
  IArticleWithMedia & {
    videoRef?: React.RefObject<HTMLVideoElement>;
    styles?: React.CSSProperties;
  }
> = (props) => {
  const { media, videoRef, styles } = props;

  const deviceMode = useDeviceMode();

  return (
    <>
      <video
        width="100%"
        height="100%"
        controls
        style={{
          maxHeight: "27.25rem",
          borderRadius: deviceMode === StyleMode.desktop ? "7px" : "",
          objectFit: "cover",
          ...styles,
        }}
        ref={videoRef}
      >
        <source src={media._url} type="video/mp4"></source>
      </video>
    </>
  );
};

export default ArticleVideo;
