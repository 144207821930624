import { t } from "i18next";
import { useDispatch } from "react-redux";
import { ICarrerButton } from "../../../../Interfaces/ICareers";
import { openModal } from "../../../../Redux/Slices/modalSlice";
import { IsMobile } from "../../../../Services/DeviceService";
import InfoIframe from "../../../Modal/InfoIframe";

const Button: React.FC<ICarrerButton> = (props) => {
  const { text, href, backgroundColor, style } = props;
  const dispatch = useDispatch();
  return (
    <button
      aria-label={t("LinkOpenExternalSite", {
        text,
      })}
      style={{ ...style, cursor: "pointer" }}
      onClick={() => {
        // dispatch(
        //   openModal({
        //     visible: true,
        //     innerWidth:"100%",
        //     closeButtonType: "internal",
        //     childrenComponent: <InfoIframe href={href} />,
        //   })
        // );
        window.open(`${href}`, `_blank`);
      }}
      role={`link`}
      tabIndex={!IsMobile() ? 0 : -1}
    >
      {text}
    </button>
  );
};

export default Button; //
