import { t } from "i18next";
import { Fragment } from "react";
import ReactModal from "react-modal";
import { useNavigate } from "react-router-dom";
import colors from "../../../../Assets/Colors/Colors.json";
import fonts from "../../../../Assets/Fonts/Fonts.json";
import Images from "../../../../Data/Images/Images";
import { StyleSheet } from "../../../../Interfaces/IStyleSheet";
import { IGuidelinesAndRatesSearchResults } from "../../../../Interfaces/IUmbraco";
import { FormatDateYYYYMMDD_Dots } from "../../../../Services/DateService";
import i18n from "../../../../Services/i18n";
import { StyleMode } from "../../../../Utils/consts";
import useDeviceMode from "../../../../Utils/useWindowDimensions";
import FileViewer from "../../../FileViewer/FileViewer";
import { useState } from "react";
import { DownloadFilesAsZipFile } from "../../../../Services/FilesToZipService";
import fontSize from "../../../../Assets/Fonts/FontsSizes.json";
import { IsMobile } from "../../../../Services/DeviceService";
import Config from "../../../../Configuration/server";

const SearchResultsGuidelinesAndRatesItem: React.FC<
  IGuidelinesAndRatesSearchResults
> = (props) => {
  const { Documentation, Subject, UpdateDate, SerialNumber } = props;
  const navigate = useNavigate();
  const deviceMode = useDeviceMode();
  const [_isModalOpen, _setIsModalOpen] = useState(false);

  const link = `guidelinesandrates`;
  const fullLink = `${window.location.href.split("/?")[0]}/?page=${link}`;

  const openModal = () => {
    if (Documentation[0].DocName.includes(".rar")) {
      window.open(
        `${Config.TENDERS_FILES}${Documentation[0].DocName}`,
        "_blank"
      );
    } else {
      if (deviceMode === StyleMode.desktop) {
        _setIsModalOpen(true);
      } else {
        DownloadFilesAsZipFile(
          [
            {
              filename: Subject,
              document: {
                _url:
                  `${Config.TENDERS_FILES}${Documentation[0].DocName}` || "",
              },
            },
          ],
          Subject
        );
      }
    }
  };

  const redirectToPage = (page?: string) => {
    page && navigate(`?page=${page}`);
  };

  const getFileIcon = () => {
    if (Documentation[0].DocName) {
      if (Documentation[0].DocName.includes(".pdf")) {
        return Images.pdfIcon;
      }
      if (Documentation[0].DocName.includes(".xls")) {
        return Images.xlsIcon;
      }
      if (Documentation[0].DocName.includes(".doc")) {
        return Images.wordFile;
      }
      if (Documentation[0].DocName.includes(".rar")) {
        return Images.rarIcon;
      }
    }
    return Images.pdfIcon;
  };

  return (
    <Fragment>
      <div style={styles.searchResultItem}>
        {deviceMode === StyleMode.desktop && (
          <div style={styles.iconContainer}>
            <img src={Images.documentIcon}></img>
          </div>
        )}
        <div style={styles.body}>
          <div>
            <span
              style={styles.title}
              role={"link"}
              tabIndex={!IsMobile() ? 0 : -1}
              onClick={() =>
                deviceMode === StyleMode.desktop && redirectToPage(link)
              }
            >
              {Subject}
            </span>
          </div>
          {deviceMode === StyleMode.desktop && (
            <div style={styles.link}>{fullLink}</div>
          )}

          <div>
            {t("SerialNumber", {
              serialNumber: SerialNumber,
            })}
          </div>

          <Fragment>
            <div style={styles.itemsSideLineContainer}>
              <div style={styles.itemSideLine}>
                <div style={styles.dateTitle}>{t("PublishDate")}</div>
                <div style={styles.dateFormat}>
                  {FormatDateYYYYMMDD_Dots(new Date(UpdateDate))}
                </div>
              </div>
              {Documentation[0] && (
                <div style={styles.itemSideLine}>
                  <div style={styles.dateTitle}>{t("Document")}</div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: ".25rem",
                    }}
                    onClick={openModal}
                  >
                    <img
                      src={getFileIcon()}
                      width={25}
                      alt={``}
                      height={`auto`}
                    />
                    <div
                      style={styles.fileName}
                      tabIndex={!IsMobile() ? 0 : -1}
                    >
                      {Documentation[0].DocDisplay}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Fragment>
        </div>
      </div>
      <ReactModal
        isOpen={_isModalOpen}
        ariaHideApp={false}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            width: "85%",
            bottom: "auto",
            height: "50rem",
            transform: "translate(-50%, -50%)",
            border: "none",
            padding: "none",
            position: "absolute",
            maxHeight: "100%",
            overflow: "visible",
          },
          overlay: {
            backgroundColor: colors.modal,
            zIndex: "99999",
            overflow: "hidden",
          },
        }}
        parentSelector={() => document.querySelector("#root") || document.body}
        preventScroll={true}
      >
        <img
          src={Images.closeBG}
          style={{
            ...styles.closeMainModalIcon,
            right: i18n.dir() === "rtl" ? "1rem" : "",
            left: i18n.dir() === "ltr" ? "1rem" : "",
          }}
          onClick={() => {
            _setIsModalOpen(false);
          }}
        ></img>
        {Documentation[0] && (
          <FileViewer
            files={[
              {
                filename: Documentation[0].DocDisplay
                  ? Documentation[0].DocDisplay
                  : "Document",
                document: {
                  _url: `${Config.TENDERS_FILES}${Documentation[0].DocName}`,
                },
              },
            ]}
            title={Documentation[0].DocDisplay || "Document"}
            curFileName={Documentation[0].DocDisplay}
          />
        )}
      </ReactModal>
    </Fragment>
  );
};

const styles: StyleSheet = {
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
  },
  icon: {
    backgroundRepeat: "no-repeat",
    width: "5rem",
    height: "5rem",
  },
  title: {
    lineHeight: "1.75rem",
    letterSpacing: "0.039375rem",
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaMedium,
    fontSize: fontSize.UmbracoElementTitle,
    maxWidth: "55rem",
    cursor: "pointer",
  },
  link: {
    color: colors.nero,
    fontSize: fontSize.Text,
    letterSpacing: "0.029375rem",
  },
  iconText: {
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaRegular,
  },
  content: {
    fontFamily: fonts.FbReformaLight,
    lineHeight: "1.6875rem",
    fontSize: fontSize.Paragraph,
    letterSpacing: "0.038125rem",
  },
  searchResultItem: {
    display: "flex",
    gap: "2rem",
    paddingBottom: "1rem",
    paddingTop: "1.5rem",
  },
  iconContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "2.5rem",
  },
  body: {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
    maxWidth: "56rem",
    width: "100%",
  },
  itemsSideLineContainer: {
    display: "flex",
    alignItems: "center",
    paddingInline: "2rem",
    gap: "5rem",
    flex: 1,
  },
  itemSideLine: {
    borderRight: i18n.dir() === "rtl" ? `.1rem solid ${colors.black}` : "",
    borderLeft: i18n.dir() === "ltr" ? `.1rem solid ${colors.black}` : "",
    padding: "0 1rem",
  },
  dateTitle: {
    fontFamily: fonts.FbReformaRegular,
    color: colors.pasterlGrayDark,
  },
  fileName: {
    cursor: "pointer",
  },
  closeMainModalIcon: {
    position: "absolute",

    top: "1rem",
    cursor: "pointer",
  },
};

export default SearchResultsGuidelinesAndRatesItem;
