import { IPlayingPodcast, IPodcast } from "../../../../Interfaces/IUmbraco";
import colors from "../../../../Assets/Colors/Colors.json";
import fonts from "../../../../Assets/Fonts/Fonts.json";
import { useEffect, useRef, useState } from "react";
import useDeviceMode from "../../../../Utils/useWindowDimensions";
import { StyleSheet } from "../../../../Interfaces/IStyleSheet";
import { StyleMode } from "../../../../Utils/consts";
import Images from "../../../../Data/Images/Images";
import { SecondsToDurationFormat } from "../../../../Services/DateService";
import { t } from "i18next";
import fontSize from "../../../../Assets/Fonts/FontsSizes.json";
import { IsMobile } from "../../../../Services/DeviceService";

const PodcastControls: React.FC<IPlayingPodcast> = (props) => {
  const { media, onClickNext, onClickPrevious, length } = props;

  const [_currentTimeInSeconds, _setCurrentTimeInSeconds] = useState(0);
  const [_isPlaying, _setIsPlaying] = useState(false);
  const audioRef = useRef<HTMLAudioElement>(null);
  const deviceMode = useDeviceMode();

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.load();
      _setIsPlaying(false);
      _setCurrentTimeInSeconds(0);
    }
  }, [media]);

  const handlePlayPause = () => {
    if (audioRef.current) {
      if (audioRef.current.paused) {
        audioRef.current.play();
      } else {
        audioRef.current.pause();
      }
    }
  };

  const handleForwardSecondsTimeSkip = (seconds: number) => {
    if (audioRef.current) {
      audioRef.current.currentTime += seconds;
    }
  };

  const handleBackwardSecondsTimeSkip = (seconds: number) => {
    if (audioRef.current) {
      audioRef.current.currentTime -= seconds;
    }
  };

  return (
    <div>
      {media && (
        <div style={styles.audioControlsWrapper}>
          <div
            style={{
              ...styles.audioControls,
              gap: deviceMode === StyleMode.desktop ? "3.5rem" : "2rem",
            }}
          >
            <img
              width={14}
              src={Images.audioStart}
              style={{
                ...styles.audioStartIcon,
              }}
              onClick={() => onClickPrevious()}
              onKeyPressCapture={() => onClickPrevious()}
              tabIndex={!IsMobile() ? 0 : -1}
              role={`button`}
              aria-label={t("PreviousPodcast")}
            ></img>
            <img
              width={14}
              src={Images.audio10SecondsBack}
              style={{
                ...styles.audio10SecondsBackIcon,
              }}
              onClick={() => handleBackwardSecondsTimeSkip(10)}
              onKeyPressCapture={() => handleBackwardSecondsTimeSkip(10)}
              tabIndex={!IsMobile() ? 0 : -1}
              role={`button`}
              aria-label={t("BackwardSecondsTime")}
            ></img>
            <img
              width={14}
              src={_isPlaying ? Images.audioPauseIcon : Images.audioPlay}
              style={{
                ...styles.audioPlayIcon,
              }}
              aria-label={_isPlaying ? t("PauseBtn") : t("PlayBtn")}
              onClick={() => handlePlayPause()}
              onKeyPressCapture={() => handlePlayPause()}
              tabIndex={!IsMobile() ? 0 : -1}
            ></img>
            <img
              width={14}
              src={Images.audio30SecondsNext}
              style={{
                ...styles.audio30SecondsNextIcon,
              }}
              onClick={() => handleForwardSecondsTimeSkip(30)}
              onKeyPressCapture={() => handleForwardSecondsTimeSkip(30)}
              tabIndex={!IsMobile() ? 0 : -1}
              role={`button`}
              aria-label={t("ForwardSecondsTimeSkip")}
            ></img>
            <img
              width={14}
              src={Images.audioEnd}
              style={{
                ...styles.audioEndIcon,
              }}
              onClick={() => onClickNext()}
              onKeyPressCapture={() => onClickNext()}
              tabIndex={!IsMobile() ? 0 : -1}
              role={`button`}
              aria-label={t("NextPodcast")}
            ></img>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1.5rem",
            }}
          >
            <audio
              autoPlay
              preload="metadata"
              onTimeUpdate={(e) => {
                _setCurrentTimeInSeconds(e.currentTarget.currentTime);
              }}
              onPause={() => {
                _setIsPlaying(false);
              }}
              onPlay={() => {
                _setIsPlaying(true);
              }}
              ref={audioRef}
              controls
              controlsList="nodownload noplaybackrate noremoteplayback noplaybackrate"
            >
              <source src={media._url} />
            </audio>
            <div style={styles.duration}>
              <div>{length && length}</div>
              <div>{SecondsToDurationFormat(_currentTimeInSeconds)}</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "5rem",
  },
  podcastDetails: {
    gap: "5rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: ".5rem",
  },
  thumbnail: {
    width: "29rem",
    height: "16rem",
    borderRadius: "5%",
  },
  titleAndDescriptionContainer: {
    display: "flex",
    flexDirection: "column",
    paddingTop: "1.5rem",
    gap: ".25rem",
  },
  title: {
    color: colors.darkBlue,
    fontSize: fontSize.UmbracoElementTitle,
    letterSpacing: "0.039375rem",
    fontFamily: fonts.FbReformaMedium,
  },
  description: {
    color: colors.nero,
    fontFamily: fonts.FbReformaLight,
    letterSpacing: "0.038125rem",
    fontSize: fontSize.Paragraph,
    maxWidth: "46.75rem",
  },
  information: {
    display: "flex",
    flexDirection: "column",
    gap: ".5rem",
    alignItems: "center",
    paddingBottom: "4rem",
  },
  duration: {
    color: colors.darkBlue,
    letterSpacing: "0.038125rem",
    fontFamily: fonts.FbReformaRegular,
    fontSize: fontSize.UmbracoElementTitle,
    display: "flex",
    justifyContent: "space-between",
  },
  date: {
    color: colors.nero,
    fontSize: fontSize.Text,
    fontFamily: fonts.FbReformaLight,
    letterSpacing: "0.0275rem",
  },
  audioControlsWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "2.5rem",
  },
  audioControls: {
    display: "flex",
    flexDirection: "row-reverse",
    alignItems: "center",
    justifyContent: "space-around",
  },
  control: {
    cursor: "pointer",
  },
  playbackRateIcon: {
    backgroundRepeat: "no-repeat",
    width: "4.125rem",
    height: "2.125rem",
    cursor: "pointer",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  audioStartIcon: {
    cursor: "pointer",
  },
  audio10SecondsBackIcon: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    width: "2rem",
    height: "2rem",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: colors.darkBlue,
  },
  audioPlayIcon: {
    cursor: "pointer",
  },
  audio30SecondsNextIcon: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    width: "1.875rem",
    height: "2rem",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: colors.darkBlue,
  },
  audioEndIcon: {
    cursor: "pointer",
  },
};

export default PodcastControls;
