import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import colors from "../../../../../Assets/Colors/Colors.json";
import { StyleSheet } from "../../../Interfaces_tenders/IStyleSheet";
import Hamburger from "../../Hamburger/Hamburger";
import Images from "../../../../../Data/Images/Images";
import { IHeader } from "../../../Interfaces_tenders/IHeader";

import { useSwipeable } from "react-swipeable";
import i18n from "../../../../../Services/i18n";
import { IMenuItem } from "../../../Interfaces_tenders/IMenu";
import { IAuctionsSystemTypes } from "../../../Interfaces_tenders/IAuctionsSystemDetails";
import { t } from "i18next";

const HeaderMobile: React.FC<IHeader> = (props) => {
  const { headerContent } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  
  const currentPage = useSelector((state: any) => state.PageReducer);

  const _currentTypes = useSelector(
    (state: any) => state.TendersReducer
  ) as IAuctionsSystemTypes[];

  const menuItems: IMenuItem[] = [
    {
      redirectTo:
        "generalauctions&step=openauctions&auctionsExtrenal=true&isModalOpen=false",
      label:
        _currentTypes.length > 0
          ? t(_currentTypes.filter((t) => t.type === 3)[0].name)
          : "Tenders",
      isEnabled: "Yes",
      subMenus: [],
      isHamburger: "string",
      isColumn: true,
      onClick: () => _setIsHamburgerOpen(false),
      currentId: 0,
    },
    {
      redirectTo:
        "cargoauctions&step=openauctions&auctionsExtrenal=true&isModalOpen=false",
      label:
        _currentTypes.length > 0
          ? t(_currentTypes.filter((t) => t.type === 3)[1].name)
          : "Tenders",
      isEnabled: "Yes",
      subMenus: [],
      isHamburger: "string",
      isColumn: true,
      onClick: () => _setIsHamburgerOpen(false),
      currentId: 1,
    },
  ];
  const mouseSwipeHandlers = useSwipeable({
    trackMouse: true,
    onSwipedLeft: () => (i18n.dir() === "rtl" ? _setIsHamburgerOpen(true) : {}),
    onSwipedRight: () =>
      i18n.dir() === "ltr" ? _setIsHamburgerOpen(true) : {},
  });

  const [_isSearchVisible, _setIsSearchVisible] = useState(false);
  const [_isHamburgerOpen, _setIsHamburgerOpen] = useState(false);
  const [_menuClicked, _setMenuClicked] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    handleCloseSearch();
  }, [location]);

  useEffect(() => {
    if (_isSearchVisible) {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  }, [_isSearchVisible]);

  const handleOpenCloseHamburger = () => {
    _setMenuClicked(true);
    _setIsHamburgerOpen(!_isHamburgerOpen);
    const html = document.documentElement;
    if (html) {
      html.style.overflow = !_isHamburgerOpen ? "hidden" : "";
    }
  };

  /*const handleLogoClick = () => {
    dispatch(setOriginStation(undefined));
    dispatch(setDestinationStation(undefined));
    navigate("/");
  };*/

  const handleCloseSearch = () => {
    _setIsSearchVisible(false);
  };

  return (
    <Fragment>
    
    </Fragment>
  );
};

const styles: StyleSheet = {
  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    zIndex: "20",
    width: "100%",
    // top: "1.3rem",
  },
  menuContainer: {
    height: "3rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "90%",
  },
  menuItemsContainer: {
    display: "flex",
    marginInlineStart: "5rem",
    marginInlineEnd: "5rem",
    gap: "25px",
    color: colors.darkBlue,
  },
  swipeOpenMenuStyles: {
    position: "fixed",
    top: "0",
    width: "4rem",
    height: "100%",
    // border: "2px dashed gray",
    zIndex: "2",
  },
};

export default HeaderMobile;
