import { t } from "i18next";
import { ITrain } from "../Interfaces/ITrain";
import store from "store2";
import Config from "../Configuration/server";
import { GetStationsDictionary } from "../Utils/helpers";
import {
  FormatDateToISOStringWithTimezone,
  FormatDateYYYYMMDD_Dots,
  FormatHoursMinutes,
} from "./DateService";
import {
  ICalendarDataForAPI,
  ICalendarStructure,
} from "../Interfaces/ICalendar";
import i18n from "./i18n";
import moment from "moment-timezone";

const DEFAULT_SENDER_MAIL = "israelrailtender@gmail.com";

const GetStationNameById = (stationId: number) => {
  const allStations = store.session.get(
    `${Config.BASE_LOCALSTORAGE_NAME}stations`
  );
  const stationsDict = GetStationsDictionary(allStations[i18n.language]);
  let stationName = stationsDict[stationId].stationName
  return stationName;
};

export const GetCalendarInfo = (
  train: ITrain & { fromStation?: number; toStation?: number },
  email: string
): ICalendarStructure | undefined => {
  if (train.fromStation === undefined || train.toStation === undefined) {
    return undefined;
  }
  const eventName = t(`TravelCalander`).replace(`{{fromStation}}`,`${GetStationNameById(train.fromStation)}`).replace(`{{toStation}}`,`${GetStationNameById(train.toStation)}`)

  const eventMessage = `${t("date")}: ${FormatDateYYYYMMDD_Dots(
    new Date(train.departureTime)
  )} ${t("Hour", {
    time: FormatHoursMinutes(train.departureTime),
  })}`;
  const eventAdditionalInfo = `${t("Platform")} ${train.originPlatform}`;
  const eventTime = FormatHoursMinutes(train.departureTime);
  const eventDate = FormatDateYYYYMMDD_Dots(new Date(train.departureTime));

  return {
    eventName,
    eventMessage,
    email,
    eventDate,
    eventTime,
    eventAdditionalInfo,
  };
};

export const GetCalenderDataForAPI = (
  train: ITrain & { fromStation?: number; toStation?: number },
  emailAddress: string,
  systemParamsDictionary: { [key: string]: string }
): ICalendarDataForAPI | undefined => {
  if (train.fromStation === undefined || train.toStation === undefined) {
    return undefined;
  }

  const fromToMessage = t("TravelCalander", {
    fromStation: GetStationNameById(train.fromStation),
    toStation: GetStationNameById(train.toStation),
  });

  const dateTimeMessage = `${t("date")}: ${FormatDateYYYYMMDD_Dots(
    new Date(train.departureTime)
  )} ${t("Hour", {
    time: FormatHoursMinutes(train.departureTime),
  })}`;

  const platformMessage = `${t("Platform")} ${train.originPlatform}`;

  const senderMail =
    systemParamsDictionary["senderMail"] || DEFAULT_SENDER_MAIL;
  const message = `${fromToMessage}, ${dateTimeMessage}, ${platformMessage}`;
  const addresses = [
    {
      name: emailAddress,
      email: emailAddress,
    },
  ];
  const subject = `${fromToMessage}, ${platformMessage}`;

  const startTime = FormatDateToISOStringWithTimezone(
    moment(train.departureTime).tz("Israel").toDate()
  );
  const endTime = FormatDateToISOStringWithTimezone(
    moment(train.arrivalTime).tz("Israel").toDate()
  );

  const data = {
    senderMail,
    message,
    addresses,
    subject,
    startTime,
    endTime,
    translationKey: "calendar_routeplan",
    translationsParams: {
      originStation: GetStationNameById(train.fromStation),
      destinationStation: GetStationNameById(train.toStation),
      platform: train.originPlatform.toString()
    },
    language: i18n.language,
  };

  return data;
};

export const GetGoogleCalendarAddEventUrl = (
  train: ITrain & { fromStation?: number; toStation?: number }
) => {
  const data = GetCalendarInfo(train, "");
  if (data !== undefined) {
    const GOOGLE_CALENDAR_URL = "http://www.google.com/calendar/event";
    const departureStr = new Date(train.departureTime)
      .toISOString()
      .replace(/-|:|\.\d\d\d/g, "");
    const arrivalStr = new Date(train.arrivalTime)
      .toISOString()
      .replace(/-|:|\.\d\d\d/g, "");

    const google_calendar_params = {
      action: "TEMPLATE",
      text: data.eventName,
      dates: [departureStr, arrivalStr],
      details: `${data.eventName}<br>${data.eventMessage}<br>${data.eventAdditionalInfo}`,
      location: train.fromStation
        ? `${t("stationNavigation")} ${GetStationNameById(train.fromStation)}`
        : "",
      trp: false,
      sprop: "",
    };
    let url = `${GOOGLE_CALENDAR_URL}?action=${
      google_calendar_params.action
    }&text=${
      google_calendar_params.text
    }&dates=${google_calendar_params.dates.join("/")}&details=${
      google_calendar_params.details
    }&location=${google_calendar_params.location}&trp=${
      google_calendar_params.trp
    }&sprop=${google_calendar_params.sprop}`;
    return url;
  }
};

export const GetIOSCalendarAddEventUrl = (
  train: ITrain & { fromStation?: number; toStation?: number }
) => {
  const data = GetCalendarInfo(train, "");
  if (data !== undefined) {
    const IOS_CALENDAR_URL = "calshow://";
    const eventTitle = encodeURIComponent(data.eventName);
    const eventLocation = encodeURIComponent(
      train.fromStation
        ? `${t("stationNavigation")} ${GetStationNameById(train.fromStation)}`
        : ""
    );
    const eventStartDate = encodeURIComponent(
      new Date(train.departureTime).toISOString()
    );
    const eventEndDate = encodeURIComponent(
      new Date(train.arrivalTime).toISOString()
    );
    const url = `${IOS_CALENDAR_URL}?title=${eventTitle}&location=${eventLocation}&startDate=${eventStartDate}&endDate=${eventEndDate}`;
    return url;
  }
};
