import colors from "../../../Assets/Colors/Colors.json";
import fonts from "../../../Assets/Fonts/Fonts.json";
import fontSize from "../../../Assets/Fonts/FontsSizes.json";
import { IContactOption } from "../../../Interfaces/IContactOption";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import { StyleMode } from "../../../Utils/consts";
import useDeviceMode from "../../../Utils/useWindowDimensions";
import ContactItem from "../ContactItem/ContactItem";

const ContactOption: React.FC<IContactOption> = (props) => {
  const { firstTitle, secondTitle, contactItems, anchor } = props;
  const deviceMode = useDeviceMode();
  return (
    <div style={styles.container} anchor-id={anchor}>
      <div
        style={{
          ...styles.title,
          color: colors.darkBlue,
          padding: deviceMode !== StyleMode.desktop ? "0 2rem" : "",
          textAlign: deviceMode !== StyleMode.desktop ? "center" : "start",
          gap: deviceMode !== StyleMode.desktop ? "1.5rem" : "",
        }}
      >
        <span
          style={{
            fontFamily: fonts.FbReformaMedium,
            fontSize: deviceMode !== StyleMode.desktop ? fontSize.H1 : "",
            lineHeight: deviceMode !== StyleMode.desktop ? fontSize.H1 : "",
          }}
        >
          {firstTitle}
        </span>
        <span
          style={{
            fontFamily: fonts.FbReformaLight,
            fontSize:
              deviceMode !== StyleMode.desktop
                ? fontSize.UmbracoElementTitle
                : "",
            lineHeight:
              deviceMode !== StyleMode.desktop
                ? fontSize.UmbracoElementTitle
                : "",
          }}
        >
          {secondTitle}
        </span>
      </div>
      <div
        style={{
          ...styles.contactItemsContainer,
          flexDirection:
            deviceMode === StyleMode.desktop ? "row" : "column-reverse",
          padding: deviceMode !== StyleMode.desktop ? "0 3rem" : "",
        }}
      >
        {contactItems.map((item, index) => (
          <ContactItem key={index} {...item} />
        ))}
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    display: "flex",
    gap: "5rem",
    flexWrap: "wrap",
  },
  title: {
    display: "flex",
    flexDirection: "column",
  },
  contactItemsContainer: {
    display: "flex",
    gap: "1.5rem",
    flexWrap: "wrap",
  },
};

export default ContactOption;
