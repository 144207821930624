import colors from "../../Assets/Colors/Colors.json";
import fonts from "../../Assets/Fonts/Fonts.json";
import fontSize from "../../Assets/Fonts/FontsSizes.json";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import { StyleMode } from "../../Utils/consts";
import useDeviceMode from "../../Utils/useWindowDimensions";
import Breadcrumb from "../UmbracoElements/Breadcrumb";

interface ITopBanner {
  cover: string;
  title: string;
  link: string;
  isCardDisplay?: boolean
}
const TopBannerOld: React.FC<ITopBanner> = (props) => {
  const { cover, title, link, isCardDisplay } = props;
  const deviceMode = useDeviceMode();

  return (
    <div
      style={{
        ...styles.cover,
        background: cover && `30% 30% / cover url(${cover})`,
        height:  deviceMode === StyleMode.desktop ? "40vh" : "147.49px",

        // minHeight: isCardDisplay
        //   ? "17.18rem"
        //   : "14.25rem"
      }}
    >
      <div className="cover-color-blur">
        <div
          style={{
            ...styles.coverElements,
            width: deviceMode === StyleMode.desktop ? "80%" : "75%",
          }}
          className="container"
        >
          <div
            style={{
              ...styles.breadcrumb,
              paddingTop: deviceMode === StyleMode.desktop ? "2rem" : "3rem",
            }}
          >
            <Breadcrumb items={[{ text: title || "", link: link }]} />
          </div>
          <h1
            style={{
              ...styles.title,
              paddingTop: deviceMode === StyleMode.desktop ? "2rem" : "3rem",
            }}
          >
            {title}
          </h1>
        </div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  cover: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "relative",
    // minHeight: "14.25rem",
  },
  title: {
    color: colors.white,
    fontSize: fontSize.PageTitle,
    lineHeight: "3rem",
    letterSpacing: "0.05625rem",
    textShadow: `2px 2px ${colors.black}`,
    fontFamily: fonts.FbReformaMedium,
  },
  breadcrumb: {
    display: "flex",
    color: colors.white,
    fontSize: fontSize.Text,
    letterSpacing: "0.05625rem",
    fontFamily: fonts.FbReformaRegular,
    position: "absolute",
  },
  coverElements: {
    gap: "3rem",
    maxWidth: "76rem",
    margin: "auto",
    lineHeight: "2.5rem",
    padding: "1rem",
  },
  cardBorder: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  cardBody: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "3rem",
    padding: "2rem",
    backgroundColor: colors.white,
    borderRadius: "0.3125rem",
    boxShadow: "0 2px 10px 0 rgba(0, 0, 0, 0.15)",
    lineHeight: `1.6rem`,
  },
  cardBodyMobile: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "3rem",
    width: "100%",
    padding: 0,

  },
  body: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
    width: "100%",
    maxWidth: "90rem",
    paddingBottom: "5rem",
    marginTop: "3rem"
  },
  searchArea: {
    padding: "2.5rem",
    display: "flex",
    justifyContent: "center",
    gap: "1.5rem",
  },
  searchBox: {
    display: "flex",
    alignItems: "center",
    height: "2.5rem",
    borderRadius: "1.25rem",
    border: `1px solid ${colors.pastelGray}`,
    width: "90%",
  },
  searchIcon: {
    transform: "scale(.8)",
    // marginInline: "1rem",
  },
  clearIcon: {
    cursor: "pointer",
    margin: "0 .25rem",
    scale: ".5",
  },
  searchButton: {
    // width: "10rem",
    height: "2.5rem",
    backgroundColor: colors.darkBlue,
    border: `1px solid ${colors.darkBlue}`,
    borderRadius: "1.25rem",
    color: colors.white,
    fontFamily: fonts.FbReformaMedium,
    fontSize: fontSize.Text,
    letterSpacing: "0.029375rem",
    cursor: "pointer",
  },
  input: {
    flex: "10",
    fontFamily: fonts.FbReformaRegular,
    fontSize: fontSize.Text,
  },
  filters: {
    display: "flex",
    gap: "2rem",
  },
  noResults: {
    color: colors.gray,
    fontSize: fontSize.H1,
    letterSpacing: "0.039375rem",
    fontFamily: fonts.FbReformaMedium,
    maxWidth: "35rem",
    lineHeight: "2rem",
    padding: "1.45rem",
  },

  totalResultsSummery: {
    fontFamily: fonts.FbReformaMedium,
    fontSize: fontSize.FooterItem,
  },
};

export default TopBannerOld;
