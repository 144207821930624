import { Fragment } from "react";
import colors from "../../../../Assets/Colors/Colors.json";
import { IDirectorateMemberModal } from "../../../../Interfaces/IDirectorateMember";
import { StyleSheet } from "../../../../Interfaces/IStyleSheet";
import fonts from "../../../../Assets/Fonts/Fonts.json";
import fontSize from "../../../../Assets/Fonts/FontsSizes.json";

const DirectorateMemberModal: React.FC<IDirectorateMemberModal> = (props) => {
  const {
    fullname,
    role,
    image,
    description,
    birthYear,
    education,
    workExperience,
  } = props;

  return (
    <Fragment>
      <div
        style={{
          ...styles.container,
          display: "flex",
        }}
      >
        <div
          style={{
            ...styles.image,
            backgroundImage: `url(${image ? image._url : ""})`,
            backgroundPosition: `center`,
          }}
        ></div>

        <div style={styles.memberInfo}>
          <div
            style={{
              maxHeight: "100%",
              overflow: "auto",
            }}
          >
            <div style={styles.header}>
              <h2 style={styles.title}>{fullname}</h2>
              <h3 style={styles.role}>{role}</h3>
              <div
                style={styles.description}
                dangerouslySetInnerHTML={{ __html: description }}
              ></div>
            </div>
            <div style={styles.birthYearContainer}>
              <div
                style={{
                  fontFamily: fonts.FbReformaMedium,
                  marginBottom: `0.25rem`,
                }}
              >
                {birthYear.title}
              </div>
              <div
                dangerouslySetInnerHTML={{ __html: birthYear.description }}
              ></div>
            </div>
            <div style={styles.educationContainer}>
              <div
                style={{
                  fontFamily: fonts.FbReformaMedium,
                  marginBottom: `0.25rem`,
                }}
              >
                {education.title}
              </div>
              <div
                dangerouslySetInnerHTML={{ __html: education.description }}
              ></div>
            </div>
            <div style={styles.workExperienceContainer}>
              <div
                style={{
                  fontFamily: fonts.FbReformaMedium,
                  marginBottom: `0.25rem`,
                }}
              >
                {workExperience.title}
              </div>
              <div
                dangerouslySetInnerHTML={{ __html: workExperience.description }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const styles: StyleSheet = {
  container: {
    justifyContent: "center",
    gap: "2rem",
    height: "100%",
    position: "relative"
  },
  memberInfo: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    flex: "1",
    // margin: "2rem",
  },
  header: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  title: {
    fontSize: fontSize.H1,
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaMedium,
    letterSpacing: "0.065rem",
    marginBottom: "0",
    lineHeight: "2rem"
  },
  role: {
    fontSize: fontSize.UmbracoElementTitle,
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaMedium,
    letterSpacing: "0.0425rem",
    marginBottom: "0",
  },
  description: {
    fontSize: fontSize.FooterItem,
    fontFamily: fonts.FbReformaLight,
    letterSpacing: "0.034375rem",
    maxWidth: "33rem",
    marginBottom: "1rem",
  },
  birthYearContainer: {
    fontSize: fontSize.FooterItem,
    fontFamily: fonts.FbReformaLight,
    letterSpacing: "0.034375rem",
    maxWidth: "33rem",
    marginBottom: "1rem",
  },
  educationContainer: {
    fontSize: fontSize.FooterItem,
    fontFamily: fonts.FbReformaLight,
    letterSpacing: "0.034375rem",
    maxWidth: "33rem",
    marginBottom: "1rem",
  },
  workExperienceContainer: {
    fontSize: fontSize.FooterItem,
    fontFamily: fonts.FbReformaLight,
    letterSpacing: "0.034375rem",
    maxWidth: "33rem",
    marginBottom: "1rem",
  },
  image: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    flex: "1",
  },
  imageMobile: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    width: "25rem",
    height: "27rem",
    borderRadius: "50%",
  },
  closeIcon: {
    position: "absolute",
    top: "1rem",
    cursor: "pointer",
  },
};

export default DirectorateMemberModal;
