import colors from "../../../Assets/Colors/Colors.json";
import fonts from "../../../Assets/Fonts/Fonts.json";
import fontSize from "../../../Assets/Fonts/FontsSizes.json";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import { ITitleSplitted } from "../../../Interfaces/ITitleSplitted";
import { StyleMode } from "../../../Utils/consts";
import useDeviceMode from "../../../Utils/useWindowDimensions";

const TitleSplitted: React.FC<ITitleSplitted> = (props) => {
  const {
    firstTitle,
    secondTitle,
    customH1Styles,
    customFirstTitleStyles,
    customSecondTitleStyles,
    isMobileOneLine = true,
  } = props;
  const deviceMode = useDeviceMode();
  return (
    <h1
      style={{
        ...styles.title,
        color: colors.darkBlue,
        ...customH1Styles,
      }}
    >
      <span
        style={{
          fontFamily:
            deviceMode === StyleMode.desktop
              ? fonts.FbReformaLight
              : fonts.FbReformaMedium,
          fontSize: "2.7rem",
          ...customFirstTitleStyles,
        }}
      >
        {firstTitle}
      </span>
      {deviceMode === StyleMode.desktop ? (
        <br />
      ) : isMobileOneLine ? (
        " "
      ) : (
        <br />
      )}
      <span
        style={{
          fontFamily: fonts.FbReformaMedium,
          ...customSecondTitleStyles,
        }}
      >
        {secondTitle}
      </span>
    </h1>
  );
};

const styles: StyleSheet = {
  title: {
    fontSize: fontSize.PageTitle,
    lineHeight: "3rem",
    color: colors.darkBlue,
    zIndex: 1,
    position: "relative",
    width: "100%",
    maxWidth: "76rem",
    paddingInlineStart: "4rem 0",
  },
  infoContainer: {
    width: "100%",
    maxWidth: "76rem",
  },
};

export default TitleSplitted;
