import colors from "../../Assets/Colors/Colors.json";
import fonts from "../../Assets/Fonts/Fonts.json";
import Images from "../../Data/Images/Images";
import { IAnyElement, IStaticModal } from "../../Interfaces/IStaticPage";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import i18n from "../../Services/i18n";
import { StyleMode } from "../../Utils/consts";
import useDeviceMode from "../../Utils/useWindowDimensions";
import AllElements from "../UmbracoElements/AllElements";
import BaseElement from "../UmbracoElements/BaseElement";
import fontSize from "../../Assets/Fonts/FontsSizes.json";

const UpdateModal: React.FC<IStaticModal> = (props) => {
  const {
    id,
    title,
    coverImage,
    elements,
    breadcrumb,
    onClose,
    isCard = false,
  } = props;
  const deviceMode = useDeviceMode();
  let isCardDisplay = isCard;

  const GetUmbracoElement: React.FC<IAnyElement & any> = (props) => {
    switch (props.contentTypeAlias) {
      case "baseElement":
        return (
          <BaseElement
            {...props}
            containerStyle={{
              maxWidth: "76rem",
              width: "80%",
              fontSize: fontSize.Paragraph,
              fontFamily: fonts.FbReformaRegular,
            }}
          />
        );
      case "titleAndDescription":
        return (
          <BaseElement
            titleAndDescription={props}
            isHeaderUnderline={true}
            headerStyle={
              {
                display: "flex",
                fontSize: fontSize.UmbracoElementTitle,
                fontFamily: fonts.FbReformaMedium,
                color: colors.darkBlue,
              } as StyleSheet
            }
            containerStyle={
              {
                maxWidth: "76rem",
                width: "80%",
                fontSize: fontSize.Paragraph,
              } as StyleSheet
            }
          />
        );
      case "link":
        return <BaseElement link={props.link} />;
      default:
        return <AllElements elementName={props.contentTypeAlias} {...props} />;
    }
  };

  return (
    <>
      <img
        src={Images.closeButton}
        style={{
          ...styles.closeMainModalIcon,
          left: i18n.dir() === "rtl" ? "-0.5rem" : "",
          right: i18n.dir() === "ltr" ? "0.5rem" : "",
        }}
        onClick={() => onClose && onClose()}
      />
      <div style={styles.mainContainer}>
        <div
          style={{
            ...styles.cover,
            background: coverImage && `30% 30% / cover url(${coverImage._url})`,
            height: isCardDisplay ? "40vh" : "20.5vh",
            minHeight: isCardDisplay ? "17.18rem" : "14.25rem",
          }}
        >
          <div className="cover-color-blur">
            <div
              style={{
                ...styles.coverElements,
                position: "absolute",
                right: i18n.dir() === "rtl" ? "15%" : "",
                left: i18n.dir() === "ltr" ? "15%" : "",
              }}
            >
              <div style={styles.title}>{title && title.text}</div>
            </div>
          </div>
        </div>
        <div
          style={
            isCardDisplay && deviceMode === StyleMode.desktop
              ? styles.cardBorder
              : styles.cardBorderMobile
          }
        >
          <div
            style={
              isCardDisplay && deviceMode === StyleMode.desktop
                ? styles.cardBody
                : styles.cardBodyMobile
            }
          >
            {elements &&
              elements.map((element, index) => (
                <GetUmbracoElement {...element} key={index} />
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

const styles: StyleSheet = {
  mainContainer: {
    // paddingTop: "3rem",
    alignItems: "center",
    flexDirection: "column",
    zIndex: "10",
    // overflow:`hidden`,
    height: `100%`,
    width: `100%`,
  },
  cardBorder: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transform: "translateY(-7.8rem)",
  },
  cardBorderMobile: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  cardBody: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "3rem",
    padding: "2rem 0",
    backgroundColor: colors.white,
    borderRadius: "0.3125rem",
    boxShadow: "0 2px 10px 0 rgba(0, 0, 0, 0.15)",
    minWidth: "80%",
  },
  cardBodyMobile: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "3rem",
    padding: "2rem 0",
    width: "100%",
  },
  cover: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "40vh",
    minHeight: "17.18rem",
    position: "relative",
  },
  title: {
    color: colors.white,
    fontSize: fontSize.PageTitle,
    lineHeight: "3rem",
    letterSpacing: "0.05625rem",
    textShadow: `2px 2px ${colors.black}`,
    fontFamily: fonts.FbReformaMedium,
    paddingTop: "5rem",
  },
  breadcrumb: {
    display: "flex",
    color: colors.white,
    fontSize: fontSize.Text,
    letterSpacing: "0.05625rem",
    fontFamily: fonts.FbReformaRegular,
    position: "absolute",
    paddingTop: "2rem",
  },
  coverElements: {
    gap: "3rem",
  },
  content: {
    position: "relative",
    paddingTop: "5rem",
    right: "25%",
    width: "50%",
  },
  titlesAndDescriptionsItems: {
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
  },
  linkItems: {
    cursor: "pointer",
  },

  closeMainModalIcon: {
    position: "absolute",
    zIndex: "1000000000",
    cursor: "pointer",
    borderRadius: "50%",
    backgroundColor: colors.white,
    transform: "scale(1.25)",
    top: "-.5rem",
  } as React.CSSProperties,
};

export default UpdateModal;
