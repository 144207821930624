import { Fragment, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Images from "../../../Data/Images/Images";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import { IWorkerTalk } from "../../../Interfaces/IUmbraco";
import { openModal } from "../../../Redux/Slices/modalSlice";
import { StyleMode } from "../../../Utils/consts";
import useDeviceMode from "../../../Utils/useWindowDimensions";
import InfoMember from "../../Modal/InfoMember";
import MemberShort from "../DirectorateMembers/MemberShort";
import colors from "../../../Assets/Colors/Colors.json";
import fonts from "../../../Assets/Fonts/Fonts.json";
import { IMemberModal } from "../../../Interfaces/IDirectorateMember";
import { replaceRichtextBoldWithFont } from "../../../Utils/utils";
import "swiper/css";
import "swiper/css/navigation";
import i18n from "../../../Services/i18n";
import fontSize from "../../../Assets/Fonts/FontsSizes.json";

const WorkerTalk: React.FC<IWorkerTalk> = (props) => {
  const { title, workers } = props;
  const dispatch = useDispatch();
  const deviceMode = useDeviceMode();
  const [_isModalOpen, _setIsModalOpen] = useState(false);
  const [_selectedWorker, _setSelectedWorker] = useState<IMemberModal>();

  const prevRef = useRef(null);

  return (
    <Fragment>
      <div className="container">
        <h3 className="title">{title}</h3>
        <div className="row">
          <Swiper
            loop={true}
            loopFillGroupWithBlank={true}
            slidesPerView={
              deviceMode === StyleMode.desktop
                ? 4
                : deviceMode === StyleMode.mobile
                ? 2
                : 3
            }
            spaceBetween={30}
            navigation={true}
            modules={[Navigation]}
            className="mySwiper"
          >
            {workers.map((worker, index) => (
              <SwiperSlide key={index}>
                <MemberShort
                  {...worker}
                  onSelect={() => {
                    _setSelectedWorker(worker);
                    if (deviceMode === StyleMode.desktop) {
                      dispatch(
                        openModal({
                          visible: true,
                          closeButtonType: "internal",
                          childrenComponent: <InfoMember {...worker} />,
                        })
                      );
                    } else {
                      _setIsModalOpen(true);
                    }
                  }}
                  key={index}
                />
              </SwiperSlide>
            ))}

            <div
              className="arrows-main prev"
              style={{ backgroundImage: `url(${Images.directorateArrow})` }}
              ref={prevRef}
            />
          </Swiper>
        </div>
      </div>
      {_isModalOpen && _selectedWorker && deviceMode !== StyleMode.desktop && (
        <div style={styles.modalOverflow}>
          <div style={styles.modalCard}>
            <div
              style={{
                ...styles.closeIcon,
                left: i18n.dir() === "rtl" ? "-1rem" : "",
                right: i18n.dir() === "ltr" ? "-1rem" : "",
              }}
              onClick={() => {
                _setIsModalOpen(false);
              }}
            ></div>
            <div style={styles.modalImageCircle}>
              <img 
                src={_selectedWorker.image._url} 
                style={styles.modalImage} 
                width={`auto`} 
                height={`auto`} 
                alt={``}
              />
            </div>
            <div style={styles.modalBody}>
              <div style={styles.modalBodyUpper}>
                <div style={styles.modalFullname}>
                  {_selectedWorker.fullname}
                </div>
                <div
                  style={{
                    fontSize: fontSize.UmbracoElementTitle,
                    fontFamily: fonts.FbReformaLight,
                  }}
                >
                  {_selectedWorker.role}
                </div>
              </div>
              <div
                style={{
                  paddingInlineStart: "3rem",
                  fontSize: fontSize.FooterItem,
                }}
              >
                <div
                  style={{
                    width: "90%",
                    fontFamily: fonts.FbReformaLight,
                    letterSpacing: "0.034375rem",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: replaceRichtextBoldWithFont(
                      _selectedWorker.description
                    ),
                  }}
                ></div>
                <div style={styles.modalInfoContainer}>
                  <div
                    style={{
                      fontFamily: fonts.FbReformaMedium,
                    }}
                  ></div>
                  <div
                    style={{
                      fontFamily: fonts.FbReformaLight,
                      letterSpacing: "0.034375rem",
                    }}
                  ></div>
                </div>
                <div style={styles.modalInfoContainer}>
                  <div
                    style={{
                      fontFamily: fonts.FbReformaMedium,
                    }}
                  ></div>
                  <div
                    style={{
                      fontFamily: fonts.FbReformaLight,
                      letterSpacing: "0.034375rem",
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

const styles: StyleSheet = {
  container: {
    display: "flex",
    flexWrap: "wrap",
    gap: "4rem",
    width: "80%",
    maxWidth: "76rem",
  },
  modalOverflow: {
    display: "flex",
    justifyContent: "center",
    top: "0",
    position: "fixed",
    width: "100%",
    height: "100%",
    backgroundColor: colors.modal,
    zIndex: "99999",
  },
  modalCard: {
    display: "flex",
    justifyContent: "center",
    position: "absolute",
    top: "8%",
    width: "85%",
    height: "80%",
    backgroundColor: colors.white,
    borderRadius: "13px 13px 288px 288px / 13px 13px 43px 43px",
  },
  modalImageCircle: {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "7rem",
    height: "7rem",
    backgroundColor: colors.white,
    top: "0",
    borderRadius: "50%",
    transform: "translateY(-3.5rem)",
    boxShadow: "-1px 1px 33px 1px rgb(64 60 67 / 40%)",
  },
  modalImage: {
    position: "absolute",
    backgroundRepeat: "no-repeat",
    width: "85%",
    height: "85%",
    borderRadius: "50%",
  },
  modalBody: {
    position: "absolute",
    top: "10%",
    maxHeight: "80%",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  modalFullname: {
    fontSize: fontSize.UmbracoElementTitle,
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaMedium,
    letterSpacing: "0.065rem",
  },
  modalBodyUpper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: ".5rem",
  },
  modalInfoContainer: {
    display: "flex",
    gap: ".5rem",
    alignItems: "center",
    width: "90%",
  },
  closeIcon: {
    position: "absolute",
    backgroundImage: `url(${Images.closeButtonMob})`,
    backgroundRepeat: "no-repeat",
    width: "2rem",
    height: "2rem",
    top: "1rem",
    cursor: "pointer",
    transform: "translateY(-3rem) scale(1.4)",
  },
};

export default WorkerTalk;
