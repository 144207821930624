import { useDispatch } from "react-redux";
import { ICareers } from "../../../Interfaces/ICareers";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import { openModal } from "../../../Redux/Slices/modalSlice";
import Career from "./Career";
import colors from "../../../Assets/Colors/Colors.json";
import fonts from "../../../Assets/Fonts/Fonts.json";
import InfoCareer from "../../Modal/InfoCareer";
import fontSize from "../../../Assets/Fonts/FontsSizes.json";
import { IsMobile } from "../../../Services/DeviceService";

const Careers: React.FC<ICareers> = (props) => {
  const { title, careers, careersText } = props;
  const dispatch = useDispatch();

  return careers.length > 0 ? (
    <div className="container">
      <h3  className="title" tabIndex={!IsMobile() ? 0 : -1}>
        {title}
      </h3>
      <div style={styles.careersContainer}>
        {careers.map((career: any, index: number) => (
          <Career
            {...career}
            onClick={() => {
              dispatch(
                openModal({
                  visible: true,
                  innerWidth: "100%",

                  closeButtonType: "internal",
                  childrenComponent: (
                    <InfoCareer {...{ ...career, careersText }} />
                  ),
                })
              );
            }}
            key={index}
          />
        ))}
      </div>
    </div>
  ) : null;
};

const styles: StyleSheet = {
  careersContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    gap: "4rem",
  }
};

export default Careers;
