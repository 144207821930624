import Images from "../../Data/Images/Images";
import { IUpdatesTicker } from "../../Interfaces/IUpdatesTicker";
import colors from "../../Assets/Colors/Colors.json";
import fonts from "../../Assets/Fonts/Fonts.json";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import { t } from "i18next";
import Marquee from "react-fast-marquee";
import { useState } from "react";
import i18n from "../../Services/i18n";
import fontSize from "../../Assets/Fonts/FontsSizes.json";
import { IsMobile } from "../../Services/DeviceService";

const UpdatesTicker: React.FC<IUpdatesTicker> = (props) => {
  const {
    updates,
    currentUpdateIndex,
    onNextButtonClick,
    onPreviousButtonClick,
    onRedirectTo,
  } = props;

  const [_isPaused, _setIsPaused] = useState(false);

  return (
    <div
      style={{
        ...styles.container,
        display: updates.length > 0 ? "flex" : "none",
      }}
    >
      <div
        style={{
          ...styles.updatesPaginationContainer,
          paddingRight: i18n.dir() === "rtl" ? "4rem" : "",
          paddingLeft: i18n.dir() === "ltr" ? "4rem" : "",
        }}
      >
        <div
          role="region"
          aria-label={t("updatesNavigationAccessability")}
          style={{
            display: "flex",
          }}
        >
          <img
            src={Images.arrowUpWhite}
            tabIndex={!IsMobile() ? 0 : -1}
            role={"button"}
            style={{
              ...styles.arrow,
              transform:
                i18n.dir() === "rtl" ? "rotate(90deg)" : "rotate(-90deg)",
            }}
            onClick={onPreviousButtonClick}
            onKeyDownCapture={onPreviousButtonClick}
            aria-label={`${t("UpdateNumberOutOfNumber", {
              currentIndex: currentUpdateIndex + 1,
              total: updates.length,
            })} ${t("PreviousUpdate")}`}
            width={`100%`}
            height={`100%`}
            alt={``}
          />
          <img
            src={Images.arrowDownWhite}
            tabIndex={!IsMobile() ? 0 : -1}
            role={"button"}
            style={{
              ...styles.arrow,
              transform:
                i18n.dir() === "rtl" ? "rotate(90deg)" : "rotate(-90deg)",
            }}
            onClick={onNextButtonClick}
            onKeyDownCapture={onNextButtonClick}
            aria-label={`${t("UpdateNumberOutOfNumber", {
              currentIndex: currentUpdateIndex + 1,
              total: updates.length,
            })} ${t("NextUpdate")}`}
            width={`100%`}
            height={`100%`}
            alt={``}
          />
        </div>
        <div style={styles.update}>
          {updates.length > 0 &&
            `${t("UpdateNumberOutOfNumber", {
              currentIndex: currentUpdateIndex + 1,
              total: updates.length,
            })}`}
          :
        </div>
      </div>
      <div style={styles.updateNumberOutOfNumberContainer}>
        <div style={styles.updatesContainer}>
          {updates.map(
            (update, index) =>
              index === currentUpdateIndex && (
                <Marquee
                  key={index}
                  gradient={false}
                  speed={60}
                  onCycleComplete={onNextButtonClick}
                  pauseOnHover={true}
                  direction={i18n.dir() === "rtl" ? "right" : "left"}
                  play={!_isPaused}
                  style={{ maxWidth: `100%`, height: "auto" }}
                >
                  <div style={styles.headerContainer}>
                    <span
                      style={styles.update}
                      tabIndex={!IsMobile() ? 0 : -1}
                      role={"link"}
                      onClick={() => onRedirectTo(update.updatePage.id)}
                      onKeyDownCapture={(e) => {
                        if (e.key === "Enter") {
                          onRedirectTo(update.updatePage.id);
                        }
                      }}
                    >
                      {update.header}
                    </span>
                    {/* <h2
                      style={{
                        ...styles.update,
                        cursor: "pointer",
                        gap: ".25rem",
                      }}
                      tabIndex={!IsMobile() ? 0 : -1}
                      role={"link"}
                      onClick={() => onRedirectTo(update.updatePage[0].id)}
                    >
                      <span>{t("More-info")}</span>
                      <span aria-hidden={"true"}>{">"}</span>
                    </h2> */}
                  </div>
                </Marquee>
              )
          )}
        </div>
      </div>
      <div
        style={{
          ...styles.allUpdatesContainer,
          paddingLeft: i18n.dir() === "rtl" ? "4rem" : "",
          paddingRight: i18n.dir() === "ltr" ? "4rem" : "",
        }}
      >
        <img
          src={!_isPaused ? Images.audioPauseSvg_white : Images.audioPlay_white}
          style={styles.pause}
          onClick={() => {
            _setIsPaused(!_isPaused);
          }}
          onKeyPress={(e) => { 
            if (e.key === "Enter" || e.key === "Space") {
              _setIsPaused(!_isPaused);
            }
          }}
          tabIndex={!IsMobile() ? 0 : -1}
          role={"button"}
          aria-label={t(_isPaused ? "PlayUpdates" : "PauseUpdates")}
          width={`18`}
          alt={``}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: ".8rem",
            cursor: "pointer",
          }}
          role={"link"}
          onClick={() => onRedirectTo("updates")}
          onKeyDownCapture={(e) => {
            if (e.key === "Enter") {
              onRedirectTo("updates");
            }
          }}
        >
          <span
            tabIndex={!IsMobile() ? 0 : -1}
            style={styles.allUpdates}
          >{`${t("All-updates")}`}</span>
          <span
            style={{
              fontFamily: fonts.FbReformaRegular,
              color: colors.white,
              fontSize: fontSize.Text,
              paddingTop: ".22rem",
            }}
          >
            {">"}
          </span>
        </div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    width: "100%",
    height: "2.5rem",
    backgroundColor: colors.darkBlue,
    display: "flex",
    gap: "1rem",
    justifyContent: "space-around",
  },
  updatesPaginationContainer: {
    display: "flex",
    alignItems: "center",
  },
  updateNumberOutOfNumberContainer: {
    display: "flex",
    alignItems: "center",
    flex: "1",
  },
  update: {
    fontFamily: fonts.FbReformaRegular,
    color: colors.white,
    fontSize: fontSize.Text,
    whiteSpace: "nowrap",
    display: "flex",
    cursor: "pointer",
    // margin: "0 1rem",
  },
  allUpdates: {
    fontFamily: fonts.FbReformaRegular,
    color: colors.white,
    fontSize: fontSize.Text,
    paddingTop: ".22rem",
    whiteSpace: "nowrap",
    cursor: "pointer",
  },
  updatesContainer: {
    display: "flex",
    flex: "1",
  },
  arrow: {
    transform: "rotate(90deg)",
    cursor: "pointer",
  },
  headerContainer: {
    display: "flex",
    gap: "1.5rem",
  },
  allUpdatesContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "1rem",
  },
  pause: {
    cursor: "pointer",
  },
};

export default UpdatesTicker;
