import { t } from "i18next";
import { useState } from "react";
import Images from "../../Data/Images/Images";
import { StyleMode } from "../../Utils/consts";
import useDeviceMode from "../../Utils/useWindowDimensions";

export interface INavAPP {
  title: string;
  icon: string;
  onClose?: () => void;
  location?: {
    lat: any;
    lng: any;
  };
}
export interface IAPPS {
  name: string;
  icon: any;
  link: string;
}
const APPS: IAPPS[] = [
  {
    name: "google",
    icon: Images.googleMaps,
    link: "https://www.google.com/maps/@{lat},{lng},15z",
  },
  {
    name: "waze",
    icon: Images.waze,
    link: "https://www.waze.com/en-GB/live-map/directions?to=ll.{lat},{lng}",
  },
  {
    name: "moovit",
    icon: Images.moovit,
    link: "https://moovitapp.com/israel-1/poi/en-gb?tll={lat}_{lng}",
  },
];

const NavApp: React.FC<INavAPP> = (props) => {
  const { location, onClose = () => {}, title,icon } = props;
  const [_translate, _setTranslate] = useState<any>({});
  const [_close, _setClose] = useState<boolean>(false);

  const deviceMode = useDeviceMode();
  const fixedLink = (item: IAPPS) => {
    return item?.link
      .replace("{lat}", location?.lat)
      .replace("{lng}", location?.lng);
  };
  const _onClose = () => {
    _setClose(true);
    setTimeout(() => {
      onClose();
    }, 250);
  };
  return (
    <div
      className="modal"
      onClick={(e: any) => {
        e?.target?.className === "modal" && _onClose();
      }}
    >
      <div
        className={`inner ${
          deviceMode === StyleMode.mobile ? "mob" : "desktop"
        } ${_close ? "close" : ""}`}
      >
        <div className="wrapper-content">
          <div className="modal-icon">
            <img src={icon}/>
          </div>
          <div style={{ textAlign: "center" }}>{title}</div>
          <div className={"content"}>
            {APPS.map((item) => (
              <a href={fixedLink(item)} className="icon-app" target={"_blank"}>
                <img src={item.icon} />
                <span style={{ fontSize: "0.8rem" }}>{item.name}</span>
              </a>
            ))}
          </div>
          <button
            className="btn-modal"
            onClick={_onClose}
            style={{ textAlign: "center" }}
          >
            {t("Cancel")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default NavApp;
