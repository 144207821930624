import { combineReducers } from "redux";
import { ScheduleReducer } from "./ScheduleReducer";
import { PageReducer } from "./PageReducer";
import { TreeReducer } from "./TreeReducer";
import { AuctionsReducer } from "./AuctionsReducer";
import { ModalReducer } from "./ModalReducer";
import {AuctionsErrorStatus, AuctionsErrorStatusUpdateClientNewTenders } from "./AuctionsReducer";

const RootReducer = combineReducers({
  ScheduleReducer,
/*  PageReducer,*/
  TreeReducer,
  AuctionsReducer,
  ModalReducer,
 // AuctionsTypeReducer,
  AuctionsErrorStatus,
  AuctionsErrorStatusUpdateClientNewTenders,
});

export default RootReducer;
