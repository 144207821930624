import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import { IShareModal } from "../../Interfaces/ISocialShare";
import { StyleMode } from "../../Utils/consts";
import useDeviceMode from "../../Utils/useWindowDimensions";
import Images from "../../Data/Images/Images";
import i18n from "../../Services/i18n";
import { IsMobile } from "../../Services/DeviceService";
import { StyleSheet } from "../../Interfaces/IStyleSheet";

const ShareModal: React.FC<IShareModal> = (props) => {
  const { onClose, title, icon, items } = props;
  const [_translate, _setTranslate] = useState<any>({});
  const [_isVisible, _setIsVisible] = useState<boolean>(false);
  const modalRef = useRef<HTMLDivElement>(null);

  const deviceMode = useDeviceMode();

  useEffect(() => {
    if (modalRef.current) {
      const elements = document.getElementsByClassName("icon-app");
      (elements[0] as HTMLElement).focus();
    }
  }, []);

  const _onClose = () => {
    _setIsVisible(true);
    setTimeout(() => {
      onClose && onClose();
    }, 250);
  };

  
  return (
    <div
      ref={modalRef}
      className="modal"
      onClick={(e: any) => {
        e?.target?.className === "modal" && _onClose();
      }}
      onKeyPressCapture={(e) => {
        if (e.key === "Escape") {
          _onClose();
        }
      }}
      onKeyDownCapture={(e) => {
        if (e.key === "Escape") {
          _onClose();
        }
      }}
    >
      <div
        className={`inner ${
          deviceMode === StyleMode.desktop ? "desktop" : "mob"
        } ${_isVisible ? "close" : ""}`}
      >
        <div className="wrapper-content" >
          <div className="modal-icon">
            <img src={icon} />
          </div>
          <div style={{ textAlign: "center" }}>{title}</div>
          <div className={"content"}>
            {items.map((item, index) => (
              <a
                href={item.link}
                className="icon-app"
                target={"_blank"}
                key={index}
              >
                <img src={item.icon} />
                <span style={{ fontSize: "0.8rem" }}>{item.name}</span>
              </a>
            ))}
          </div>
          <span
            className="btn-modal"
            onClick={_onClose}
            style={{ textAlign: "center", backgroundColor: "#F0F0F0" }}
            role="button"
            tabIndex={0}
          >
            {t("Cancel")}
          </span>
        </div>
      </div>
    </div>
  );
};
interface ISliderModal {
  title?: string;
  icon: string;
  onClose?: () => void;
  btnShow?: boolean;
  innerStyle?: React.CSSProperties;
}
export const SlideModal: React.FC<ISliderModal> = (props) => {
  const { onClose, title, icon, btnShow = true, innerStyle = {} } = props;
  const [_translate, _setTranslate] = useState<any>({});
  const [_isVisible, _setIsVisible] = useState<boolean>(false);

  const deviceMode = useDeviceMode();

  const _onClose = () => {
    _setIsVisible(true);
    setTimeout(() => {
      onClose && onClose();
    }, 250);
  };
  return (
    <div
      className="modal"
      onClick={(e: any) => {
        e?.target?.className === "modal" && _onClose();
      }}
    >
      <div
        className={`inner ${
          deviceMode === StyleMode.desktop ? "desktop" : "mob"
        } ${_isVisible ? "close" : ""}`}
        style={{
          height: deviceMode !== StyleMode.desktop ? "90%" : "unset",
          ...innerStyle,
        }}
      >
        {
          <img
            src={Images.close}
            style={{
              ...styles.close,
              right: i18n.dir() === "ltr" ? "0" : "",
              left: i18n.dir() === "rtl" ? "0" : "",
            }}
            tabIndex={!IsMobile() ? 0 : -1}
            aria-label={"כפתור סגירה "}
            onClick={_onClose}
            role={"button"}
            width={`auto`}
            height={`auto`}
            alt={``}
          />
        }
        <div className="wrapper-content" style={{ overflow: "auto" }}>
          <div className="modal-icon" style={{ zIndex: "1" }}>
            <img src={icon} />
          </div>
          {title && <div style={{ textAlign: "center" }}>{title}</div>}
          <div
            className={"content"}
            style={{ flex: deviceMode !== StyleMode.desktop ? 1 : "unset" }}
          >
            {props.children}
          </div>
          {btnShow && (
            <button
              className="btn-modal"
              onClick={_onClose}
              style={{ textAlign: "center" }}
            >
              {t("Cancel")}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ShareModal;

const styles: StyleSheet = {
  close: {
    zIndex: 1,
    position: "absolute",
    top: "0",
    padding: "1rem",
    cursor: "pointer",
    left: "0px",
  } as React.CSSProperties,
};
