import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import colors from "../../../Assets/Colors/Colors.json";
import fonts from "../../../Assets/Fonts/Fonts.json";
import fontSize from "../../../Assets/Fonts/FontsSizes.json";
import { ILevelItem } from "../../../Interfaces/ILevels";
import Images from "../../../Data/Images/Images";
import useDeviceMode from "../../../Utils/useWindowDimensions";
import { StyleMode } from "../../../Utils/consts";
import ButtonsFunctional from "../ButtonFunctional/ButtonsFunctional";

const LevelItem: React.FC<ILevelItem> = (props) => {
  const { title, body, lineColor, index, anchor, buttons } = props;
  const deviceMode = useDeviceMode();
  return (
    <div
      style={{ ...styles.container, marginTop: index === 0 ? "3rem" : "" }}
      anchor-id={anchor}
      className="snap-item"
    >
      <span
        style={{
          ...styles.index,
          fontSize: deviceMode === StyleMode.desktop ? "9.375rem" : "6.8125rem",
          minWidth: deviceMode === StyleMode.desktop ? "9rem" : "4.5rem",
        }}
      >
        {index + 1}
      </span>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img
          src={Images.bulletCircle}
          width={"29"}
          height={"29"}
          style={{ position: "relative", zIndex: "1" }}
        />
        <div style={styles.line}>
          <div
            style={{
              ...styles.lineDesign,
              backgroundColor: `#${lineColor}`,
              width: deviceMode === StyleMode.desktop ? "1rem" : ".8rem",
            }}
          ></div>
        </div>
      </div>
      <div
        style={{
          ...styles.informationContainer,
          gap: deviceMode === StyleMode.desktop ? "1rem" : "",
        }}
      >
        <h1
          style={{
            ...styles.title,
            // marginBottom: deviceMode === StyleMode.desktop ? "4rem" : "",
          }}
        >
          {title}
        </h1>
        <div
          style={{ marginBottom: "2rem" }}
          dangerouslySetInnerHTML={{ __html: body }}
        ></div>
        <ButtonsFunctional buttons={buttons} />
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    display: "flex",
  },
  line: {
    width: "29px",
    height: "calc(100% + 14.5px)",
    minHeight: "18.625rem",
    display: "flex",
    justifyContent: "center",
  },
  lineDesign: {
    width: "1rem",
    height: "calc(100% + 14.5px)",
    marginTop: "-7.25px",
    // borderRadius: "5px",
  },
  title: {
    color: "#1F3273",
    fontFamily: fonts.FbReformaMedium,
    fontSize: fontSize.H1,
    // height: "29px",
    marginBlockStart: 0,
    marginBlockEnd: 0,
    lineHeight: "2.2rem",
    // marginBottom: "4rem",
  },
  index: {
    opacity: 0.1359,
    fontFamily: fonts.FbReformaMedium,
    color: colors.darkBlue,
  },
  informationContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
    padding: "0 3rem",
    marginBottom: "3rem",
  },
};

export default LevelItem;
