import { t } from "i18next";
import { Fragment, SetStateAction, useEffect, useRef, useState } from "react";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";
import { useSelector } from "react-redux";
import colors from "../../../Assets/Colors/Colors.json";
import fonts from "../../../Assets/Fonts/Fonts.json";
import fontSize from "../../../Assets/Fonts/FontsSizes.json";
import FromWhereMobile from "../../../Components/FromWhereMobile/FromWhereMobile";
import Loading from "../../../Components/Loading/Loading";
import NoTrains from "../../../Components/NoTrains/NoTrains";
import RouteDetails from "../../../Components/RouteDetails/RouteDetails";
import SearchResultsButtonsMobile from "../../../Components/SearchResultsButtons/Mobile/SearchResultsButtonsMobile";
import TimeCapsuleMobile from "../../../Components/TimeCapsule/Mobile/TimeCapsuleMobile";
import TrainCardMobile from "../../../Components/TrainCard/Mobile/TrainCardMobile";
import Images from "../../../Data/Images/Images";
import { IRoutePlanSearchResultsMobile } from "../../../Interfaces/IRoutePlanSearchResults";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import { ITravel } from "../../../Interfaces/ITravel";
import { ScheduleState } from "../../../Redux/Slices/scheduleSlice";
import { UmbracoStoreState } from "../../../Redux/Slices/umbracoSlice";
import i18n from "../../../Services/i18n";
import { StyleMode } from "../../../Utils/consts";
import useDeviceMode from "../../../Utils/useWindowDimensions";
import RoutePlanSearchResultsMoreActions from "./RoutePlanSearchResultsMoreActions";
// import { createEvent, EventAttributes } from "ics";
import moment from "moment";
import ReactModal from "react-modal";
import { useNavigate } from "react-router";
import store from "store2";
import TransitTransportPage from "../../../Components/ComplimentaryTransport/TransitTransportPage";
import ShareModal, { SlideModal } from "../../../Components/Modal/ShareModal";
import { IFromTO } from "../../../Components/PaymentAndDiscounts/CapsuleFromTo";
import Fare from "../../../Components/PaymentAndDiscounts/Fare";
import {
  linkBuilder,
  linkShareFormat,
} from "../../../Components/SharePage/ShareOptions";
import Config from "../../../Configuration/server";
import { IStation } from "../../../Interfaces/IStation";
import { ITrain } from "../../../Interfaces/ITrain";
import {
  FormatDateYYYYMMDDTHHMM,
  GetDurationFormatted,
  GetDurationTimeMS,
  GetDurationTimeMSStringType,
  IsToday,
  IsTommorow,
} from "../../../Services/DateService";
import { Translations } from "../../../Services/GlobalService";
import { GetStationsDictionary } from "../../../Utils/helpers";
import useDeviceModeOSType from "../../../Utils/useDeviceOS";
import RouteToCalendar from "../RouteToCalendar";
import { IsMobile } from "../../../Services/DeviceService";
import { AddToDataLayer } from "../../../Services/GoogleAnalyticsService";

const RoutePlanSearchResultsMobile: React.FC<IRoutePlanSearchResultsMobile> = (
  props
) => {
  const {
    travels,
    isLoading,
    isNoTrains,
    selectedTravel,
    noTrainsInfo,
    selectedTrainKey,
    onClickTravelCard,
    getDay,
    isToday,
  } = props;

  const [_isRouteDetailsVisible, _setIsRouteDetailsVisible] = useState(false);
  const [_isActionsVisible, _setIsActionsVisible] = useState(false);
  const [_lastMoreActionsPositionX, _setLastMoreActionsPositionX] = useState(0);
  const [_lastMoreActionsPositionY, _setLastMoreActionsPositionY] = useState(0);
  const [_moreActionsPositionX, _setMoreActionsPositionX] = useState(0);
  const [_moreActionsPositionY, _setMoreActionsPositionY] = useState(0);
  const [_isDragging, _setIsDragging] = useState(false);
  const [_isPurchaseModalOpen, _setIsPurchaseModalOpen] = useState(false);
  const [_isFromTo, _setFromTO] = useState<IFromTO>({
    fromStation: 0,
    toStation: 0,
  });
  const [_travelsToDisplay, _setTravelsToDisplay] =
    useState<ITravel[]>(travels);

  const [_selectedTrainToShare, _setSelectedTrainToShare] = useState<ITrain>();
  const [_selectedTrainToCalendar, _setSelectedTrainToCalendar] =
    useState<ITrain>();
  const [_isDelayInfoVisible, _setIsDelayInfoVisible] = useState(false);
  const [_isAddCalendarModalVisible, _setIsAddCalendarModalVisible] =
    useState(false);

  const closeRouteDetailsButtonRef = useRef<HTMLImageElement>(null);
  const closeTransitDetailsButtonRef = useRef<HTMLImageElement>(null);
  const Translation = Translations[
    i18n.language as `he` | `en` | `ar` | `ru`
  ] as any;

  let deviceModeOsType = useDeviceModeOSType();

  const navigate = useNavigate();
  const deviceMode = useDeviceMode();

  interface IDragInfo {
    x: number;
    y: number;
    time: number;
  }

  const [_dragInfo, _setDragInfo] = useState<IDragInfo>();
  const [_isNotificationListVisible, _setIsNotificationListVisible] =
    useState(false);

  const [_isShareModalVisible, _setIsShareModalVisible] = useState(false);
  const [_isTransitModalVisible, _setIsTransitModalVisible] = useState(false);

  const [_currentNotificationTrainIndex, _setCurrentNotificationTrainIndex] =
    useState(-1);

  const [_transitTime, _setTransitTime] = useState(
    _selectedTrainToShare && _selectedTrainToShare.arrivalTime
  );

  const EARLY_QUERY = -29;
  const LATE_QUERY = 240;
  const [_earlyQuery, _setEarlyQuery] = useState(false);
  const [_lateQuery, _setLateQuery] = useState(false);
  const [_durationEarlyTime, _setDurationEarlyTime] = useState();
  const [_durationLateTime, _setDurationLateTime] = useState(false);

  const durationEarlyTime =
    _transitTime && GetDurationTimeMSStringType(_transitTime, new Date());

  const durationLateTime =
    _transitTime && GetDurationTimeMSStringType(_transitTime, new Date());

  useEffect(() => {
    if (_travelsToDisplay.length > 1) {
      _setEarlyQuery(false);
      _setLateQuery(false);
      _setIsTransitModalVisible(false);
    } else {
      if (
        (durationLateTime && durationLateTime > LATE_QUERY) ||
        (durationEarlyTime && durationEarlyTime < EARLY_QUERY)
      ) {
        if (durationLateTime && durationLateTime > LATE_QUERY) {
          _setLateQuery(true);
          _setEarlyQuery(false);
          // alert("late")
        }
        if (durationEarlyTime && durationEarlyTime < EARLY_QUERY) {
          _setEarlyQuery(true);
          _setLateQuery(false);
          // alert("early")
        }
      }
    }
  }, [_transitTime, _travelsToDisplay]);

  // useEffect(() => {

  //   console.log("_transitTime:",_transitTime);

  //   _setEarlyQuery(false);
  //   _setLateQuery(false);
  // }, [_transitTime]);

  const schedule = useSelector(
    (state: any) => state.ScheduleReducer
  ) as ScheduleState;

  const handleOpenRouteDetails = () => {
    _setIsRouteDetailsVisible(true);
  };

  const handleCloseRouteDetails = () => {
    _setIsRouteDetailsVisible(false);
  };

  const handleCloseTransitDetails = () => {
    _setIsTransitModalVisible(false);
  };

  const handleCloseMoreActions = () => {
    _setIsActionsVisible(false);
  };

  const transitTimeHandler = (st: SetStateAction<string | undefined>) => {
    _setTransitTime(st);
  };

  const handleAddToCalendar = () => {
    if (_selectedTrainToShare) {
      const departureDate = new Date(_selectedTrainToShare.departureTime);
      const arrivalDate = new Date(_selectedTrainToShare.arrivalTime);

      const year = departureDate.getFullYear();
      const month = departureDate.getMonth() + 1;
      const day = departureDate.getDate();
      const hours = departureDate.getHours();
      const minutes = departureDate.getMinutes();

      const timeDurationMS = GetDurationTimeMS(departureDate, arrivalDate);
      const timeDurationFormat = GetDurationFormatted(timeDurationMS);

      let hoursDuration;
      let minutesDuration;
      if (timeDurationFormat.indexOf(":") === -1) {
        hoursDuration = 0;
        minutesDuration = +timeDurationFormat;
      } else {
        hoursDuration = +timeDurationFormat.split(":")[0];
        minutesDuration = +timeDurationFormat.split(":")[1];
      }

      const allStations = store.session.get(
        `${Config.BASE_LOCALSTORAGE_NAME}stations`
      );
      const stations: IStation[] = allStations && allStations[i18n.language];
      const stationsDict = GetStationsDictionary(stations);

      const title = t("TravelCalander", {
        fromStation: stationsDict[schedule.fromStation!].stationName,
        toStation: stationsDict[schedule.toStation!].stationName,
      });

      // const event: EventAttributes = {
      //   start: [year, month, day, hours, minutes],
      //   duration: { hours: hoursDuration, minutes: minutesDuration },
      //   title: title,
      // };

      // createEvent(event, (error, value) => {
      //   if (error) {
      //     console.log(error);
      //     return;
      //   }
      //   const blob = new Blob([value], { type: "text/plain;charset=utf-8" });
      //   saveAs(blob, "event-schedule.ics");
      // });
    }
  };

  const getShareTravelUrl = (url: string) => {
    let allStations = store.session.get(
      `${Config.BASE_LOCALSTORAGE_NAME}stations`
    );
    let Stations: any = GetStationsDictionary(
      allStations ? allStations[i18n.language] : []
    );
    let fromStation = schedule.fromStation
      ? Stations[schedule.fromStation].stationName
      : "";
    let toStation = schedule.toStation
      ? Stations[schedule.toStation].stationName
      : "";
    if (_selectedTrainToShare) {
      const date = moment(_selectedTrainToShare.departureTime).toDate();
      const dateFormat = moment(date).format("yyyyMMDD");

      const hourNumber = date.getHours();
      const minuteNumber = date.getMinutes();
      const hours = hourNumber <= 9 ? `0${hourNumber}` : hourNumber.toString();
      const minutes =
        minuteNumber <= 9 ? `0${minuteNumber}` : minuteNumber.toString();

      const description = `${Translation["travel by train"]} ${Translation["from station"]} ${fromStation} ${Translation["to station"]} ${toStation}%0A`;
      const andTime = `${t("date")}: ${moment(date).format("yyyy-MM-DD")}%0A${t(
        "HourText"
      )}: ${hours}:${minutes}%0A`;

      const navigateUrl = `https://www.rail.co.il/mldp?FSID=${
        schedule.fromStation
      }&TSID=${
        schedule.toStation
      }&Date=${dateFormat}&Hour=${hours}${minutes}&Train=${
        _selectedTrainToShare.trainNumber
      }`.replaceAll("&", "%26");
      linkShareFormat.title = description;
      linkShareFormat.subject = t("sharedWithYou") + " " + description;
      linkShareFormat.body = encodeURIComponent(
        description + andTime + "" + navigateUrl
      );
      linkShareFormat.summary = encodeURIComponent(description + andTime);
      linkShareFormat.text = encodeURIComponent(
        description + andTime + navigateUrl
      );
      linkShareFormat.quote = description;
      linkShareFormat.url = encodeURIComponent(navigateUrl);
      linkShareFormat.u = encodeURIComponent(navigateUrl);
      let newLink = linkBuilder(url, linkShareFormat);
      return decodeURI(newLink);
    }
    return "";
  };

  let handleDragStart = (e: DraggableEvent, data: DraggableData) => {
    _setDragInfo({
      x: data.x,
      y: data.y,
      time: Date.now(),
    });
  };

  let handleDragStop = (e: any, data: DraggableData) => {
    if (!_dragInfo) return;

    _setMoreActionsPositionX(data.x);
    _setMoreActionsPositionY(data.y);
    let change = {
      x: Math.abs(data.x - _dragInfo.x),
      y: Math.abs(data.y - _dragInfo.y),
      time: Date.now() - _dragInfo.time,
    };
    if (change.x + change.y <= 10 && change.time < 300) e.srcElement?.click?.();
  };

  useEffect(() => {
    if (_isRouteDetailsVisible) {
      closeRouteDetailsButtonRef?.current?.focus();
    }
  }, [_isRouteDetailsVisible]);

  useEffect(() => {
    if (_isTransitModalVisible) {
      closeTransitDetailsButtonRef?.current?.focus();
    }
  }, [_isTransitModalVisible]);

  const isSafariOs = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const isSafariAndWebMode = isSafariOs && deviceMode === "DESKTOP";

  const [_isIos, _setIsIos] = useState(isSafariOs && !isSafariAndWebMode);

  const umbracoStore = (
    useSelector((state: any) => state.UmbracoStoreReducer) as UmbracoStoreState
  ).umbracoStore;

  useEffect(() => {
    let allTravels = [...travels];
    if (_currentNotificationTrainIndex === -1) {
      _setTravelsToDisplay(
        allTravels.map((travel) => {
          return { ...travel, isNotificationVisible: false };
        })
      );
    } else {
      _setTravelsToDisplay(
        allTravels
          .filter((train, index) => index === _currentNotificationTrainIndex)
          .map((travel) => {
            return {
              ...travel,
              isNotificationVisible: true,
            };
          })
      );
    }

    transitTimeHandler(
      _selectedTrainToShare && _selectedTrainToShare.arrivalTime
    );
  }, [_currentNotificationTrainIndex, travels]);

  useEffect(() => {
    _setIsIos(isSafariOs && !isSafariAndWebMode);
  }, [isSafariAndWebMode]);
  const travelsContainerNotIos = isSafariOs
    ? {}
    : styles.travelsContainerNotIos;
  const nextPreviousContainer = isSafariOs
    ? { paddingTop: "1rem" }
    : { paddingTop: "4rem" };

  return (
    <Fragment>
      {_isPurchaseModalOpen && (
        <SlideModal
          icon={
            umbracoStore?.popUpFares?.icon._url
              ? umbracoStore.popUpFares.icon._url
              : Images.fares
          }
          innerStyle={{ padding: 0 }}
          btnShow={false}
          onClose={() => _setIsPurchaseModalOpen(false)}
        >
          <Fare
            title={umbracoStore.popUpFares?.title}
            fromStation={_isFromTo.fromStation}
            toStation={_isFromTo.toStation}
          />
        </SlideModal>
      )}
      <div style={styles.mainWrapper}>
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div style={styles.coverWrapper}>
            <div
              style={{
                top: "4rem",
                position: "absolute",
                width: "100%",
                zIndex: "6",
              }}
            >
              <FromWhereMobile />
            </div>
            <div
              style={{
                ...styles.cover,
                height: deviceMode === StyleMode.desktop ? "33vh" : "14rem",
              }}
              className={"blurred-container-mobile"}
            >
              <div style={styles.blurryCover}></div>
            </div>
            <div
              style={{
                ...styles.searchResultsButtonsMobile,
                // width: !_isIos ? "100%" : "85%",
                // padding: !_isIos ? "1rem .5rem" : "0 7.5%",
                padding: "1rem .5rem",
                boxSizing: "border-box",
              }}
            >
              <SearchResultsButtonsMobile />
            </div>
            <div>
              <TimeCapsuleMobile />
            </div>
          </div>
          {isLoading ? (
            <Loading
              message="Loading-schedule"
              containerStyles={{
                // backgroundColor: colors.ghostWhite,
                gap: "",
                alignItems: "center",
                justifyContent: "center",
                height:
                  window.innerHeight -
                  14 *
                    parseFloat(
                      getComputedStyle(document.documentElement).fontSize
                    ),
                minHeight: "",
              }}
            />
          ) : (
            <Fragment>
              {!isNoTrains ? (
                <div
                  style={{
                    ...styles.travelsContainer,
                    ...travelsContainerNotIos,
                    height:
                      window.innerHeight -
                      14 *
                        parseFloat(
                          getComputedStyle(document.documentElement).fontSize
                        ),
                    minHeight: "",
                  }}
                  id="travelsContainer"
                >
                  {!isToday && !_isNotificationListVisible ? (
                    <div
                      style={{
                        ...styles.nextPreviousContainer,
                        ...nextPreviousContainer,
                      }}
                      onClick={() => getDay("searchTrainLuzBeforeDate")}
                    >
                      <button
                        style={styles.nextPrevious}
                        role={"button"}
                        aria-label={t("View-previous-day")}
                      >
                        {t("View-previous-day")}
                      </button>
                      <img
                        src={Images.arrowUp}
                        style={styles.arrowIcon}
                        width={`auto`}
                        height={`auto`}
                        alt={``}
                      />
                    </div>
                  ) : (
                    <div
                      style={{
                        ...nextPreviousContainer,
                      }}
                    ></div>
                  )}
                  {_travelsToDisplay.map((train, key) => (
                    <div
                      key={train.arrivalTime + train.departureTime + key}
                      style={styles.trainCard}
                      onClick={() => {
                        onClickTravelCard(train);
                        handleOpenRouteDetails();
                      }}
                      onKeyPressCapture={() => {
                        onClickTravelCard(train);
                        handleOpenRouteDetails();
                      }}
                    >
                      <TrainCardMobile
                        {...train.trains[0]}
                        travelMessages={train.travelMessages}
                        arrivalTime={train.arrivalTime}
                        departureTime={train.departureTime}
                        changes={train.trains.length - 1}
                        key={key}
                        isSelected={
                          `${train.trains[0].arrivalTime}-${train.trains[0].departureTime}` ===
                          selectedTrainKey
                        }
                        lastDestanation={
                          train.trains[train.trains.length - 1].routeStations[
                            train.trains[train.trains.length - 1].routeStations
                              .length - 1
                          ].stationId
                        }
                        index={key}
                        totalTrains={travels.length}
                        trainPosition={train.trains[0].trainPosition}
                        onPurchaseButtonClick={() => {

                          const allStations = store.session.get(
                            `${Config.BASE_LOCALSTORAGE_NAME}stations`
                          );
                          const stations: IStation[] = allStations && allStations[i18n.language];
                          const dictStations = stations && GetStationsDictionary(stations);
        
                          if(schedule.fromStation && schedule.toStation) {
                            AddToDataLayer({
                              event_name: `prices_click`,
                              from_station: dictStations[schedule.fromStation].stationName,
                              to_station: dictStations[schedule.toStation].stationName,
                              when: IsToday(new Date(schedule.date)) ? "today" : IsTommorow(new Date(schedule.date)) ? "tomorrow" : "custom date",
                              departure_time: `${schedule.hours}:${schedule.minutes}` ,
                              type: "result page",
                            })
                          }

                          _setFromTO({
                            fromStation: train.trains[0].orignStation,
                            toStation:
                              train.trains[train.trains.length - 1]
                                .destinationStation,
                          });
                          _setIsPurchaseModalOpen(true);
                        }}
                        onNotificationButtonClick={(
                          trainIndex,
                          selectedTrain
                        ) => {
                          _setSelectedTrainToShare(selectedTrain);
                          _setSelectedTrainToCalendar(selectedTrain);
                          _setIsNotificationListVisible(
                            !_isNotificationListVisible
                          );
                          if (!_isNotificationListVisible) {
                            _setCurrentNotificationTrainIndex(trainIndex);
                          } else {
                            _setCurrentNotificationTrainIndex(-1);
                          }
                        }}
                        isNotificationVisible={train.isNotificationVisible}
                        handleDelayInfoModal={_setIsDelayInfoVisible}
                        destPlatform={train.trains[train.trains.length - 1].destPlatform}
                      />
                    </div>
                  ))}
                  {_isNotificationListVisible && (
                    <div style={styles.notificationsContainer}>
                      {/* <div
                        style={styles.notificationRow}
                        onClick={handleAddToCalendar}
                      >
                        <img src={Images.calendarShare} width={"20"} />
                        <span>{t("AddTravelToCalendar")}</span>
                      </div> */}
                      <div style={styles.notificationRow}>
                        <img src={Images.calendarShare} width={"20"} />
                        <span
                          onClick={() => {
                            _setIsAddCalendarModalVisible(true);
                            _setSelectedTrainToCalendar(_selectedTrainToShare);
                          }}
                        >
                          {t("AddToCalendarTitle")}
                          {/* <a
                            target={"_blank"}
                            style={{
                              textDecoration: "none",
                              color: colors.nero,
                            }}
                            href={
                              deviceModeOsType === "IOS"
                                ? _selectedTrainToCalendar
                                  ? GetIOSCalendarAddEventUrl({
                                      ..._selectedTrainToCalendar,
                                      toStation: schedule.toStation,
                                      fromStation: schedule.fromStation,
                                    })
                                  : ""
                                : deviceModeOsType === "Android"
                                ? _selectedTrainToCalendar
                                  ? GetGoogleCalendarAddEventUrl({
                                      ..._selectedTrainToCalendar,
                                      toStation: schedule.toStation,
                                      fromStation: schedule.fromStation,
                                    })
                                  : ""
                                : ""
                            }
                          >
                            {t("AddToCalendarTitle")}
                          </a> */}
                        </span>
                      </div>
                      <div
                        style={styles.notificationRow}
                        onClick={(e) => {
                          e.stopPropagation();
                          _setIsShareModalVisible(true);
                        }}
                      >
                        <img src={Images.shareBlue} width={"20"} />
                        <span>{t("ShareTravel")}</span>
                      </div>
                      <div
                        style={styles.notificationRow}
                        onClick={(e) => {
                          e.stopPropagation();
                          _setIsTransitModalVisible(true);
                        }}
                      >
                        <img src={Images.transitDetails} width={"20"} />
                        <span> {t("BusesFromTheDestinationStation")}</span>
                      </div>
                    </div>
                  )}
                  {!_isActionsVisible && !_isNotificationListVisible && (
                    <Draggable
                      onStart={handleDragStart}
                      onStop={handleDragStop}
                      position={{
                        x: _moreActionsPositionX,
                        y: _moreActionsPositionY,
                      }}
                    >
                      <div
                        style={styles.moreActionsCircleIcon}
                        onClick={() => {
                          _setIsActionsVisible(true);
                        }}
                      >
                        <img
                          src={Images.moreActionsCircle}
                          width={`auto`}
                          height={`auto`}
                          alt={``}
                        />
                        <img
                          src={Images.moreActionsDots}
                          style={styles.moreActionsDotsIcon}
                          width={`auto`}
                          height={`auto`}
                          alt={``}
                        />
                      </div>
                    </Draggable>
                  )}
                  {!_isNotificationListVisible && (
                    <div
                      style={{ ...styles.nextPreviousContainer }}
                      onClick={() => getDay("searchTrainLuzAfterDate")}
                    >
                      <button
                        style={styles.nextPrevious}
                        role={"button"}
                        aria-label={t("View-next-day")}
                      >
                        {t("View-next-day")}
                      </button>
                      <img
                        src={Images.arrowDown}
                        style={styles.arrowIcon}
                        width={`auto`}
                        height={`auto`}
                        alt={``}
                      />
                    </div>
                  )}
                </div>
              ) : (
                <div
                  style={{
                    ...styles.noTrainsContainer,
                    height:
                      window.innerHeight -
                      14 *
                        parseFloat(
                          getComputedStyle(document.documentElement).fontSize
                        ),
                  }}
                >
                  <NoTrains
                    clientMessageId={noTrainsInfo.clientMessageId}
                    lastTrainDate={noTrainsInfo.lastTrainDate}
                    nextTrainDate={noTrainsInfo.nextTrainDate}
                    onClick={noTrainsInfo.onClick}
                    messageTitle={noTrainsInfo.messageTitle}
                    shareStatus={noTrainsInfo.shareStatus}
                  />
                </div>
              )}
            </Fragment>
          )}
        </div>
        {(_isRouteDetailsVisible || _isActionsVisible) && (
          <div
            style={styles.modal}
            onClick={() => {
              _setIsRouteDetailsVisible(false);
              _setIsActionsVisible(false);
            }}
          ></div>
        )}
        {_isRouteDetailsVisible && (
          <Fragment>
            <div>
              <div
                id={"routeDetailsContainer"}
                style={{
                  ...styles.routeDetailsContainer,
                }}
                onClick={() => {
                  _setIsRouteDetailsVisible(false);
                  _setIsActionsVisible(false);
                }}
              >
                <div
                  style={styles.routeDetailsWrapper}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginInlineEnd: "1rem",
                      marginInlineStart: "1rem",
                      paddingBottom: ".5rem",
                    }}
                  >
                    <img
                      ref={closeRouteDetailsButtonRef}
                      id={"closeRouteDetailsButton"}
                      tabIndex={0}
                      role={"button"}
                      aria-label={t("Close")}
                      src={Images.closeIconWhite}
                      onClick={handleCloseRouteDetails}
                      width={`auto`}
                      height={`auto`}
                      alt={``}
                      style={{
                        cursor: "pointer",
                        zIndex: "9999999",
                        position: "relative",
                        top: "-2rem",
                      }}
                    />
                  </div>
                  <div style={styles.routeDetails}>
                    <RouteDetails
                      travel={selectedTravel}
                      routeDetailsBodyStyles={{
                        margin: "1rem",
                        // height: "57vh",
                        maxHeight:
                          Math.round(window.devicePixelRatio * 100) / 1 < 175
                            ? "33rem"
                            : window.innerHeight -
                              14 *
                                parseFloat(
                                  getComputedStyle(document.documentElement)
                                    .fontSize
                                ),
                        overflowY: "scroll",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        )}
        {_isActionsVisible && (
          <Fragment>
            <div style={styles.moreActions}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginInlineEnd: "1rem",
                    marginInlineStart: "1rem",
                    paddingBottom: ".5rem",
                  }}
                >
                  <img
                    src={Images.closeIconWhite}
                    onClick={handleCloseMoreActions}
                    width={`auto`}
                    height={`auto`}
                    alt={``}
                  />
                </div>
                <div
                  style={{
                    backgroundColor: colors.white,
                    borderTopLeftRadius: "1rem",
                    borderTopRightRadius: "1rem",
                  }}
                >
                  {schedule.fromStation && schedule.toStation && (
                    <RoutePlanSearchResultsMoreActions
                      fromStation={schedule.fromStation}
                      toStation={schedule.toStation}
                    />
                  )}
                </div>
              </div>
            </div>
          </Fragment>
        )}
      </div>
      <ReactModal
        isOpen={_isShareModalVisible}
        ariaHideApp={false}
        style={{
          content: {
            width: "100%",
            maxWidth: "42rem",
            border: "none",
            padding: "none",
            position: "absolute",
            maxHeight: "100%",
            overflow: "visible",
            backgroundColor: "transparent",
          },
          overlay: {
            backgroundColor: "transparent",
            zIndex: "9999999999999999",
            overflow: "hidden",
          },
        }}
        parentSelector={() => document.querySelector("#root") || document.body}
        preventScroll={true}
      >
        <ShareModal
          title={t("Share")}
          icon={Images.share}
          onClose={() => _setIsShareModalVisible(false)}
          items={[
            {
              name: t("Facebook"),
              icon: Images.facebookHeader,
              link: getShareTravelUrl(
                `https://www.facebook.com/sharer/sharer.php?u=`
              ),
              ariaLabel: t("ShareFacebook"),
            },
            {
              name: t("WhatsApp"),
              icon: Images.whatsapp,
              link: getShareTravelUrl(`https://wa.me/?text=`),
              ariaLabel: t("ShareWhatsapp"),
            },
            {
              name: t("Mail"),
              icon: Images.shareMail,
              link: getShareTravelUrl(`mailto:?subject=&body=`),
              ariaLabel: t("ShareEmail"),
            },
            {
              name: t("Linkedin"),
              icon: Images.linkedinHeader,
              link: getShareTravelUrl(
                `https://www.linkedin.com/sharing/share-offsite/?url=`
              ),
              ariaLabel: t("ShareLinkedin"),
            },
          ]}
        />
      </ReactModal>

      {_isTransitModalVisible && _earlyQuery && !_lateQuery && (
        <div
          style={styles.oe}
          onClick={() => {
            _setIsTransitModalVisible(false);
          }}
        >
          Early
        </div>
      )}
      {_isTransitModalVisible && !_earlyQuery && _lateQuery && (
        <div
          style={styles.oe}
          onClick={() => {
            _setIsTransitModalVisible(false);
          }}
        >
          Late
        </div>
      )}

      {_isTransitModalVisible && !_earlyQuery && _lateQuery && (
        <div style={styles.modalOverlay_BusMessageQuery}>
          <div
            style={{
              ...styles.modalCard_BusMessageQuery,
              maxWidth: deviceMode === StyleMode.desktop ? "40%" : "90%",
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div style={styles.iconWrapper_BusMessageQuery}>
              <div style={styles.iconContainer_BusMessageQuery}>
                <img src={Images.error}></img>
              </div>
            </div>
          </div>
          <div style={styles.errorModalContentContainer}>
            <div style={styles.errorModalContentHeader}>{t("rideNotStat")}</div>
            <div style={styles.errorModalContentSubHeader}>
              {t("futureBus")}
            </div>
            <div
              tabIndex={0}
              style={styles.errorModalBtn}
              onClick={() => {
                _setIsTransitModalVisible(false);
              }}
            >
              {t("closeBusModal")}
            </div>
          </div>
        </div>
      )}

      {_isTransitModalVisible && _earlyQuery && !_lateQuery && (
        <div style={styles.modalOverlay_BusMessageQuery}>
          <div
            style={{
              ...styles.modalCard_BusMessageQuery,
              maxWidth: deviceMode === StyleMode.desktop ? "40%" : "90%",
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div style={styles.iconWrapper_BusMessageQuery}>
              <div style={styles.iconContainer_BusMessageQuery}>
                <img src={Images.error}></img>
              </div>
            </div>
          </div>

          <div style={styles.errorModalContentContainer}>
            <div style={styles.errorModalContentHeader}>{t("rideOver")}</div>
            <div style={{ ...styles.errorModalContentSubHeader, width: "50%" }}>
              {t("pastBus")}
            </div>
            <div
              tabIndex={0}
              style={styles.errorModalBtn}
              onClick={() => {
                _setIsTransitModalVisible(false);
              }}
            >
              {t("closeBusModal")}
            </div>
          </div>
        </div>
      )}

      {(_isTransitModalVisible || _isActionsVisible) &&
        !_lateQuery &&
        !_earlyQuery && (
          <div
            style={styles.modal}
            onClick={() => {
              _setIsTransitModalVisible(false);
              _setIsActionsVisible(false);
            }}
          ></div>
        )}
      {_isTransitModalVisible && !_lateQuery && !_earlyQuery && (
        <Fragment>
          <div>
            <div
              id={"routeDetailsContainer"}
              style={{
                ...styles.routeDetailsContainer,
              }}
              onClick={() => {
                _setIsTransitModalVisible(false);
                _setIsActionsVisible(false);
              }}
            >
              <div
                style={styles.routeDetailsWrapper}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginInlineEnd: "1rem",
                    marginInlineStart: "1rem",
                    paddingBottom: ".5rem",
                  }}
                >
                  <img
                    ref={closeRouteDetailsButtonRef}
                    id={"closeTransitDetailsButtonRef"}
                    tabIndex={0}
                    role={"button"}
                    aria-label={t("Close")}
                    src={Images.closeIconWhite}
                    onClick={handleCloseTransitDetails}
                    width={`auto`}
                    height={`auto`}
                    alt={``}
                    style={{
                      cursor: "pointer",
                      zIndex: "9999999",
                      position: "relative",
                      top: "-2rem",
                    }}
                  />
                </div>
                <div style={styles.purchaseModalIconWrapper}>
                  <div style={styles.purchaseModalIconContainer}>
                    <img
                      src={Images.bus}
                      style={styles.exitIcon}
                      width={`20px`}
                      height={`20px`}
                      alt={``}
                      tabIndex={0}
                    />
                  </div>
                </div>
                <div style={styles.routeDetails}>
                  <TransitTransportPage
                    stationId={schedule.toStation}
                    typeOfPage="transitInfo"
                    startTime={_transitTime}
                    onClick={() =>
                      transitTimeHandler(FormatDateYYYYMMDDTHHMM(new Date()))
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
      {_isDelayInfoVisible && (
        <div
          style={{
            position: "fixed",
            width: "100%",
            height: "100%",
            backgroundColor: colors.modal,
            zIndex: "999999",
            top: "0",
            display: "flex",
            alignItems: "center",
          }}
          onClick={() => {
            _setIsDelayInfoVisible(false);
          }}
        >
          <div
            style={{
              backgroundColor: colors.white,
              bottom: "50%",
              width: "100%",
              minHeight: "30%",
              position: "absolute",
              borderTopLeftRadius: "2rem",
              borderTopRightRadius: "2rem",
              borderRadius: "13px 13px 288px 288px / 13px 13px 43px 43px",
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div
              style={{
                position: "absolute",
                width: "5rem",
                height: "5rem",
                backgroundColor: colors.white,
                borderRadius: "50%",
                left: "calc(50% - 2.5rem)",
                top: "-2.5rem",
                boxShadow: "0 5px 11px 0 rgba(0, 0, 0, 0.21)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={Images.info_mobile} style={{ scale: "1.5" }} />
            </div>
            <img
              src={Images.closeButtonMob}
              style={{
                ...styles.closeMainModalIcon,
                left: i18n.dir() === "rtl" ? "1rem" : "",
                right: i18n.dir() === "ltr" ? "1rem" : "",
              }}
              onClick={() => {
                _setIsDelayInfoVisible(false);
              }}
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%",
                position: "absolute",
              }}
            >
              <h3
                style={{
                  textAlign: "center",
                  lineHeight: "1.5rem",
                  color: colors.darkBlue,
                }}
              >
                {t("delayInfo")}
              </h3>
            </div>
          </div>
        </div>
      )}
      <ReactModal
        isOpen={_isAddCalendarModalVisible}
        ariaHideApp={false}
        onRequestClose={() => _setIsAddCalendarModalVisible(false)}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            width: "85%",
            maxWidth: "45rem",
            bottom: "auto",
            minHeight: "70%",
            transform: "translate(-50%, -50%)",
            border: "none",
            padding: "none",
            position: "absolute",
            maxHeight: "100%",
            overflow: "visible",
            borderRadius: "13px 13px 288px 288px / 13px 13px 43px 43px",
          },
          overlay: {
            backgroundColor: colors.modal,
            zIndex: "99999",
            overflow: "hidden",
          },
        }}
        parentSelector={() => document.querySelector("#root") || document.body}
        preventScroll={true}
      >
        <img
          src={Images.closeButtonMob}
          style={{
            ...styles.routeToCalendarModalCloseIcon,
            left: i18n.dir() === "rtl" ? "1rem" : "",
            right: i18n.dir() === "ltr" ? "1rem" : "",
          }}
          alt={`${t(`Close`)}`}
          aria-label={`${t(`Close`)}`}
          tabIndex={!IsMobile() ? 0 : -1}
          onClick={() => _setIsAddCalendarModalVisible(false)}
          width={`auto`}
          role={"button"}
          height={`auto`}
        />
        {_selectedTrainToCalendar && (
          <>
            <RouteToCalendar
              {..._selectedTrainToCalendar}
              toStation={schedule.toStation}
              fromStation={schedule.fromStation}
            />
          </>
        )}
      </ReactModal>
    </Fragment>
  );
};

const styles: StyleSheet = {
  mainWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflow: "hidden",
    minHeight: "100vh",
  },
  coverWrapper: {
    // overflow: "hidden",
    height: "14rem",
  },
  cover: {
    background: `30% 80% url(${Images.coverImage}) no-repeat`,
    backgroundSize: "cover",
    width: "100%",
    height: "14rem",
    position: "relative",
  },
  blurryCover: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backdropFilter: "brightness(.4)",
    WebkitBackdropFilter: "brightness(.4)",
  },
  travelsContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
    // backgroundColor: colors.ghostWhite,
    //maxHeight: "35rem",
    //paddingBottom: "5rem",
    overflow: "auto",
    // minHeight: "100vh",
    // maxHeight: "calc(100vh - 17rem)",
    marginTop: "2rem",
  },
  travelsContainerNotIos: {
    //  maxHeight: "35rem",
    // maxHeight: "76vh",
    paddingBottom: "2.5rem",
    minHeight: "100vh",
  },
  trainsCardsWrapper: {
    display: "flex",
    flexDirection: "column",
    flex: "2",
  },
  trainsCards: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  trainCard: {
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
  },
  routeDetailsContainer: {
    position: "fixed",
    // background: colors.white,
    maxHeight: "100vh",
    // minHeight: "90vh",
    left: "0",
    bottom: "0",
    width: "100%",
    zIndex: "20",
  },
  routeDetailsWrapper: {
    display: "flex",
    flexDirection: "column",
    paddingTop: "4rem",
  },
  routeDetails: {
    backgroundColor: colors.white,
    borderTopLeftRadius: "1rem",
    borderTopRightRadius: "1rem",
  },

  modal: {
    backgroundColor: colors.modal,
    position: "fixed",
    width: "100%",
    height: "100%",
    top: "0",
    zIndex: "10",
  },
  nextPreviousContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  nextPrevious: {
    color: colors.darkCyan,
    cursor: "pointer",
    border: "none",
    backgroundColor: "transparent",
    fontSize: fontSize.Paragraph,
  },
  arrowIcon: {
    cursor: "pointer",
  },
  searchResultsButtonsMobile: {
    position: "absolute",
    top: "0",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    zIndex: "10",
  },
  fromWhereMobile: {
    position: "absolute",
    // top: "25%",
    padding: "1rem",
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  moreActionsCircleIcon: {
    position: "fixed",
    bottom: "25%",
    left: "0",
    zIndex: "8",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    // scale: "1.5",
  },
  moreActionsDotsIcon: {
    position: "absolute",
  },
  moreActions: {
    position: "fixed",
    left: "0",
    bottom: "0",
    width: "100%",
    zIndex: "15",
  },
  noTrainsContainer: {
    justifyContent: "center",
    display: "flex",
  },
  purchaseModalIconWrapper: {
    display: "flex",
    justifyContent: "center",
    position: "relative",
    top: "-2.5rem",
    zIndex: "9999999",
  },
  purchaseModalBody: {
    position: "absolute",
    top: "12%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    gap: "1.25rem",
    zIndex: "9999999",
  },
  exitIcon: {
    transform: "scale(1.3)",
  },
  purchaseModalIconContainer: {
    width: "4.3rem",
    height: "4.3rem",
    boxShadow: "0 5px 11px 0 rgba(0, 0, 0, 0.21)",
    borderRadius: "50%",
    backgroundColor: "white",
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transform: "translateY(5px)",
  } as React.CSSProperties,
  purchaseModalTitle: {
    fontSize: fontSize.UmbracoElementTitle,
    lineHeight: "1.5rem",
    maxWidth: "18rem",
    fontFamily: fonts.FbReformaMedium,
    textAlign: "center",
  },
  purchaseOptions: {
    display: "flex",
    justifyContent: "center",
    gap: "1rem",
    flexWrap: "wrap",
  },
  purchaseItem: {
    cursor: "pointer",
    scale: ".8",
  },
  purchaseModalCloseIcon: {
    position: "absolute",
    left: i18n.dir() === "rtl" ? "1rem" : "",
    right: i18n.dir() === "ltr" ? "1rem" : "",
    top: "-2rem",
    cursor: "pointer",
  },
  purchaseModalOverlay: {
    position: "fixed",
    width: "100%",
    height: "100%",
    backgroundColor: colors.modal,
    left: "0",
    top: "0",
    zIndex: "99999999",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  purchaseModal: {
    backgroundColor: colors.white,
    height: "30rem",
    width: "85%",
    maxWidth: "32rem",
    position: "absolute",
    borderRadius: "13px 13px 288px 288px / 13px 13px 43px 43px",
  },
  notificationsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  notificationRow: {
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    padding: "0 3rem",
    cursor: "pointer",
    // paddingInlineStart: "1.5rem",
  },
  closeMainModalIcon: {
    position: "absolute",
    cursor: "pointer",
    scale: "1.25",
    transform: "translateY(-1.8rem)",
  },
  oe: {
    background: "red",
    position: "absolute",
    width: "200px",
  },

  modalOverlay_BusMessageQuery: {
    position: "fixed",
    backgroundColor: colors.modal,
    width: "100%",
    height: "100%",
    top: "0",
    left: "0",
    zIndex: "99999",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalCard_BusMessageQuery: {
    position: "absolute",
    backgroundColor: colors.white,
    borderRadius: "13px 13px 288px 288px / 13px 13px 43px 43px",
    minWidth: "40%",
    minHeight: "50%",
    width: "80%",
    maxHeight: "70%",
  },

  travelClosedCloseButton_BusMessageQuery: {
    position: "absolute",
    top: "0",
    cursor: "pointer",
    transform: "translateY(-2rem)",
  },
  iconWrapper_BusMessageQuery: {
    display: "flex",
    justifyContent: "center",
    position: "relative",
    top: "-2.5rem",
    zIndex: "9999999",
  },
  iconContainer_BusMessageQuery: {
    width: "4.3rem",
    height: "4.3rem",
    boxShadow: "0 5px 11px 0 rgba(0, 0, 0, 0.21)",
    borderRadius: "50%",
    backgroundColor: "white",
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transform: "translateY(5px)",
  } as React.CSSProperties,
  bellIcon_BusMessageQuery: {
    transform: "scale(3)",
  },
  modalContent_BusMessageQuery: {
    paddingTop: "3rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "17rem",
  },
  messages_BusMessageQuery: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: fontSize.UmbracoElementTitle,
    lineHeight: "2rem",
    textAlign: "center",
  },

  errorModalContentContainer: {
    zIndex: 1,
    display: "flex",
    flexDirection: "column",
    gap: "7rem",
    alignItems: "center",
    width: "100%",
  },
  errorModalBtn: {
    backgroundColor: colors.darkBlue,
    color: colors.white,
    fontSize: "1rem",
    borderRadius: "16rem",
    justifyContent: "center",
    paddingTop: ".5rem",
    alignItems: "center",
    maxWidth: "12rem",
    minHeight: "1.8rem",
    textAlign: "center",
    cursor: "pointer",
    paddingInline: "1rem",
  },

  errorModalContentHeader: {
    fontSize: fontSize.UmbracoElementTitle,
    fontWeight: "700",
  },
  errorModalContentSubHeader: {
    fontSize: fontSize.SubTitle,
    width: "60%",
    textAlign: "center",
  },
  routeToCalendarModalCloseIcon: {
    position: "absolute",
    top: "-2rem",
    cursor: "pointer",
  },
};

export default RoutePlanSearchResultsMobile;
