import { useEffect, useState, useRef, Fragment } from "react";
import colors from "../Assets/Colors/Colors.json";
import fonts from "../Assets/Fonts/Fonts.json";
import AutoCompleteForm from "../Components/Form/AutoCompleteForm/AutoCompleteForm";
import DatePickerForm from "../Components/Form/DatePickerForm/DatePickerForm";
import InputForm from "../Components/Form/InputForm/InputForm";
import GuideLinesAndRatesCard from "../Components/GuideLinesAndRates/GuideLinesAndRatesCard";
import PaginationDots from "../Components/PaginationDots/PaginationDots";
import Title from "../Components/UmbracoElements/Title";
import Images from "../Data/Images/Images";
import {
  IGuideLinesAndRates,
  IGuideLinesAndRatesCard,
} from "../Interfaces/IGuideLinesAndRates";
import { StyleSheet } from "../Interfaces/IStyleSheet";
import {
  FilterGuideLinesAndRates,
  IFilterGuideLinesAndRates,
} from "../Services/GuideLinesAndRatesFiltersService";
import { StyleMode } from "../Utils/consts";
import useDeviceMode from "../Utils/useWindowDimensions";
import { GetDistinctedItems } from "../Utils/utils";
import { FiFilter } from "react-icons/fi";
import { FaFilter } from "react-icons/fa";
import {
  FormatDateYYYYMMDD,
  IsDateBetween,
  IsToday,
} from "../Services/DateService";
import { t } from "i18next";
import i18n from "../Services/i18n";
import { GetPlanningAndRates } from "../Services/CommonService";
import Loading from "../Components/Loading/Loading";
import fontSize from "../Assets/Fonts/FontsSizes.json";
import { IsMobile } from "../Services/DeviceService";
import DatePicker from "../Components/DatePicker/DatePicker";
import ReactModal from "react-modal";
import { PostEmailList } from "../Services/tenderServerServices";
import { useSelector } from "react-redux";
import PdfDocument from "../Components/FileViewer/PdfDocument";
import { SystemParamsState } from "../Redux/Slices/systemParamsSlice";

const GuideLinesAndRates: React.FC<IGuideLinesAndRates> = (props) => {
  const { dynamicPart } = props;

  const [_guideLinesAndRatesCards, _setGuideLinesAndRatesCards] = useState<
    IGuideLinesAndRatesCard[]
  >([]);

  const [_totalGuideLines, _setTotalGuideLines] = useState<
    IGuideLinesAndRatesCard[]
  >([]);

  const [_planningAreas, _setPlanningAreas] = useState<string[]>([]);
  const [_selectedPlanningArea, _setSelectedPlanningArea] = useState("");
  const [_selectedFromDate, _setSelectedFromDate] = useState(new Date());
  const [_selectedToDate, _setSelectedToDate] = useState(new Date());
  const [_minFromDate, _setMinFromDate] = useState(new Date());
  const [_inputValue, _setInputValue] = useState("");
  const [_currentPage, _setCurrentPage] = useState(1);
  const [_cardsPerPage] = useState(12);
  const [_isFiltersVisible, _setIsFiltersVisible] = useState(false);
  const [_isFiltered, _setIsFiltered] = useState(false);
  const [_isLoading, _setIsLoading] = useState(true);
  const [_isLoadingSearch, _setIsLoadingSearch] = useState(false);
  const [_currentItemsFound, _setCurrentItemsFound] = useState(0);
  const [_isDatePickerVisibleMobile, _setIsDatePickerVisibleMobile] =
    useState(false);
  const [_selectedDatePickerTypeMobile, _setSelectedDatePickerTypeMobile] =
    useState<"" | "from" | "to">("");
  const [_fromDatePlaceHolder, _setFromDatePlaceHolder] = useState<string>(
    t("From-post-date")
  );
  const [_toDatePlaceHolder, _setToDatePlaceHolder] = useState<string>(
    t("To-post-date")
  );
  const [_specificGuideline, _setSpecificGuideline] = useState("");

  const [_mailingFormDisplay, _setMailingFormDisplay] = useState(true);
  const [_emailAdressValue, _setEmailAdressValue] = useState<string>("");
  const [_nameAdressValue, _setNameAdressValue] = useState<string>("");
  const [_isUpdateMeModalOpen, _setIsUpdateMeModalOpen] = useState(false);
  const validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  const errorDisplay = useSelector((state: any) => state.AuctionsErrorStatus);
  const errorDisplayUpdateMe = useSelector(
    (state: any) => state.AuctionsErrorStatusUpdateClientNewTenders
  );
  const [_indexOfLastCard, _setIndexOfLastCard] = useState(
    _currentPage * _cardsPerPage
  );
  const [_indexOfFirstCard, _setIndexOfFirstCard] = useState(
    _indexOfLastCard - _cardsPerPage
  );

  const [_currentCardsInPage, _setCurrentCardsInPage] = useState(
    _guideLinesAndRatesCards.slice(_indexOfFirstCard, _indexOfLastCard)
  );

  const containerRef = useRef<HTMLDivElement>(null);
  const mainScroll = document.getElementById("mainScroll")!;
  const deviceMode = useDeviceMode();


  const systemParams = (
    useSelector((state: any) => state.SystemParamsReducer) as SystemParamsState
  ).systemParams;

  const systemParamsDictionary = systemParams.reduce(
    (result: { [key: string]: string }, current) => {
      result[current.key] = current.value;
      return result;
    },
    {}
  );

  useEffect(() => {
    let isMounted = true;
    (async () => {
      let guideLines: IGuideLinesAndRatesCard[] = await GetPlanningAndRates();

      guideLines = guideLines
        .filter(
          (guideLine) =>
            !guideLine.updateDate.includes("0001") &&
            !guideLine.updateDate.includes("1900") &&
            !guideLine.updateDate.includes("8900")
        )
        .sort(
          (a, b) => parseFloat(a.serialNumber) - parseFloat(b.serialNumber)
        );

      if (isMounted) {
        const indexLastCard = _currentPage * _cardsPerPage;
        const indexFirstCard = indexLastCard - _cardsPerPage;

        _setIndexOfLastCard(indexLastCard);
        _setIndexOfFirstCard(indexFirstCard);
        _setCurrentPage(_currentPage);

        if (guideLines.length > 0) {
          const updateDates = guideLines.map((card) =>
            new Date(card.updateDate).getTime()
          );
          const minDate =
            updateDates.length > 0
              ? new Date(Math.min(...updateDates))
              : new Date();
          _setSelectedFromDate(minDate);
          _setMinFromDate(minDate);

          guideLines = guideLines.filter((guideLine) =>
            IsDateBetween(
              FormatDateYYYYMMDD(minDate),
              FormatDateYYYYMMDD(new Date()),
              FormatDateYYYYMMDD(new Date(guideLine.updateDate))
            )
          );

          _setGuideLinesAndRatesCards(guideLines);
          _setTotalGuideLines(guideLines);
          _setCurrentItemsFound(guideLines.length);
          _setCurrentCardsInPage(
            guideLines.slice(indexFirstCard, indexLastCard)
          );

          const planningAreas = GetDistinctedItems(
            guideLines.map((guideLine) => guideLine.planningAreas)
          );
          _setPlanningAreas(planningAreas);
        }

        _setIsLoading(false);
      }
    })();

    return () => {
      isMounted = false;
    };
  }, []);

  const handlePagination = (selectedPage: number) => {
    const indexLastCard = selectedPage * _cardsPerPage;
    const indexFirstCard = indexLastCard - _cardsPerPage;

    _setIndexOfLastCard(indexLastCard);
    _setIndexOfFirstCard(indexFirstCard);
    _setCurrentCardsInPage(
      _guideLinesAndRatesCards.slice(indexFirstCard, indexLastCard)
    );
    _setCurrentPage(selectedPage);
    mainScroll.scrollTo({ top: 0, behavior: "smooth" });
  };

  const resetFilters = () => {
    const selectedPage = 1;
    const indexLastCard = selectedPage * _cardsPerPage;
    const indexFirstCard = indexLastCard - _cardsPerPage;
    _setIndexOfLastCard(indexLastCard);
    _setIndexOfFirstCard(indexFirstCard);
    _setGuideLinesAndRatesCards(_totalGuideLines);
    _setCurrentCardsInPage(
      _totalGuideLines.slice(indexFirstCard, indexLastCard)
    );
    _setCurrentPage(selectedPage);
    _setInputValue("");
    _setSelectedFromDate(_minFromDate);
    _setSelectedToDate(new Date());
    _setSelectedPlanningArea("");
    _setIsFiltered(false);
    _setIsDatePickerVisibleMobile(false);
    _setFromDatePlaceHolder(t("From-post-date"));
    _setToDatePlaceHolder(t("To-post-date"));
    _setCurrentItemsFound(_totalGuideLines.length);

    const searchGuideLinesInput: HTMLInputElement = document.getElementById(
      "searchGuideLines"
    ) as HTMLInputElement;
    if (searchGuideLinesInput) {
      searchGuideLinesInput.value = "";
    }

    const planningAreasInput: HTMLInputElement = document.getElementById(
      "planningAreas"
    ) as HTMLInputElement;
    if (planningAreasInput) {
      planningAreasInput.value = "";
    }
  };

  const handleSearch = () => {
    _setIsLoadingSearch(true);
    const filterObj: IFilterGuideLinesAndRates = {
      guideLinesAndRates: _totalGuideLines,
      searchValue: _inputValue,
      planningArea: _selectedPlanningArea,
      fromDate: _selectedFromDate,
      toDate: _selectedToDate,
    };
    const searchResults = FilterGuideLinesAndRates(filterObj);
    _setCurrentItemsFound(searchResults.length);

    const currentPage = 1;
    const indexLastCard = currentPage * _cardsPerPage;
    const indexFirstCard = indexLastCard - _cardsPerPage;

    _setCurrentPage(currentPage);
    // _setGuideLinesAndRatesCards(searchResults);
    _setIndexOfLastCard(indexLastCard);
    _setIndexOfFirstCard(indexFirstCard);
    _setGuideLinesAndRatesCards(searchResults);

    _setCurrentCardsInPage(searchResults.slice(indexFirstCard, indexLastCard));
    mainScroll.scrollTo({ top: 0, behavior: "smooth" });

    _setIsFiltersVisible(false);

    if (
      _selectedPlanningArea !== "" ||
      _inputValue !== "" ||
      (_selectedFromDate.getFullYear() !== _minFromDate.getFullYear() &&
        _selectedFromDate.getMonth() !== _minFromDate.getMonth() &&
        _selectedFromDate.getDate() !== _minFromDate.getDate()) ||
      !IsToday(_selectedToDate)
    ) {
      _setIsFiltered(true);
    }

    let timeout = setTimeout(() => {
      _setIsLoadingSearch(false);
      clearTimeout(timeout);
    }, 500);
  };

  const sendEmailToMailingListHandler = () => {
    (async () => {
      const postEmailAdress = await PostEmailList({
        mails: _emailAdressValue,
        name: _nameAdressValue,
        tenderId: _specificGuideline === "" ? undefined : _specificGuideline,
        planningAndRatesType: true,
        page: 11
      });

      if (postEmailAdress.status === 200) {
        _setMailingFormDisplay(false);
      }
    })();
  };



  return (
    <Fragment>
      {!_isLoading ? (
        <div ref={containerRef}>
          {deviceMode === StyleMode.desktop ? (
            <>
              <div style={{ marginInlineStart: `15%`, marginBottom: `1.3rem` }}>
                {/* <a
                  style={{ ...styles.link }}
                  href={`${t(`GuideLinesAndRatesTreeLink`)}`}
                  target="_blank"
                  tabIndex={!IsMobile() ? 0 : -1}
                  aria-label={t("ResetFilters")}
                >
                  {`${t(`GuideLinesAndRatesTreeText`)}`}
                </a> */}
                <PdfDocument details={_guideLinesAndRatesCards} />
                {/* <PdfDownloadButton/> */}
                {/* <MyComponent /> */}
              </div>
              <div style={styles.filters}>
                <InputForm
                  placeholder={t("FreeSearch")}
                  onChange={(e) => {
                    _setInputValue(e);
                  }}
                  type={"Text"}
                  isIcon={true}
                  id={"searchGuideLines"}
                />
                <AutoCompleteForm
                  items={_planningAreas}
                  onItemClick={(selectedItem) => {
                    _setSelectedPlanningArea(selectedItem);
                  }}
                  placeholder={_selectedPlanningArea || t("PlanningAreas")}
                  onChange={(e) => {
                    _setSelectedPlanningArea(e.target.value);
                  }}
                  key={_planningAreas.length}
                  id={"planningAreas"}
                />
                <DatePickerForm
                  icon={Images.blueCalender}
                  onDateSelected={(selectedDate) => {
                    _setSelectedFromDate(selectedDate);
                    _setFromDatePlaceHolder("");
                  }}
                  key={`date_${_selectedFromDate.getDate()}`}
                  date={_selectedFromDate}
                  minDate={_minFromDate}
                  noMinDate={false}
                  maxDate={_selectedToDate}
                  placeholder={_fromDatePlaceHolder}
                />
                <DatePickerForm
                  icon={Images.blueCalender}
                  onDateSelected={(selectedDate) => {
                    _setSelectedToDate(selectedDate);
                    _setToDatePlaceHolder("");
                  }}
                  date={_selectedToDate}
                  minDate={_selectedFromDate}
                  placeholder={_toDatePlaceHolder}
                />
                <button
                  style={styles.searchButton}
                  aria-label={t("SearchFilters")}
                  onClick={handleSearch}
                >
                  {t("StartSearch")}
                </button>
                <span
                  style={styles.clear}
                  onClick={resetFilters}
                  tabIndex={!IsMobile() ? 0 : -1}
                  role={"button"}
                  aria-label={t("ResetFilters")}
                >
                  {t("Clean")}
                </span>
              </div>
            </>
          ) : (
            <div style={styles.filtersMobile}>
              <div
                style={{
                  ...styles.updateMeContainer,
                  fontSize:
                    deviceMode === StyleMode.desktop ? "unset" : "1.5rem",
                }}
              >
                <Fragment>
                  <img
                    src={Images.bellIcon}
                  // style={{ height: isIos ? "1rem" : "1.3rem" }}
                  ></img>
                  <div style={styles.updateMe}>
                    <span
                      tabIndex={0}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        _setIsUpdateMeModalOpen(true);
                      }}
                    >
                      {t("updateMeNewGuidlines")}
                    </span>
                  </div>
                </Fragment>
              </div>
              {!_isLoadingSearch && (
                <div
                  style={{
                    ...styles.topInfoMobileContainer,
                    width: deviceMode === StyleMode.desktop ? "80%" : "100%",
                  }}
                >
                  {" "}
                  <span
                    style={{
                      fontSize: fontSize.Paragraph,
                      color: colors.pasterlGrayDark,
                    }}
                  >
                    {`${t("ResultsCount", {
                      results: _currentItemsFound,
                    })}`}
                  </span>
                  <div
                    style={{
                      fontSize: fontSize.UmbracoElementTitle,
                      color: colors.cyanBold,
                      textDecoration: "underline",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {_isFiltered ? <FaFilter /> : <FiFilter />}
                    <span onClick={() => _setIsFiltersVisible(true)}>
                      {t("Filter")}
                    </span>
                  </div>
                </div>
              )}
            </div>
          )}
          {deviceMode === StyleMode.desktop && (
            <div
              style={{
                display: "flex",
                gap: "3rem",
                paddingTop: "3rem",
                paddingInlineStart: "14rem",
                paddingInlineEnd: "14rem",
                justifyContent: "center",
              }}
            >
              {!_isLoadingSearch && (
                <div style={styles.topInfoMobileContainer}>
                  {" "}
                  <span
                    style={{
                      fontSize: fontSize.Paragraph,
                      color: colors.pasterlGrayDark,
                    }}
                  >
                    {`${t("ResultsCount", {
                      results: _currentItemsFound,
                    })}`}
                  </span>
                  <div style={styles.updateMeContainer}>
                    <Fragment>
                      <div style={styles.bellIcon}></div>
                      <div style={styles.updateMe}>
                        <span
                          tabIndex={0}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            _setIsUpdateMeModalOpen(true);
                          }}
                        >
                          {t("updateMeNewGuidlines")}
                        </span>
                      </div>
                    </Fragment>
                  </div>
                </div>
              )}
            </div>
          )}
          {!_isLoadingSearch ? (
            <>
              <div style={{ ...styles.cards }}>
                {_currentCardsInPage.length > 0 ? (
                  _currentCardsInPage.map((card, index) => (
                    <GuideLinesAndRatesCard
                      key={index}
                      {...card}
                      dynamicPart={dynamicPart}
                      onDisplayModal={(curId) => {
                        _setSpecificGuideline(curId);

                        _setIsUpdateMeModalOpen(true);
                      }}
                      displayUpdateMeOption={systemParamsDictionary["updateSpecificGuidLine"] === "true"}
                    />
                  ))
                ) : (
                  <h1 style={styles.noResults}>{t("NoResultsFound")}</h1>
                )}
              </div>

              {_currentCardsInPage.length > 0 && (
                <PaginationDots
                  active={parseInt(_currentPage.toString())}
                  itemsPerPage={_cardsPerPage}
                  totalItems={_guideLinesAndRatesCards.length}
                  step={2}
                  onClickHandler={handlePagination}
                />
              )}
            </>
          ) : (
            <Loading
              containerStyles={{
                height: "50vh",
                minHeight: "50vh",
              }}
            />
          )}

          {_isFiltersVisible && (
            <div
              style={styles.filtersModalOverlay}
              onClick={() => _setIsFiltersVisible(false)}
            >
              <div
                style={styles.filtersMobileContainer}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: "-3rem",
                    left: i18n.dir() === "rtl" ? "2rem" : "",
                    right: i18n.dir() === "ltr" ? "2rem" : "",
                  }}
                >
                  <img
                    src={Images.closeButtonMob}
                    style={{ height: `auto`, width: `auto` }}
                    alt={``}
                    role={"button"}
                    onClick={() => _setIsFiltersVisible(false)}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    padding: "0 5rem",
                    boxSizing: "border-box",
                  }}
                >
                  <Title text={t("Filter")} />
                  <h2
                    style={{ ...styles.clear, fontSize: fontSize.SubTitle }}
                    onClick={resetFilters}
                  >
                    {t("Clean")}
                  </h2>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "2rem",
                    paddingTop: "1rem",
                    justifyContent: "space-around",
                    alignItems: "center",
                    paddingBottom: "2rem",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "2rem",
                    }}
                  >
                    {!_isDatePickerVisibleMobile ? (
                      <>
                        <InputForm
                          placeholder={t("FreeSearch")}
                          onChange={(e) => {
                            _setInputValue(e);
                          }}
                          type={"Text"}
                          isIcon={true}
                          id={"searchGuideLines"}
                        />
                        <AutoCompleteForm
                          items={_planningAreas}
                          onItemClick={(selectedItem) => {
                            _setSelectedPlanningArea(selectedItem);
                          }}
                          placeholder={
                            _selectedPlanningArea || t("PlanningAreas")
                          }
                          onChange={(e) => {
                            _setSelectedPlanningArea(e.target.value);
                          }}
                          key={_planningAreas.length}
                          id={"planningAreas"}
                        />
                        <DatePickerForm
                          icon={Images.blueCalender}
                          onDateSelected={(selectedDate) => {
                            _setSelectedFromDate(selectedDate);
                            _setFromDatePlaceHolder("");
                          }}
                          key={`fromDate_${_selectedFromDate.getDate()}`}
                          date={_selectedFromDate}
                          minDate={_minFromDate}
                          noMinDate={false}
                          maxDate={_selectedToDate}
                          placeholder={_fromDatePlaceHolder}
                          isButton={true}
                          onClick={() => {
                            _setIsDatePickerVisibleMobile(true);
                            _setSelectedDatePickerTypeMobile("from");
                          }}
                        />
                        <DatePickerForm
                          icon={Images.blueCalender}
                          onDateSelected={(selectedDate) => {
                            _setSelectedToDate(selectedDate);
                            _setToDatePlaceHolder("");
                          }}
                          date={_selectedToDate}
                          minDate={_selectedFromDate}
                          placeholder={_toDatePlaceHolder}
                          key={`toDate_${_selectedFromDate.getDate()}`}
                          isButton={true}
                          onClick={() => {
                            _setIsDatePickerVisibleMobile(true);
                            _setSelectedDatePickerTypeMobile("to");
                          }}
                        />
                      </>
                    ) : (
                      <>
                        <DatePicker
                          onChange={(date) => {
                            if (_selectedDatePickerTypeMobile === "from") {
                              _setSelectedFromDate(date);
                              _setFromDatePlaceHolder("");
                            }
                            if (_selectedDatePickerTypeMobile === "to") {
                              _setSelectedToDate(date);
                              _setToDatePlaceHolder("");
                            }
                            _setIsDatePickerVisibleMobile(false);
                          }}
                          selectedDate={
                            _selectedDatePickerTypeMobile === "from"
                              ? FormatDateYYYYMMDD(_selectedFromDate)
                              : FormatDateYYYYMMDD(_selectedToDate)
                          }
                          minDate={
                            _selectedDatePickerTypeMobile === "from"
                              ? _minFromDate
                              : _selectedDatePickerTypeMobile === "to"
                                ? _selectedFromDate
                                : undefined
                          }
                          maxDate={
                            _selectedDatePickerTypeMobile === "from"
                              ? _selectedToDate
                              : _selectedDatePickerTypeMobile === "to"
                                ? undefined
                                : undefined
                          }
                          showMonthDropdown
                          showYearDropdown
                          showDisabledMonthNavigation
                        />
                      </>
                    )}
                  </div>
                  {!_isDatePickerVisibleMobile && (
                    <button
                      style={{ ...styles.searchButton, minWidth: "50%" }}
                      onClick={handleSearch}
                    >
                      {t("StartSearch")}
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <Loading message={t("GuideLinesAndRatesPageLoading")} />
      )}
      ;
      <ReactModal
        isOpen={_isUpdateMeModalOpen}
        ariaHideApp={false}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            width: "85%",
            maxWidth: "42rem",
            bottom: "auto",
            height: "30rem",
            transform: "translate(-50%, -50%)",
            border: "none",
            padding: "none",
            position: "absolute",
            maxHeight: "100%",
            overflow: "visible",
            borderRadius: "13px 13px 288px 288px / 13px 13px 43px 43px",
          },
          overlay: {
            backgroundColor: colors.modal,
            zIndex: "99999",
            overflow: "hidden",
          },
        }}
        parentSelector={() => document.querySelector("#root") || document.body}
        preventScroll={true}
      >
        <div style={styles.updateMeModalIconWrapper}>
          <div style={styles.updateMeModalIconContainer}>
            <div style={{ ...styles.bellBigIcon }}></div>
          </div>
        </div>
        <img
          role={"button"}
          aria-label={t("closeButtonAria")}
          style={{ ...styles.closeUpdateMeIcon }}
          onClick={() => {
            _setIsUpdateMeModalOpen(false);
            _setSpecificGuideline("")
            _setMailingFormDisplay(true);
          }}
          src={Images.closeBG}
          tabIndex={0}
        />
        <div style={styles.updateMeModalBody}>
          <div style={styles.updateMeModalFormContainer}>
            <div
              style={styles.updateMeModalTitle}
              dangerouslySetInnerHTML={{
                __html: t("updateMeModalTitle"),
              }}
            ></div>
            {_mailingFormDisplay && (
              <InputForm
                placeholder={t("updateMeModalFirstnamePlaceholder")}
                onChange={(e) => {
                  _setNameAdressValue(e);
                }}
                type="Text"
              />
            )}
            {_mailingFormDisplay && (
              <InputForm
                placeholder={t("updateMeModalEmailPlaceholder")}
                onChange={(e) => {
                  _setEmailAdressValue(e);
                }}
                type="Email"
              />
            )}
            {_mailingFormDisplay && (
              <div
                style={
                  _emailAdressValue === "" ||
                    !_emailAdressValue.match(validRegex)
                    ? {
                      ...styles.updateMeButton,
                      pointerEvents: "none",
                      opacity: "0.4",
                    }
                    : {
                      ...styles.updateMeButton,
                    }
                }
                tabIndex={0}
                role={"button"}
                onClick={sendEmailToMailingListHandler}
              >
                {t("updateMeModalButtonText")}
              </div>
            )}
            {!_mailingFormDisplay && !errorDisplayUpdateMe && (
              <div style={styles.updateMeModalTitle}>{t("mailingListAdd")}</div>
            )}
            {errorDisplayUpdateMe && (
              <p>{t("errorMessageUpdateClientNewTenders")}</p>
            )}
          </div>
        </div>
      </ReactModal>
    </Fragment>
  );
};

const styles: StyleSheet = {
  mainContainer: {
    paddingTop: "3rem",
    alignItems: "center",
    flexDirection: "column",
    zIndex: "10",
    minHeight: "100vh",
  },
  title: {
    color: colors.white,
    fontSize: fontSize.PageTitle,
    lineHeight: "3rem",
    letterSpacing: "0.05625rem",
    textShadow: `2px 2px ${colors.black}`,
    fontFamily: fonts.FbReformaMedium,
  },
  breadcrumb: {
    display: "flex",
    color: colors.white,
    fontSize: fontSize.Text,
    letterSpacing: "0.05625rem",
    fontFamily: fonts.FbReformaRegular,
    position: "absolute",
  },
  coverElements: {
    gap: "3rem",
    maxWidth: "76rem",
    margin: "auto",
    lineHeight: "2.5rem",
    padding: "1rem",
  },
  body: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: "90rem",
  },
  searchArea: {
    padding: "2.5rem",
    display: "flex",
    justifyContent: "center",
    gap: "2.5rem",
  },
  filters: {
    display: "flex",
    gap: "3rem",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    width: "100%",
  },
  filtersMobile: {
    display: "flex",
    gap: "1rem",
    // justifyContent: "space-around",
    // alignItems: "center",
    width: "80%",
    flexDirection: "column",
    margin: "auto",
  },
  cards: {
    paddingTop: "3rem",
    display: "flex",
    flexWrap: "wrap",
    gap: "4rem",
    paddingBottom: "3rem",
    justifyContent: "center",
  },
  searchButton: {
    width: "10rem",
    height: "2.5rem",
    backgroundColor: colors.darkBlue,
    border: `1px solid ${colors.darkBlue}`,
    color: colors.white,
    borderRadius: "1.25rem",
    fontFamily: fonts.FbReformaMedium,
    fontSize: fontSize.Text,
    letterSpacing: "0.029375rem",
    cursor: "pointer",
  },
  clear: {
    color: colors.cyanBold,
    cursor: "pointer",
    textDecoration: "underline",
  },
  link: {
    color: colors.cyanBold,
    cursor: "pointer",
  },
  filtersModalOverlay: {
    backgroundColor: colors.modal,
    position: "fixed",
    width: "100%",
    height: "100%",
    zIndex: "99999",
    left: 0,
    top: 0,
  },
  filtersMobileContainer: {
    position: "absolute",
    backgroundColor: "white",
    width: "100%",
    height: "90%",
    maxHeight: "90%",
    bottom: 0,
    borderTopLeftRadius: "3.5rem",
    borderTopRightRadius: "3.5rem",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
  },
  noResults: {
    color: colors.gray,
    fontSize: fontSize.H1,
    letterSpacing: "0.039375rem",
    fontFamily: fonts.FbReformaMedium,
    maxWidth: "35rem",
    lineHeight: "2rem",
    padding: "1.45rem",
  },
  bellIcon: {
    backgroundImage: `url(${Images.bellIcon})`,
    width: "1.2rem",
    height: "1.2rem",
    backgroundRepeat: "no-repeat",
  } as React.CSSProperties,

  updateMeButton: {
    backgroundColor: colors.darkBlue,
    color: colors.white,
    fontFamily: fonts.FbReformaMedium,
    fontSize: "1.2rem",
    borderRadius: "16rem",
    width: "8rem",
    height: "1.8rem",
    fontWeight: "bold",
    letterSpacing: "0.029375rem",
    textAlign: "center",
    paddingTop: ".5rem",
    cursor: "pointer",
  } as React.CSSProperties,
  closeUpdateMeIcon: {
    position: "absolute",
    /* backgroundImage: `url(${Images.closeBG})`,
    backgroundRepeat: "no-repeat",
    width: "2rem",
    height: "1.5rem",*/
    left: "1rem",
    top: "1rem",
    cursor: "pointer",
    // transform: "scale(1.5)",
  } as React.CSSProperties,
  updateMeModalIconContainer: {
    width: "4rem",
    height: "4rem",
    boxShadow: "0px 0px 10px black",
    borderRadius: "50%",
    backgroundColor: "white",
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  } as React.CSSProperties,
  updateMeModalIconWrapper: {
    display: "flex",
    justifyContent: "center",
    position: "relative",
    top: "-2.5rem",
    zIndex: "9999999",
  } as React.CSSProperties,
  updateMeModalBody: {
    position: "absolute",
    top: "12%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  } as React.CSSProperties,
  updateMeModalFormContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "1.5rem",
  } as React.CSSProperties,
  updateMeModalTitle: {
    fontSize: "1.6rem",
    letterSpacing: "0.035625rem",
    lineHeight: "1.75rem",
    fontFamily: fonts.FbReformaRegular,
    color: colors.darkBlue,
    maxWidth: "22rem",
    textAlign: "center",
  } as React.CSSProperties,
  updateMe: {
    fontFamily: fonts.FbReformaRegular,
    //  fontSize: "1.525rem",
    color: "rgb(0, 69, 138)",
  } as React.CSSProperties,
  updateMeContainer: {
    display: "flex",
    gap: ".5rem",
    alignItems: "center",
  } as React.CSSProperties,

  topInfoMobileContainer: {
    display: "flex",
    width: "85%",
    // margin: "auto",
    justifyContent: "space-between",
  } as React.CSSProperties,

  bellBigIcon: {
    backgroundImage: `url(${Images.bellBigIcon})`,
    width: "2rem",
    height: "2rem",
    backgroundRepeat: "round",
  },
};

export default GuideLinesAndRates;
