import Tags from "./Tags";
import { StyleSheet } from "../../../../Interfaces/IStyleSheet";
import colors from "../../../../Assets/Colors/Colors.json";
import fonts from "../../../../Assets/Fonts/Fonts.json";
import fontSize from "../../../../Assets/Fonts/FontsSizes.json";
import { ITitleAndDescription } from "../../../../Interfaces/IBaseElement";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const ArticleMediaTitleDescriptionMobile: React.FC<{
  titleAndDescription: ITitleAndDescription;
  author: string;
  creationDate: Date;
  readingTime: string;
  tags: string[];
  redirectTo?: string;
}> = (props) => {
  const {
    titleAndDescription,
    author,
    creationDate,
    readingTime,
    tags,
    redirectTo,
  } = props;

  const navigate = useNavigate();

  const redirectToPage = (page?: string) => {
    page && navigate(`?page=${page}`);
  };

  return (
    <div style={styles.container}>
      <div style={styles.tags}>
        <Tags tags={tags} />
      </div>
      <div style={styles.bottomContainer}>
        <span
          style={styles.bottomTitle}
          onClick={() => redirectTo && redirectToPage(redirectTo)}
        >
          {titleAndDescription.title}
        </span>
        <span style={styles.bottomText}>
          {author} | {moment(creationDate).format("DD.MM.YY")} | {readingTime}
        </span>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    backgroundColor: "rgba(249, 249, 249, 1)",
  },

  bottomContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "rgba(249, 249, 249, 1)",
    width: "100%",
    minHeight: "6rem",
    bottom: 0,
    left: 0,
    boxSizing: "border-box",
    padding: "1.25rem",
    gap: ".5rem",
  },
  bottomTitle: {
    fontSize: fontSize.SubTitle,
    fontFamily: fonts.FbReformaMedium,
    color: colors.darkBlue,
  },
  bottomText: {
    fontSize: fontSize.Text,
    fontFamily: fonts.FbReformaLight,
    color: colors.nero,
  },
  tags: {
    display: "flex",
    flexDirection: "column",
    padding: "0 1rem",
    boxSizing: "border-box",
  },
};

export default ArticleMediaTitleDescriptionMobile;
