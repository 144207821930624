import { t } from "i18next";
import { useState } from "react";
import ReactModal from "react-modal";
import { useNavigate } from "react-router-dom";
import fontSize from "../../Assets/Fonts/FontsSizes.json";
import colors from "../../Assets/Colors/Colors.json";
import Images from "../../Data/Images/Images";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import { IsMobile } from "../../Services/DeviceService";
import i18n from "../../Services/i18n";
import IconAndText, { IIconAndText } from "../IconAndText";
import useDeviceMode from "../../Utils/useWindowDimensions";
import { StyleMode } from "../../Utils/consts";

export interface IServicesCustomerOptions {
  services: IIconAndText[];
}
const ServicesCustomerOptions: React.FC<IServicesCustomerOptions> = (props) => {
  const { services } = props;
  const navigate = useNavigate();
  const [_selectedItem, _setSelecedItem] = useState<IIconAndText>();
  const [_modalVisible, _setModalVisible] = useState(false);
  const [_isYesNoModal, _setIsYesNoModal] = useState(false);
  const deviceMode = useDeviceMode();

  const redirectToPage = (page?: string) => {
    page && navigate(`?page=${page}`);
  };
  const handleCustomerServiceSelection = (
    customerServiceItem: IIconAndText
  ) => {
    _setSelecedItem(customerServiceItem);

    switch (customerServiceItem.openMode) {
      case "Modal":
        _setModalVisible(true);
        break;
      case "New Window":
        if (customerServiceItem.openType === "External") {
          window.open(customerServiceItem.link, "_blank");
        } else if (customerServiceItem.openType === "Internal") {
          redirectToPage(customerServiceItem.link);
        }
        break;
    }
  };
  const handleOpenYesNoModal = () => {
    _setIsYesNoModal(true);
  };
  return (
    <>
      <div
        className="max-width"
        id="pageMainContent"
        tabIndex={!IsMobile() ? 0 : -1}
      >
        <div
          className="row"
          style={{
            gap: "2.5rem",
            flexWrap: "wrap",
            justifyContent: "center",
            paddingTop: deviceMode !== StyleMode.desktop ? "2rem" : 0,
          }}
        >
          {services.map((item, index) => (
            <IconAndText
              key={index}
              {...item}
              style={{
                translate: deviceMode === StyleMode.desktop ? "0 -50%" : "0 0",
                width: "10rem",
              }}
              onClick={(clicked) => handleCustomerServiceSelection(clicked)}
            />
          ))}
        </div>
      </div>
      {_modalVisible && (
        <ReactModal
          // shouldCloseOnOverlayClick={true}
          isOpen={true}
          onRequestClose={() => {
            _setModalVisible(false);
          }}
          style={{
            content: styles.modalContent,

            overlay: {
              backgroundColor: colors.modal,
              zIndex: "99999",
              overflow: "hidden",
            },
          }}
          appElement={document.getElementById("#root") || document.body}
          parentSelector={() =>
            document.querySelector("#root") || document.body
          }
          preventScroll={true}
        >
          <img
            src={Images.closeButton}
            style={{
              ...styles.closeMainModalIcon,
              left: i18n.dir() === "rtl" ? "-1rem" : "",
              right: i18n.dir() === "ltr" ? "1rem" : "",
            }}
            onClick={handleOpenYesNoModal}
            onKeyPressCapture={handleOpenYesNoModal}
            tabIndex={!IsMobile() ? 0 : -1}
            width={`auto`}
            height={`auto`}
            alt={``}
          />

          <iframe
            src={_selectedItem?.link}
            width={"100%"}
            height={"100%"}
            tabIndex={!IsMobile() ? 0 : -1}
            aria-label={`${_selectedItem?.title} iframe`}
          ></iframe>
        </ReactModal>
      )}
      {_isYesNoModal && (
        <ReactModal
          isOpen={true}
          style={{
            content: styles.yesNoModalContent,
            overlay: {
              backgroundColor: colors.modal,
              zIndex: "999999",
              overflow: "hidden",
            },
          }}
          appElement={document.getElementById("#root") || document.body}
          parentSelector={() =>
            document.querySelector("#root") || document.body
          }
          preventScroll={true}
        >
          <div style={styles.yesNoModalContainer}>
            <div
              style={styles.yesNoModalTitle}
              tabIndex={!IsMobile() ? 0 : -1}
              aria-label={`${t("Close-window-question")}`}
            >
              {t("Close-window-question")}
            </div>
            <div
              style={{
                display: "flex",
                gap: "1rem",
              }}
            >
              <div
                style={styles.yesButton}
                onClick={() => {
                  _setIsYesNoModal(false);
                  _setModalVisible(false);
                }}
                onKeyPressCapture={() => {
                  _setIsYesNoModal(false);
                  _setModalVisible(false);
                }}
                role={`button`}
                tabIndex={!IsMobile() ? 0 : -1}
              >
                {t("Yes")}
              </div>
              <div
                style={styles.noButton}
                onClick={() => {
                  _setIsYesNoModal(false);
                  _setModalVisible(true);
                }}
                onKeyPressCapture={() => {
                  _setIsYesNoModal(false);
                  _setModalVisible(true);
                }}
                role={`button`}
                tabIndex={!IsMobile() ? 0 : -1}
              >
                {t("No")}
              </div>
            </div>
          </div>
        </ReactModal>
      )}
    </>
  );
};

const styles: StyleSheet = {
  modalContent: {
    display: "flex",
    flexDirection: "column",
    top: "50%",
    left: "50%",
    right: "auto",
    maxWidth: "90.625rem",
    width: "90%",
    bottom: "auto",
    height: "50rem",
    // height: deviceMode === StyleMode.desktop ? "40rem" : "80rem",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "none",
    position: "absolute",
    borderRadius: "0.625rem",
    maxHeight: "100%",
    overflow: "unset",
  },
  yesNoModalContent: {
    display: "flex",
    flexDirection: "column",
    top: "50%",
    left: "50%",
    right: "auto",
    maxWidth: "35rem",
    width: "80%",
    bottom: "auto",
    height: "15rem",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "none",
    position: "absolute",
    borderRadius: "0.625rem",
    maxHeight: "100%",
    overflow: "hidden",
    justifyContent: "center",
  },
  yesNoModalContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "2rem",
  },
  yesNoModalTitle: {
    fontSize: fontSize.UmbracoElementTitle,
  },
  yesButton: {
    backgroundColor: colors.darkBlue,
    color: colors.white,
    fontSize: fontSize.Text,
    borderRadius: "16rem",
    width: "8rem",
    height: "1.8rem",
    fontWeight: "bold",
    textAlign: "center",
    paddingTop: ".5rem",
    cursor: "pointer",
  },
  noButton: {
    backgroundColor: colors.white,
    color: colors.darkBlue,
    fontSize: fontSize.Text,
    borderRadius: "16rem",
    width: "8rem",
    height: "1.8rem",
    fontWeight: "bold",
    textAlign: "center",
    paddingTop: ".5rem",
    cursor: "pointer",
    border: `.1rem solid ${colors.darkBlue}`,
  },
  closeMainModalIcon: {
    position: "absolute",
    top: "-.5rem",
    cursor: "pointer",
    borderRadius: "50%",
    backgroundColor: colors.white,
  },
};

export default ServicesCustomerOptions;
