import { t } from "i18next";
import { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
import colors from "../Assets/Colors/Colors.json";
import fonts from "../Assets/Fonts/Fonts.json";
import fontSize from "../Assets/Fonts/FontsSizes.json";
import AllElements from "../Components/UmbracoElements/AllElements";
import Breadcrumb from "../Components/UmbracoElements/Breadcrumb";
import { StyleSheet } from "../Interfaces/IStyleSheet";
import { ChangeTitle, StyleMode, UpdateSeo } from "../Utils/consts";
import useDeviceMode from "../Utils/useWindowDimensions";
import i18n from "../Services/i18n";
import Images from "../Data/Images/Images";
import { UseWindowScrollPositions } from "../Utils/useWindowScrollPositions";
import { IsMobile } from "../Services/DeviceService";
import { useSelector } from "react-redux";
import { SystemParamsState } from "../Redux/Slices/systemParamsSlice";
import { ISystemParam } from "../Interfaces/IConfig";
const NewCareer: React.FC<any> = (props) => {
  const [_currentItemIndex, _setCurrentItemIndex] = useState(0);
  const [_isArrowAnimation, _setIsArrowAnimation] = useState(true);
  const [_isCareerArrowUp, _setIsCareerArrowUp] = useState(false);
  const [_isCarrerArrowExists, _setIsCarrerArrowExists] = useState(false);
  const deviceMode = useDeviceMode();
  const { scrollY } = UseWindowScrollPositions();

  const handlePageScrolls = (element: Element) => {
    if (!_isCareerArrowUp) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else {
      const mainScroll = document.getElementById("mainScroll")!;
      mainScroll.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    const elements = document.getElementsByClassName("banner-search-job");
    if (elements && elements.length > 0) {
      const elementContainer = elements[0];
      if (
        elementContainer.getBoundingClientRect().bottom > scrollY ||
        scrollY === 0
      ) {
        _setIsCareerArrowUp(false);
      } else {
        _setIsCareerArrowUp(true);
      }
    }
  }, [scrollY]);

  useEffect(() => {
    ChangeTitle(props?.title?.text);
    UpdateSeo(
      props.seoTitle,
      props.seo,
      props.seoSocialNetworksTitle,
      props.seoSocialNetworksDescription,
      props.seoSocialNetworksImage
    );
  }, []);

  useEffect(() => {
    const elements = document.getElementsByClassName("banner-search-job");
    if (elements && elements.length === 0) {
      _setIsCarrerArrowExists(false);
    } else {
      _setIsCarrerArrowExists(true);
    }
  }, [])

  const systemParams = (
    useSelector((state: any) => state.SystemParamsReducer) as SystemParamsState
  ).systemParams;

  let systemParamsDictionary = systemParams.reduce(
    (result: { [key: string]: string }, current) => {
      result[current.key] = current.value;
      return result;
    },
    {}
  );

  const isCarrerScrollVisible = systemParamsDictionary["careerPage_scroll"]
    ? systemParamsDictionary["careerPage_scroll"] === "true"
      ? true
      : systemParamsDictionary["careerPage_scroll"] === "false"
      ? false
      : false
    : false;

  return (
    <>
      {_isCarrerArrowExists && isCarrerScrollVisible && (
        <div
          style={{
            ...styles.moreCareersContainer,
            width: "100%",
            left: deviceMode === StyleMode.desktop ? (i18n.dir() === "rtl" ? "0" : "") :  (i18n.dir() === "rtl" ? "" : "0"),
            right: deviceMode === StyleMode.desktop ? (i18n.dir() === "ltr" ? "0" : "") : (i18n.dir() === "ltr" ? "" : "0"),
            borderTopRightRadius: deviceMode === StyleMode.desktop ? (i18n.dir() === "rtl" ? "1.5rem" : "") : (i18n.dir() === "rtl" ? "" : "1.5rem"),
            borderBottomRightRadius: deviceMode === StyleMode.desktop ? (i18n.dir() === "rtl" ? "1.5rem" : "") : (i18n.dir() === "rtl" ? "" : "1.5rem"),
            borderTopLeftRadius: deviceMode === StyleMode.desktop ? (i18n.dir() === "ltr" ? "1.5rem" : "") : (i18n.dir() === "ltr" ? "" : "1.5rem"),
            borderBottomLeftRadius: deviceMode === StyleMode.desktop ? (i18n.dir() === "ltr" ? "1.5rem" : "") : (i18n.dir() === "ltr" ? "" : "1.5rem"),
          }}
          onClick={() => {
            const elements =
              document.getElementsByClassName("banner-search-job");
            if (elements && elements.length > 0) {
              const elementContainer = elements[0];
              handlePageScrolls(elementContainer);
            }
          }}
        >
          <img src={Images.misraIcon} width={"60"} />
          <span>
            {t(
              !_isCareerArrowUp
                ? "MoreCareersFloatButton"
                : "MoreCareersFloatButtonUp"
            )}
          </span>
          <div
            style={{
              ...styles.moreCareersArrow,
              borderBottomRightRadius: deviceMode === StyleMode.desktop ? (i18n.dir() === "rtl" ? "1.5rem" : "") : (i18n.dir() === "rtl" ? "" : "1.5rem"),
              borderBottomLeftRadius: deviceMode === StyleMode.desktop ? (i18n.dir() === "ltr" ? "1.5rem" : "") : (i18n.dir() === "ltr" ? "" : "1.5rem"),
            }}
          >
            <img
              src={
                _isCareerArrowUp ? Images.arrowUpWhite : Images.arrowDownWhite
              }
              style={{
                borderRadius: "50%",
                scale: "1.5",
              }}
            />
          </div>
        </div>
      )}
      <div style={{ overflow: "hidden" }}>
        <div
          style={{
            ...styles.mainHeader,
             maxHeight: deviceMode === StyleMode.desktop ? "40vh" : "147.49px",
            backgroundImage: `url(${props?.coverImage?._url})`,
          }}
        >
          <div style={styles.cover}>
            <div style={{ ...styles.maxWidth, width: "100%" }}>
              <div
                style={{
                  ...styles.breadcrumb,
                  paddingTop: "2rem",
                  marginInlineStart: deviceMode !== StyleMode.desktop ? "2rem" : "",
                  color: "white",
                }}
              >
                <Breadcrumb items={[{ text: t("career"), link: "career" }]} />
              </div>
              <h1
                style={{
                  color: "white",
                  fontSize: deviceMode === StyleMode.desktop ? "4rem" : "3rem",
                  lineHeight: 1,
                  margin: 0,
                  marginInlineStart: deviceMode !== StyleMode.desktop ? "2rem" : "",
                }}
              >
                {props.title?.text}
              </h1>
            </div>
          </div>
        </div>

        <div style={{ paddingTop: "3rem" }}>
          {props?.elements?.map((item: any, index: number) => (
            <AllElements
              key={index}
              elementName={item.contentTypeAlias}
              {...item}
            />
          ))}
        </div>
      </div>
    </>
  );
};

const styles: StyleSheet = {
  wrap: {
    display: "flex",
    flexWrap: "wrap",
  },
  bgGray: {
    backgroundColor: "#F9F9F9",
  },
  title: {
    fontSize: fontSize.PageTitle,
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaLight,
    lineHeight: "3rem",
    margin: 0,
  },
  subtitle: {
    fontSize: fontSize.H1,
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaLight,
    margin: 0,
  },
  info: {
    fontSize: fontSize.Paragraph,
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaLight,
    margin: 0,
  },
  inTitle: {
    fontSize: fontSize.PageTitle,
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaMedium,
  },
  mainHeader: {
    display: "flex",
    height: "100vh",
    maxHeight: "11.5rem",
    backgroundSize: "cover",
    backgroundPosition: "100%",
  },
  cover: {
    backgroundColor: "rgba(0, 70, 143, 0.5)",
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  maxWidth: {
    maxWidth: "68rem",
    margin: "auto",
    paddingBlock: "2rem",
    paddingInline: "1rem",
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  column: {
    display: "flex",
    flexDirection: "column",
  },
  btn: {
    borderRadius: "60px",
    backgroundColor: "rgb(249, 186, 77)",
    border: "0px",
    fontSize: fontSize.Text,
    padding: "1rem",
    fontFamily: fonts.FbReformaMedium,

    width: "16rem",
  },
  btnOutline: {
    backgroundColor: "transparent",
    color: colors.darkBlue,
    border: "1px solid",
  },
  wrapperInfo: {
    flex: "1.5 1 0%",
    flexDirection: "column",
    gap: "1rem",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    textAlign: "center",
  },
  infoBlue: {
    fontSize: fontSize.CareerElements,
    fontFamily: fonts.FbReformaMedium,
    lineHeight: "normal",
    color: colors.darkBlue,
  },
  name: {
    fontSize: fontSize.H1,
    color: "rgb(249, 186, 77)",
  },
  lineDivider: {
    width: 1,

    backgroundColor: colors.darkBlue,
  },
  description: {
    fontFamily: fonts.FbReformaLight,
    color: colors.darkBlue,
    fontSize: fontSize.UmbracoElementTitle,
    letterSpacing: "0.023rem",
    lineHeight: "2rem",
  },
  cardJob: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0 2px 23px 0 rgb(0 0 0 / 8%)",

    padding: "1rem",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    flex: 1,
  },
  tags: {
    display: "flex",
    flexWrap: "wrap",
  },
  tag: {
    border: "1px solid #00458a",
    borderRadius: "50px",
    minWidth: "4.4rem",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#D8F2FC",
    textAlign: "center",
    display: "inline-flex",
  },
  linkMoreInfo: {
    color: "#009DD6",
    fontSize: fontSize.Paragraph,
    letterSpacing: "0.55px",
    lineHeight: "24px",
  },
  whyToWork: {
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
  },
  whyTitle: {
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaRegular,
    fontSize: fontSize.H1,
  },
  infoBlack: {
    color: "#282828",
    fontSize: fontSize.UmbracoElementTitle,
    letterSpacing: "0.68px",
    fontFamily: fonts.FbReformaLight,

    lineHeight: "32px",
  },
  // ".boxss": { backgroundColor: "#D8F3FE" },
  topBox: {
    backgroundColor: "#D8F3FE",
    padding: "1rem",
    flexDirection: "column",
    display: "flex",
    gap: "7.5px",
  },
  letsTalks: {
    color: colors.darkBlue,
    fontSize: fontSize.UmbracoElementTitle,
    fontFamily: fonts.FbReformaLight,
    letterSpacing: "0.44px",
    lineHeight: "30px",
  },
  letsNote: {
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaMedium,
    fontSize: fontSize.UmbracoElementTitle,
    letterSpacing: "0.75px",
    lineHeight: "27px",
  },
  letsDescription: {
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaRegular,
    fontSize: fontSize.Text,
    letterSpacing: "0.57px",
    lineHeight: "18px",
  },
  moreCareersContainer: {
    position: "fixed",
    top: "50%",
    textAlign: "center",
    maxWidth: "12rem",
    backgroundColor: colors.white,
    // border: `1px solid ${colors.darkBlue}`,
    zIndex: "10",
    boxShadow: "#00000094 0px 2px 10px 0px",
    padding: ".25rem",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",
  },
  moreCareersArrow: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.darkBlue,
    marginTop: "1rem",
  },
};
export default NewCareer;
