import { useState } from "react";
import colors from "../../Assets/Colors/Colors.json";
import fonts from "../../Assets/Fonts/Fonts.json";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import Title from "../UmbracoElements/Title";
import fontSize from "../../Assets/Fonts/FontsSizes.json";

export interface IInfoCareer {
  title: string;
  description: string;
  img:string,
  maxWidth?:string
}

const TextAndBGModal: React.FC<IInfoCareer> = (props) => {
  const { title, description,img ,maxWidth} = props;
  const [_translate, _setTranslate] = useState<any>({});

  return (
    <div style={{
      ...styles.modalContainer,
      backgroundImage: `url(${img})`,
      backgroundSize: "cover",
      maxWidth: maxWidth
    
    }}>
      <div style={{
        padding: "2rem 3rem",
        backgroundColor: colors.whiteTransparent,
        minHeight:"50vh"
      }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        
        }}
        // tabIndex={!IsMobile() ? 0 : -1}
      >
        <Title text={title} />
        <p style={{fontSize:"1.1rem"}}>{description}</p>
        </div>
        </div>
    </div>
  );
};

const styles: StyleSheet = {
  modalContainer: {
    
    maxHeight: "100%",
    overflow: "auto",
  },
  title: {
    color: colors.darkBlue,
    fontSize: fontSize.UmbracoElementTitle,
    fontFamily: fonts.FbReformaMedium,
  },
  propertyInfo: {
    fontSize: fontSize.Paragraph,
    fontFamily: fonts.FbReformaRegular,
  },
  propertyInfoContainer: {
    display: "flex",
    gap: "5rem",
  },
  propertyInfoBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    width: "14rem",
    paddingTop: "1rem",
    paddingBottom: "1rem",
  },
  description: {
    fontFamily: fonts.FbReformaRegular,
    fontSize: fontSize.FooterItem,
    lineHeight: "2rem",
    maxWidth: "80%",
  },
  button: {
    backgroundColor: colors.darkBlue,
    color: colors.white,
    fontSize: fontSize.Text,
    borderRadius: "16rem",
    borderColor: colors.darkBlue,
    minWidth: "8rem",
    height: "2rem",
    fontWeight: "bold",
    textAlign: "center",
    cursor: "pointer",
  },
};

export default TextAndBGModal;
