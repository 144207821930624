import { t } from "i18next";
import moment from "moment";
import { Fragment, useEffect, useRef, useState } from "react";
import ReactModal from "react-modal";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import store from "store2";
import colors from "../../Assets/Colors/Colors.json";
import fonts from "../../Assets/Fonts/Fonts.json";
import fontSize from "../../Assets/Fonts/FontsSizes.json";
import Loading from "../../Components/Loading/Loading";
import NoTrains from "../../Components/NoTrains/NoTrains";
import RouteDetails from "../../Components/RouteDetails/RouteDetails";
import RoutePlanBar from "../../Components/RoutePlanBar/RoutePlanBar";
import RoutePlanMultiProps from "../../Components/RoutePlanBar/RoutePlanMultiProps";
import { TabsColumn } from "../../Components/Tabs/Tabs";
import TrainCard from "../../Components/TrainCard/TrainCard";
import Config from "../../Configuration/server";
import Images from "../../Data/Images/Images";
import { IRoutePlanSearchResultsDesktop } from "../../Interfaces/IRoutePlanSearchResults";
import { IStation } from "../../Interfaces/IStation";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import { ITab } from "../../Interfaces/ITabs";
import { ITrain } from "../../Interfaces/ITrain";
import { PageState } from "../../Redux/Slices/pageSlice";
import {
  ScheduleState,
  ScheduleStateBuild,
} from "../../Redux/Slices/scheduleSlice";
import { IsMobile } from "../../Services/DeviceService";
import { GetSortedStations } from "../../Services/SearchStationsAutoCompleteService";
import i18n from "../../Services/i18n";
import {
  GetStationsDictionary,
  IDictStation,
  getMaxDistance,
  getTravelPrice
} from "../../Utils/helpers";
// import { createEvent, EventAttributes } from "ics";
import ShareModal from "../../Components/Modal/ShareModal";
import {
  linkBuilder,
  linkShareFormat,
} from "../../Components/SharePage/ShareOptions";
import RouteToCalendar from "./RouteToCalendar";
import { Translations } from "../../Services/GlobalService";
import { GeoToKm } from "../../Services/LocationService";
import { StyleMode } from "../../Utils/consts";
import useDeviceMode from "../../Utils/useWindowDimensions";

const RoutePlanSearchResults: React.FC<IRoutePlanSearchResultsDesktop> = (
  props
) => {
  const {
    travels,
    isLoading,
    isNoTrains,
    selectedTravel,
    noTrainsInfo,
    selectedTrainKey,
    dateTitle,
    onClickTravelCard,
    getDay,
    onClickRoutePlanBarSearch,
    onSearchMulti,
    onTabClick,
    isToday,
    multisearch,
  } = props;

  const [_stationKey, _setStationKey] = useState<IDictStation>({});
  const [_station, _setStation] = useState<IStation[]>([]);
  const [_tabs, _setTabs] = useState<ITab[]>([]);
  const [_tabSelected, _setTabSelected] = useState<string>("");
  const [_selectedTrainToShare, _setSelectedTrainToShare] = useState<ITrain>();
  const [_isShareModalVisible, _setIsShareModalVisible] = useState(false);
  const [_travelKm, _setTravelKm] = useState("");
  const [_isAddCalendarModalVisible, _setIsAddCalendarModalVisible] =
    useState(false);
  const [_selectedTrainToCalendar, _setSelectedTrainToCalendar] =
    useState<ITrain>();

  const [_isNotificationListVisible, _setIsNotificationListVisible] =
    useState(false);

  const [_currentNotificationTrainIndex, _setCurrentNotificationTrainIndex] =
    useState(-1);

  const titleRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const deviceMode = useDeviceMode();
  const Translation = Translations[
    i18n.language as `he` | `en` | `ar` | `ru`
  ] as any;

  const schedule = useSelector(
    (state: any) => state.ScheduleReducer
  ) as ScheduleState;

  const currentPage = (
    useSelector((state: any) => state.PageReducer) as PageState
  ).page;

  const getTotalTaarif = async () => {
    const stations: IStation[] = store.session.get(
      `${Config.BASE_LOCALSTORAGE_NAME}stations`
    )[i18n.language];
    const dictStations = GetStationsDictionary(stations);

    if (
      schedule.fromStation !== undefined &&
      schedule.toStation !== undefined
    ) {
      const fromStationLongLant = [
        dictStations[schedule.fromStation].location.latitude,
        dictStations[schedule.fromStation].location.lontitude,
      ];
      const toStationLongLant = [
        dictStations[schedule.toStation].location.latitude,
        dictStations[schedule.toStation].location.lontitude,
      ];
      const travelPriceInfo = await getTravelPrice({fromStation:schedule.fromStation,toStation:schedule.toStation});

      let distance = GeoToKm(fromStationLongLant, toStationLongLant);
      const maxDistance = travelPriceInfo.search?.maxRange;
      if (maxDistance) {
        const maxKm = maxDistance.split("-").pop();
        if (maxKm) {
          _setTravelKm(maxKm);
        }
      }
    }
  };

  useEffect(() => {
    if (titleRef.current) {
      titleRef.current.focus();
      titleRef.current.style.outline = "none";
      titleRef.current.tabIndex = -1;
    }
  }, [location, currentPage]);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    multisearch && SearchBarParams();
  }, [_stationKey]);

  const SearchBarParams = () => {
    let url = new URLSearchParams(window.location.search);
    let params: any = {
      fromStation: url.get("fromStation")?.split(","),
      toStation: url.get("toStation")?.split(","),
      date: url.get("date")?.split(","),
      hours: url.get("hours")?.split(","),
      minutes: url.get("minutes")?.split(","),
      scheduleType: url.get("scheduleType")?.split(","),
    };
    let _schedules: ScheduleStateBuild[] = [];
    for (let index = 0; index < params.fromStation.length; index++) {
      let obj = {
        fromStation: params.fromStation[index],
        toStation: params.toStation[index],
        date: params.date[index],
        hours: params.hours[index],
        minutes: params.minutes[index],
        scheduleType: params.scheduleType[index],
      };
      _schedules.push(obj);
    }
    SetTab(_schedules);
  };
  const init = () => {
    let res = GetSortedStations();
    let resKey = GetStationsDictionary(res);
    _setStationKey(resKey);
    _setStation(res);
    getTotalTaarif();
  };

  const SetTab = (schedules: ScheduleStateBuild[]) => {
    let Tabs: ITab[] = [];
    for (let index = 0; index < schedules.length; index++) {
      const element = schedules[index];
      if (element.fromStation && element.toStation) {
        Tabs.push({
          id: `${element.fromStation}-${element.toStation}-${index}`,
          name: `${_stationKey[element.fromStation]?.stationName}-${
            _stationKey[element.toStation]?.stationName
          }`,
          isSelected: index === 0,
        });
      }
      index === 0 &&
        _setTabSelected(`${element.fromStation}-${element.toStation}-${index}`);
    }

    _setTabs(Tabs);
  };
  const getShareTravelUrl = (url: string) => {
    let allStations = store.session.get(
      `${Config.BASE_LOCALSTORAGE_NAME}stations`
    );
    let Stations: any = GetStationsDictionary(
      allStations ? allStations[i18n.language] : []
    );
    let fromStation = schedule.fromStation
      ? Stations[schedule.fromStation].stationName
      : "";
    let toStation = schedule.toStation
      ? Stations[schedule.toStation].stationName
      : "";
    if (_selectedTrainToShare) {
      const date = moment(_selectedTrainToShare.departureTime).toDate();
      const dateFormat = moment(date).format("yyyyMMDD");

      const hourNumber = date.getHours();
      const minuteNumber = date.getMinutes();
      const hours = hourNumber <= 9 ? `0${hourNumber}` : hourNumber.toString();
      const minutes =
        minuteNumber <= 9 ? `0${minuteNumber}` : minuteNumber.toString();

      const description = `${Translation["travel by train"]} ${Translation["from station"]} ${fromStation} ${Translation["to station"]} ${toStation}%0A`;

      const andTime = `${t("date")}: ${dateFormat}%0A${t(
        "HourText"
      )}: ${hours}:${minutes}%0A`;

      const navigateUrl = `https://www.rail.co.il/mldp?FSID=${
        schedule.fromStation
      }&TSID=${
        schedule.toStation
      }&Date=${dateFormat}&Hour=${hours}${minutes}&Train=${_selectedTrainToShare}`.replaceAll(
        "&",
        "%26"
      );

      linkShareFormat.title = description;
      linkShareFormat.subject = t("sharedWithYou") + " " + description;
      linkShareFormat.body = encodeURIComponent(
        description + andTime + " " + navigateUrl
      );
      linkShareFormat.summary = encodeURIComponent(description + " " + andTime);
      linkShareFormat.text = encodeURIComponent(
        description + andTime + " " + navigateUrl
      );
      linkShareFormat.quote = description;
      linkShareFormat.url = encodeURIComponent(navigateUrl);
      linkShareFormat.u = encodeURIComponent(navigateUrl);
      // sharePagetext
      let newLink = linkBuilder(url, linkShareFormat);
      return decodeURI(newLink);
    }
    return "";
  };

  // const handleAddToCalendar = () => {
  //   if (_selectedTrainToShare) {
  //     const departureDate = new Date(_selectedTrainToShare.departureTime);
  //     const arrivalDate = new Date(_selectedTrainToShare.arrivalTime);

  //     const year = departureDate.getFullYear();
  //     const month = departureDate.getMonth() + 1;
  //     const day = departureDate.getDate();
  //     const hours = departureDate.getHours();
  //     const minutes = departureDate.getMinutes();

  //     const timeDurationMS = GetDurationTimeMS(departureDate, arrivalDate);
  //     const timeDurationFormat = GetDurationFormatted(timeDurationMS);

  //     let hoursDuration;
  //     let minutesDuration;
  //     if (timeDurationFormat.indexOf(":") === -1) {
  //       hoursDuration = 0;
  //       minutesDuration = +timeDurationFormat;
  //     } else {
  //       hoursDuration = +timeDurationFormat.split(":")[0];
  //       minutesDuration = +timeDurationFormat.split(":")[1];
  //     }

  //     const allStations = store.session.get(
  //       `${Config.BASE_LOCALSTORAGE_NAME}stations`
  //     );
  //     const stations: IStation[] = allStations && allStations[i18n.language];
  //     const stationsDict = GetStationsDictionary(stations);

  //     const title = t("TravelCalander", {
  //       fromStation: stationsDict[schedule.fromStation!].stationName,
  //       toStation: stationsDict[schedule.toStation!].stationName,
  //     });

  //     // const event: EventAttributes = {
  //     //   start: [year, month, day, hours, minutes],
  //     //   duration: { hours: hoursDuration, minutes: minutesDuration },
  //     //   title: title,
  //     // };

  //     // createEvent(event, (error, value) => {
  //     //   if (error) {
  //     //     console.log(error);
  //     //     return;
  //     //   }
  //     //   const blob = new Blob([value], { type: "text/plain;charset=utf-8" });
  //     //   saveAs(blob, "event-schedule.ics");
  //     // });
  //   }
  // };

  // window.innerWidth-10 10 pixels, to make sure the title does not exceed the scroll
  return (
    <Fragment>
      <div style={styles.mainWrapper}>
        <div
          className="route-plan-bar"
          // style={{ width: window.innerWidth - 10 }}
        >
          <div
            ref={titleRef}
            style={{ position: "absolute", zIndex: -1, color: "transparent" }}
            tabIndex={!IsMobile() ? 0 : -1}
          >
            {t("CurrentPage", {
              page: t("SearchResults"),
            })}
          </div>

          {multisearch ? (
            <RoutePlanMultiProps
              multisearch={multisearch}
              onSearchMulti={onSearchMulti}
              onTabClick={onTabClick}
              onSearchTrainsClick={onClickRoutePlanBarSearch}
              isLoading={isLoading}
            />
          ) : (
            <RoutePlanBar
              onSearchTrainsClick={onClickRoutePlanBarSearch}
              isLoading={isLoading}
            />
          )}
        </div>
        <div style={{ display: "flex", width: "100%" }}>
          {multisearch && _tabs.length > 0 && (
            <TabsColumn
              tabs={_tabs}
              onTabClick={(select: ITab) => {
                let indexOf = _tabs.findIndex((item) => item.id === select.id);
                let indexOfSelect = _tabs.findIndex((item) => item.isSelected);
                _tabs[indexOf].isSelected = true;
                _tabs[indexOfSelect].isSelected = false;
                _setTabs([..._tabs]);
                _setTabSelected(select.id);
                props.onTabClick && props.onTabClick(select.id);
              }}
            />
          )}
          {isLoading ? (
            <Loading
              message="Loading-schedule"
              containerStyles={{ backgroundColor: colors.ghostWhite }}
            />
          ) : (
            <Fragment>
              {!isNoTrains ? (
                <div style={styles.body} className="body-list-trains">
                  <div
                    style={styles.trainsCardsWrapper}
                    id="trainsCardsWrapper"
                    className="list-trains"
                  >
                    <h1
                      style={{
                        ...styles.availableTrains,
                        ...styles.dateTitle,
                        flexDirection: "column",
                        gap: "1rem",
                        // width: "50%",
                        padding: "0 15%",
                        marginInlineEnd: "2rem",
                        alignItems: "unset",
                      }}
                      tabIndex={!IsMobile() ? 0 : -1}
                    >
                      <span
                        style={{
                          fontFamily: fonts.FbReformaMedium,
                          fontSize: fontSize.Paragraph,
                        }}
                      >
                        {t("Available-trains")}{" "}
                      </span>
                      <span
                        style={{
                          fontWeight: "300",
                          fontFamily: fonts.FbReformaLight,
                          fontSize: fontSize.SubTitle,
                        }}
                      >
                        <b>{dateTitle}</b> |{" "}
                        {t("TaarifKmDistance", {
                          km: _travelKm,
                        })}
                      </span>
                      <span></span>
                    </h1>
                    <div style={styles.trainsCards} className="trains-cards">
                      <div style={styles.dateTitle}>
                        <div></div>
                        <div>
                          {!isToday && (
                            <div
                              style={{
                                ...styles.nextPreviousContainer,
                                paddingInlineStart:
                                  deviceMode === StyleMode.desktop
                                    ? "15rem"
                                    : "",
                              }}
                              onClick={() => {
                                getDay("searchTrainLuzBeforeDate");
                              }}
                            >
                              <button
                                style={styles.nextPrevious}
                                role={"button"}
                                tabIndex={!IsMobile() ? 0 : -1}
                                aria-label={t("View-previous-day")}
                              >
                                {t("View-previous-day")}
                              </button>
                              <img
                                src={Images.arrowUp}
                                style={styles.arrowIcon}
                                width={`auto`}
                                height={`auto`}
                                alt={``}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      {travels.map((train, key) => (
                        <div
                          id={`trainNumber_${train.trains[0].trainNumber}`}
                          key={train.arrivalTime + train.departureTime + key}
                          className="train-card"
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                          }}
                          onClick={() => {
                            onClickTravelCard(train);
                          }}
                        >
                          <TrainCard
                            {...train.trains[0]}
                            myDestanation={
                              train.trains[train.trains.length - 1]
                                .destinationStation
                            }
                            travelMessages={train.travelMessages}
                            arrivalTime={train.arrivalTime}
                            departureTime={train.departureTime}
                            changes={train.trains.length - 1}
                            lastDestanation={
                              train.trains[train.trains.length - 1]
                                .routeStations[
                                train.trains[train.trains.length - 1]
                                  .routeStations.length - 1
                              ].stationId
                            }
                            isSelected={
                              `${train.trains[0].arrivalTime}-${train.trains[0].departureTime}` ===
                              selectedTrainKey
                            }
                            key={key}
                            index={key}
                            totalTrains={travels.length}
                            onNotificationButtonClick={(
                              trainIndex,
                              selectedTrain
                            ) => {
                              _setSelectedTrainToShare(selectedTrain);

                              _setIsNotificationListVisible(
                                !_isNotificationListVisible
                              );
                              if (!_isNotificationListVisible) {
                                _setCurrentNotificationTrainIndex(trainIndex);
                              } else {
                                _setCurrentNotificationTrainIndex(-1);
                              }
                            }}
                            trainPosition={train.trains[0].trainPosition}
                            onAddToCalendar={(trainIndex, train) => {
                              _setIsAddCalendarModalVisible(true);
                              _setSelectedTrainToCalendar(train);
                            }}
                            destPlatform={train.trains[train.trains.length - 1].destPlatform}
                          />
                          <div>
                            {`${train.trains[0].arrivalTime}-${train.trains[0].departureTime}` ===
                              selectedTrainKey && (
                              <img
                                src={Images.arrowSide}
                                style={{
                                  filter: `brightness(0.3)`,
                                  transform: `rotate(${
                                    i18n.dir() === "rtl" ? "180deg" : ""
                                  })`,
                                }}
                                alt={``}
                                width={"20"}
                                height={"20"}
                              />
                            )}
                          </div>
                        </div>
                      ))}
                      <div style={styles.bottomCards}>
                        <div></div>
                        <div
                          style={styles.nextPreviousContainer}
                          onClick={() => {
                            getDay("searchTrainLuzAfterDate");
                          }}
                        >
                          <button
                            id="nextTrainsButton"
                            style={styles.nextPrevious}
                            role={"button"}
                            tabIndex={-1}
                            aria-label={t("View-next-day")}
                          >
                            {t("View-next-day")}
                          </button>
                          <img
                            src={Images.arrowDown}
                            style={styles.arrowIcon}
                            width={`auto`}
                            height={`auto`}
                            alt={``}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={styles.routeDetailsContainer}
                    id={"routeDetailsContainer"}
                  >
                    <RouteDetails travel={selectedTravel} />
                  </div>
                </div>
              ) : (
                <div style={styles.noTrainsContainer}>
                  <NoTrains
                    clientMessageId={noTrainsInfo.clientMessageId}
                    lastTrainDate={noTrainsInfo.lastTrainDate}
                    nextTrainDate={noTrainsInfo.nextTrainDate}
                    messageTitle={noTrainsInfo.messageTitle}
                    shareStatus={noTrainsInfo.shareStatus}
                    onClick={noTrainsInfo.onClick}
                  />
                </div>
              )}
            </Fragment>
          )}
        </div>
      </div>

      <ReactModal
        isOpen={_isNotificationListVisible}
        ariaHideApp={false}
        onRequestClose={() => _setIsNotificationListVisible(false)}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            width: "85%",
            maxWidth: "45rem",
            bottom: "auto",
            height: "50%",
            transform: "translate(-50%, -50%)",
            border: "none",
            padding: "none",
            position: "absolute",
            maxHeight: "100%",
            overflow: "visible",
            borderRadius: "13px 13px 288px 288px / 13px 13px 43px 43px",
          },
          overlay: {
            backgroundColor: colors.modal,
            zIndex: "99999",
            overflow: "hidden",
          },
        }}
        parentSelector={() => document.querySelector("#root") || document.body}
        preventScroll={true}
        appElement={document.getElementById("#root") || document.body}
      >
        <img
          src={Images.closeButtonMob}
          style={{
            ...styles.notificationShareModalCloseIcon,
            left: i18n.dir() === "rtl" ? "1rem" : "",
            right: i18n.dir() === "ltr" ? "1rem" : "",
          }}
          alt={`${t(`Close`)}`}
          aria-label={`${t(`Close`)}`}
          tabIndex={!IsMobile() ? 0 : -1}
          onClick={() => _setIsNotificationListVisible(false)}
          width={`auto`}
          role={"button"}
          height={`auto`}
        />
        <div style={styles.notificationShareModalIconWrapper}>
          <div style={styles.notificationShareModalIconContainer}>
            <img
              src={Images.bellBigIcon}
              style={{ ...styles.bellIcon }}
              alt=""
            />
          </div>
        </div>
        <div style={styles.notificationShareBody}>
          <h3 style={styles.notificationShareModalTitle} tabIndex={0}></h3>
          <div style={styles.notificationShareOptions}>
            {_selectedTrainToShare && (
              <TrainCard {..._selectedTrainToShare} isPreviewOnly={true} />
            )}
            <div style={styles.notificationsContainer}>
              <div
                style={styles.notificationRow}
                // onClick={handleAddToCalendar}
                className={"shareOption"}
              >
                <img src={Images.calendarShare} width={"20"} alt={``} />
                <span role={"button"} tabIndex={0}>
                  {t("AddTravelToCalendar")}
                </span>
              </div>
              <div
                style={styles.notificationRow}
                className={"shareOption"}
                onClick={(e) => {
                  e.stopPropagation();
                  _setIsShareModalVisible(true);
                }}
              >
                <img src={Images.shareBlue} width={"20"} alt={``} />
                <span role={"button"} tabIndex={0}>
                  {t("ShareTravel")}
                </span>
              </div>
            </div>
          </div>
        </div>
      </ReactModal>
      <ReactModal
        isOpen={_isShareModalVisible}
        ariaHideApp={false}
        style={{
          content: {
            width: "100%",
            maxWidth: "42rem",
            border: "none",
            padding: "none",
            position: "absolute",
            maxHeight: "100%",
            overflow: "visible",
            backgroundColor: "transparent",
          },
          overlay: {
            backgroundColor: "transparent",
            zIndex: "9999999999999999",
            overflow: "hidden",
          },
        }}
        parentSelector={() => document.querySelector("#root") || document.body}
        preventScroll={true}
      >
        <ShareModal
          title={t("Share")}
          icon={Images.share}
          onClose={() => _setIsShareModalVisible(false)}
          items={[
            {
              name: t("Facebook"),
              icon: Images.facebookHeader,
              link: getShareTravelUrl(
                `https://www.facebook.com/sharer/sharer.php?u=`
              ),
              ariaLabel: t("ShareFacebook"),
            },
            {
              name: t("WhatsApp"),
              icon: Images.whatsapp,
              link: getShareTravelUrl(`https://wa.me/?text=`),
              ariaLabel: t("ShareWhatsapp"),
            },
            {
              name: t("Mail"),
              icon: Images.shareMail,
              link: getShareTravelUrl(`mailto:?subject=&body=`),
              ariaLabel: t("ShareEmail"),
            },
            {
              name: t("Linkedin"),
              icon: Images.linkedinHeader,
              link: getShareTravelUrl(
                `https://www.linkedin.com/sharing/share-offsite/?url=`
              ),
              ariaLabel: t("ShareLinkedin"),
            },
          ]}
        />
      </ReactModal>
      <ReactModal
        isOpen={_isAddCalendarModalVisible}
        ariaHideApp={false}
        onRequestClose={() => _setIsAddCalendarModalVisible(false)}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            width: "85%",
            maxWidth: "45rem",
            bottom: "auto",
            minHeight: "50%",
            transform: "translate(-50%, -50%)",
            border: "none",
            padding: "none",
            position: "absolute",
            maxHeight: "100%",
            overflow: "visible",
            borderRadius: "13px 13px 288px 288px / 13px 13px 43px 43px",
          },
          overlay: {
            backgroundColor: colors.modal,
            zIndex: "99999",
            overflow: "hidden",
          },
        }}
        parentSelector={() => document.querySelector("#root") || document.body}
        preventScroll={true}
      >
        <img
          src={Images.closeButtonMob}
          style={{
            ...styles.routeToCalendarModalCloseIcon,
            left: i18n.dir() === "rtl" ? "1rem" : "",
            right: i18n.dir() === "ltr" ? "1rem" : "",
          }}
          alt={`${t(`Close`)}`}
          aria-label={`${t(`Close`)}`}
          tabIndex={!IsMobile() ? 0 : -1}
          onClick={() => _setIsAddCalendarModalVisible(false)}
          width={`auto`}
          role={"button"}
          height={`auto`}
        />
        {_selectedTrainToCalendar && (
          <>
            <RouteToCalendar
              {..._selectedTrainToCalendar}
              toStation={schedule.toStation}
              fromStation={schedule.fromStation}
            />
          </>
        )}
      </ReactModal>
    </Fragment>
  );
};

const styles: StyleSheet = {
  mainWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "100vh",
  },
  body: {
    display: "flex",
    width: "100%",
    // paddingTop: "6rem",
    backgroundColor: colors.ghostWhite,
  },
  topCards: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: "3rem",
    marginInlineStart: "15%",
    marginInlineEnd: "15%",
    marginBottom: "1rem",
  },
  bottomCards: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: "1rem",
    marginInlineStart: "15%",
    marginInlineEnd: "15%",
    marginBottom: "1rem",
  },
  dateTitle: {
    display: "flex",
    justifyContent: "space-around",
    gap: "5rem",
    alignItems: "center",
    color: colors.darkBlue,
    fontSize: fontSize.UmbracoElementTitle,
  },
  availableTrains: {
    fontSize: fontSize.UmbracoElementTitle,
  },
  nextPreviousContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "1rem",
    paddingBottom: "1rem",
  },
  nextPrevious: {
    color: colors.oldLavender,
    cursor: "pointer",
    border: "none",
    backgroundColor: "transparent",
    fontSize: fontSize.Text,
    fontFamily: fonts.FbReformaMedium,
  },
  trainsCardsWrapper: {
    display: "flex",
    flexDirection: "column",
    flex: "2",
    maxHeight: "90vh",
    marginTop: "1rem",
  },
  trainsCards: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    maxHeight: "100%",
    overflow: "auto",
  },
  trainCard: {
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
  },
  noTrainsContainer: {
    width: "100%",
    height: "100vh",
    backgroundColor: colors.ghostWhite,
  },
  routeDetailsContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: colors.white,
    border: `.1rem solid ${colors.pastelGray}`,
    // maxHeight: "90vh",
    flex: "1",
  },
  arrowIcon: {
    cursor: "pointer",
  },
  notificationShareModalIconWrapper: {
    display: "flex",
    justifyContent: "center",
    position: "relative",
    top: "-2.5rem",
    zIndex: "9999999",
  },
  notificationShareBody: {
    position: "absolute",
    top: "12%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "70%",
    overflow: "auto",
    gap: "1.25rem",
  },
  bellIcon: {
    backgroundRepeat: "no-repeat",
    transform: "scale(.8)",
  },
  notificationShareModalIconContainer: {
    width: "4.3rem",
    height: "4.3rem",
    boxShadow: "0 5px 11px 0 rgba(0, 0, 0, 0.21)",
    borderRadius: "50%",
    backgroundColor: "white",
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transform: "translateY(5px)",
  } as React.CSSProperties,
  notificationShareModalTitle: {
    fontSize: fontSize.UmbracoElementTitle,
    lineHeight: "1.5rem",
    maxWidth: "22rem",
    fontFamily: fonts.FbReformaMedium,
    textAlign: "center",
  },
  notificationShareOptions: {
    display: "flex",
    justifyContent: "center",
    gap: "3rem",
    flexWrap: "wrap",
    width: "70%",
  },
  notificationShareModalCloseIcon: {
    position: "absolute",
    top: "-2rem",
    cursor: "pointer",
  },
  notificationsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  notificationRow: {
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    paddingInlineStart: "1.5rem",
    cursor: "pointer",
  },
  routeToCalendarModalCloseIcon: {
    position: "absolute",
    top: "-2rem",
    cursor: "pointer",
  },
};

export default RoutePlanSearchResults;
