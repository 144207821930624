import moment from "moment";
import colors from "../../../../Assets/Colors/Colors.json";
import fonts from "../../../../Assets/Fonts/Fonts.json";
import fontSize from "../../../../Assets/Fonts/FontsSizes.json";
import { StyleSheet } from "../../../../Interfaces/IStyleSheet";

type ICreationDetails = { author: string, creationDate: Date, readingTime: string }
const CreationDetails: React.FC<ICreationDetails> = ({ author, creationDate, readingTime }) => {

    let text = author || ""
    text += creationDate.toString() && creationDate.toString() !== "0001-01-01T00:00:00"  ? " | " + moment(creationDate).format("DD.MM.YY") : ""
    text += readingTime ? " | " + readingTime : ""

    return (<div style={styles.creationDetails}>
        {text}
    </div>
    );
};
const styles: StyleSheet = {
    creationDetails: {
        fontSize: fontSize.Text,
        fontWeight: "300",
        lineHeight: "1.6875rem",
        fontFamily: fonts.FbReformaLight,
        margin: 0,
        color: colors.nero,
    },
};

export default CreationDetails;
