import { t } from "i18next";
import { useState } from "react";
import colors from "../../../Assets/Colors/Colors.json";
import fonts from "../../../Assets/Fonts/Fonts.json";
import Images from "../../../Data/Images/Images";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import { IPodcast } from "../../../Interfaces/IUmbraco";
import {
  FormatDateYYYYMMDD_Dots,
  SecondsToDurationFormat,
} from "../../../Services/DateService";
import { StyleMode } from "../../../Utils/consts";
import { StripHTML } from "../../../Utils/helpers";
import useDeviceMode from "../../../Utils/useWindowDimensions";
import {
  GetLongTextWithDots,
  replaceRichtextBoldWithFont,
} from "../../../Utils/utils";
import fontSize from "../../../Assets/Fonts/FontsSizes.json";
import { IsMobile } from "../../../Services/DeviceService";
import FileElement from "../FileElement/FileElement";

const Podcast: React.FC<IPodcast> = (props) => {
  const {
    titleAndDescription,
    thumbnail,
    thumbnailAlt,
    date,
    onClick,
    isSelected,
    transcribedEpisode,
    length,
  } = props;
  const [_isOver, _setIsOver] = useState(false);
  const [_duration, _setDuration] = useState<number>(0);
  const deviceMode = useDeviceMode();

  // console.log("length", length);

  return (
    <div
      style={{
        ...styles.container,
        backgroundColor: isSelected
          ? colors.pattensBlue
          : _isOver
          ? colors.ghostWhite
          : colors.white,
        cursor: _isOver ? "pointer" : "",
        flexDirection: deviceMode === StyleMode.desktop ? "row" : "column",
        textAlign: deviceMode === StyleMode.desktop ? undefined : "center",
      }}
      onMouseOver={() => {
        _setIsOver(true);
      }}
      onMouseLeave={() => {
        _setIsOver(false);
      }}
      onFocusCapture={() => {
        _setIsOver(true);
      }}
      onBlurCapture={() => {
        _setIsOver(false);
      }}
      onClick={onClick}
      onKeyPress={onClick}
    >
      <img
        src={thumbnail && thumbnail._url ? thumbnail._url : Images.podcast}
        style={styles.thumbnail}
        alt={thumbnailAlt ? thumbnailAlt : ``}
        width={`auto`}
        height={`auto`}
      />

      <div
        style={styles.titleAndDescriptionContainer}
        tabIndex={!IsMobile() ? 0 : -1}
        role={"button"}
        aria-expanded={isSelected}
        aria-label={`${titleAndDescription.title} heading level 3,
             ${StripHTML(titleAndDescription.description)}
             ${t(`PodcastTime`, { time: SecondsToDurationFormat(_duration) })}`}
      >
        <h3 style={styles.title}>{titleAndDescription.title}</h3>
        <div
          title={
            titleAndDescription.description.length > 121
              ? StripHTML(titleAndDescription.description)
              : ""
          }
          style={styles.description}
          dangerouslySetInnerHTML={{
            __html: replaceRichtextBoldWithFont(
              titleAndDescription.description.length > 121
                ? GetLongTextWithDots(titleAndDescription.description, 121)
                : titleAndDescription.description
            ),
          }}
        ></div>
        {transcribedEpisode._url && (
          <FileElement
            file={transcribedEpisode}
            fileName={titleAndDescription.title}
            openType="preview"
            containerStyles={{
              paddingTop: "1rem",
              alignItems: "start",
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
        )}
      </div>
      <div style={styles.information}>
        <div style={styles.date}>{FormatDateYYYYMMDD_Dots(new Date(date))}</div>
        <div style={styles.length}>{length}</div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    display: "flex",
    gap: "2rem",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: ".5rem",
    flexWrap: "wrap",
  },
  thumbnail: {
    width: "15rem",
    height: "8rem",
    borderRadius: "5%",
  },
  titleAndDescriptionContainer: {
    display: "flex",
    flexDirection: "column",
    flex: "2",
    padding: "1rem",
  },
  title: {
    color: colors.darkBlue,
    fontSize: fontSize.UmbracoElementTitle,
    letterSpacing: "0.039375rem",
    fontFamily: fonts.FbReformaMedium,
    marginBlockStart: "0",
  },
  description: {
    color: colors.nero,
    fontFamily: fonts.FbReformaLight,
    letterSpacing: "0.038125rem",
    fontSize: fontSize.Paragraph,
    maxWidth: "46.75rem",
  },
  information: {
    display: "flex",
    flexDirection: "column",
    gap: ".5rem",
    alignItems: "center",
    paddingBottom: "4rem",
    flex: "1",
  },
  length: {
    color: colors.darkBlue,
    letterSpacing: "0.038125rem",
    fontFamily: fonts.FbReformaRegular,
    fontSize: fontSize.Paragraph,
  },
  date: {
    color: colors.nero,
    fontSize: fontSize.Text,
    fontFamily: fonts.FbReformaLight,
    letterSpacing: "0.0275rem",
  },
};

export default Podcast;
