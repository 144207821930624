const init_state = "";

export const ModalReducer = (state = init_state, action: any) => {
  switch (action.type) {
    case "openModal":
      state = action.payload;
      return state;
    case "closeModal":
      state = action.payload;
      return state;
    default:
      return state;
  }
};
