import { ITwoItemsGallery } from "../../../../Interfaces/IGallery";
import { StyleSheet } from "../../../../Interfaces/IStyleSheet";
import { IVideo } from "../../../../Interfaces/IVideo";
import GalleryVideo from "../GalleryItems/GalleryVideo";
import colors from "../../../../Assets/Colors/Colors.json";
import fonts from "../../../../Assets/Fonts/Fonts.json";
import { Fragment, useState } from "react";
import useDeviceMode from "../../../../Utils/useWindowDimensions";
import { StyleMode } from "../../../../Utils/consts";
import { GetLongTextWithDots } from "../../../../Utils/utils";
import fontSize from "../../../../Assets/Fonts/FontsSizes.json"

const TwoItemsGallery: React.FC<ITwoItemsGallery> = (props) => {
  const { gallery, direction } = props;
  const [_galleryIndexOver, _setGalleryIndexOver] = useState(-1);

  const deviceMode = useDeviceMode();

  return (
    <div
      style={{
        ...styles.twoItemsGalleryContainer,
        flexDirection:
          deviceMode === StyleMode.desktop
            ? direction !== "Left to Right"
              ? "row-reverse"
              : "row"
            : "column",
        maxWidth: deviceMode === StyleMode.desktop ? "76rem" : "100%",
        width: deviceMode === StyleMode.desktop ? "80%" : "45%",
        gap: deviceMode === StyleMode.desktop ? "4rem" : "1rem",
      }}
    >
      {gallery.map((galleryItem, index) => {
        return galleryItem.contentTypeAlias === "imageGalleryItem" ? (
          <Fragment key={index}>
            {deviceMode === StyleMode.desktop ? (
              <div
                style={{
                  width: index === 1 ? "737px" : "415px",
                  height: "464px",
                  position: "relative",
                }}
                onMouseOver={() => _setGalleryIndexOver(index)}
                onMouseLeave={() => _setGalleryIndexOver(-1)}
              >
                <img
                  src={galleryItem.media._url}
                  width={index === 1 ? "737px" : "415px"}
                  height={"100%"}
                  alt={``}
                  style={{
                    objectFit: "contain",
                  }}
                />
                {_galleryIndexOver === index && (
                  <div style={styles.galleryItemHover}>
                    <h1
                      style={{
                        color: colors.darkBlue,
                        fontFamily: fonts.FbReformaRegular,
                        textAlign: "center",
                        margin: "auto",
                        padding: "20%",
                        fontSize: fontSize.Paragraph,
                        lineHeight: "3.2rem",
                      }}
                      title={
                        galleryItem.header.text.length > 45
                          ? galleryItem.header.text
                          : ""
                      }
                    >
                      {galleryItem.header.text.length > 45
                        ? GetLongTextWithDots(galleryItem.header.text, 45)
                        : galleryItem.header.text}
                    </h1>
                  </div>
                )}
              </div>
            ) : (
              <img
                src={galleryItem.media._url}
                style={{

                  width: "100%",
                  height: "50%",
                }}
              />
            )}
          </Fragment>
        ) : (
          <GalleryVideo
            key={index}
            media={galleryItem.media as IVideo}
            header={galleryItem.header}
            thumbnail={galleryItem.thumbnail}
            isPlayButtonEnabled={true}
          />
        );
      })}
    </div>
  );
};

const styles: StyleSheet = {
  twoItemsGalleryContainer: {
    display: "flex",
    position: "relative",
    justifyContent: "center",
  },
  galleryItemHover: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: "#ffffff80",
    zIndex: "1",
    left: 0,
    top: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

export default TwoItemsGallery;
