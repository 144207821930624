import { Fragment, useEffect, useState } from "react";
import ReactModal from "react-modal";
import colors from "../../../../../../../Assets/Colors/Colors.json";
import Images from "../../../../../../../Data/Images/Images";
import { StyleSheet } from "../../../../../Interfaces_tenders/IStyleSheet";
import { IServerFile } from "../../../../../Interfaces_tenders/IUmbraco";
import { FormatDateYYYYMMDD_Dots } from "../../../../../../../Services/DateService";
import i18n from "../../../../../../../Services/i18n";
import Tag from "../../../../Tag/Tag";
import AuctionDetailsModal from "../../AuctionDetailsModal/AuctionDetailsModal";
import useDeviceMode from "../../../../../../../Utils/useWindowDimensions";
import { StyleMode } from "../../../../../../../Utils/consts";
import { useSelector } from "react-redux";
import { FilesToZipServiceServer } from "../../../../../../../Services/FilesToZipServiceServer";
import FileViewerServer from "../../../../FileViewer/FileViewerServer";
import fonts from "../../../../../../../Assets/Fonts/Fonts.json";
import {
  IAuctionsSystemAuctions,
  IAuctionsSystemDomains,
  IAuctionsSystemTypes,
  IAuctionsTenderTypes,
} from "../../../../../Interfaces_tenders/IAuctionsSystemDetails";
import { t } from "i18next";
import QueryString from "qs";
import { useLocation, useNavigate } from "react-router-dom";
import { truncateText } from "../../../../../../../Utils/helpers";
import AuctionDetailsBottomModal from "../../AuctionDetailsModal/AuctionDetailsBottomModal";
import { FormatHoursMinutes } from "../../../../../../../Services/DateService";

const CompletedAuctionCardMobile: React.FC<IAuctionsSystemAuctions> = (props) => {
  const {
    tenderName,
    tenderNumber,
    referentName,
    referentMail,
    publishDate,
    filed,
    type,
    clarifyingDate,
    biddingDate,
    documentation,
    dynamicTextsDict,
    auctionSystem,
    currentIndex,
    numOfAuctions,
  } = props;

  const location = useLocation();

  const navigate = useNavigate();
  const [_isModalOpen, _setIsModalOpen] = useState(false);
  const [_isDetailsModalOpen, _setIsDetailsModalOpen] = useState(false);
  const [_currentFileName, _setCurrentFileName] = useState<string>("");
  const _currentTypes = (useSelector(
    (state: any) => state.TendersReducer
  ).tenderType) as IAuctionsSystemTypes[];

  const dynamicTexts =
    dynamicTextsDict && dynamicTextsDict.length > 0
      ? dynamicTextsDict[0]
      : undefined;
  const deviceMode = useDeviceMode();
  const serverFiles: IServerFile[] =
    documentation &&
    documentation
     // .filter((file: any) => file.document !== undefined)
      .map((file: any) => {
        return {
          document: file.docName,
          filename: file.docDisplay ? file.docDisplay : t("defaultFileNeme"),
        };
      });

  useEffect(() => {
    const urlParams = QueryString.parse(location.search.split("?")[1]);

    const currentUrl = {
      ...urlParams,
      isModalOpen: _isDetailsModalOpen.toString(),
    };

    //navigate("?" + QueryString.stringify(currentUrl), { replace: true });
  }, [_isDetailsModalOpen]);


  const closeBootomModalHandler = ()=>{
    _setIsDetailsModalOpen(false)
  }
  return (
    <Fragment>
      <div
        style={styles.auctionCardContainer}
        role={"main"}
        tabIndex={0}
        aria-label={
          t("tenderIndexNumber") +
          (currentIndex && currentIndex) +
          t("fromTotal") +
          (numOfAuctions && numOfAuctions)
        }
      >
        <div style={styles.auctionHeader}>
          {_currentTypes.length > 0 && (
            <div style={styles.domainNameAndTagWrapper}>
              <div style={styles.domainOptions}>
                {filed !== 0
                  ? _currentTypes &&
                    auctionSystem &&
                    auctionSystem.domainOptions.filter(
                      (domain: IAuctionsSystemDomains) =>
                        domain.domainKey ===
                        _currentTypes.filter((field) => field.id === filed)[0]
                          .name
                    )[0].domainValue
                  : ""}
              </div>
              <div>
                <Tag
                  text={
                    filed !== 0
                      ? _currentTypes &&
                        auctionSystem &&
                        auctionSystem.typeOptions.filter(
                          (curType: IAuctionsTenderTypes) =>
                            curType.typeKey ===
                            _currentTypes.filter(
                              (field) => field.id === type
                            )[0].name
                        )[0].typeValue
                      : ""
                  }
                  customStyles={filed === 0}
                />
              </div>
            </div>
          )}
          <div style={styles.auctionName} title={tenderName}>
            {tenderName && truncateText(tenderName, 45)}
          </div>
          {tenderNumber !== undefined && (
            <div style={styles.auctionNumber}>
              {dynamicTexts && dynamicTexts["auctionNumber"].text}{" "}
              {tenderNumber}
            </div>
          )}
          <div style={styles.referent}>
            {referentName && (
            <div style={styles.referentRow}>
            <img src={Images.person}></img>
            {referentMail && (
              <div>
                {dynamicTexts && dynamicTexts["referent"].text}:{" "}
                {referentMail.slice(0, 40)}
              </div>
            )}
          </div>
            )}
            <div style={styles.referentRow}>
              {serverFiles && serverFiles.length > 0 && (
                <Fragment>
                 <img
                      src={Images.fileIcon}
                   
                    ></img>
                  <div
                    onClick={() => {
                      if (deviceMode !== StyleMode.mobile) {
                        _setIsModalOpen(true);
                      } else {
                        const filesToDownload = [
                          ...serverFiles.filter(
                            (file) => file.document !== null
                          ),
                        ];
                        FilesToZipServiceServer(filesToDownload, tenderNumber);
                      }
                    }}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    {serverFiles.length}{" "}
                    {dynamicTexts && dynamicTexts["attachedFiles"].text}
                  </div>
                </Fragment>
              )}
            </div>
          </div>
        </div>
        <div style={{...styles.dates, fontSize:deviceMode===StyleMode.mobile?"0.9rem":"unset"}}>
          {publishDate &&
          publishDate.toString() !== "0001-01-01T00:00:00" &&
          publishDate.toString() !== "1900-01-01T00:00:00" &&
          publishDate.toString() !== "8900-12-31T00:00:00" ? (
            <div style={{
              ...styles.date,
              borderRight:
                i18n.dir() === "rtl" ? `.1rem solid ${colors.black}` : "",
              borderLeft:
                i18n.dir() === "ltr" ? `.1rem solid ${colors.black}` : "",
            }}>
              <div style={styles.dateTitle}>
                {dynamicTexts && dynamicTexts["publishDate"].text}
              </div>
              <div style={styles.dateFormat}>
                {FormatDateYYYYMMDD_Dots(new Date(publishDate))}
              </div>
              <div style={styles.dateFormat}>
                    {FormatHoursMinutes(publishDate?.toString())}
              </div>
            </div>
          ) : (
            <div></div>
          )}
          {publishDate &&
          publishDate.toString() !== "0001-01-01T00:00:00" &&
          publishDate.toString() !== "1900-01-01T00:00:00" &&
          publishDate.toString() !== "8900-12-31T00:00:00" ? (
            <div style={{
              ...styles.date,
              borderRight:
                i18n.dir() === "rtl" ? `.1rem solid ${colors.black}` : "",
              borderLeft:
                i18n.dir() === "ltr" ? `.1rem solid ${colors.black}` : "",
            }}>
              <div style={styles.dateTitle}>
                {dynamicTexts &&
                  dynamicTexts["deadlineForSubmissionOfBids"].text}
              </div>
              <div style={styles.dateFormat}>
                {FormatDateYYYYMMDD_Dots(new Date(biddingDate))}
              </div>
              <div style={styles.dateFormat}>
                    {FormatHoursMinutes(biddingDate?.toString())}
              </div>
            </div>
          ) : (
            <div></div>
          )}

          {clarifyingDate &&
          clarifyingDate.toString() !== "0001-01-01T00:00:00" &&
          clarifyingDate.toString() !== "1900-01-01T00:00:00" &&
          clarifyingDate.toString() !== "8900-12-31T00:00:00" ? (
            <div style={{
              ...styles.date,
              borderRight:
                i18n.dir() === "rtl" ? `.1rem solid ${colors.black}` : "",
              borderLeft:
                i18n.dir() === "ltr" ? `.1rem solid ${colors.black}` : "",
            }}>
              <Fragment>
                <div style={styles.dateTitle}>
                  {t("AuctionDeadlineForSubmissionOfQuestions")}
                </div>
                <div style={styles.auctionPrice}>
                  {FormatDateYYYYMMDD_Dots(new Date(clarifyingDate))}
                </div>
                <div style={styles.dateFormat}>
                    {FormatHoursMinutes(clarifyingDate?.toString())}
                </div>
              </Fragment>
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <div
          style={styles.button}
          onClick={() => {
            _setIsDetailsModalOpen(true);
          }}
        >
          <div style={styles.moreDetails} tabIndex={0}>
            {dynamicTexts && dynamicTexts["moreDetails"].text}
          </div>
        </div>
      </div>
      <ReactModal
        ariaHideApp={false}
        isOpen={_isModalOpen}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            width: "85%",
            bottom: "auto",
            height: "50rem",
            transform: "translate(-50%, -50%)",
            border: "none",
            padding: "none",
            position: "absolute",
            maxHeight: "100%",
            overflow: "visible",
          },
          overlay: {
            backgroundColor: colors.modal,
            zIndex: "99999",
            overflow: "hidden",
          },
        }}
        parentSelector={() => document.querySelector("#root") || document.body}
        preventScroll={true}
      >
        <div
          style={{
            ...styles.closeMainModalIcon,
            right: i18n.dir() === "rtl" ? "-0.1rem" : "",
            left: i18n.dir() === "ltr" ? "1.1rem" : "",
          }}
          onClick={() => {
            _setIsModalOpen(false);
          }}
        ></div>
        {serverFiles && serverFiles.length > 0 && (
          <FileViewerServer
            files={serverFiles}
            title={`${t("tenderIndexNumber")} ${tenderNumber}`}
            curFileName={_currentFileName}
            key={new Date().toDateString()}
          />
        )}
      </ReactModal>
      {deviceMode !== StyleMode.mobile && (
        <ReactModal
          isOpen={_isDetailsModalOpen}
          ariaHideApp={false}
          shouldCloseOnOverlayClick={true}
          onRequestClose={() => {
            _setIsDetailsModalOpen(false);
          }}
          style={{
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              width: "60%",
              minWidth: "27rem",
              bottom: "auto",
              /* height: "50rem",*/
              transform: "translate(-50%, -50%)",
              border: "none",
              padding: "none",
              position: "absolute",
              maxHeight: "50rem",
              overflow: "auto",
              borderRadius: ".7rem",
            },
            overlay: {
              backgroundColor: colors.modal,
              zIndex: "99999",
              overflow: "hidden",
            },
          }}
          parentSelector={() =>
            document.querySelector("#root") || document.body
          }
          preventScroll={true}
        >
          {deviceMode !== StyleMode.mobile && (
            <img
              role={"button"}
              aria-label={t("closeButtonAria")}
              style={{
                ...styles.closeUpdateMeIcon,
                left: i18n.dir() === "rtl" ? "2%" : "",
                right: i18n.dir() === "ltr" ? "2%" : "",
              }}
              onClick={() => _setIsDetailsModalOpen(false)}
              src={Images.closeBG}
              tabIndex={0}
            />
          )}
          <AuctionDetailsModal {...props} />
        </ReactModal>
      )}

{_isDetailsModalOpen && deviceMode !== StyleMode.desktop && (
        <div style={styles.ModalOverlay} className="modal-bottom">
          <div style={styles.innerModal}>
            <div
              style={{
                display: "flex",
                flexDirection: "row-reverse",
                margin: "1rem",
                boxSizing: "border-box",
              }}
            >
              <img src={Images.closeButtonMob}  onClick={closeBootomModalHandler}></img>
              <div
                style={{
                  position: "absolute",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  top: "-.5rem",
                  left: "0",
                }}
              ></div>
            </div>

            <Fragment>
             <AuctionDetailsBottomModal {...props}/>
            </Fragment>
          </div>
        </div>
      )}
    </Fragment>
  );
};

const styles: StyleSheet = {
  auctionCardContainer: {
    display: "flex",
    flexDirection: "column",
    height: "24rem",
    maxWidth: "40.625rem",
    width: "80%",
    //minWidth: "25rem",
    gap: ".5rem",
    boxShadow: "0 0 1px 0 rgba(0, 0, 0, 0.2), 0 0 15px 0 rgba(0, 0, 0, 0.19)",
    borderRadius: "0.625rem",
    position: "relative",
  },
  auctionHeader: {
    display: "flex",
    flexDirection: "column",
    padding: "1.45rem",
    gap: ".5rem",
  },
  auctionName: {
    color: colors.darkBlue,
    fontSize: "1.6rem",
    letterSpacing: "0.039375rem",
    fontFamily: fonts.FbReformaMedium,
    maxWidth: "30rem",
    lineHeight: "2rem",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  domainNameAndTagWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  auctionNumber: {
    color: colors.darkBlue,
    fontSize: "1rem",
    letterSpacing: "0.0275rem",
    fontFamily: fonts.FbReformaLight,
  },
  referent: {
    display: "flex",
    flexDirection:"column",
  },
  referentRow: {
    display: "flex",
    gap: "0.5rem",
    alignItems:"center"
  },
  icon: {
    backgroundRepeat: "no-repeat",
    width: "1.25rem",
    height: "1.5rem",
  },
  dates: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    width:"95%",
    alignSelf:"center",
    flex: 1,
  },
  date: {
    // borderRight: i18n.dir() === "rtl" ? `.1rem solid ${colors.black}` : "",
    // borderLeft: i18n.dir() === "ltr" ? `.1rem solid ${colors.black}` : "",
    padding: "0 1rem",
  },
  dateTitle: {
    fontFamily: fonts.FbReformaRegular,
    color: colors.pasterlGrayDark,
  },
  dateFormat: {
    fontFamily: fonts.FbReformaRegular,
    letterSpacing: "0.038125rem",
    color: colors.black,
  },
  winningAmount: {
    fontFamily: fonts.FbReformaRegular,
    letterSpacing: "0.038125rem",
    color: colors.black,
  },
  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.darkBlue,
    height: "2.75rem",
    borderBottomRightRadius: "0.625rem",
    borderBottomLeftRadius: "0.625rem",
    cursor: "pointer",
  },
  moreDetails: {
    color: colors.white,
    fontFamily: fonts.FbReformaMedium,
    letterSpacing: "0.029375rem",
    fontSize: "1rem",
  },
  domainOptions: {
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaRegular,
    fontSize: "1rem",
    letterSpacing: "0.025rem",
  },
  closeMainModalIcon: {
    position: "absolute",
    backgroundImage: `url(${Images.closeBG})`,
    backgroundRepeat: "no-repeat",
    width: "2rem",
    height: "1.5rem",
    left: "1rem",
    top: "1rem",
    cursor: "pointer",
    transform: "scale(1.5)",
  },

  modalOverflow: {
    display: "flex",
    justifyContent: "center",
    top: "0",
    position: "fixed",
    width: "100%",
    height: "100%",
    backgroundColor: colors.modal,
    zIndex: "99999",
  },
  modalCard: {
    display: "flex",
    justifyContent: "center",
    position: "absolute",
    top: "8%",
    width: "85%",
    height: "88%",
    backgroundColor: colors.white,
    borderRadius: "13px 13px 288px 288px / 13px 13px 43px 43px",
  },

  modalBody: {
    position: "absolute",
    top: "10%",
    maxHeight: "80%",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
  },
  modalFullname: {
    fontSize: "1.5rem",
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaMedium,
    letterSpacing: "0.065rem",
  },
  modalBodyUpper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: ".5rem",
  },
  modalInfoContainer: {
    display: "flex",
    gap: ".5rem",
    alignItems: "center",
    width: "90%",
  },


  closeUpdateMeIcon: {
    position: "absolute",
    left: "1rem",
    top: "1rem",
    cursor: "pointer",
    transform: "scale(1.5)",
  } as React.CSSProperties,

  ModalOverlay: {
    backgroundColor: colors.modal,
    position: "fixed",
    width: "100%",
    height: "100%",
    top: "0",
    zIndex: "999",
  },

  innerModal: {
    position: "absolute",
    width: "100%",
    bottom: "0",
    height: "95%",
  },

  closeModalIcon: {
    backgroundImage: `url(${Images.closeButtonMob})`,
    backgroundRepeat: "no-repeat",
 
    cursor: "pointer",

    zIndex: "99999999",
  },

  containerStyles: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: colors.white,
    //  lineHeight: "19rem",
    width: "100%",
    // height: `${6 * languageSelection.length}`,
    padding: "2rem",
    boxSizing: "border-box",
    borderRadius: "2.6rem",
    //zoom: "140%",
    height: "100%",
  },
    formElementsContainerMobile: {
    display: "flex",
    flexDirection: "column",
    /* justifyContent: "center",
    flexWrap: "wrap",
    alignItems: "center",*/
    height: "100%",
    /* overflowY: "auto",
    overflowX: "hidden",
    alignContent: "start",
    gap: "1.2rem",*/
  } as React.CSSProperties,

    mobileTitleAndClear: {
    flex: "2",
  } as React.CSSProperties,

    mobileHeaderFIlter: {
    display: "flex",
    minHeight: "5vh",
    width: "100%",
    justifyContent: "center",
  } as React.CSSProperties,


   clearMobile: {
    fontFamily: fonts.FbReformaRegular,
    fontSize: "1rem",
    textDecoration: "underline",
    color: colors.darkCyan,
    cursor: "pointer",
  } as React.CSSProperties,



  mobileFilterInputes: {
    flex: "15",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "1rem",
  } as React.CSSProperties,

    searchButtonMobileContainer: {
    flex: "3",
    display: "flex",
    justifyContent: "center",
  } as React.CSSProperties,


  searchButtonMobile: {
    width: "100%",
    height: "2.5rem",
    backgroundColor: colors.darkBlue,
    border: `1px solid ${colors.darkBlue}`,
    borderRadius: "1.25rem",
    color: colors.white,
    fontFamily: fonts.FbReformaMedium,
    fontSize: "1rem",
    letterSpacing: "0.029375rem",
    cursor: "pointer",
  } as React.CSSProperties,


};

export default CompletedAuctionCardMobile;
