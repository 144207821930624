import { t } from "i18next";
import { Fragment, useEffect, useState } from "react";
import ReactModal from "react-modal";
import { useDispatch } from "react-redux";
import colors from "../../../Assets/Colors/Colors.json";
import fonts from "../../../Assets/Fonts/Fonts.json";
import fontSize from "../../../Assets/Fonts/FontsSizes.json";
import location2 from "../../../Assets/Icons/location-2.svg";
import Images from "../../../Data/Images/Images";
import {
  IActivityTime,
  IGateActivityHours
} from "../../../Interfaces/IActivityTime";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import { IsMobile } from "../../../Services/DeviceService";
import i18n from "../../../Services/i18n";
import { GetCurrentLocation } from "../../../Services/SearchStationsAutoCompleteService";
import NavApp from "../../Modal/NavApp";
import Enters from "./Enters";

const ActivityTime: React.FC<IActivityTime> = (props) => {
  const { gateAddress, gateActivityHours, gateName, index, type } = props;
  let _subTitle = type === "desktop" ? styles.subTitleDesktop : styles.subTitle;
  const [_selectedIndex, _setSelectedIndex] = useState(0);
  const [visible, setVisible] = useState<boolean>(false);
  const [_currentPosition,_setCurrentPosition]= useState(`0,0`)
  const [_entersKey, _setEntersKey] = useState(0);
  const dispatch = useDispatch();

  let topHeader =
    type === "desktop" ? styles.topHeaderDesktop : styles.topHeader;
  let [_hours, _setHours] = useState([]);
 
  useEffect(() => {
    let resData = gateActivityHours.reduce((r: any, a: any) => {
      if (r[a?.activityHoursType]) {
        r[a?.activityHoursType].hours.push({ ...a });
      } else {
        a.hours = [{ ...a }];
        r = { ...r, [a.activityHoursType]: a };
      }
      return r;
    }, Object.create(null));

    _setHours(Object.values(resData));
  }, []);

  useEffect(() => {
    let isMounted = true;
    (async () => {
      const position = await GetCurrentLocation();
      _setCurrentPosition(position)
    })();
    return () => {
      isMounted = false;
    };
  }, []);


  return (
    <>
      <ReactModal
        isOpen={visible}
        ariaHideApp={false}
        style={{
          content: {
            width: "100%",
            maxWidth: "42rem",
            border: "none",
            padding: "none",
            position: "absolute",
            maxHeight: "100%",
            overflow: "visible",
            backgroundColor:"transparent"
          },
          overlay: {
            backgroundColor: "transparent",
            zIndex: "99999",
            overflow: "hidden",
          },
        }}
        parentSelector={() => document.querySelector("#root") || document.body}
        preventScroll={true}
      >
        <NavApp
          icon={Images.map}
          title={t("navigation")}
          location={{ lat: props.gateLatitude, lng: props.gateLontitude }}
          onClose={()=> setVisible(false)}
        />
      </ReactModal>
      <div style={styles.cardAtivityTime}>
        <div
          style={{
            ...topHeader,
            display: "flex",
            flexDirection: type === "desktop" ? "column" : "row",
            alignItems: type !== "desktop" ? "center" : "",
            justifyContent: type !== "desktop" ? "space-between" : "",
          }}
        >
          <h3 style={{ ..._subTitle, margin: 0 }}>{gateName}</h3>
          <a
            href={IsMobile()?
              `geo:0,0?q=${props.gateLatitude},${props.gateLontitude}(${props.gateName} ${t(`stationNavigation`)} ${props.stationName})`
              :`https://www.google.com/maps/dir/'${_currentPosition}'/'${props.gateLatitude},${props.gateLontitude}'/`}
              target="_blank"
              style={{
              color: colors.darkBlue,
              fontSize: fontSize.Text,
              cursor: "pointer",
            }}
          >
            <img
              src={location2}
              style={{ marginInlineEnd: "0.5rem" }}
              width={`auto`}
              height={`auto`}
              alt={``}
            />
            {t("NavigationStation")}
          </a>
        </div>
        <Fragment key={_entersKey}>
          {_hours.map((option: IGateActivityHours, i: number) => (
            <Enters
              {...option}
              type={type}
              isShow={type === "desktop" ? true : i === _selectedIndex}
              lastChild={_hours.length === i + 1}
              key={i}
              index={i}
              onClick={(selectedIndex) => {
                if (selectedIndex !== _selectedIndex) {
                  _setSelectedIndex(selectedIndex);
                } else {
                  _setSelectedIndex(-1);
                }
                _setEntersKey(Math.random() * 1000);
              }}
            />
          ))}
        </Fragment>
      </div>
    </>
  );
};

const styles: StyleSheet = {
  nameOption: {
    fontFamily: fonts.FbReformaRegular,
    color: "var(--darkBlue)",
    textAlign: "center",
    fontSize: fontSize.Text,
    whiteSpace: "nowrap",
  },

  circleWapper: {
    width: "6.25rem",
    height: "6.25rem",
    borderRadius: "50%",
    padding: "20px",
    border: "2px solid var(--darkBlue)",
    boxSizing: "border-box",
    position: "relative",
    backgroundColor: "var(--white)",
    boxShadow: "var(--shadowColor)",
    margin: "auto",
    marginBottom: "0.5rem",
  },
  topHeaderDesktop: {
    backgroundColor: colors.pattensBlue,
    padding: "1rem",
    lineHeight:1
  },
  topHeader: {
    backgroundColor: colors.ghostWhite,
    padding: "0.5rem 1rem",
  },
  itemDescription: {
    flex: "1 1 20rem",
  },
  itemOption: {
    position: "relative",
    textAlign: "center",
    padding: "0.19rem",
  },
  title: {
    display: "inline-block",
    fontFamily: fonts.FbReformaMedium,
    fontSize: fontSize.UmbracoElementTitle,
    borderBottom: `1px solid var(${colors.koromiko})`,
    color: colors.darkBlue,
    borderBottomWidth: ".2rem",
    padding: ".5rem 0",
  },
  subTitleDesktop: {
    display: "inline-block",
    fontFamily: "FbReforma-Medium , HelveticaNeue-Bold",
    fontWeight: `${i18n.language === "ar" ? `600` : ``}`,
    fontSize: fontSize.H1,
    color: colors.darkBlue,
  },
  subTitle: {
    display: "inline-block",
    fontFamily: fonts.FbReformaMedium,
    fontSize: fontSize.Text,
  },
  wrapperTwoRow: {
    flex: "1 0 25rem",
    maxWidth: "50%",
  },
  stationOptions: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    rowGap: "3rem",
    columnGap: "1rem",
  },
  text: {
    fontFamily: fonts.FbReformaLight,
    fontSize: fontSize.FooterItem,
    lineHeight: "1.75rem",
  },
  note: {
    alignItems: "center",
    lineHeight: "3rem",
  },
  updates: {
    backgroundColor: "var(--lightGray)",
    padding: "2rem 3rem",
    lineHeight: "3rem",
    borderRadius: "1rem",
  },
  attention: {
    borderInlineStart: "4px solid var(--red)",
    paddingInlineStart: "2rem",
    lineHeight: "1.75rem",
  },
  cardAtivityTime: {
    boxShadow: "var(--shadowColor)",
    borderRadius: "1rem",
    backgroundColor: "var(--white)",
    minWidth: "15rem",
    overflow: "hidden",
    height: "100%",
  },
  textMedium: {
    fontFamily: fonts.FbReformaMedium,
    fontSize: fontSize.Text,
  },
  container: {
    width: "100%",
  },
  spaceBetweenElement: {
    marginBlock: "1.5rem",
  },
  charts: {
    display: "flex",
    gap: "3rem",
    alignItems: "center",
    justifyContent: "center",
  },
  donutCounted: {
    display: "flex",
    gap: "2rem",
    alignItems: "center",
  },
  donutChart: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    width: "100%",
    top: "35%",
    justifyContent: "center",
  },
  donutChartLabel: {
    display: "flex",
    flexDirection: "column",
    fontFamily: fonts.FbReformaRegular,
    textAlign: "center",
    gap: "1rem",
    color: colors.darkBlue,
  },
  donutChartValueLabel: {
    fontSize: fontSize.PageTitle,
  },
  donutChartTextLabel: {
    fontSize: fontSize.Paragraph,
  },
  legend: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "5rem",
    fontSize: fontSize.Text,
    gap: ".5rem",
    letterSpacing: "0.0275rem",
    textAlign: "center",
    color: colors.nero,
  },
  legendSquare: {
    width: "1.2rem",
    height: "1.2rem",
    borderRadius: "6px",
  },
  line: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: "3rem",
  },
  accurecyIndexTitle: {
    fontFamily: fonts.FbReformaMedium,
    letterSpacing: "0.08375",
    color: colors.darkBlue,
    fontSize: fontSize.PageTitle,
  },
  accurecyIndexDescription: {
    fontFamily: fonts.FbReformaLight,
    letterSpacing: "0.038125rem",
    color: colors.nero,
    fontSize: fontSize.Paragraph,
    maxWidth: "19rem",
    paddingBottom: "1rem",
  },
  dateUpdated: {
    display: "flex",
    justifyContent: "flex-end",
    fontFamily: fonts.FbReformaRegular,
    letterSpacing: "0.0275rem",
    fontSize: fontSize.Text,
    color: colors.nero,
  },
};
export default ActivityTime;
