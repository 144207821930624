import { t } from "i18next";
import QueryString from "qs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import colors from "../../../Assets/Colors/Colors.json";
import fonts from "../../../Assets/Fonts/Fonts.json";
import Header from "../Components/Header/Header";
import HeaderMobile from "../Components/Header/Mobile/HeaderMobile";

import { StyleMode } from "../../../Utils/consts";
import useDeviceMode from "../../../Utils/useWindowDimensions";

import { setPage } from "../../../Redux/Slices/pageSlice";
import AuctionsContainer from "./AuctionsContainer";
import Config from "../../../Configuration/server";
//import {ITenders} from "../Interfaces_old/ITenders"/

const Main: React.FC = () => {

  const deviceMode = useDeviceMode();
  const location = useLocation();
  const dispatch = useDispatch();
  const [_isAuctionsExtrenal, _setIsAuctionsExtrenal] = useState(false);
  //const [_isRemoveMailingListUrl, _setIsRemoveMailingListUrl] = useState(false);
  //const { setPage } = bindActionCreators(pageActions, dispatch);
  const errorDisplay = useSelector((state: any) => state.AuctionsErrorStatus);
  const navigate = useNavigate();

  useEffect(() => {
   
    const urlParams = QueryString.parse(location.search.split("?")[1]);

    if (urlParams) {
      if (urlParams.auctionsExtrenal) {
        _setIsAuctionsExtrenal(urlParams.auctionsExtrenal === "true");
      }
      if (urlParams.page) {
        dispatch(setPage(urlParams.page.toString()));
      }
      // if (urlParams.mailingListValue && urlParams.mailingListValue !== "") {
      //   _setIsRemoveMailingListUrl(true);
      // }
    }

    

  }, [location.search]);




  return (
    <div>
      {_isAuctionsExtrenal &&
        (deviceMode === StyleMode.desktop ? (
          <Header />
        ) : (
          Config.IS_TENDERS === "false" && <HeaderMobile />
        ))}

      {
        <AuctionsContainer
          step={
            QueryString.parse(
              location.search.split("?")[1]
            )?.step?.toString() || "openauctions"
          }
          auctionsExtrenal={false}
          auctionId={QueryString.parse(
            location.search.split("?")[1]
          )?.auctionId?.toString()}
         // mailingListValue={_isRemoveMailingListUrl}
        />
      }
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "5rem",
        }}
      >
        {errorDisplay && (
          <div style={styles.errorMessage}>{t("errorMessage")}</div>
        )}
      </div>
    </div>
  );
};

const styles: any = {
  errorMessage: {
    color: colors.red,
    fontSize: "1.75rem",
    letterSpacing: "0.039375rem",
    fontFamily: fonts.FbReformaMedium,
    maxWidth: "35rem",
    lineHeight: "2rem",
    padding: "1.45rem",
  } as React.CSSProperties,
};

export default Main;
