import { Fragment, useState } from "react";
import ReactModal from "react-modal";
import Images from "../../../Data/Images/Images";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import { IFileElement, IUmbracoFile } from "../../../Interfaces/IUmbraco";
import i18n from "../../../Services/i18n";
import FileViewer from "../../FileViewer/FileViewer";
import colors from "../../../Assets/Colors/Colors.json";
import fonts from "../../../Assets/Fonts/Fonts.json";
import { DownloadFilesAsZipFile } from "../../../Services/FilesToZipService";
import { useNavigate } from "react-router-dom";
import store from "store2";
import useDeviceMode from "../../../Utils/useWindowDimensions";
import { StyleMode } from "../../../Utils/consts";
import { t } from "i18next";
import fontSize from "../../../Assets/Fonts/FontsSizes.json";
import { IsMobile } from "../../../Services/DeviceService";
import { GetLongTextWithDots } from "../../../Utils/utils";

const FileElement: React.FC<IFileElement> = (props) => {
  const {
    fileName,
    file,
    openType,
    anchor,
    containerStyles,
    onClick,
    isDownloadIcon,
    isBorder,
  } = props;
  const [_isPreviewModalActive, _setIsPreviewModalActive] = useState(false);
  const navigate = useNavigate();
  const deviceMode = useDeviceMode();

  const redirectToPage = (page?: string) => {
    page && navigate(`?page=${page}`);
  };

  const openPageNewTab = (page?: string) => {
    window.open(`?page=${page}`, "_blank");
  };

  const getFileIcon = () => {
    if (!file.umbracoExtension) return Images.pdfIcon;
    switch (file.umbracoExtension) {
      case "pdf":
        return Images.pdfFile;
      case "docx":
        return Images.wordFile;
      case "xls":
        return Images.excelFile;
    }
  };

  const openFile = () => {
    if (deviceMode !== StyleMode.desktop) {
      DownloadFilesAsZipFile(
        [
          {
            filename: fileName,
            document: file,
          },
        ],
        fileName
      );
      return;
    }

    switch (openType) {
      case "new tab":
        store.session.set("fileElement", file._url);
        store.session.set("fileElementName", fileName);
        openPageNewTab("filePreview_external");
        break;
      case "in window":
        store.session.set("fileElement", file._url);
        store.session.set("fileElementName", fileName);
        redirectToPage("filePreview_internal");
        break;
      case "preview":
        _setIsPreviewModalActive(true);
        break;
      case "download only":
        DownloadFilesAsZipFile(
          [
            {
              filename: fileName,
              document: file,
            },
          ],
          fileName
        );
        break;
    }
  };

  return (
    <Fragment>
      <div
        style={{
          ...styles.container,
          border: `${isBorder ? `1px solid ${colors.pastelGray}` : "none"}`,
          ...containerStyles,
          paddingInlineStart: ".5rem",
          paddingInlineEnd: ".5rem",
        }}
        anchor-id={anchor}
      >
        <div
          style={{
            ...styles.fileWrapper,
            padding: ".25rem",
          }}
          onClick={(e) => {
            openFile();
            onClick && onClick(e);
          }}
          role={`button`}
          tabIndex={!IsMobile() ? 0 : -1}
          aria-label={
            openType != "download only"
              ? `${t(`OpenFile`, {
                  fileName: fileName,
                  fileType: file.umbracoExtension,
                })} ${
                  openType === "new tab"
                    ? `${t(`NewTab`)}`
                    : openType === `preview`
                    ? `${t(`Preview`)}`
                    : ``
                }`
              : `${t(`Download`)} ${fileName} ${file.umbracoExtension}`
          }
        >
          <img src={getFileIcon()} style={{ scale: "1.5" }} alt={``} />
          <span style={styles.filename} title={fileName}>
            {fileName.length > 65
              ? GetLongTextWithDots(fileName, 65)
              : fileName}
          </span>
          {isDownloadIcon && <img src={Images.downloadArrow} />}
        </div>
      </div>
      <ReactModal
        isOpen={_isPreviewModalActive}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            width: "85%",
            bottom: "auto",
            height: "50rem",
            transform: "translate(-50%, -50%)",
            border: "none",
            padding: "none",
            position: "absolute",
            maxHeight: "100%",
            overflow: "visible",
          },
          overlay: {
            backgroundColor: colors.modal,
            zIndex: "99999",
            overflow: "hidden",
          },
        }}
        appElement={document.getElementById("#root") || document.body}
        parentSelector={() => document.querySelector("#root") || document.body}
        preventScroll={true}
      >
        <div
          style={{
            ...styles.closePreviewModalIcon,
            right: i18n.dir() === "rtl" ? "-0.1rem" : "",
            left: i18n.dir() === "ltr" ? "0.1rem" : "",
          }}
          onClick={() => {
            _setIsPreviewModalActive(false);
          }}
        ></div>
        {file && (
          <FileViewer
            files={[
              {
                document: file,
                filename: fileName,
              },
            ]}
            curFileName=""
            title={fileName}
          />
        )}
      </ReactModal>
    </Fragment>
  );
};

const styles: StyleSheet = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  fileWrapper: {
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    cursor: "pointer",
  },
  filename: {
    fontSize: fontSize.FooterItem,
    fontFamily: fonts.FbReformaRegular,
  },
  icon: {
    backgroundRepeat: "no-repeat",
    width: "1rem",
    height: "1rem",
  },
  closePreviewModalIcon: {
    position: "absolute",
    backgroundImage: `url(${Images.closeBG})`,
    backgroundRepeat: "no-repeat",
    width: "2rem",
    height: "1.5rem",
    left: "1rem",
    top: "1rem",
    cursor: "pointer",
    transform: "scale(1.5)",
  },
};

export default FileElement;
