import { Fragment, useState } from "react";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import i18n from "../../Services/i18n";
import colors from "../../Assets/Colors/Colors.json";
import { FormatDateYYYYMMDD_Dots } from "../../Services/DateService";
import { IGuideLinesAndRatesCard } from "../../Interfaces/IGuideLinesAndRates";
import fonts from "../../Assets/Fonts/Fonts.json";
import Images from "../../Data/Images/Images";
import ReactModal from "react-modal";
import FileViewer from "../FileViewer/FileViewer";
import useDeviceMode from "../../Utils/useWindowDimensions";
import { StyleMode } from "../../Utils/consts";
import { DownloadFilesAsZipFile } from "../../Services/FilesToZipService";
import { GetDynamicTextsDictionary } from "../../Utils/helpers";
import fontSize from "../../Assets/Fonts/FontsSizes.json";
import { IsMobile } from "../../Services/DeviceService";
import { GetLongTextWithDots } from "../../Utils/utils";
import Config from "../../Configuration/server";
import { t } from "i18next";

const GuideLinesAndRatesCard: React.FC<IGuideLinesAndRatesCard> = (props) => {
  const {
    documentation,
    planningAreas,
    serialNumber,
    subject,
    updateDate,
    dynamicPart,
    onDisplayModal,
    displayUpdateMeOption,
  } = props;
  const [_currentFileName, _setCurrentFileName] = useState<string>("");
  const [_isModalOpen, _setIsModalOpen] = useState(false);

  const deviceMode = useDeviceMode();

  const dynamicTextsDict =
    dynamicPart && GetDynamicTextsDictionary(dynamicPart.dynamicElements);

  const GetserialNumber = () => {
    // let serial = serialNumber+''
    // if(serial.length>1&&serial[1]&&serial[1]!=''&&serial[1].length===1){
    //   return serialNumber.toString()+`0`
    // }
    // else
    return serialNumber + ``;
  };

  const openModal = () => {
    if (documentation[0].docName.includes(".rar")) {
      window.open(
        `${Config.TENDERS_FILES}${documentation[0].docName}`,
        "_blank"
      );
    } else {
      if (deviceMode === StyleMode.desktop) {
        _setIsModalOpen(true);
      } else {
        DownloadFilesAsZipFile(
          [
            {
              filename: subject,
              document: {
                _url:
                  `${Config.TENDERS_FILES}${documentation[0].docName}` || "",
              },
            },
          ],
          subject
        );
      }
    }
  };

  const closeModal = () => {
    _setIsModalOpen(false);
  };

  const updatePlan = () => {
    onDisplayModal && onDisplayModal(props.id);
  };

  const getFileIcon = () => {
    if (documentation[0].docName) {
      if (documentation[0].docName.includes(".pdf")) {
        return Images.pdfIcon;
      }
      if (documentation[0].docName.includes(".xls")) {
        return Images.xlsIcon;
      }
      if (
        documentation[0].docName.includes(".doc") ||
        documentation[0].docName.includes(".docx")
      ) {
        return Images.wordFile;
      }
      if (
        documentation[0].docName.includes(".rar") ||
        documentation[0].docName.includes(".zip")
      ) {
        return Images.rarIcon;
      }
    }
    return Images.pdfIcon;
  };

  return (
    <Fragment>
      <div
        style={styles.container}
        role={"main"}
        tabIndex={!IsMobile() ? 0 : -1}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={styles.serialNumber}>{GetserialNumber()}</div>
          {planningAreas && (
            <div style={{}}>
              <div style={styles.planningArea}>{planningAreas}</div>
            </div>
          )}
        </div>
        <div style={styles.header}>
          <div style={styles.title} title={subject}>
            {subject}
          </div>
        </div>
        <div style={styles.itemsSideLineContainer}>
          <div style={styles.itemSideLine}>
            <div style={styles.dateTitle}>
              {dynamicTextsDict && dynamicTextsDict["dateID"].text}
            </div>
            <div style={styles.dateFormat}>
              {FormatDateYYYYMMDD_Dots(new Date(updateDate))}
            </div>
          </div>
          {documentation[0] && (
            <div style={styles.itemSideLine}>
              <div style={styles.dateTitle}>
                {" "}
                {dynamicTextsDict && dynamicTextsDict["documentID"].text}
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: ".25rem",
                }}
                onClick={openModal}
              >
                <img src={getFileIcon()} width={14} alt={``} />
                <div style={styles.fileName} tabIndex={!IsMobile() ? 0 : -1}>
                  {deviceMode !== StyleMode.desktop
                    ? GetLongTextWithDots(documentation[0].docDisplay, 10)
                    : documentation[0].docDisplay.length > 31
                    ? GetLongTextWithDots(documentation[0].docDisplay, 25)
                    : documentation[0].docDisplay}
                </div>
              </div>
            </div>
          )}
          {displayUpdateMeOption && (
            <div style={styles.itemSideLine}>
              <Fragment>
                <div
                  style={styles.updateMe}
                  tabIndex={0}
                  onClick={() => {
                    updatePlan();
                  }}
                >
                  <div style={styles.bellIcon}></div>
                  {IsMobile() !== true && (
                    <div> {t("updateMeNewCurGuidlines")}</div>
                  )}
                </div>
              </Fragment>
            </div>
          )}
        </div>
      </div>
      <ReactModal
        isOpen={_isModalOpen}
        ariaHideApp={false}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            width: "85%",
            bottom: "auto",
            height: "50rem",
            transform: "translate(-50%, -50%)",
            border: "none",
            padding: "none",
            position: "absolute",
            maxHeight: "100%",
            overflow: "visible",
          },
          overlay: {
            backgroundColor: colors.modal,
            zIndex: "99999",
            overflow: "hidden",
          },
        }}
        parentSelector={() => document.querySelector("#root") || document.body}
        preventScroll={true}
      >
        <img
          src={Images.closeBG}
          style={{
            ...styles.closeMainModalIcon,
            right: i18n.dir() === "rtl" ? "1rem" : "",
            left: i18n.dir() === "ltr" ? "1rem" : "",
          }}
          onClick={() => {
            _setIsModalOpen(false);
          }}
        ></img>
        {documentation[0] && (
          <FileViewer
            files={[
              {
                filename: documentation[0].docDisplay
                  ? documentation[0].docDisplay
                  : "Document",
                document: {
                  _url: `${Config.TENDERS_FILES}${documentation[0].docName}`,
                },
              },
            ]}
            title={`${planningAreas} ${serialNumber}` || "Document"}
            curFileName={_currentFileName}
          />
        )}
      </ReactModal>
    </Fragment>
  );
};

const styles: StyleSheet = {
  container: {
    display: "flex",
    flexDirection: "column",
    minHeight: "15rem",
    maxWidth: "40.625rem",
    width: "80%",
    gap: ".5rem",
    boxShadow: "0 0 1px 0 rgba(0, 0, 0, 0.2), 0 0 15px 0 rgba(0, 0, 0, 0.19)",
    borderRadius: "0.625rem",
    overflow: "auto",
  },
  header: {
    display: "flex",
    paddingInlineStart: "1.45rem",
    paddingInlineEnd: "1.45rem",
    gap: "2rem",
    justifyContent: "space-between",
    flex: 1,
  },
  title: {
    color: colors.darkBlue,
    fontSize: fontSize.UmbracoElementTitle,
    lineHeight: "1.7rem",
    letterSpacing: "0.039375rem",
    fontFamily: fonts.FbReformaMedium,
  },
  serialNumber: {
    paddingInlineStart: "1rem",
    paddingInlineEnd: "1rem",
    paddingTop: ".25rem",
    boxSizing: "border-box",
  },
  planningArea: {
    background: colors.pattensBlue,
    borderBottomRightRadius: "1rem",
    padding: ".5rem",
    letterSpacing: "0.029375rem",
    textAlign: "center",
    color: colors.dimGray,
  },
  row: {
    display: "flex",
    gap: "0.5rem",
  },
  icon: {
    backgroundRepeat: "no-repeat",
    width: "1.25rem",
    height: "1.5rem",
  },
  itemsSideLineContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingInline: "2rem",
    // gap: "1rem",
    flex: 1,
  },
  itemSideLine: {
    borderRight: i18n.dir() === "rtl" ? `.1rem solid ${colors.black}` : "",
    borderLeft: i18n.dir() === "ltr" ? `.1rem solid ${colors.black}` : "",
    padding: "0 1rem",
  },
  dateTitle: {
    fontFamily: fonts.FbReformaRegular,
    color: colors.pasterlGrayDark,
  },
  fileName: {
    cursor: "pointer",
  },
  closeMainModalIcon: {
    position: "absolute",
    backgroundRepeat: "no-repeat",
    top: "1rem",
    cursor: "pointer",
    transform: "scale(1.5)",
  },
  bellIcon: {
    backgroundImage: `url(${Images.bellIcon})`,
    width: "1.2rem",
    height: "1.2rem",
    backgroundRepeat: "no-repeat",
  } as React.CSSProperties,
  updateMe: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    fontFamily: fonts.FbReformaLight,
    //  fontSize: fonts.FbReformaLight,
    color: "rgb(0, 69, 138)",
    cursor: "pointer",
  } as React.CSSProperties,
};

export default GuideLinesAndRatesCard;
