import { useNavigate } from "react-router-dom";
import colors from "../../../Assets/Colors/Colors.json";
import fonts from "../../../Assets/Fonts/Fonts.json";
import { IContactItem } from "../../../Interfaces/IContactItem";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";

const ContactItem: React.FC<IContactItem> = (props) => {
  const { topText, link, linkType, image } = props;

  const navigate = useNavigate();

  const links = {
    Phone: `tel:${link.replace(/[^+\d]/g, "")}`,
    Email: `mailto:${link}`,
    Telegram: `https://t.me/${link.replace("@", "")}`,
    WhatsApp: `https://wa.me/${link.replace(/[^a-zA-Z0-9]+/g, "")}`,
  };

  const handleLink = () => {
    if (linkType === "Text") return;
    if (linkType === "External Link") {
      window.open(
        !link.includes("https://") ? `https://${link}` : link,
        "_blank"
      );
    } else if (linkType === "Internal Link") {
      navigate(`?page=${link}`);
    } else {
      window.open(links[linkType], "_blank");
    }
  };

  return (
    <div style={styles.container}>
      <div style={{ display: "flex", gap: "1rem" }}>
        <img src={image._url} />
        <div style={styles.midContainer}>
          <span style={styles.topText}>{topText}</span>
          <span
            role={"link"}
            tabIndex={0}
            style={{
              ...styles.link,
              cursor: linkType === "Text" ? "default" : "pointer",
            }}
            onClick={() => {
              if (linkType !== "Text") handleLink();
            }}
          >
            {link}
          </span>
        </div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    display: "flex",
    alignItems: "center",
  },
  topText: {
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaLight,
  },
  midContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  link: {
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaMedium,
    cursor: "pointer",
  },
};

export default ContactItem;
