import { t } from "i18next";
import Images from "../../../../../../Data/Images/Images";

import { Fragment, useEffect, useRef, useState } from "react";
import ReactModal from "react-modal";
import colors from "../../../../../../Assets/Colors/Colors.json";
import i18n from "../../../../../../Services/i18n";
import { StyleSheet } from "../../../../Interfaces_tenders/IStyleSheet";
import Title from "../../Title";

import { FormatDateYYYYMMDD_Dots } from "../../../../../../Services/DateService";
import { IServerFile } from "../../../../Interfaces_tenders/IUmbraco";
import RichTextEditor from "../../RichTextEditor";

import {
  ChangeTitle,
  StyleMode,
  TitlePage,
} from "../../../../../../Utils/consts";
import useDeviceMode from "../../../../../../Utils/useWindowDimensions";

import { FilesToZipServiceServer } from "../../../../../../Services/FilesToZipServiceServer";
import { PostEmailList } from "../../../../../../Services/tenderServerServices";
import {
  IAuctionsSystemAuctions,
  IAuctionsSystemMeetings,
} from "../../../../Interfaces_tenders/IAuctionsSystemDetails";
import FileViewerServer from "../../../FileViewer/FileViewerServer";
import ServerFile from "../../File/ServerFile";
import MeetingCard from "../Meetings/MeetingCard";

import { useSelector } from "react-redux";
import fonts from "../../../../../../Assets/Fonts/Fonts.json";
import {
  linkBuilder,
  linkShareFormat,
} from "../../../../../../Components/SharePage/ShareOptions";
import { FormatHoursMinutes } from "../../../../../../Services/DateService";
import { UseWindowScrollPositions } from "../../../../../../Utils/useWindowScrollPositions";
import InputForm from "../../../Form/InputForm/InputForm";
import { SystemParamsState } from "../../../../../../Redux/Slices/systemParamsSlice";
import ShareModal from "../../../../../../Components/Modal/ShareModal";
import { ga } from "react-ga";

const AuctionDetailsModal: React.FC<IAuctionsSystemAuctions> = (props) => {
  const {
    tenderName,
    tenderNumber,
    referentMail,
    publishDate,
    clarifyingDate,
    biddingDate,
    meetings,
    documentation,
    biddings,
    description,
    auctionSystem,
  } = props;

  const [_isOpenFileViewerModal, _setIsOpenFileViewerModal] = useState(false);
  const [_currentFileName, _setCurrentFileName] = useState<string>("");
  const appInfoRef = useRef<HTMLDivElement | null>(null);
  const [_isArrowAnimation, _setIsArrowAnimation] = useState(true);
  const handleOpenFilesViewer = () => {
    _setIsOpenFileViewerModal(true);
  };

  const [_isUpdateMeModalOpen, _setIsUpdateMeModalOpen] = useState(false);
  const [_fakeLoaderFlag, _setFakeLoaderFlag] = useState(true);
  const [_emailAdressValue, _setEmailAdressValue] = useState<string>("");
  const [_nameAdressValue, _setNameAdressValue] = useState<string>("");

  const [_mobileFilterIsOpen, _setMobileFilterIsOpen] = useState(false);
  const [_curDisplayContentGot, _setCurDisplayContentGot] = useState(false);

  const [_areAuctionsFiltered, _setAreAuctionsFiltered] = useState(false);

  const [_mailingFormDisplay, _setMailingFormDisplay] = useState(true);
  const errorDisplayUpdateMe = useSelector(
    (state: any) => state.AuctionsErrorStatusUpdateClientNewTenders
  );

  const [_placeHolderFrom, _setPlaceHolderFrom] = useState(true);
  const [_placeHolderTo, _setPlaceHolderTo] = useState(true);
  const deviceMode = useDeviceMode();
  const validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  const { scrollY } = UseWindowScrollPositions();
  const serverFiles: IServerFile[] =
    documentation &&
    documentation.map((file, index) => {
      return {
        document: file.docName,
        filename: file.docDisplay ? file.docDisplay : t("defaultFileNeme"),
      };
    });

  const half = Math.ceil(serverFiles.length / 2);
  const firstHalf = serverFiles.slice(0, half);
  const secondHalf = serverFiles.slice(half);

  const [_isDetailsModalOpen, _setIsDetailsModalOpen] = useState(false);
  const [_currentMeetingsDetails, _seCcurrentMeetingsDetails] =
    useState<IAuctionsSystemMeetings[]>();

  const [_isShareCardModalVisible, _setIsShareCardModalVisible] =
    useState(false);

  useEffect(() => {
    ChangeTitle(t(`${TitlePage.HomePage}`));
  }, []);

  const handleShareModal = () => {
    // _setIsShareCardModalVisible(true);
    handleShare();
  };
  const systemParams = (
    useSelector((state: any) => state.SystemParamsReducer) as SystemParamsState
  ).systemParams;

  const systemParamsDictionary = systemParams.reduce(
    (result: { [key: string]: string }, current) => {
      result[current.key] = current.value;
      return result;
    },
    {}
  );

  console.log(`${window.location.protocol}`);
  console.log(`${window.location.host}`);
  console.log(`${window.location.pathname}`);
  console.log(`${window.location.search}`);

  const fullUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}${window.location.search}`;


  const shareData = {
    title: tenderName,
    text: t("shareButtonAria") + " " + tenderNumber,
    url: fullUrl,
  };

  const handleShare = async () => {
    
    try {
      await navigator.share(shareData);
    } catch (err) {
      console.error(`Error: ${err}`);
    }
  };

  // const getShareUrl = (url: string) => {
  //   let link = `${window.location.href}`;
  //   linkShareFormat.title = description;
  //   linkShareFormat.subject = t("shareButtonAria") + " " + tenderNumber;
  //   linkShareFormat.body = encodeURIComponent(
  //     t("shareButtonAria") + " " + tenderNumber + "%0A" + link
  //   );
  //   linkShareFormat.summary = encodeURIComponent(description + "%0A" + link);
  //   linkShareFormat.text = encodeURIComponent(
  //     t("shareButtonAria") + " " + tenderNumber + "%0A" + link
  //   );
  //   linkShareFormat.quote = description;
  //   linkShareFormat.url = encodeURIComponent(link);
  //   linkShareFormat.u = encodeURIComponent(link);

  //   console.log("linkShareFormat", linkShareFormat);

  //   // sharePagetext
  //   let newLink = linkBuilder(url, linkShareFormat);

  //   return decodeURI(newLink);
  // };
  const sendEmailToMailingListHandler = () => {
    (async () => {
      const postEmailAdress = await PostEmailList({
        mails: _emailAdressValue,
        name: _nameAdressValue,
        tenderId: props.id,
        page: props.category,
      });

      if (postEmailAdress.status === 200) {
        _setMailingFormDisplay(false);
      }
    })();
  };

  return (
    <Fragment>
      <div style={styles.container}>
        <div
          style={{
            paddingTop: "3rem",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Title text={t("AuctionNumber", { tenderNumber }).slice(0, 35)} />
          {new Date(biddingDate) > new Date() &&
            systemParamsDictionary["updateSpecificTender"] === "true" && (
              <div
                style={styles.updateMeContainer}
                onClick={() => {
                  _setIsUpdateMeModalOpen(true);
                }}
                tabIndex={0}
                aria-label={t("updateMeOnChange")}
              >
                <img
                  src={Images.bellIcon}
                  style={styles.bellIcon}
                  alt={"bellIcon"}
                />
                {deviceMode !== StyleMode.mobile && (
                  <div style={styles.updateMe}>{t("updateMeOnChange")}</div>
                )}
              </div>
            )}
        </div>
        <div>
          <Title text={tenderName} isUnderline={false} />
        </div>
        <div style={styles.datesContainer}>
          <div style={styles.dateItem}>
            <img src={Images.datePicker} alt={"datePicker"}></img>
            <div style={styles.datePublishContainer}>
              <div>{t("AuctionPublishDate")}</div>
              <div style={{ fontWeight: "bold" }}>
                {FormatDateYYYYMMDD_Dots(new Date(publishDate))}
                {` - `}
                {FormatHoursMinutes(publishDate?.toString())}
              </div>
              {/* <div style={{ fontWeight: "bold" }}>
              {new Date(publishDate).toLocaleTimeString().substring(0,5)}
              </div> */}
            </div>
          </div>
          {clarifyingDate &&
            clarifyingDate.toString() !== "0001-01-01T00:00:00" && (
              <div style={styles.dateItem}>
                <img src={Images.datePicker} alt={"datePicker"}></img>
                <div style={styles.datePublishContainer}>
                  <div>{t("AuctionDeadlineForSubmissionOfQuestions")}</div>
                  <div style={{ fontWeight: "bold" }}>
                    {FormatDateYYYYMMDD_Dots(new Date(clarifyingDate))}
                    {` - `}
                    {FormatHoursMinutes(clarifyingDate?.toString())}
                  </div>
                  <div style={{ fontWeight: "bold" }}></div>
                </div>
              </div>
            )}
          <div style={styles.dateItem}>
            <img src={Images.datePicker} alt={"datePicker"}></img>

            <div style={styles.datePublishContainer}>
              <div>{t("AuctionDeadlineForSubmissionOfBids")}</div>
              <div style={{ fontWeight: "bold" }}>
                {FormatDateYYYYMMDD_Dots(new Date(biddingDate))}
                {` - `}
                {FormatHoursMinutes(biddingDate?.toString())}
              </div>
              {/* <div style={{ fontWeight: "bold" }}>
              {new Date(biddingDate).toLocaleTimeString().substring(0,5)}
              </div> */}
            </div>
          </div>
        </div>
        {meetings && meetings.length > 0 && (
          <div style={styles.meetingContainer}>
            <Title text={t("MeetingOffers")} />

            {meetings &&
              meetings.map((meeting, index) => (
                <MeetingCard
                  key={index}
                  meetingsId={meeting.meetingsId}
                  meetingDate={meeting.meetingDate}
                  registretionLink={meeting.registretionLink}
                  location={meeting.location}
                />
              ))}
          </div>
        )}
        {biddings && biddings.length > 0 && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "2rem",
            }}
          >
            <Title text={t("HowAndWhenSendOffer")} />
            <div>
              <RichTextEditor text={biddings} />
            </div>
          </div>
        )}
        {
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "2rem",
              paddingBottom: "2rem",
            }}
          >
            {documentation && documentation.length > 0 && (
              <div>
                <Title text={t("AuctionDocuments")} />
              </div>
            )}
            {serverFiles && serverFiles.length > 0 && (
              <div
                style={
                  deviceMode === StyleMode.mobile
                    ? {
                        ...styles.auctionDocumentsContainer,
                        flexDirection: "column",
                      }
                    : styles.auctionDocumentsContainer
                }
              >
                <div style={styles.filesContainer}>
                  <div className="firstColumnFiles">
                    {" "}
                    {firstHalf &&
                      firstHalf.map((document, index) => (
                        <div
                          key={index}
                          style={styles.auctionDocumentFile}
                          onClick={() => {
                            if (deviceMode !== StyleMode.mobile) {
                              handleOpenFilesViewer();
                            } else {
                              const filesToDownload = [
                                ...firstHalf.filter(
                                  (file) => file.document !== null
                                ),
                              ];
                              //const filesToDownload = [document];
                              FilesToZipServiceServer(
                                filesToDownload,
                                document.filename
                              );
                            }
                            //handleOpenFilesViewer();
                          }}
                        >
                          <ServerFile
                            document={document.document}
                            filename={document.filename}
                            onClick={() => _setIsOpenFileViewerModal}
                            onChangeFileName={(text) =>
                              _setCurrentFileName(text)
                            }
                            filenameStyles={{
                              display: "flex",
                              gap: ".5rem",
                              justifyContent: "start",
                              alignItems: "center",
                              color: colors.darkBlue,
                              fontSize: "1.2rem",
                              cursor: "pointer",
                              marginBottom: "1%",
                              textDecoration: "none",
                            }}
                          />
                          {/*
                  <div
                    style={{
                      ...styles.fileIcon,
                      background: `url(${
                        Images[
                          `${document.document.umbracoExtension}Icon` as keyof {
                            [key: string]: any;
                          }
                        ]
                      }) no-repeat`,
                    }}
                  ></div>
                
                  <div>{document.filename}</div>*/}
                        </div>
                      ))}
                  </div>
                  <div className="secondColumnFiles">
                    {" "}
                    {secondHalf &&
                      secondHalf.map((document, index) => (
                        <div
                          key={index}
                          style={styles.auctionDocumentFile}
                          onClick={() => {
                            if (deviceMode !== StyleMode.mobile) {
                              handleOpenFilesViewer();
                            } else {
                              const filesToDownload = [
                                ...secondHalf.filter(
                                  (file) => file.document !== null
                                ),
                              ];
                              //const filesToDownload = [document];
                              FilesToZipServiceServer(
                                filesToDownload,
                                document.filename
                              );
                            }
                            //handleOpenFilesViewer();
                          }}
                        >
                          <ServerFile
                            document={document.document}
                            filename={document.filename}
                            onClick={() => _setIsOpenFileViewerModal}
                            onChangeFileName={(text) =>
                              _setCurrentFileName(text)
                            }
                            filenameStyles={{
                              display: "flex",
                              gap: ".5rem",
                              justifyContent: "start",
                              alignItems: "center",
                              color: colors.darkBlue,
                              fontSize: "1.2rem",
                              cursor: "pointer",
                              marginBottom: "1%",
                              textDecoration: "none",
                            }}
                          />
                          {/*
                  <div
                    style={{
                      ...styles.fileIcon,
                      background: `url(${
                        Images[
                          `${document.document.umbracoExtension}Icon` as keyof {
                            [key: string]: any;
                          }
                        ]
                      }) no-repeat`,
                    }}
                  ></div>
                
                  <div>{document.filename}</div>*/}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            )}

            {description && description.length > 0 && (
              <div>
                <Title text={t("AuctionDescription")} />
              </div>
            )}

            {description && description.length > 0 && (
              <div>
                <div
                  style={styles.descriptionText}
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </div>
            )}

            <div
              style={{
                display: "flex",
              }}
            ></div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <div
                  tabIndex={0}
                  style={styles.requestClarification}
                  onClick={() => {
                    window.location.href = `mailto:${referentMail}`;
                  }}
                >
                  {t("RequestForClarification")}
                </div>
              </div>
              <div>
                <span>
                  <img
                    src={
                      appInfoRef.current &&
                      appInfoRef.current.getBoundingClientRect().top +
                        appInfoRef.current.getBoundingClientRect().height <
                        scrollY
                        ? Images.sharePng
                        : Images.sharePng
                    }
                    style={
                      deviceMode === StyleMode.mobile
                        ? {
                            ...styles.arrowIcon,
                            left: i18n.dir() === "rtl" ? "14%" : "",
                            right: i18n.dir() === "ltr" ? "14%" : "",
                            animationName: _isArrowAnimation
                              ? "moveUpDown"
                              : "",
                            width: "3rem",
                            // borderRadius: "100%",
                            cursor: "pointer",
                          }
                        : {
                            ...styles.arrowIcon,
                            left: i18n.dir() === "rtl" ? "4%" : "",
                            right: i18n.dir() === "ltr" ? "4%" : "",

                            animationName: _isArrowAnimation
                              ? "moveUpDown"
                              : "",
                            width: "3rem",
                            //  borderRadius: "100%",
                            cursor: "pointer",
                          }
                    }
                    tabIndex={0}
                    role={"button"}
                    aria-label={t("shareButtonAria")}
                    onClick={handleShareModal}
                  />
                </span>
              </div>
            </div>
          </div>
        }
      </div>

      <ReactModal
        isOpen={_isOpenFileViewerModal}
        ariaHideApp={false}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            width: "85%",
            bottom: "auto",
            height: "50rem",
            transform: "translate(-50%, -50%)",
            border: "none",
            padding: "none",
            position: "absolute",
            maxHeight: "100%",
            overflow: "visible",
          },
          overlay: {
            backgroundColor: colors.modal,
            zIndex: "99999",
            overflow: "hidden",
          },
        }}
        parentSelector={() => document.querySelector("#root") || document.body}
        preventScroll={true}
      >
        <img
          src={Images.closeBG}
          tabIndex={0}
          style={{
            ...styles.closeFileViewerModalIcon,
            right: i18n.dir() === "rtl" ? "1rem" : "",
            left: i18n.dir() === "ltr" ? "1.1rem" : "",
          }}
          onClick={() => {
            _setIsOpenFileViewerModal(false);
          }}
          role={"button"}
          aria-label={t("closeButtonAria")}
        />
        {serverFiles && serverFiles.length > 0 && (
          <FileViewerServer
            files={serverFiles}
            title={`${t("tenderIndexNumber")} ${tenderNumber}`}
            curFileName={_currentFileName}
            key={new Date().toDateString()}
          />
        )}
      </ReactModal>

      {/* {_isShareCardModalVisible && (
        <ShareModal
          title={t("Share")}
          icon={Images.share}
          onClose={() => _setIsShareCardModalVisible(false)}
          items={[
            {
              name: t("Facebook"),
              icon: Images.facebookHeader,
              link: getShareUrl(
                `https://www.facebook.com/sharer/sharer.php?u=`
              ),
              ariaLabel: t("ShareFacebook"),
            },
            {
              name: t("WhatsApp"),
              icon: Images.whatsapp,
              link: getShareUrl(`https://wa.me/?text=`),
              ariaLabel: t("ShareWhatsapp"),
            },
            {
              name: t("Mail"),
              icon: Images.shareMail,
              link: getShareUrl(`mailto:?subject=&body=`),
              ariaLabel: t("ShareEmail"),
            },
            {
              name: t("Linkedin"),
              icon: Images.linkedinHeader,
              link: getShareUrl(
                `https://www.linkedin.com/sharing/share-offsite/?url=`
              ),
              ariaLabel: t("ShareLinkedin"),
            },
          ]}
        />
      )} */}
      <ReactModal
        isOpen={_isUpdateMeModalOpen}
        ariaHideApp={false}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            width: "85%",
            maxWidth: "42rem",
            bottom: "auto",
            height: "30rem",
            transform: "translate(-50%, -50%)",
            border: "none",
            padding: "none",
            position: "absolute",
            maxHeight: "100%",
            overflow: "visible",
            borderRadius: "13px 13px 288px 288px / 13px 13px 43px 43px",
          },
          overlay: {
            backgroundColor: colors.modal,
            zIndex: "99999",
            overflow: "hidden",
          },
        }}
        parentSelector={() => document.querySelector("#root") || document.body}
        preventScroll={true}
      >
        <div style={styles.updateMeModalIconWrapper}>
          <div style={styles.updateMeModalIconContainer}>
            <div style={styles.bellBigIcon} />
          </div>
        </div>
        <img
          role={"button"}
          aria-label={t("closeButtonAria")}
          style={styles.closeUpdateMeIcon}
          onClick={() => _setIsUpdateMeModalOpen(false)}
          src={Images.closeBG}
          tabIndex={0}
        />
        <div style={styles.updateMeModalBody}>
          <div style={styles.updateMeModalFormContainer}>
            <div
              style={styles.updateMeModalTitle}
              dangerouslySetInnerHTML={{
                __html: t("updateMeOnChange"),
              }}
            />
            {_mailingFormDisplay && (
              <InputForm
                placeholder={t("firstName")}
                onChange={(e) => {
                  _setNameAdressValue(e);
                }}
                type="Text"
              />
            )}
            {_mailingFormDisplay && (
              <InputForm
                placeholder={t("Mail")}
                onChange={(e) => {
                  _setEmailAdressValue(e);
                }}
                type="Email"
              />
            )}
            {_mailingFormDisplay && (
              <div
                style={
                  _emailAdressValue === "" ||
                  !_emailAdressValue.match(validRegex)
                    ? {
                        ...styles.updateMeButton,
                        pointerEvents: "none",
                        opacity: "0.4",
                      }
                    : {
                        ...styles.updateMeButton,
                      }
                }
                tabIndex={0}
                role={"button"}
                onClick={sendEmailToMailingListHandler}
              >
                {t("updateMe")}
              </div>
            )}
            {!_mailingFormDisplay && !errorDisplayUpdateMe && (
              <div style={styles.updateMeModalTitle}>{t("mailingListAdd")}</div>
            )}
            {errorDisplayUpdateMe && (
              <p>{t("errorMessageUpdateClientNewTenders")}</p>
            )}
          </div>
        </div>
      </ReactModal>
    </Fragment>
  );
};

const styles: StyleSheet = {
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "5rem",
    height: "100%",
    overflow: "auto",
    paddingInlineStart: "3rem",
    paddingInlineEnd: "3rem",
    maxWidth: "70rem",
    width: "100%",
  },
  meetingTimeItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "1rem",
    cursor: "pointer",
  },
  icon: {
    backgroundRepeat: "no-repeat",
    width: "2.5rem",
    height: "2.5rem",
  },
  fileIcon: {
    backgroundSize: "contain",
    width: "1.5rem",
    height: "1.5rem",
  },
  datesContainer: {
    display: "flex",
    gap: "3rem",
    flexWrap: "wrap",
    alignItems: "center",
  },
  dateItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: colors.darkBlue,
    fontSize: "1.2rem",
    gap: "1rem",
  },
  auctionDocumentFile: {
    display: "flex",
    gap: ".5rem",
    justifyContent: "start",
    alignItems: "center",
    color: colors.darkBlue,
    fontSize: "1.2rem",
    cursor: "pointer",
    marginBottom: "1%",
    flexBasis: "45%",
  },

  auctionDocumentsContainer: {
    display: "flex",
    gap: "10%",
    flexWrap: "wrap",
  },
  bellBigIcon: {
    backgroundImage: `url(${Images.bellBigIcon})`,
    width: "2rem",
    height: "2rem",
    backgroundRepeat: "round",
  },
  bellIcon: {
    width: "1.5rem",
  } as React.CSSProperties,
  updateMe: {
    fontFamily: fonts.FbReformaRegular,
    //  fontSize: "1.525rem",
    color: "rgb(0, 69, 138)",
  } as React.CSSProperties,
  updateMeContainer: {
    display: "flex",
    gap: ".5rem",
    alignItems: "center",
    cursor: "pointer",
  } as React.CSSProperties,
  closeFileViewerModalIcon: {
    position: "absolute",
    /* backgroundImage: `url(${Images.closeBG})`,
    backgroundRepeat: "no-repeat",
    width: "2rem",
    height: "1.5rem",*/
    left: "1rem",
    top: "1rem",
    cursor: "pointer",
    transform: "scale(1.5)",
  },
  requestClarification: {
    backgroundColor: colors.darkBlue,
    color: colors.white,
    fontSize: "1rem",
    borderRadius: "16rem",
    justifyContent: "center",
    paddingTop: ".5rem",
    alignItems: "center",
    maxWidth: "12rem",
    minHeight: "1.8rem",
    textAlign: "center",
    cursor: "pointer",
    paddingInline: "1rem",
  },
  datePublishContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  meetingContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
  },

  meetingContainerDetails: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },

  meetingContainerDetailsFirstRow: {
    display: "flex",
    gap: "3rem",
    flexWrap: "wrap",
    alignItems: "center",
  },
  meetingContainerDetailsSecondRow: {
    display: "flex",
    gap: "3rem",
    flexWrap: "wrap",
    alignItems: "center",
  },
  purchaseModalCloseIcon: {
    position: "absolute",
    top: "-2rem",
    cursor: "pointer",
  },
  purchaseModalIconWrapper: {
    display: "flex",
    justifyContent: "center",
    position: "relative",
    top: "-2.5rem",
    zIndex: "9999999",
  },
  purchaseModalIconContainer: {
    width: "4.3rem",
    height: "4.3rem",
    boxShadow: "0px 0px 10px black",
    borderRadius: "50%",
    backgroundColor: "white",
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  } as React.CSSProperties,

  purchaseModalBody: {
    position: "absolute",
    top: "5%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    gap: "2.5rem",
  },
  purchaseModalTitle: {
    fontSize: "1.5rem",
    lineHeight: "1.5rem",
    maxWidth: "18rem",
    fontFamily: fonts.FbReformaMedium,
    textAlign: "center",
  },
  purchaseOptions: {
    display: "flex",
    gap: "5rem",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  purchaseItem: {
    cursor: "pointer",
    height: "5rem",
  },

  purchaseOptionsFirst: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "0.5rem",
    minWidth: "7rem",
  },
  purchaseOptionsSecond: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "0.5rem",
    minWidth: "7rem",
  },

  closeUpdateMeIcon: {
    position: "absolute",
    left: "1rem",
    top: "1rem",
    cursor: "pointer",
    transform: "scale(1.5)",
  } as React.CSSProperties,

  descriptionText: {
    color: colors.darkBlue,
    fontSize: "1.2rem",
  } as React.CSSProperties,
  updateMeModalIconContainer: {
    width: "4rem",
    height: "4rem",
    boxShadow: "0px 0px 10px black",
    borderRadius: "50%",
    backgroundColor: "white",
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  } as React.CSSProperties,
  updateMeModalIconWrapper: {
    display: "flex",
    justifyContent: "center",
    position: "relative",
    top: "-2.5rem",
    zIndex: "9999999",
  } as React.CSSProperties,
  updateMeModalBody: {
    position: "absolute",
    top: "12%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  } as React.CSSProperties,
  updateMeModalFormContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "1.5rem",
  } as React.CSSProperties,
  updateMeModalTitle: {
    fontSize: "1.6rem",
    letterSpacing: "0.035625rem",
    lineHeight: "1.75rem",
    fontFamily: fonts.FbReformaRegular,
    color: colors.darkBlue,
    maxWidth: "22rem",
    textAlign: "center",
  } as React.CSSProperties,

  ModalOverlay: {
    backgroundColor: colors.modal,
    position: "fixed",
    width: "100%",
    height: "100%",
    top: "0",
    zIndex: "999",
  },

  innerModal: {
    position: "absolute",
    width: "100%",
    bottom: "0",
    height: "85%",
  },

  closeModalIcon: {
    backgroundImage: `url(${Images.closeButtonMob})`,
    backgroundRepeat: "no-repeat",
    width: "1.2rem",
    height: "1.2rem",
    cursor: "pointer",
    transform: "scale(1.5)",
    zIndex: "99999999",
  },

  containerStyles: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: colors.white,
    //  lineHeight: "19rem",
    width: "100%",
    // height: `${6 * languageSelection.length}`,
    padding: "2rem",
    boxSizing: "border-box",
    borderRadius: "2.6rem",
    //zoom: "140%",
    height: "100%",
  },
  mobileHeaderFIlter: {
    display: "flex",
    minHeight: "5vh",
    width: "100%",
    justifyContent: "center",
  } as React.CSSProperties,

  topInfoMobileContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    width: "80%",
  } as React.CSSProperties,

  filterIconContainer: {
    display: "flex",
    gap: "0.5rem",
    alignItems: "center",
    color: "rgb(0, 157, 214)",
    fontSize: "1.5rem",
  } as React.CSSProperties,
  auctionName: {
    color: colors.gray,
    fontSize: "1.75rem",
    letterSpacing: "0.039375rem",
    fontFamily: fonts.FbReformaMedium,
    maxWidth: "35rem",
    lineHeight: "2rem",
    padding: "1.45rem",
    overflow: "hidden",
    textOverflow: "ellipsis",
  } as React.CSSProperties,

  mobileTitleAndClear: {
    flex: "2",
  } as React.CSSProperties,

  mobileFilterInputes: {
    flex: "15",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "1rem",
  } as React.CSSProperties,

  searchButtonMobileContainer: {
    flex: "3",
    display: "flex",
    justifyContent: "center",
  } as React.CSSProperties,

  errorMessage: {
    color: colors.red,
    fontSize: "1.75rem",
    letterSpacing: "0.039375rem",
    fontFamily: fonts.FbReformaMedium,
    maxWidth: "35rem",
    lineHeight: "2rem",
    padding: "1.45rem",
  } as React.CSSProperties,
  clearMobile: {
    fontFamily: fonts.FbReformaRegular,
    fontSize: "1rem",
    textDecoration: "underline",
    color: colors.darkCyan,
    cursor: "pointer",
  } as React.CSSProperties,
  updateMeButton: {
    backgroundColor: colors.darkBlue,
    color: colors.white,
    fontFamily: fonts.FbReformaMedium,
    fontSize: "1.2rem",
    borderRadius: "16rem",
    width: "8rem",
    height: "1.8rem",
    fontWeight: "bold",
    letterSpacing: "0.029375rem",
    textAlign: "center",
    paddingTop: ".5rem",
    cursor: "pointer",
  } as React.CSSProperties,

  filesContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    flexWrap: "wrap",
  } as React.CSSProperties,
};

export default AuctionDetailsModal;
