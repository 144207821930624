import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import colors from "../../../Assets/Colors/Colors.json";
import fonts from "../../../Assets/Fonts/Fonts.json";
import fontSize from "../../../Assets/Fonts/FontsSizes.json";
import { ILevelsGrouping } from "../../../Interfaces/ILevels";
import LevelItem from "./LevelItem";
import { StyleMode } from "../../../Utils/consts";
import useDeviceMode from "../../../Utils/useWindowDimensions";
import { useEffect, useRef } from "react";
import TitleSplitted from "../TitleSplitted/TitleSplitted";

const LevelsGrouping: React.FC<ILevelsGrouping> = (props) => {
  const { firstTitle, secondTitle, levels, finalText, anchor } = props;
  const deviceMode = useDeviceMode();
  const containerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          } else {
            entry.target.classList.remove("visible");
          }
        });
      },
      { threshold: 0.5 }
    );

    const items = containerRef.current?.querySelectorAll(".snap-item");
    items?.forEach((item) => observer.observe(item));

    return () => {
      items?.forEach((item) => observer.unobserve(item));
    };
  }, []);

  return (
    <div style={styles.mainContainer} anchor-id={anchor}>
      <div
        style={{
          ...styles.container,
          flexDirection: deviceMode === StyleMode.desktop ? "row" : "column",
          padding: deviceMode === StyleMode.desktop ? "0 20rem" : "0 2rem",
        }}
      >
        <TitleSplitted
          firstTitle={firstTitle}
          secondTitle={secondTitle}
          customH1Styles={{
            ...styles.title,
            color: colors.darkBlue,
            padding: deviceMode !== StyleMode.desktop ? "0 2rem" : "",
            textAlign: deviceMode !== StyleMode.desktop ? "center" : "start",
            width: "",
          }}
          customFirstTitleStyles={{
            fontFamily:
              deviceMode === StyleMode.desktop
                ? fonts.FbReformaLight
                : fonts.FbReformaMedium,
            fontSize: "2.7rem",
          }}
          customSecondTitleStyles={{
            fontFamily: fonts.FbReformaMedium,
          }}
          isMobileOneLine={false}
        />
        <div
          style={styles.levelsContainer}
          // className="scroll-container"
          ref={containerRef}
        >
          {levels.map((level, index) => (
            <LevelItem {...level} index={index} key={index} />
          ))}
        </div>
      </div>
      <span
        style={{
          ...styles.finalText,
          textAlign: deviceMode !== StyleMode.desktop ? "center" : "start",
          padding: deviceMode !== StyleMode.desktop ? "0 2rem" : "",
        }}
      >
        {finalText}
      </span>
    </div>
  );
};

const styles: StyleSheet = {
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  container: {
    display: "flex",
  },
  levelsContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 2,
  },
  title: {
    fontSize: fontSize.PageTitle,
    lineHeight: "3rem",
    color: colors.darkBlue,
    zIndex: 1,
    position: "relative",
    flex: 1,
  },
  finalText: {
    fontWeight: 400,
    color: colors.nero,
    fontSize: fontSize.Text,
  },
};

export default LevelsGrouping;
