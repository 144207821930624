import moment from "moment";
import QueryString from "qs";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import store from "store2";
import Loading from "../Components/Loading/Loading";
import StationHeader from "../Components/StationInfo/StationHeader";
import { IBreadCrumbItem } from "../Interfaces/IBreadcrumb";
import { IFooter } from "../Interfaces/IFooter";
import { IStaticPage } from "../Interfaces/IStaticPage";
import {
  ICategoryPages,
  ITreeData,
  IUmbracoFull,
  IUpdates,
} from "../Interfaces/ITree";
import {
  ICookies,
  IHeaderContent,
  IHomeContent,
  IPopUpFares,
  IRoutePlanSearchResultsUmbraco,
  ISearchResultsContent,
} from "../Interfaces/IUmbraco";
import { PageState, setPage } from "../Redux/Slices/pageSlice";
import {
  setDate,
  setDestinationStation,
  setHours,
  setMinutes,
  setOriginStation,
  setScheduleType,
} from "../Redux/Slices/scheduleSlice";
import {
  SomethingWentWrongState,
  setSomethingWentWrong,
} from "../Redux/Slices/somethingWentWrongSlice";
import {
  UmbracoStoreState,
  setUmbracoStore,
} from "../Redux/Slices/umbracoSlice";
import { FormatDateYYYYMMDD } from "../Services/DateService";
import { GetAllUmbracoData, GetStations } from "../Services/GlobalService";
import i18n from "../Services/i18n";
import { StyleMode, setGlassixPages } from "../Utils/consts";
import {
  ChangeHrefLang,
  CreateBreadcrumbSchema,
  CreateQuestionAndAnswerSchema,
} from "../Utils/seo";
import { CreateSiteDate, CreateXml } from "../Utils/sitemap";
import { GetCurrentContentByTree } from "../Utils/tree";
import { GetStaionIdByName } from "../Utils/utils";
import { IsNumeric } from "../Utils/validation";
import Entitled from "./Entitled";
import ErrorPageMain from "./ErrorPageMain";
import Home from "./Home";
import NewCareer from "./NewCareer";
import RoutePlan from "./RoutePlan";
import RoutePlanSearchResultsMain from "./RoutePlanSearchResults/RoutePlanSearchResultsMain";
import RoutePlanSearchResultsMainMulti from "./RoutePlanSearchResults/RoutePlanSearchResultsMainMulti";
import SearchResults from "./SearchResults";
import SiteMap from "./SiteMap";
import StaticPage from "./StaticPage";
import StaticPageJob from "./StaticPageJob";
import TestPage from "./TestPage";
import Updates from "./Updates";
import XmlSiteMap from "./XmlSiteMap";

import Tender from "../Auctions/src/Screens/Main";

import i18next from "i18next";
import { IAuctionsSystem } from "../Auctions/src/Interfaces_tenders/IAuctionsSystem";
import HeaderMobile from "../Components/Header/Mobile/HeaderMobile";
import { IMagazinePage } from "../Interfaces/IMagazinePage";
import useDeviceMode from "../Utils/useWindowDimensions";
import ContactUsNew from "./ContactUsNew";
import MagazinePage from "./MagazinePage";
import PaymentStatus from "./PaymentStatus";
import StaticPageTenders from "./StaticPageTenders";
import Unsubscribe from "./Unsubscribe";
import NewAddress from "./NewAddress";
import Config from "../Configuration/server";
import { IAuctionNavigation } from "../Interfaces/IAuctionNavigation";
import AuctionsLandingPage from "./AuctionsLandingPage";

export interface IMain {
  isStations: boolean;
  isPreview?: boolean;
}

const Main: React.FC<IMain> = (props) => {
  const { isStations, isPreview = false } = props;

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const deviceMode = useDeviceMode();
  const [_isLoading, _setIsLoading] = useState<boolean>(true);
  const [_isPageNotExists, _setIsPageNotExists] = useState<boolean>(false);
  const [_isRemoveMailingListUrl, _setIsRemoveMailingListUrl] = useState(false);
  const params = new URLSearchParams(location.search);

  const languagesDictionary: { [locale: string]: string } = {
    he: "Hebrew",
    en: "English",
    ru: "Russian",
    ar: "Arabic",
  };

  const currentPage = (
    useSelector((state: any) => state.PageReducer) as PageState
  ).page;

  const umbracoStore = (
    useSelector((state: any) => state.UmbracoStoreReducer) as UmbracoStoreState
  ).umbracoStore;

  const somethingWentWrong = (
    useSelector(
      (state: any) => state.SomethingWentWrongReducer
    ) as SomethingWentWrongState
  ).somethingWentWrong;

  const currentTree = umbracoStore.treeData as ITreeData;
  const [_staticPageContent, _setStaticPageContent] = useState<IStaticPage>();
  const [_magazinePageContent, _setMagazinePageContent] =
    useState<IMagazinePage>();
  const [_auctionNavigation, _setAuctionNavigation] =
    useState<IAuctionNavigation>();

  let itemLink = "";

  useEffect(() => {
    let isMounted = true;
    (async () => {
      const allStations = store.session.get(
        `${Config.BASE_LOCALSTORAGE_NAME}stations`
      );
      if (allStations !== `` && allStations !== undefined) {
      } else {
        let station = await GetStations();
        store.session.set(`${Config.BASE_LOCALSTORAGE_NAME}stations`, station);
      }
      if (isMounted) {
      }
    })();

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    dispatch(setSomethingWentWrong(false));
    _setIsLoading(true);
    handleUrlParams();
  }, [location]);

  useEffect(() => {
    if (
      currentPage === "" ||
      currentPage === "sitemap" ||
      currentPage === "xmlsitemap" ||
      currentPage === "routeplan" ||
      currentPage === "routeplansearchresults" ||
      currentPage === "searchresults" ||
      currentPage === "stationinfo" ||
      currentPage === "updates" ||
      currentPage === "guidelinesandrates" ||
      currentPage === "GeneralAuctions" ||
      currentPage === "CargoAuctions"
    ) {
      _setIsPageNotExists(false);
    } else {
      if (_staticPageContent) {
        _setIsPageNotExists(false);
      } else {
        _setIsPageNotExists(true);
      }
    }
  }, [_staticPageContent, currentPage]);

  useEffect(() => {
    (async () => {
      _setIsLoading(true);
      let tree: ITreeData;
      if (
        currentTree &&
        currentTree.paths.categories.length === 0 &&
        currentTree.categories.length === 0 &&
        currentTree.Tree.length === 0
      ) {
        tree = await getTree();
      } else {
        tree = { ...currentTree };
      }
      setStaticPageContent(tree);

      _setIsLoading(false);
    })();

    CreateBreadcrumbSchema();
    CreateQuestionAndAnswerSchema();
  }, [location]);

  useEffect(() => {
    let isMounted = true;
    (async () => {
      if (isMounted) {
        _setIsLoading(true);
      }
      let tree = await getTree();

      if (isMounted) {
        setStaticPageContent(tree);
        _setIsLoading(false);
      }
    })();

    return () => {
      isMounted = false;
    };
  }, [i18n.language]);

  //   useEffect(() => {
  //     const urlParams = location.search.split("?")[1] || "";
  //     if (urlParams) {
  //       const allParams = QueryString.parse(urlParams);
  //       const { page } = allParams;
  //       const current = page as string;
  //       const glassixPages = store.get(`GlassixPages`)
  //       if(glassixPages?.toLowerCase()?.includes(`"${current.toLowerCase()}"`)){
  //         const glassix =document.getElementById(`glassix-container`)
  //         const glassix_icon = document.getElementsByClassName(`glassix-widget-icon-wrapper`)[0]
  //        if(glassix){
  //            glassix.setAttribute('style',
  //                'display:block !important;animation: fadeInAnimation ease 3s;animation-iteration-count: 1;animation-fill-mode: forwards;')
  //            let icon =  glassix_icon?.getElementsByTagName('img').item(0)
  //            icon?.setAttribute('style',`max-width: 65%;`)
  //        }
  //       }
  //       else{
  //         const glassix =document.getElementById(`glassix-container`)
  //         if(glassix){
  //             glassix.setAttribute('style',
  //                 '!important;animation: fadeOutAnimation ease 3s;animation-iteration-count: 1;animation-fill-mode: forwards;')
  //                 glassix.setAttribute('style','display:none !important;')
  //         }
  //       }
  //     }
  //     else{
  //       const glassix =document.getElementById(`glassix-container`)
  //       if(glassix){
  //           glassix.setAttribute('style',
  //               '!important;animation: fadeOutAnimation ease 3s;animation-iteration-count: 1;animation-fill-mode: forwards;')
  //               glassix.setAttribute('style','display:none !important;')
  //       }
  //     }

  // }, [location]);

  const handleUrlParams = () => {
    const urlParams = location.search.split("?")[1] || "";
    if (urlParams) {
      const allParams = QueryString.parse(urlParams);
      const {
        page,
        step,
        fromStation,
        toStation,
        date,
        hours,
        minutes,
        scheduleType,
        lan,
      } = allParams;

      let currentLanguage = store.get(`i18nextLng`);

      if (!currentLanguage) {
        store.set(`i18nextLng`, `he`);
        i18n.changeLanguage(`he`);
      }

      if (lan) {
        if (lan != currentLanguage) {
          i18n.changeLanguage(lan as string);
          store.set(`i18nextLng`, lan);
          let newUrl = `?` + urlParams.replace(`&lan=${lan}`, ``);
          newUrl = newUrl.replace(`lan=${lan}`, ``);
          navigate(newUrl);
        } else {
          let newUrl = `?` + urlParams.replace(`&lan=${lan}`, ``);
          newUrl = newUrl.replace(`lan=${lan}`, ``);
          navigate(newUrl);
        }
      }
      let url =
        `?${page ? `page=${page}` : ``}${step ? `&step=${step}` : ``}${
          fromStation ? `&fromStation=${fromStation}` : ``
        }` +
        `${toStation ? `&toStation=${toStation}` : ``}${
          date ? `&date=${date}` : ``
        }${hours ? `&hours=${hours}` : ``}` +
        `${minutes ? `&minutes=${minutes}` : ``}${
          scheduleType ? `&scheduleType=${scheduleType}` : ``
        }`;
      ChangeHrefLang(url);
      let stationFrom = undefined;
      let stationTo = undefined;
      if (fromStation) {
        if (!IsNumeric(fromStation + "")) {
          stationFrom = GetStaionIdByName(fromStation + "");
        }
        dispatch(
          setOriginStation(stationFrom ? stationFrom.stationId : +fromStation)
        );
        if (step) {
          if (step === "origin") {
            dispatch(setDestinationStation(undefined));
            dispatch(setDate(FormatDateYYYYMMDD(moment().toDate())));
          }
        }
      }
      if (toStation) {
        if (!IsNumeric(toStation + "")) {
          stationTo = GetStaionIdByName(toStation + "");
        }
        dispatch(
          setDestinationStation(stationTo ? stationTo.stationId : +toStation)
        );
        if (step) {
          if (step === "destination") {
            if (!fromStation) {
              dispatch(setDate(FormatDateYYYYMMDD(moment().toDate())));
              dispatch(setOriginStation(undefined));
              dispatch(setDestinationStation(undefined));
              navigate("?page=routePlan&step=origin");
            }
          }
        }
      } else {
        if (step) {
          if (step === "destination") {
            if (!fromStation) {
              dispatch(setOriginStation(undefined));
              dispatch(setDestinationStation(undefined));
              navigate("?page=routePlan&step=origin");
            }
          }
        }
      }
      if (date) {
        dispatch(setDate(date as string));
      } else {
        if (step) {
          if (step === "when") {
            if (fromStation && !toStation) {
              navigate(
                `?page=routePlan&step=origin&fromStation=${fromStation}`
              );
            } else if (toStation && !fromStation) {
              dispatch(setDestinationStation(undefined));
              navigate("?page=routePlan&step=origin");
            } else if (!toStation && !fromStation) {
              dispatch(setOriginStation(undefined));
              dispatch(setDestinationStation(undefined));
              navigate("?page=routePlan&step=origin");
            } else {
              const dateFormat = moment(new Date()).format("YYYY-MM-DD");
              dispatch(setDate(dateFormat));
            }
          }
        }
      }
      if (hours) {
        dispatch(setHours(hours as string));
      } else {
        const currentHour = new Date().getHours();
        dispatch(
          setHours(
            currentHour <= 9 ? `0${currentHour}` : currentHour.toString()
          )
        );
      }
      if (minutes) {
        dispatch(setMinutes(minutes as string));
      } else {
        const currentMinutes = new Date().getMinutes();
        dispatch(
          setMinutes(
            currentMinutes <= 9
              ? `0${currentMinutes}`
              : currentMinutes.toString()
          )
        );
      }
      if (scheduleType) {
        dispatch(setScheduleType(+scheduleType));
      } else {
        dispatch(setScheduleType(1));
      }

      if (
        page &&
        page.toString().toLowerCase() === "routePlanSearchResults".toLowerCase()
      ) {
        if (
          (!date || !hours || !minutes || !scheduleType) &&
          fromStation &&
          toStation
        ) {
          const currentDate = new Date();
          const currentHour = currentDate.getHours();
          const currentMinutes = currentDate.getMinutes();
          const formmatedDate = FormatDateYYYYMMDD(currentDate);
          const formmatedHours =
            currentHour <= 9 ? `0${currentHour}` : currentHour.toString();
          const formmatedMinutes =
            currentMinutes <= 9
              ? `0${currentMinutes}`
              : currentMinutes.toString();
          dispatch(setDate(formmatedDate));
          dispatch(setHours(formmatedHours));
          dispatch(setMinutes(formmatedMinutes));

          let timeout = setTimeout(() => {
            dispatch(setPage("routePlan"));
            navigate(
              `?page=routePlan&step=when&fromStation=${fromStation}&toStation=${toStation}`
            );
          }, 100);
          return () => {
            clearTimeout(timeout);
          };
        }
      }
      if (
        page &&
        page.toString().toLowerCase() === "routePlanSearchResults".toLowerCase()
      ) {
        if (!toStation && fromStation) {
          navigate(
            `?page=routePlan&step=destination&fromStation=${fromStation}`
          );
        }
        if (!fromStation && toStation) {
          let timeout = setTimeout(() => {
            dispatch(setOriginStation(undefined));
            dispatch(setDestinationStation(undefined));
            dispatch(setPage("routePlan"));
            navigate(`?page=routePlan&step=origin`);
          }, 100);
          return () => {
            clearTimeout(timeout);
          };
        }
        if (!fromStation && !toStation) {
          let timeout = setTimeout(() => {
            dispatch(setOriginStation(undefined));
            dispatch(setDestinationStation(undefined));
            dispatch(setPage("routePlan"));
            navigate(`?page=routePlan&step=origin`);
          }, 100);
          return () => {
            clearTimeout(timeout);
          };
        }
      }
    }
  };

  const getTree = async () => {
    const Umbraco =
      // isPreview?
      // await PreviewService(i18n.language, isPreview);
      // :
      // await UmbracoSource(i18n.language,isPreview)
      (await GetAllUmbracoData(i18n.language, isPreview)) as IUmbracoFull;
    let translation = Umbraco.railWeb.translations.result;

    i18next.addResourceBundle(
      i18n.language,
      "translation",
      translation,
      true,
      true
    );
    let glassixPages: string[] = [];
    // const tree = CreateTree(Umbraco);
    const tree = Umbraco.tree; //await GetUmbracoTreeData(i18n.language);

    const treeData = tree;

    treeData.categories.map((data) => {
      data.pages.map((page) => {
        if (page.glassixActive) {
          glassixPages.push(page.pageId.toLowerCase());
        }
      });
    });
    const StationsSeos = treeData.seos?.stations.reduce((prev, current) => {
      prev = {
        ...prev,
        [current.stationId]: current,
      };
      return prev;
    }, {});
    setGlassixPages(glassixPages);
    dispatch(setUmbracoStore({ ...umbracoStore, ...treeData }));
    CreateXml(treeData.Tree);
    CreateSiteDate(treeData.categories);

    if (Umbraco) {
      const headerContent = Umbraco.railWeb.contents.find(
        (content) => content.contentTypeAlias === "header"
      ) as unknown as IHeaderContent;
      const footer = Umbraco.railWeb.contents.find(
        (content) => content.contentTypeAlias === "footer"
      ) as unknown as IFooter;
      const homeContent = Umbraco.railWeb.contents.find(
        (content) => content.contentTypeAlias === "home"
      ) as unknown as IHomeContent;
      const cookies = Umbraco.railWeb.contents.find(
        (content) => content.contentTypeAlias === "cookies"
      ) as unknown as ICookies;
      const popUpFares = Umbraco.railWeb.contents.find(
        (content) => content.contentTypeAlias === "popUpFares"
      ) as unknown as IPopUpFares;
      const routePlanSearchResults = Umbraco.railWeb.contents.find(
        (content) => content.contentTypeAlias === "routePlanSearchResults"
      ) as unknown as IRoutePlanSearchResultsUmbraco;
      const updates = Umbraco.railWeb.contents.find(
        (content) => content.contentTypeAlias === "updates"
      ) as unknown as IUpdates;
      const searchResults = Umbraco.railWeb.contents.find(
        (content) => content.contentTypeAlias === "searchResults"
      ) as unknown as ISearchResultsContent;
      const career = Umbraco.railWeb.contents.find(
        (content) =>
          content.contentTypeAlias === "category" && content.id === "Career"
      ) as unknown as ICategoryPages;

      const tenders = Umbraco.railWeb.contents.filter(
        (type) => type.contentTypeAlias === "auctionsSystem"
      ) as unknown as (ICategoryPages & IAuctionsSystem)[] | undefined;

      const magazineContent = Umbraco.railWeb.contents.find(
        (type) => type.contentTypeAlias === "magazine"
      );
      if (magazineContent) {
        _setMagazinePageContent(magazineContent as unknown as IMagazinePage);
      }

      const auctionNavigation = Umbraco.railWeb.contents.find(
        (type) => type.contentTypeAlias === "auctionNavigation"
      ) as unknown as IAuctionNavigation;

      if (auctionNavigation) {
        _setAuctionNavigation(auctionNavigation);
      }

      dispatch(
        setUmbracoStore({
          headerContent,
          footer,
          homeContent,
          treeData,
          popUpFares,
          cookies,
          routePlanSearchResults,
          updates,
          searchResults,
          career,
          tenders,
          StationsSeos,
        })
      );
    }

    return treeData;
  };

  const setStaticPageContent = (tree: ITreeData) => {
    window.scrollTo(0, 0);
    const urlParams = QueryString.parse(location.search.split("?")[1]);

    if (urlParams.page?.length) {
      let urlPage = urlParams.page.toString().toLowerCase();
      if (urlPage === "mldpa") {
        urlPage = "mldp";
      }
      dispatch(setPage(urlPage));

      const currentPageContent = GetCurrentContentByTree(urlPage, tree);

      if (
        currentPageContent?.breadcrumb?.items &&
        currentPageContent?.breadcrumb.items.length > 1
      ) {
        if (currentPageContent?.breadcrumb.items[0].link === "") {
          umbracoStore?.headerContent?.menuCategories?.map((item) => {
            if (
              item.label.toLocaleLowerCase() ===
              currentPageContent?.breadcrumb?.items[0].text.toLocaleLowerCase()
            )
              itemLink = item.redirectTo;
          });
          let currentBreadcrumb = { ...currentPageContent?.breadcrumb };
          let newBreadcrumb: IBreadCrumbItem[] = [];
          currentBreadcrumb?.items.map((item, index) => {
            if (item.link === "") {
              item.link = itemLink;
            }
            if (index === 0) {
              newBreadcrumb.push(item);
            }
            if (
              index !== 0 &&
              item.link.toLowerCase() !==
                currentBreadcrumb.items[0].link.toLowerCase()
            ) {
              newBreadcrumb.push(item);
            }
          });

          _setStaticPageContent({
            ...currentPageContent,
            breadcrumb: { items: [...newBreadcrumb] },
          });
        }
      } else {
        _setStaticPageContent(undefined);
      }
    } else {
      _setStaticPageContent(undefined);
      dispatch(setPage(""));
    }
  };

  const RenderPage: React.FC = () => {
    const page = currentPage.toLowerCase();
    if (!somethingWentWrong) {
      if (page.includes("career_") === true) {
        let jobNumber = page.split(`_`);
        if (jobNumber.length > 1) {
          return <StaticPageJob jobNumber={parseInt(jobNumber[1])} />;
        } else return <Home />;
      } else {
        switch (page) {
          case "":
          case "homepage":
            return <Home />;
          case "career":
            return _staticPageContent ? (
              <NewCareer {..._staticPageContent} />
            ) : (
              <></>
            );
          case "unsubscribe":
            return <Unsubscribe />;
          case "landing":
            if (params.get("newAddress")) {
              return <NewAddress />;
            } else {
              return <Home />;
            }
          case "contact-us":
            return _staticPageContent ? (
              <ContactUsNew {..._staticPageContent} />
            ) : (
              <></>
            );
          case "magazine":
            return _magazinePageContent ? (
              <MagazinePage {..._magazinePageContent} />
            ) : null;
          case "entitled":
            return <Entitled {..._staticPageContent} />;
          case "sitemap":
            return <SiteMap />;
          case "xmlsitemap":
            return <XmlSiteMap />;
          case "routeplan":
            if (isStations) {
              return <RoutePlan />;
            }
            return <ErrorPageMain errorName="SomethingWentWrong" />;
          case "routeplansearchresults":
            if (isStations) {
              return <RoutePlanSearchResultsMain />;
            }
            return somethingWentWrong && !isStations ? (
              <ErrorPageMain errorName="SomethingWentWrong" />
            ) : null;
          case "routeplansearchresultsmulti":
            if (isStations) {
              return <RoutePlanSearchResultsMainMulti />;
            }
            return somethingWentWrong && !isStations ? (
              <ErrorPageMain errorName="SomethingWentWrong" />
            ) : null;
          case "searchresults":
            return <SearchResults />;
          case "stationinfo":
            return <StationHeader />;
          case "updates":
            return <Updates />;
          case "testpage":
            return <TestPage />;
          case "paymentsucceed":
          case "paymenterror":
            return <PaymentStatus />;
          // case "GeneralAuctions".toLowerCase():
          // case "CargoAuctions".toLowerCase():
          //     return (
          //       <Tender/>
          //     );
          default:
            if (!_isPageNotExists && _staticPageContent) {
              return <StaticPage {..._staticPageContent} />;
            }
            if (
              page !== "filePreview_external".toLowerCase() &&
              page !== "filePreview_internal".toLowerCase()
            ) {
              if (_isPageNotExists && !_staticPageContent) {
                return <ErrorPageMain errorName="PageNotFound" />;
              }
            }
        }
        return null;
      }
    } else {
      return <ErrorPageMain errorName="SomethingWentWrong" />;
    }
  };

  return _isLoading ? (
    <Loading />
  ) : (
    <Fragment>
      <div role={`main`}>
        {currentPage.toLowerCase() !== "routePlan".toLowerCase() &&
          currentPage.toLowerCase() !==
            "routePlanSearchResults".toLowerCase() &&
          deviceMode !== StyleMode.desktop && (
            <HeaderMobile headerContent={umbracoStore?.headerContent} />
          )}
        {deviceMode !== StyleMode.desktop && somethingWentWrong && (
          <HeaderMobile headerContent={umbracoStore?.headerContent} />
        )}
        {currentPage.toLowerCase() === "generalauctions".toLowerCase() ||
        currentPage.toLowerCase() === "cargoauctions".toLowerCase() ? (
          <div>
            {_staticPageContent && (
              <StaticPageTenders {..._staticPageContent} />
            )}
            <Tender />
          </div>
        ) : Config.IS_TENDERS === "false" ? (
          <RenderPage />
        ) : (
          <div>
            {_auctionNavigation && (
              <AuctionsLandingPage {..._auctionNavigation} />
            )}
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default Main;
