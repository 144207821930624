import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Images from "../../Data/Images/Images";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import { ModalState, openModal } from "../../Redux/Slices/modalSlice";
import i18n from "../../Services/i18n";
import fontSize from "../../Assets/Fonts/FontsSizes.json";
import { IsMobile } from "../../Services/DeviceService";
import FocusTrap from "focus-trap-react";
import useDeviceMode from "../../Utils/useWindowDimensions";
import { StyleMode } from "../../Utils/consts";

const Modal: React.FC = () => {
  const textInput: any = useRef(null);

  const dispatch = useDispatch();
  const modal = (useSelector((state: any) => state.ModalReducer) as ModalState)
    .modal;
  let [_visibleYesOrNo, _setVisibleYesOrNo] = useState(false);

  const deviceMode = useDeviceMode();

  const {
    modalStyle,
    childrenComponent,
    closeButtonType,
    position = "center",
    isYesNoModal = false,
    isOverlayClick = closeButtonType !== undefined,
    innerWidth,
    maxWidth,
    elementFocusAfterClose,
  } = modal;
  useEffect(() => {
    document.body.style.overflow = modal.visible ? "hidden" : "auto";
    modal.visible && textInput.current.focus();
    _setVisibleYesOrNo(false);
    return () => {
      // modal.visible ? textInput.current.focus() : textInput.current.blur()
      document.body.style.overflow = "auto";
    };
  }, [modal.visible]);

  const onClose = (e: any) => {
    if (isYesNoModal) {
      _setVisibleYesOrNo(true);
    } else {
      dispatch(openModal({ ...modal, visible: false }));
    }
    elementFocusAfterClose && elementFocusAfterClose();
  };
  const YesOrNoModal = () => {
    return (
      <div className="modal modal-smaller">
        <div className="modal-inner column smaller">
          <div className="title-modal">האם אתה בטוח שברצונך לסגור חלון זה?</div>
          <div className="row centering">
            <div
              className="btn-standard"
              onClick={() => dispatch(openModal({ ...modal, visible: false }))}
            >
              כן
            </div>
            <div
              className="btn-standard outline"
              onClick={() => _setVisibleYesOrNo(false)}
            >
              לא
            </div>
          </div>
        </div>
      </div>
    );
  };

  return modal.visible ? (
    deviceMode === StyleMode.desktop ? (
      <FocusTrap>
        <div
          ref={textInput}
          className="modal"
          aria-labelledby="modal-inner"
          aria-modal="true"
          tabIndex={!IsMobile() ? 0 : -1}
          onKeyUp={(e) => e.key === "Escape" && onClose(e)}
          role={"dialog"}
          style={{ justifyContent: position }}
          onClick={(e: any) =>
            isOverlayClick && e.target?.className === "modal" && onClose(e)
          }
        >
          <div
            style={{
              display: "flex",
              position: "absolute",
              maxWidth: maxWidth,
              width: innerWidth,
              maxHeight: "90vh",
              animationName: "grow",
              animationDuration: "0.5s",
            }}
          >
            <div className="modal-inner" id={"modal-inner"} style={modalStyle}>
              {childrenComponent}
            </div>
            {closeButtonType && (
              <img
                src={Images.close}
                style={{
                  ...styles.close,
                  right: i18n.dir() === "ltr" ? "0" : "",
                  left: i18n.dir() === "rtl" ? "0" : "",
                }}
                tabIndex={!IsMobile() ? 0 : -1}
                aria-label={"כפתור סגירה"}
                onClick={onClose}
                role={"button"}
                width={`auto`}
                height={`auto`}
                alt={``}
              />
            )}
          </div>
          {_visibleYesOrNo && YesOrNoModal()}
        </div>
      </FocusTrap>
    ) : (
      <div
        ref={textInput}
        className="modal"
        aria-labelledby="modal-inner"
        aria-modal="true"
        tabIndex={!IsMobile() ? 0 : -1}
        onKeyUp={(e) => e.key === "Escape" && onClose(e)}
        role={"dialog"}
        style={{ justifyContent: position }}
        onClick={(e: any) =>
          isOverlayClick && e.target?.className === "modal" && onClose(e)
        }
      >
        <div
          style={{
            display: "flex",
            position: "absolute",
            maxWidth: maxWidth,
            width: innerWidth,
            maxHeight: "90vh",
            animationName: "grow",
            animationDuration: "0.5s",
          }}
        >
          <div className="modal-inner" id={"modal-inner"} style={modalStyle}>
            {childrenComponent}
          </div>
          {closeButtonType && (
            <img
              src={Images.close}
              style={{
                ...styles.close,
                right: i18n.dir() === "ltr" ? "0" : "",
                left: i18n.dir() === "rtl" ? "0" : "",
              }}
              tabIndex={!IsMobile() ? 0 : -1}
              aria-label={"כפתור סגירה "}
              onClick={onClose}
              role={"button"}
              width={`auto`}
              height={`auto`}
              alt={``}
            />
          )}
        </div>
        {_visibleYesOrNo && YesOrNoModal()}
      </div>
    )
  ) : (
    <></>
  );
};

const styles: StyleSheet = {
  close: {
    display: "flex",
    justifyContent: "end",
    position: "absolute",
    zIndex: "1",
    fontSize: fontSize.UmbracoElementTitle,
    lineHeight: "normal",
    padding: "15px",
    color: "#81d4fa",
    cursor: "pointer",
  },
};

export default Modal;
