import colors from "../../Assets/Colors/Colors.json";
import fonts from "../../Assets/Fonts/Fonts.json";
import Images from "../../Data/Images/Images";
import { IAnyElement, IStaticModal } from "../../Interfaces/IStaticPage";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import i18n from "../../Services/i18n";
import useDeviceMode from "../../Utils/useWindowDimensions";
import AllElements from "../UmbracoElements/AllElements";
import BaseElement from "../UmbracoElements/BaseElement";
import fontSize from "../../Assets/Fonts/FontsSizes.json";
import RichTextEditor from "../UmbracoElements/RichTextEditor";

const UpdateModalMobile: React.FC<IStaticModal> = (props) => {
  const {
    id,
    title,
    coverImage,
    elements,
    breadcrumb,
    onClose,
    isCard = false,
  } = props;
  const deviceMode = useDeviceMode();
  let isCardDisplay = isCard;

  const GetUmbracoElement: React.FC<IAnyElement & any> = (props) => {
    switch (props.contentTypeAlias) {
      case "baseElement":
        return (
          <BaseElement
            {...props}
            containerStyle={{
              maxWidth: "76rem",
              width: "80%",
              fontSize: fontSize.Paragraph,
              fontFamily: fonts.FbReformaRegular,
            }}
          />
        );
      case "titleAndDescription":
        return (
          <BaseElement
            titleAndDescription={props}
            isHeaderUnderline={true}
            headerStyle={
              {
                display: "flex",
                fontSize: fontSize.UmbracoElementTitle,
                fontFamily: fonts.FbReformaMedium,
                color: colors.darkBlue,
              } as StyleSheet
            }
            containerStyle={
              {
                maxWidth: "76rem",
                width: "80%",
                fontSize: fontSize.Paragraph,
              } as StyleSheet
            }
          />
        );
      case "link":
        return <BaseElement link={props.link} />;
      case "richTextEditor":
        return (
          <RichTextEditor
            {...props}
            customStyles={{
              width: "100%",
            }}
          />
        );
      default:
        return <AllElements elementName={props.contentTypeAlias} {...props} />;
    }
  };

  return (
    <div
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
      }}
      onClick={onClose}
    >
      <div
        style={styles.mainContainer}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <img
          src={Images.closeButtonMob}
          style={{
            top: "-2.5rem",
            position: "relative",
            left: i18n.dir() === "rtl" ? "" : "45%",
            right: i18n.dir() === "ltr" ? "" : "45%",
          }}
          onClick={() => onClose && onClose()}
        />
        <div
          style={{
            overflow: "auto",
          }}
        >
          <h2
            style={{
              color: colors.darkBlue,
              justifySelf: "center",
              alignSelf: "center",
              lineHeight: "2rem",
              padding: "0 2rem",
            }}
          >
            {title.text}
          </h2>
          {elements &&
            elements.map((element, index) => (
              <div
                key={index}
                style={{
                  // overflow: "auto",
                  padding: "0 2rem",
                }}
              >
                <GetUmbracoElement {...element} key={index} />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  mainContainer: {
    position: "absolute",
    alignItems: "center",
    flexDirection: "column",
    zIndex: "10",
    backgroundColor: colors.white,
    maxHeight: `90%`,
    height: "90%",
    width: `100%`,
    display: "flex",
    borderTopLeftRadius: "2rem",
    borderTopRightRadius: "2rem",
    boxSizing: "border-box",
    bottom: 0,
    paddingBottom: ".5rem",
  },
  cardBorder: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transform: "translateY(-7.8rem)",
  },
  cardBorderMobile: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  cardBody: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "3rem",
    padding: "2rem 0",
    backgroundColor: colors.white,
    borderRadius: "0.3125rem",
    boxShadow: "0 2px 10px 0 rgba(0, 0, 0, 0.15)",
    minWidth: "80%",
  },
  cardBodyMobile: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "3rem",
    padding: "2rem 0",
    width: "100%",
  },
  cover: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "40vh",
    minHeight: "17.18rem",
    position: "relative",
  },
  title: {
    color: colors.white,
    fontSize: fontSize.PageTitle,
    lineHeight: "3rem",
    letterSpacing: "0.05625rem",
    textShadow: `2px 2px ${colors.black}`,
    fontFamily: fonts.FbReformaMedium,
    paddingTop: "5rem",
  },
  breadcrumb: {
    display: "flex",
    color: colors.white,
    fontSize: fontSize.Text,
    letterSpacing: "0.05625rem",
    fontFamily: fonts.FbReformaRegular,
    position: "absolute",
    paddingTop: "2rem",
  },
  coverElements: {
    gap: "3rem",
  },
  content: {
    position: "relative",
    paddingTop: "5rem",
    right: "25%",
    width: "50%",
  },
  titlesAndDescriptionsItems: {
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
  },
  linkItems: {
    cursor: "pointer",
  },

  closeMainModalIcon: {
    position: "absolute",
    zIndex: "1000000000",
    cursor: "pointer",
    borderRadius: "50%",
    top: "-.5rem",
    left: "1rem",
  } as React.CSSProperties,
};

export default UpdateModalMobile;
