import colors from "../../../Assets/Colors/Colors.json";
import fonts from "../../../Assets/Fonts/Fonts.json";
import fontSize from "../../../Assets/Fonts/FontsSizes.json";
import { IContentTab } from "../../../Interfaces/IContentTab";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import { StyleMode } from "../../../Utils/consts";
import useDeviceMode from "../../../Utils/useWindowDimensions";

const ContentTab: React.FC<IContentTab> = (props) => {
  const { title, elements, isSelected, handleTabClick, index } = props;
  const deviceMode = useDeviceMode();
  return (
    <div
      tabIndex={isSelected ? 0 : -1}
      aria-checked={isSelected}
      role={"radio"}
      onClick={() => handleTabClick(index, elements)}
      onKeyPress={() => handleTabClick(index, elements)}
      style={{
        ...styles.tab,
        color: isSelected
          ? colors.white
          : deviceMode === StyleMode.desktop
          ? colors.nero
          : colors.trolleyGrey,
        fontFamily: !isSelected
          ? deviceMode === StyleMode.desktop
            ? fonts.FbReformaRegular
            : fonts.FbReformaMedium
          : fonts.FbReformaMedium,
      }}
    >
      {title}
    </div>
  );
};

const styles: StyleSheet = {
  tab: {
    width: "33%",
    cursor: "pointer",
    alignSelf: "center",
    whiteSpace: "nowrap",
  },
};

export default ContentTab;
