import { t } from "i18next";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import store from "store2";
import "swiper/css";
import "swiper/css/effect-fade";
import colors from "../../Assets/Colors/Colors.json";
import fonts from "../../Assets/Fonts/Fonts.json";
import fontSize from "../../Assets/Fonts/FontsSizes.json";
import Images from "../../Data/Images/Images";
import { IPopMessages } from "../../Interfaces/IPopMessages";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import i18n from "../../Services/i18n";
import { StyleMode } from "../../Utils/consts";
import useDeviceMode from "../../Utils/useWindowDimensions";
import PopMessageCard from "./PopMessageCard";

const PopMessageCards: React.FC<IPopMessages> = (props) => {
  const { messages, onAcceptReading } = props;
  const [_isCurrentMessageChecked, _setIsCurrentMessageChecked] =
    useState(false);
  const currentMessageCheckboxRef = useRef<HTMLInputElement>(null);

  const deviceMode = useDeviceMode();
  const HomeReducer = useSelector((state: any) => state.HomeReducer);

  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

  const handleNextMessage = (index: number) => {
    if (currentMessageIndex < messages.length - 1) {
      setCurrentMessageIndex(index);
    }
  };

  return (
    <div style={styles.modalOverlay}>
      <div
        style={{
          ...styles.modalCard,
          width: deviceMode === StyleMode.desktop ? "40%" : "90%",
          // minHeight: deviceMode === StyleMode.desktop ? "50%" : "60%",
        }}
      >
        <div style={styles.iconWrapper}>
          <div style={styles.iconContainer}>
            <img
              alt={"megaphone"}
              src={Images.megaphone}
              style={{
                transform: i18n.dir() === "ltr" ? "scaleX(-1)" : "",
              }}
            />
          </div>
        </div>
        <div
          style={{
            ...styles.modalContent,
          }}
        >
          <div style={styles.messages}>
            <div>
              <PopMessageCard
                {...messages[currentMessageIndex]}
                supportRichText={
                  HomeReducer.systemParamsKey["IsRtfActive"]
                    ? HomeReducer.systemParamsKey["IsRtfActive"].value ===
                      "true"
                    : false
                }
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              paddingTop: "2rem",
              alignItems: "center",
            }}
          >
            <div style={styles.dontAskAgain}>
              <input
                ref={currentMessageCheckboxRef}
                style={{
                  alignSelf: "start",
                }}
                type={"checkbox"}
                onChange={(e) => {
                  _setIsCurrentMessageChecked(e.target.checked);
                }}
              />
              <span
                style={{
                  fontFamily: fonts.FbReformaRegular,
                  // fontWeight: "bold",
                }}
              >
                {t("PopMessageReadCheckbox")}
              </span>
            </div>

            <button
              tabIndex={0}
              style={styles.finishButton}
              // role={"button"}
              aria-label={t(`Confirm`)}
              onClick={() => {
                const readedPopMessagesLocalStorage: string[] | null =
                  store.local.get("readedPopMessages");
                let readedPopMessages: string[] = [];
                if (_isCurrentMessageChecked) {
                  const currentMessageID =
                    messages[currentMessageIndex].id.toString();

                  if (readedPopMessagesLocalStorage !== null) {
                    if (
                      !readedPopMessagesLocalStorage.includes(currentMessageID)
                    ) {
                      readedPopMessages = [
                        ...readedPopMessagesLocalStorage,
                        currentMessageID,
                      ];
                    } else {
                      readedPopMessages = [...readedPopMessagesLocalStorage];
                    }
                  } else {
                    readedPopMessages = [currentMessageID];
                  }

                  store.local.set("readedPopMessages", readedPopMessages);
                } else {
                  readedPopMessages =
                    readedPopMessagesLocalStorage === null
                      ? []
                      : [...readedPopMessagesLocalStorage];
                }

                if (currentMessageIndex < messages.length - 1) {
                  handleNextMessage(currentMessageIndex + 1);
                  if (currentMessageCheckboxRef.current !== null) {
                    currentMessageCheckboxRef.current.checked = false;
                    _setIsCurrentMessageChecked(false);
                  }
                } else {
                  onAcceptReading && onAcceptReading();
                }
              }}
            >
              {t(`Confirm`)}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  modalOverlay: {
    position: "fixed",
    backgroundColor: colors.modal,
    width: "100%",
    height: "100%",
    top: "0",
    left: "0",
    zIndex: "99999",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  bellIcon: {
    transform: "scale(3)",
  },
  finishButton: {
    borderRadius: "3.75rem",
    backgroundColor: colors.darkBlue,
    border: "0.06rem solid",
    fontFamily: fonts.FbReformaMedium,
    fontSize: fontSize.UmbracoElementTitle,
    fontWeight: `${i18n.language === "ar" ? `600` : ``}`,
    textAlign: "center",
    minWidth: "8rem",
    minHeight: "3rem",
    color: colors.white,
    cursor: "pointer",
    flex: 1,
    marginTop: "5%",
    marginBottom: "3rem",
    padding: "0 1rem",
  },
  iconContainer: {
    width: "4.3rem",
    height: "4.3rem",
    boxShadow: "0 5px 11px 0 rgba(0, 0, 0, 0.21)",
    borderRadius: "50%",
    backgroundColor: "white",
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transform: "translateY(5px)",
  } as React.CSSProperties,
  modalCard: {
    position: "absolute",
    backgroundColor: colors.white,
    borderRadius: "13px 13px 288px 288px / 13px 13px 43px 43px",
    // minHeight: "70%",
    maxHeight: "80%",
  },
  modalContent: {
    paddingTop: "3rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // height: "24rem",
  },
  messages: {
    width: "100%",
    height: "100%",
    flex: 1,
  },
  iconWrapper: {
    display: "flex",
    justifyContent: "center",
    position: "relative",
    top: "-2.5rem",
    zIndex: "9999999",
  },
  dontAskAgain: {
    display: "flex",
    alignItems: "center",
  },
};

export default PopMessageCards;
