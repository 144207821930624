import QueryString from "qs";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import TendersMain from "../../../Auctions/src/Screens/Main";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import { IDynamicPart } from "../../../Interfaces/IUmbraco";
import { PageState } from "../../../Redux/Slices/pageSlice";
import Fines from "../../../Screens/Fines";
import GuideLinesAndRates from "../../../Screens/GuideLinesAndRates";
import LinesMap from "../../../Screens/LinesMap";
import RouteShared from "../../../Screens/RouteShared";
import { GetAccurecyIndex } from "../../../Services/CommonService";
import { StyleMode } from "../../../Utils/consts";
import useDeviceMode from "../../../Utils/useWindowDimensions";
import AccurecyIndex from "../../AccurecyIndex/AccurecyIndex";
import Fares from "../../PaymentAndDiscounts/Fares";
import Pis from "../../../Screens/Pis";

const DynamicPart: React.FC<IDynamicPart> = (props) => {
  const { id } = props;
  const [_dynamicData, _setDynamicData] = useState<any>();
  const [_isFullWidthDisplay, _setIsFullWidthDisplay] = useState(false);
  const [_isRemoveMailingListUrl, _setIsRemoveMailingListUrl] = useState(false);

  const deviceMode = useDeviceMode();
  const location = useLocation();
  const currentPage = (
    useSelector((state: any) => state.PageReducer) as PageState
  ).page;

  const isMounted = useRef(false);

  const getDynamicData = async () => {
    switch (id) {
      case "AccurecyIndex":
        const data = await GetAccurecyIndex();
        if (isMounted.current) {
          _setDynamicData(data);
        }
        break;
    }
  };

  const handleDisplayStyles = () => {
    switch (id) {
      case "linesmap":
        _setIsFullWidthDisplay(deviceMode === StyleMode.desktop);
        break;
      case "GeneralAuctions":
      case "CargoAuctions":
      case "pis":
      case "guidelinesAndRates":
        if (isMounted.current) {
          _setIsFullWidthDisplay(true);
        }
    }
  };
  const GetDynamicSpecialElement: React.FC<IDynamicPart> = (props) => {
    switch (id) {
      case "AccurecyIndex":
        return <AccurecyIndex dynamicPart={props} dynamicData={_dynamicData} />;
      case "Fares":
        return <Fares dynamicPart={props} dynamicData={_dynamicData} />;
      case "pis":
        return <Pis dynamicPart={props} dynamicData={_dynamicData} />;
      case "Fines":
        return <Fines {...props} />;
      case "linesmap":
        return <LinesMap dynamicPart={props} />;
      case "guidelinesAndRates": return <GuideLinesAndRates dynamicPart={props} />;

      case "GeneralAuctions":
      case "CargoAuctions":
        return <TendersMain />;
      case "mldp":
      case "mldpa":
        return <RouteShared dynamicPart={props} name={id} />;
      default:
        return null;
    }
  };

  const setRemoveEmailList = () => {
    const urlParamsMailingList = QueryString.parse(
      location.search.split("?")[1]
    );
    if (
      urlParamsMailingList.mailingListValue &&
      urlParamsMailingList.mailingListValue !== ""
    ) {
      _setIsRemoveMailingListUrl(true);
    }
  };

  useEffect(() => {
    isMounted.current = true;
    getDynamicData();
    handleDisplayStyles();
    setRemoveEmailList();
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <div
      style={{
        ...styles.container,
        width: !_isFullWidthDisplay
          ? "80%"
          : id === "CargoAuctions" ||
            id === "GeneralAuctions" ||
            id === "guidelinesAndRates" ||
            id === "pis"
          ? "100%"
          : "",
        maxWidth: !_isFullWidthDisplay ? "76rem" : "",
      }}
    >
      <GetDynamicSpecialElement {...props} />
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    width: "80%",
    maxWidth: "76rem",
    position: "relative",
  },
};

export default DynamicPart;
