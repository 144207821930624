import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import colors from "../../../Assets/Colors/Colors.json";
import fonts from "../../../Assets/Fonts/Fonts.json";
import fontSize from "../../../Assets/Fonts/FontsSizes.json";
import { IContactCard } from "../../../Interfaces/IContactCard";
import ContactItem from "../ContactItem/ContactItem";
import useDeviceMode from "../../../Utils/useWindowDimensions";
import { StyleMode } from "../../../Utils/consts";

const ContactCard: React.FC<IContactCard> = (props) => {
  const { title, subTitle, body, contactItems, anchor } = props;
  const deviceMode = useDeviceMode();
  return (
    <div
      anchor-id={anchor}
      style={{
        ...styles.container,
        width: deviceMode !== StyleMode.desktop ? "85%" : "100%",
      }}
    >
      <div style={styles.topContainer}>
        <div style={styles.topTitles}>
          <h1 style={styles.title}>{title}</h1>
          <h2 style={styles.subTitle}>{subTitle}</h2>
        </div>
        <div
          style={styles.body}
          dangerouslySetInnerHTML={{ __html: body }}
        ></div>
      </div>
      <div
        style={{
          ...styles.bottomContainer,
          flexDirection:
            deviceMode === StyleMode.desktop ? "row" : "column-reverse",
          padding: deviceMode !== StyleMode.desktop ? "1.8rem" : "",
        }}
      >
        {contactItems.map((contactItem, index) => (
          <ContactItem {...contactItem} key={index} />
        ))}
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "50.875rem",
    minHeight: "26.25rem",
    backgroundColor: colors.white,
    boxShadow:
      "rgba(0, 0, 0, 0.2) 0px 0px 1px 0px, rgba(0, 0, 0, 0.19) 0px 0px 15px 0px",
    borderRadius: "10px",
  },
  topContainer: {
    flex: 3,
    padding: "3rem 2.8rem",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  topTitles: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    color: colors.darkBlue,
  },
  title: {
    fontFamily: fonts.FbReformaMedium,
    fontSize: fontSize.PageTitle,
    letterSpacing: "2px",
    lineHeight: "3rem",
    marginBlockStart: 0,
    marginBlockEnd: 0,
  },
  subTitle: {
    fontFamily: fonts.FbReformaLight,
    fontSize: fontSize.Paragraph,
    letterSpacing: "2px",
    lineHeight: "1.5rem",
    marginBlockStart: 0,
    marginBlockEnd: 0,
    fontWeight: "400",
    paddingBottom: "1rem",
  },
  body: {
    color: colors.black,
  },
  bottomContainer: {
    display: "flex",
    backgroundColor: colors.ghostWhite,
    minHeight: "7rem",
    justifyContent: "space-evenly",
  },
};

export default ContactCard;
