import colors from "../../../Assets/Colors/Colors.json";
import fonts from "../../../Assets/Fonts/Fonts.json";
import { ISectionTab } from "../../../Interfaces/ISectionTab";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import { StyleMode } from "../../../Utils/consts";
import useDeviceMode from "../../../Utils/useWindowDimensions";

const SectionTab: React.FC<ISectionTab> = (props) => {
  const { title, elements, isSelected, handleTabClick, index, anchor } = props;
  const deviceMode = useDeviceMode();
  return (
    <div
      anchor-id={anchor}
      tabIndex={isSelected ? 0 : -1}
      aria-checked={isSelected}
      role={"radio"}
      onClick={() => handleTabClick(index, elements)}
      onKeyPress={() => handleTabClick(index, elements)}
      style={{
        ...styles.tab,
        color: isSelected
          ? colors.darkBlue
          : deviceMode === StyleMode.desktop
          ? colors.nero
          : colors.nero,
        fontFamily: !isSelected
          ? deviceMode === StyleMode.desktop
            ? fonts.FbReformaRegular
            : fonts.FbReformaMedium
          : fonts.FbReformaMedium,
      }}
    >
      {title}
    </div>
  );
};

const styles: StyleSheet = {
  tab: {
    width: "33%",
    cursor: "pointer",
    alignSelf: "center",
    whiteSpace: "nowrap",
  },
};

export default SectionTab;
