import { IArticleWithMedia } from "../../../../Interfaces/IMagazinePage";
import { StyleSheet } from "../../../../Interfaces/IStyleSheet";
import ArticleImage from "./ArticleImage";
import ArticleVideo from "./ArticleVideo";
import colors from ".././../../../Assets/Colors/Colors.json";
import fonts from ".././../../../Assets/Fonts/Fonts.json";
import fontSize from ".././../../../Assets/Fonts/FontsSizes.json";
import moment from "moment";
import Images from "../../../../Data/Images/Images";
import { useRef, useState } from "react";
import ArticleMediaTitleDescription from "./ArticleMediaTitleDescription";
import useDeviceMode from "../../../../Utils/useWindowDimensions";
import { StyleMode } from "../../../../Utils/consts";
import ArticleMediaTitleDescriptionMobile from "./ArticleMediaTitleDescriptionMobile";

const ArticleMedia: React.FC<
  IArticleWithMedia & {
    isBottomContainerVisible: boolean;
    containerStyles?: React.CSSProperties;
    imageStyles?: React.CSSProperties;
    videoStyles?: React.CSSProperties;
  }
> = (props) => {
  const {
    tags,
    author,
    creationDate,
    readingTime,
    titleAndDescription,
    media,
    isBottomContainerVisible,
    videoThumbnail,
    containerStyles,
    imageStyles,
    videoStyles,
    redirectTo
  } = props;

  const videoRef = useRef<HTMLVideoElement>(null);
  const [_isVideoPaused, _setIsVideoPaused] = useState(true);
  const [_isIconVisible, _setIsIconVisible] = useState(true);
  const deviceMode = useDeviceMode();

  const checkIsVideo = () => {
    switch (media.umbracoExtension) {
      case "mp4":
      case "webm":
        return true;
    }

    return false;
  };

  const handlePlayPauseClick = async () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        if (_isVideoPaused) await videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
      _setIsVideoPaused(!_isVideoPaused);
      if (deviceMode !== StyleMode.desktop) _setIsIconVisible(false);
    }
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div
        style={{
          ...styles.container,
          borderRadius: deviceMode === StyleMode.desktop ? "5px" : "",
          ...containerStyles,
        }}
        onMouseOver={() =>
          deviceMode === StyleMode.desktop && _setIsIconVisible(true)
        }
        onMouseLeave={() =>
          deviceMode === StyleMode.desktop &&
          videoRef.current &&
          !videoRef.current.paused &&
          _setIsIconVisible(false)
        }
      >
        {checkIsVideo() ? (
          <ArticleVideo {...props} videoRef={videoRef} styles={videoStyles} />
        ) : (
          <ArticleImage {...props} styles={imageStyles} />
        )}
        {videoThumbnail && checkIsVideo() && _isVideoPaused && (
          <img
            src={videoThumbnail._url}
            style={{
              ...styles.thumbnail,
              borderRadius: deviceMode === StyleMode.desktop ? "5px" : "",
            }}
          />
        )}
        {_isIconVisible && (
          <img
            src={
              checkIsVideo()
                ? _isVideoPaused
                  ? Images.play
                  : Images.pause
                : Images.photoIcon
            }
            style={{
              ...styles.playPauseButton,
              cursor: checkIsVideo() ? "pointer" : "unset",
            }}
            width={deviceMode === StyleMode.desktop ? "40" : "30"}
            onClick={handlePlayPauseClick}
          />
        )}
        {deviceMode === StyleMode.desktop &&
          isBottomContainerVisible &&
          checkIsVideo() &&
          _isVideoPaused && (
            <ArticleMediaTitleDescription
              titleAndDescription={titleAndDescription}
              author={author}
              creationDate={creationDate}
              readingTime={readingTime}
              tags={tags}
              redirectTo={redirectTo}
            />
          )}
      </div>{" "}
      {deviceMode !== StyleMode.desktop && isBottomContainerVisible && (
        <ArticleMediaTitleDescriptionMobile
          titleAndDescription={titleAndDescription}
          author={author}
          creationDate={creationDate}
          readingTime={readingTime}
          tags={tags}
          redirectTo={redirectTo}
        />
      )}
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    position: "relative",
    width: "100%",
    // height: "100%",
  },
  playPauseButton: {
    position: "absolute",
    top: "1rem",
    right: "1rem",
    cursor: "pointer",
    zIndex: 1000000
  },
  bottomContainer: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    width: "100%",
    minHeight: "6rem",
    bottom: 0,
    left: 0,
    boxSizing: "border-box",
    padding: "1.25rem",
    gap: ".5rem",
  },
  bottomTitle: {
    fontSize: fontSize.SubTitle,
    fontFamily: fonts.FbReformaMedium,
    color: colors.darkBlue,
  },
  bottomText: {
    fontSize: fontSize.Text,
    fontFamily: fonts.FbReformaLight,
    color: colors.nero,
  },
  thumbnail: {
    position: "absolute",
    width: "100%",
    height: "100%",
    left: 0,
    bottom: 0,
    objectFit: "cover",
    zIndex: 100000
  },
  tags: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    bottom: "6.5rem",
    right: "1.5rem",
  },
};

export default ArticleMedia;
