import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactModal from "react-modal";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import colors from "../Assets/Colors/Colors.json";
import fontSize from "../Assets/Fonts/FontsSizes.json";
import LastTravelsMain from "../Components/LastTravels/LastTravelsMain";
import AppInfo from "../Components/UmbracoElements/AppInfo";
import GalleryWhatIsNew from "../Components/UmbracoElements/Gallery/WhatIsNew/GalleryWhatIsNew";
import HomeTopContainer from "../Components/UmbracoElements/HomeTopContainer/HomeTopContainer";
import InfoCards from "../Components/UmbracoElements/InfoCards";
import Images from "../Data/Images/Images";
import { StyleSheet } from "../Interfaces/IStyleSheet";
import { HomeState } from "../Redux/Slices/homeSlice";
import { PageState } from "../Redux/Slices/pageSlice";
import { UmbracoStoreState } from "../Redux/Slices/umbracoSlice";
import { IsMobile } from "../Services/DeviceService";
import i18n from "../Services/i18n";
import {
  ChangeTitle,
  StyleMode,
  titleId,
  TitlePage,
  UpdateSeo,
} from "../Utils/consts";
import useDeviceModeOSType, { GetSystemOS } from "../Utils/useDeviceOS";
import useDeviceMode from "../Utils/useWindowDimensions";
import { UseWindowScrollPositions } from "../Utils/useWindowScrollPositions";
import { GetSystemParams } from "../Services/GlobalService";
import { IPopMessageBackend } from "../Interfaces/IPopMessages";
import { FormatDateYYYYMMDD, IsDateBetween } from "../Services/DateService";
import store from "store2";
import { languagesDictionary } from "../Utils/utils";
import { GetPopUpMessages } from "../Services/CommonService";
import { SystemParamsState } from "../Redux/Slices/systemParamsSlice";
import PopMessageCards from "../Components/PopMessage/PopMessageCards";
import moment from "moment";

const Home: React.FC = () => {
  const [_currentItemIndex, _setCurrentItemIndex] = useState(0);
  const [_isArrowAnimation, _setIsArrowAnimation] = useState(true);
  const umbracoStore = (
    useSelector((state: any) => state.UmbracoStoreReducer) as UmbracoStoreState
  ).umbracoStore;
  const [_currentLinks, _setCurrentLinks] = useState(
    umbracoStore.homeContent?.appInfo.links
  );
  const localVersion = localStorage.getItem("websiteVersion");
  const curWebsiteVersion = (
    useSelector((state: any) => state.HomeReducer) as HomeState
  ).websiteVersion;
  const [_whatIsNewModal, _setWhatIsNewModal] = useState(true);

  const [_popMessages, _setPopMessages] = useState<IPopMessageBackend[]>([]);
  const [_isPopMessagesMainPageVisible, _setIsPopMessagesMainPageVisible] =
    useState(false);
  const [_isReadRoutePlanPopMessages, _setIsReadRoutePlanPopMessages] =
    useState(false);

  const systemParams = (
    useSelector((state: any) => state.SystemParamsReducer) as SystemParamsState
  ).systemParams;

  const systemParamsDictionary = systemParams.reduce(
    (result: { [key: string]: string }, current) => {
      result[current.key] = current.value;
      return result;
    },
    {}
  );

  const getIsCurrentDateBetweenDates = (startDate: string, endDate: string) => {
    const currentDate = moment();
    return currentDate.isBetween(
      moment(startDate),
      moment(endDate),
      undefined,
      "[]"
    );
  };

  const infoCardsRef = useRef<HTMLDivElement | null>(null);
  const appInfoRef = useRef<HTMLDivElement | null>(null);
  const searchBoxRef = useRef<HTMLDivElement | null>(null);
  const titleRef = useRef<HTMLDivElement>(null);

  const getRelevantPopupMessagesFiltered = (
    popupMessages: IPopMessageBackend[]
  ) => {
    const relevantsPopups = popupMessages.filter((message) =>
      getIsCurrentDateBetweenDates(message.startDate, message.endDate)
    );

    return relevantsPopups;
  };

  const currentPage = (
    useSelector((state: any) => state.PageReducer) as PageState
  ).page;
  const location = useLocation();

  let deviceMode = useDeviceMode();
  let deviceModeOsType = useDeviceModeOSType();
  const { scrollY } = UseWindowScrollPositions();

  const { t } = useTranslation();
  useEffect(() => {
    ChangeTitle(t(`${TitlePage.HomePage}`));
    GetSystemOS();
    const homeSeo = umbracoStore.homeContent;
    UpdateSeo(
      homeSeo?.seoTitle,
      homeSeo?.seo,
      homeSeo?.seoSocialNetworksTitle,
      homeSeo?.seoSocialNetworksDescription,
      homeSeo?.seoSocialNetworksImage,
      false
    );
    let new_title = document.getElementById(titleId);
    new_title?.focus();
  }, []);

  useEffect(() => {
    let isMounted = true;
    (async () => {
      const systemParams = await GetSystemParams();
      if (systemParams.length > 0) {
        const dispalywhatNew = systemParams.find(
          (systemParam) => systemParam.key === "dispalywhatNew"
        );

        if (isMounted) {
          _setWhatIsNewModal(
            dispalywhatNew !== undefined && dispalywhatNew.value === "true"
          );
        }
      }
    })();
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (
      currentPage.toLowerCase() === "" ||
      currentPage.toLowerCase() === "homepage"
    ) {
      (async () => {
        let popMessages: IPopMessageBackend[] = await GetPopUpMessages(
          "MainPage",
          languagesDictionary[i18n.language]
        );
        if (popMessages && popMessages?.length > 0) {
          const readedPopMessagesLocalStorage: string[] | null =
            store.local.get("readedPopMessages");
          let readedPopMessagesIDS: string[] = [];
          if (readedPopMessagesLocalStorage !== null) {
            readedPopMessagesIDS = [...readedPopMessagesLocalStorage];
          }
          _setPopMessages([
            ...getRelevantPopupMessagesFiltered(
              [
                ...popMessages.filter(
                  (message) =>
                    message.title !== "" && message.messageBody !== ""
                ),
              ].filter((message) => {
                const isMessageRead =
                  readedPopMessagesIDS.find(
                    (readedId) => readedId === message.id.toString()
                  ) !== undefined;
                return !isMessageRead;
              })
            ),
          ]);
        }
      })();
    }
  }, [currentPage]);

  useEffect(() => {
    if (titleRef.current) {
      titleRef.current.tabIndex = 0;
      titleRef.current.focus();
      titleRef.current.style.outline = "none";
      titleRef.current.tabIndex = -1;
    }
  }, []);

  useEffect(() => {
    if (deviceModeOsType === "IOS") {
      _setCurrentLinks(
        umbracoStore.homeContent?.appInfo.links.filter(
          (os) => os.image.name === "App Store"
        )
      );
    } else if (deviceModeOsType === "Android") {
      _setCurrentLinks(
        umbracoStore.homeContent?.appInfo.links.filter(
          (os) => os.image.name === "Google Play"
        )
      );
    } else {
      _setCurrentLinks(umbracoStore.homeContent?.appInfo.links);
    }
  }, [deviceModeOsType, deviceMode]);

  const handlePageScrolls = () => {
    if (infoCardsRef.current) {
      if (
        infoCardsRef.current.getBoundingClientRect().bottom > scrollY ||
        scrollY === 0
      ) {
        handleScrollToInfoCards();
      }
      if (appInfoRef.current) {
        if (
          appInfoRef.current.getBoundingClientRect().bottom +
            appInfoRef.current.getBoundingClientRect().height >
            scrollY &&
          scrollY >
            infoCardsRef.current.getBoundingClientRect().bottom -
              infoCardsRef.current.getBoundingClientRect().height
        ) {
          handleScrollToAppInfo();
        }
        if (
          appInfoRef.current.getBoundingClientRect().top +
            appInfoRef.current.getBoundingClientRect().height <
          scrollY
        ) {
          handleScrollToTop();
        }
      }
    }
  };

  const handleScrollToInfoCards = () => {
    if (infoCardsRef.current) {
      infoCardsRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  const handleScrollToAppInfo = () => {
    if (appInfoRef.current) {
      appInfoRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  const handleScrollToTop = () => {
    if (searchBoxRef.current) {
      const mainScroll = document.getElementById("mainScroll")!;
      mainScroll.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const closeWhatIsNewModalHandler = () => {
    _setWhatIsNewModal(false);
    localStorage.setItem("websiteVersion", curWebsiteVersion);
    window.location.reload();
  };

  return (
    <>
      <div
        ref={titleRef}
        style={{ position: "absolute", zIndex: -1, color: "transparent" }}
        tabIndex={!IsMobile() ? 0 : -1}
      >
        {t("CurrentPage", {
          page: t("Homepage"),
        })}
      </div>
      <div>
        {deviceMode === StyleMode.desktop && (
          <img
            src={
              appInfoRef.current &&
              appInfoRef.current.getBoundingClientRect().top +
                appInfoRef.current.getBoundingClientRect().height <
                scrollY
                ? Images.galleryArrowUp
                : Images.galleryArrowDown
            }
            style={{
              ...styles.arrowIcon,
              left: i18n.dir() === "rtl" ? "5%" : "",
              right: i18n.dir() === "ltr" ? "5%" : "",
              position: "fixed",
              animationName: _isArrowAnimation ? "moveUpDown" : "",
            }}
            role={"button"}
            onClick={handlePageScrolls}
            width={`auto`}
            height={`auto`}
            alt={``}
          />
        )}
      </div>
      <div
        ref={searchBoxRef}
        style={{
          ...styles.searchBoxWrapper,
        }}
      >
        {umbracoStore.homeContent && (
          <HomeTopContainer
            updates={umbracoStore.homeContent.updates}
            textAlign={umbracoStore.homeContent.textAlign}
            homeContent={umbracoStore.homeContent}
          />
        )}
        <div style={styles.lastTravels}>
          <LastTravelsMain
            myTravelsText={umbracoStore.homeContent?.myTravelsText}
            myTravelsResetText={umbracoStore.homeContent?.myTravelsResetText}
            noTravelsImage={umbracoStore.homeContent?.noTravelsImage}
            noTravelsText={umbracoStore.homeContent?.noTravelsText}
            noTravelsOptionalText={
              umbracoStore.homeContent?.noTravelsOptionalText
            }
            hideTravelsHistory={umbracoStore.homeContent?.hideTravelsHistory}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          position: "relative",
          flexDirection: "column",
          gap: "10rem",
          paddingBottom: "10rem",
          transform: "translateY(-2rem)",
        }}
      >
        {umbracoStore.homeContent?.infoCards && (
          <div ref={infoCardsRef}>
            <InfoCards infoCards={umbracoStore.homeContent.infoCards} />
          </div>
        )}
        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {umbracoStore.homeContent?.appInfo && _currentLinks && (
            <div
              style={{
                width: "80%",
              }}
              ref={appInfoRef}
            >
              <AppInfo
                baseElement={umbracoStore.homeContent?.appInfo.baseElement}
                links={_currentLinks}
                sideImage={umbracoStore.homeContent?.appInfo.sideImage}
                subTitle={umbracoStore.homeContent.appInfo.subTitle}
              />
            </div>
          )}
        </div>
      </div>

      {_whatIsNewModal &&
        (localVersion === "null" ||
          localVersion === null ||
          (localVersion &&
            parseFloat(localVersion) < parseFloat(curWebsiteVersion))) &&
        umbracoStore.homeContent?.gallery && (
          <GalleryWhatIsNew
            galleryType={umbracoStore.homeContent.gallery.galleryType}
            gallery={umbracoStore.homeContent.gallery.gallery}
            contentTypeAlias={umbracoStore.homeContent.gallery.contentTypeAlias}
            replaceTimeInSecond={
              umbracoStore.homeContent.gallery.replaceTimeInSecond
            }
            maxItemsInRow={umbracoStore.homeContent.gallery.maxItemsInRow}
            onClick={closeWhatIsNewModalHandler}
          />
        )}

      {_popMessages &&
        systemParamsDictionary["display_pop_messages"] &&
        systemParamsDictionary["display_pop_messages"] === "true" &&
        _popMessages.length > 0 &&
        !_isPopMessagesMainPageVisible && (
          <PopMessageCards
            messages={_popMessages}
            onAcceptReading={() => {
              _setIsPopMessagesMainPageVisible(true);
            }}
          />
        )}
    </>
  );
};

const styles: StyleSheet = {
  searchBoxWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    zIndex: "10",
  },
  cover: {
    backgroundSize: "cover",
    width: "100%",
    position: "relative",
    zIndex: "1",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    minHeight: "14.5rem",
  },
  title: {
    fontSize: fontSize.H1,
    color: colors.white,
    position: "absolute",
    zIndex: "1",
    textShadow: "0 2px 30px rgba(0, 0, 0, 0.7)",
    bottom: "4rem",
  },
  lastTravels: {
    width: "80%",
    maxWidth: "44rem",
    paddingBottom: "5rem",
  },
  arrowIcon: {
    top: "60%",
    zIndex: "99999",
    cursor: "pointer",
    animationIterationCount: "6",
    animationDirection: "alternate",
    animationDuration: ".7s",
  },
};
export default Home;
