import { ICookiesModal } from "../../Interfaces/ICookiesModal";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import colors from "../../Assets/Colors/Colors.json";
import fonts from "../../Assets/Fonts/Fonts.json";
import Images from "../../Data/Images/Images";
import i18n from "../../Services/i18n";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import ReactModal from "react-modal";
import fontSize from "../../Assets/Fonts/FontsSizes.json";
import { IsMobile } from "../../Services/DeviceService";

const CookiesModal: React.FC<ICookiesModal> = (props) => {
  const { onClick, cookies, onOpenSettings } = props;
  const navigate = useNavigate();
  const cookiesModalRef = useRef<HTMLImageElement>(null);
  const [_isModalOpen, _setIsModalOpen] = useState(true);
  const redirectToPage = (page?: string) => {
    onClick("Skip");
    page && navigate(`?page=${page}`);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      cookiesModalRef.current?.focus();
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div style={styles.container} onClick={() => onClick("Skip")}>
      <div
        style={styles.modal}
        role="dialog"
        aria-modal="true"
        id="cookiesModalId"
        onClick={(e) => e.stopPropagation()}
      >
        <div style={{ display: "flex", gap: "2rem" }}>
          <img
            src={Images.cookie}
            alt={``}
            style={{ maxHeight: `100%`, maxWidth: `100%` }}
          />
          <div
            style={styles.information}
            // role={"textbox"}
            tabIndex={!IsMobile() ? 0 : -1}
            ref={cookiesModalRef}
          >
            <div>
              {cookies?.firstText}
              <span
                style={styles.link}
                role={"link"}
                tabIndex={!IsMobile() ? 0 : -1}
                onClick={() => redirectToPage(cookies?.firstLink?.redirectTo)}
              >
                {" "}
                {cookies?.firstLink?.text}
              </span>
            </div>
            {cookies?.secondText && (
              <div>
                {cookies?.secondText}
                {cookies?.secondLink?.text && (
                  <span
                    style={styles.link}
                    role={"link"}
                    tabIndex={!IsMobile() ? 0 : -1}
                    onClick={() => {
                      onOpenSettings && onOpenSettings();
                    }}
                  >
                    {" "}
                    {cookies?.secondLink?.text}
                  </span>
                )}
              </div>
            )}
          </div>
        </div>
        <div style={{ display: "flex", gap: "2rem" }}>
          <button
            style={styles.acceptButton}
            onClick={() => onClick("Accept")}
            onKeyPressCapture={() => onClick("Accept")}
          >
            {cookies?.agreeBtn}
          </button>
          <button
            style={styles.refuseButton}
            onClick={() => onClick("Refuse")}
            onKeyPressCapture={() => {
              onClick("Refuse");
            }}
            onKeyDown={(e) => {
              if (e.key === "Tab") {
                if (cookiesModalRef.current) {
                  e.stopPropagation();
                  e.preventDefault();
                  cookiesModalRef.current.focus();
                }
              }
            }}
          >
            {cookies?.refuseBtn}
          </button>
        </div>
        {/* <img
          role={"button"}
          aria-label={t("Close")}
          alt={``}
          tabIndex={!IsMobile() ? 0 : -1}
          src={Images.closeButton}
          style={{
            ...styles.closeButton,
            left: i18n.dir() === "rtl" ? 0 : "",
            right: i18n.dir() === "ltr" ? 0 : "",
          }}
          onClick={() => onClick("Skip")}
          onKeyPressCapture={() => onClick("Skip")}
          onKeyDown={(e) => {
            if (e.key === "Tab") {
              if (cookiesModalRef.current) {
                e.stopPropagation();
                e.preventDefault();
                cookiesModalRef.current.focus();
              }
            }
          }}
        /> */}
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    width: "100%",
    height: "100%",
    position: "fixed",
    left: "0",
    top: "0",
    backgroundColor: colors.modal,
    zIndex: "999999",
  },
  modal: {
    position: "absolute",
    minHeight: "26rem",
    width: "100%",
    bottom: "-1rem",
    backgroundColor: colors.white,
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  information: {
    fontFamily: fonts.FbReformaRegular,
    fontSize: `${i18n.language === "ru" ? fontSize.Text : fontSize.Paragraph}`,
    lineHeight: "1.75rem",
    letterSpacing: "0.03875rem",
    maxWidth: "60.5rem",
    color: colors.darkBlue,
    display: "flex",
    flexDirection: "column",
    outline: "none",
  },
  acceptButton: {
    borderRadius: "2rem",
    backgroundColor: colors.darkBlue,
    border: ".2rem solid white",
    color: colors.white,
    fontSize: fontSize.Paragraph,
    width: "14rem",
    height: "3.2rem",
    cursor: "pointer",
    fontFamily: fonts.FbReformaMedium,
  },
  refuseButton: {
    borderRadius: "2rem",
    backgroundColor: colors.white,
    border: `.1rem solid ${colors.darkBlue}`,
    color: colors.darkBlue,
    fontSize: fontSize.Paragraph,
    width: "14rem",
    height: "3rem",
    cursor: "pointer",
    fontFamily: fonts.FbReformaMedium,
  },
  closeButton: {
    position: "absolute",
    top: 0,
    cursor: "pointer",
  },
  link: {
    color: colors.oldLavender,
    cursor: "pointer",
    textDecoration: "underline",
  },
};

export default CookiesModal;
