import { useState, useRef, useEffect } from "react";
import {
  IGallery,
  IGalleryYoutube,
} from "../../../../../../Interfaces/IGallery";
import { StyleSheet } from "../../../../../../Interfaces/IStyleSheet";
import { IVideo } from "../../../../../../Interfaces/IVideo";
import GalleryImage from "../../../GalleryItems/GalleryImage";
import GalleryVideo from "../../../GalleryItems/GalleryVideo";
import colors from "../../../../../../Assets/Colors/Colors.json";
import fonts from "../../../../../../Assets/Fonts/Fonts.json";
import Images from "../../../../../../Data/Images/Images";
import GalleryYoutube from "../../../GalleryItems/GalleryYoutube";
import useDeviceMode from "../../../../../../Utils/useWindowDimensions";
import { StyleMode } from "../../../../../../Utils/consts";
import { Swiper as ReactSwiper, SwiperSlide } from "swiper/react";
import Swiper, { Navigation, Pagination, Autoplay } from "swiper";
import { GetLongTextWithDots } from "../../../../../../Utils/utils";
import { t } from "i18next";
import fontSize from "../../../../../../Assets/Fonts/FontsSizes.json";
import { IsMobile } from "../../../../../../Services/DeviceService";
import i18n from "../../../../../../Services/i18n";

const DefaultGalleryMobile: React.FC<IGallery> = (props) => {
  const [_currentItemIndex, _setCurrentItemIndex] = useState(0);
  const [_gallery, _setGallery] = useState(props.gallery);
  const { replaceTimeInSecond } = props;
  const [_isHeaderVisible, _setIsHeaderVisible] = useState(true);
  const [_swiper, _setSwiper] = useState<Swiper>();
  const [_isPaused, _setIsPaused] = useState(false);
  const galleryContainerRef = useRef<HTMLDivElement>(null);

  const deviceMode = useDeviceMode();

  useEffect(() => {
    const bullets = galleryContainerRef.current?.getElementsByClassName(
      "swiper-pagination-bullet"
    );
    if (bullets) {
      Array.from(bullets).forEach((bullet, index) => {
        bullet.setAttribute("tabIndex", "0");
        bullet.setAttribute(
          "aria-label",
          t("GalleryItemNumberOutOfNumber", {
            currentIndex: index + 1,
            total: bullets.length,
          })
        );
      });
    }
  }, []);

  const handleHeaderText = (text: string) => {
    if (deviceMode !== StyleMode.desktop) {
      if (text.length < 50) {
        return text;
      } else {
        return GetLongTextWithDots(text, 158);
      }
    }
  };

  const handlePlayPause = (isPaused: boolean) => {
    if (!isPaused) {
      _swiper && _swiper.autoplay.stop();
    } else {
      _swiper && _swiper.autoplay.start();
    }

    _setIsPaused(!isPaused);
  };

  return (
    <div
      style={styles.galleryContainer}
      id={"defaultGalleryMobile"}
      ref={galleryContainerRef}
    >
      <ReactSwiper
        onSwiper={(swiper) => _setSwiper(swiper)}
        className={"mySwiper"}
        slideToClickedSlide={true}
        role={"region"}
        slidesPerView={1}
        navigation={true}
        modules={[Pagination, Navigation, Autoplay]}
        pagination={{ clickable: true }}
        loop={true}
        autoplay={
          replaceTimeInSecond !== 0
            ? {
                delay: replaceTimeInSecond * 1000,
                disableOnInteraction: false,
              }
            : false
        }
      >
        {_gallery.map((gallery, index) => (
          <SwiperSlide key={index}>
            {gallery.contentTypeAlias === "imageGalleryItem" && (
              <GalleryImage
                {...gallery}
                imageStyles={{
                  backgroundRepeat: "no-repeat",
                  height: "28rem",
                  width: "100%",
                  objectFit: "contain",
                }}
              />
            )}
            {gallery.contentTypeAlias === "videoGalleryItem" && (
              <GalleryVideo
                media={gallery.media as IVideo}
                header={gallery.header}
                videoStyles={{
                  backgroundRepeat: "no-repeat",
                  height: "28rem",
                  width: "100%",
                }}
                thumbnail={gallery.thumbnail}
                isPlayButtonEnabled={true}
                onClick={() => {
                  _setIsHeaderVisible(false);
                  handlePlayPause(false);
                }}
              />
            )}
            {gallery.contentTypeAlias === "youtubeGalleryItem" && (
              <GalleryYoutube {...(gallery as IGalleryYoutube)} />
            )}
            {_isHeaderVisible &&
              gallery.contentTypeAlias !== "youtubeGalleryItem" && (
                <div
                  style={
                    gallery.contentTypeAlias === "imageGalleryItem"
                      ? {
                          ...styles.headerContainer,
                          bottom: "0",
                          justifyContent: "center",
                        }
                      : {
                          ...styles.headerContainer,
                          bottom: "0",
                          justifyContent: "center",
                        }
                  }
                >
                  <div
                    style={{
                      ...styles.header,
                      right: "",
                    }}
                  >
                    {handleHeaderText(gallery.header.text)}
                  </div>
                </div>
              )}
          </SwiperSlide>
        ))}
      </ReactSwiper>
      {replaceTimeInSecond !== 0 && (
        <div
          style={{
            position: "absolute",
            left: "1rem",
            top: "1rem",
            zIndex: 99999,
            backgroundColor: colors.white,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "50%",
            padding: ".5rem",
            width: "2rem",
            height: "2rem",
            scale: ".8",
            cursor: "pointer",
          }}
          tabIndex={!IsMobile() ? 0 : -1}
          role={"button"}
          aria-label={t(_isPaused ? "PlayGallery" : "PauseGallery")}
          onClick={() => handlePlayPause(_isPaused)}
          onKeyPress={() => handlePlayPause(_isPaused)}
          aria-pressed={_isPaused}
        >
          <img
            src={!_isPaused ? Images.audioPauseIcon : Images.audioPlay}
            style={{
              scale: _isPaused ? ".7" : "",
              width: "100%",
              height: "100%",
            }}
            alt={``}
          />
        </div>
      )}
    </div>
  );
};

const styles: StyleSheet = {
  galleryContainer: {
    position: "relative",
    width: "100%",
    height: "100%",
    cursor: "pointer",
  },
  currentDisplayed: {
    position: "relative",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: "85.5rem",
    height: "48rem",
  },
  arrows: {
    display: "flex",
    justifyContent: "space-between",
  },
  headerContainer: {
    position: "absolute",
    backgroundColor: colors.whiteTransparent,
    width: "100%",
    minHeight: "5rem",
    maxHeight: "8rem",
    display: "flex",
  },
  header: {
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaMedium,
    fontSize: fontSize.UmbracoElementTitle,
    position: "absolute",
    top: "20%",
    width: "80%",
  },
  galleryBullets: {
    display: "flex",
    gap: "1rem",
  },
  galleryItemBullet: {
    width: ".75rem",
    height: ".75rem",
    border: `.1rem solid ${colors.darkBlue}`,
    borderRadius: "0.75rem",
    cursor: "pointer",
  },
};

export default DefaultGalleryMobile;
