import AuctionDetailsModal from "../Components/UmbracoElements/Auctions/AuctionDetailsModal/AuctionDetailsModal";
import { IAuctionPage } from "../Interfaces_tenders/IAuctionsSystem";
import { StyleSheet } from "../Interfaces_tenders/IStyleSheet";
import colors from "../../../Assets/Colors/Colors.json";

import { IAuctionsSystemAuctions } from "../Interfaces_tenders/IAuctionsSystemDetails";
import fonts from "../../../Assets/Fonts/Fonts.json";

const AuctionPage: React.FC<IAuctionPage> = (props) => {
  const { auctionsSystemServer,auctionId } = props;

  const currentAuction = auctionsSystemServer.find(
    (auction) => auction.id.toString() === auctionId
  );

  return (
    <div style={styles.mainContainer}>
      {currentAuction && (
        <AuctionDetailsModal {...(currentAuction as IAuctionsSystemAuctions)} />
      )}
    </div>
  );
};

const styles: StyleSheet = {
  mainContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    zIndex: "10",
    boxSizing: "border-box",
    padding:"1rem"
  },
  cover: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "40vh",
    position: "relative",
  },
  title: {
    color: colors.white,
    fontSize: "2.8125rem",
    lineHeight: "3rem",
    letterSpacing: "0.05625rem",
    textShadow: `2px 2px ${colors.black}`,
    fontFamily: fonts.FbReformaMedium,
  },
  breadcrumb: {
    display: "flex",
    color: colors.white,
    fontSize: "1rem",
    letterSpacing: "0.05625rem",
    fontFamily: fonts.FbReformaRegular,
    gap: ".5rem",
  },
  coverElements: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    right: "15%",
    top: "2rem",
    gap: "1.2rem",
  },
};

export default AuctionPage;
