import React, { useState } from "react";
import colors from "../../Assets/Colors/Colors.json";
import { useNavigate } from "react-router-dom";
import { CheckIsEnable, StyleMode } from "../../Utils/consts";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import { IFooterItem, ISubFooterItem } from "../../Interfaces/IFooter";
import { useSelector } from "react-redux";
import { t } from "i18next";
import { PageState } from "../../Redux/Slices/pageSlice";
import { ILinkOpenType, ISubMenuItem } from "../../Interfaces/IMenu";
import fonts from "../../Assets/Fonts/Fonts.json";
import useDeviceMode from "../../Utils/useWindowDimensions";
import fontSize from "../../Assets/Fonts/FontsSizes.json";
import { IsMobile } from "../../Services/DeviceService";

const FooterItem: React.FC<IFooterItem> = (props) => {
  const { label, subMenus, isEnabled, isColumn = true, openType } = props;
  const navigate = useNavigate();
  const [_itemKeyOver, _setItemKeyOver] = useState("");
  const currentPage = (
    useSelector((state: any) => state.PageReducer) as PageState
  ).page;

  const currentUrl =
    window.location.search.split("?page=").length === 2
      ? window.location.search.split("?page=")[1].toLowerCase()
      : null;

  const deviceMode = useDeviceMode();

  const redirectToPage = (page?: string, open?: ILinkOpenType[`Type`]) => {
    let currentOpenType = open ? open : openType;
    if (page) {
      switch (currentOpenType) {
        case "Internal":
          navigate(`?page=${page}`);
          break;
        case "External":
          // if (deviceMode === StyleMode.desktop && page.includes("tel:")) {
          //   break;
          // }
          window.open(page, "_blank");
          break;
      }
    }
  };

  const getAriaLabelByType = (menu: ISubFooterItem, index: number) => {
    let link = ``;
    if (menu.redirectTo.includes("mailto:")) {
      link = `${t("SendMailTo", { mailto: menu.redirectTo })}`;
    } else if (menu.redirectTo.includes("tel:")) {
      link = `${t("CallTo")}`;
    } else if (menu.redirectTo.includes("https://www.youtube.com")) {
      link = `${t("OpenIsraelRailwaysYoutube")}`;
    } else if (menu.redirectTo.includes("https://www.facebook.com")) {
      link = `${t("OpenIsraelRailwaysFacebook")}`;
    } else if (menu.redirectTo.includes("https://www.linkedin.com")) {
      link = `${t("OpenIsraelRailwaysLinkedin")}`;
    } else if (menu.redirectTo.includes("https://www.instagram.com")) {
      link = `${t("OpenIsraelRailwaysInstagram")}`;
    } else if (menu.redirectTo.includes("https://www.tiktok.com")) {
      link = `${t("OpenIsraelRailwaysTiktok")}`;
    }

    /*
${t("SubMenuItemNumberOutOfNumber", {
      currentIndex: index + 1,
      total: subMenus.length,
    })}
*/

    return `${link} ${menu.label} `;
  };

  const getCursorType = (menu: ISubFooterItem) => {
    if (
      currentUrl !== menu.redirectTo.toLowerCase() &&
      menu.redirectTo !== ""
    ) {
      // if (
      //   deviceMode === StyleMode.desktop &&
      //   menu.redirectTo.includes("tel:")
      // ) {
      //   return "text";
      // }
      return "pointer";
    }

    return "text";
  };

  switch (CheckIsEnable(isEnabled)) {
    case true:
      return (
        <div style={styles.itemContainer}>
          <div style={styles.itemTitleContainer}>
            <h3 style={styles.itemTitle}>{label}</h3>
            <div
              style={{
                position: "absolute",
                backgroundColor: colors.koromiko,
                width: "1.8125rem",
                height: "2px",
              }}
            ></div>
          </div>
          <ul
            style={{
              display: "flex",
              flexDirection: isColumn ? "column" : "row",
              gap: "1.5rem",
              maxWidth: "20rem",
              paddingInlineStart: "0",
              padding: 0,
            }}
            role={"list"}
          >
            {subMenus?.map((menu, key) => (
              <li
                key={menu.label + key + menu.redirectTo}
                onMouseOver={() => {
                  _setItemKeyOver(menu.label + key + menu.redirectTo);
                }}
                onMouseLeave={() => {
                  _setItemKeyOver("");
                }}
                aria-label={getAriaLabelByType(menu, key)}
                style={{
                  ...styles.subMenuItem,
                  color:
                    currentUrl === menu.redirectTo.toLowerCase() &&
                    menu.redirectTo !== ""
                      ? colors.koromiko
                      : colors.white,
                  fontFamily:
                    currentUrl === menu.redirectTo.toLowerCase() &&
                    menu.redirectTo !== ""
                      ? fonts.FbReformaMedium
                      : fonts.FbReformaRegular,
                  textDecoration:
                    _itemKeyOver === menu.label + key + menu.redirectTo &&
                    currentUrl !== menu.redirectTo.toLowerCase() &&
                    menu.redirectTo !== ""
                      ? "underline"
                      : "",
                  cursor: getCursorType(menu),
                }}
              >
                <span
                  style={{
                    ...styles.subMenuItem,
                    color:
                      currentUrl === menu.redirectTo.toLowerCase() &&
                      menu.redirectTo !== ""
                        ? colors.koromiko
                        : colors.white,
                  }}
                >
                  {menu.image && (
                    <img
                      tabIndex={!menu.label ? 0 : -1}
                      src={menu.image._url}
                      style={{
                        filter:
                          _itemKeyOver === menu.label + key + menu.redirectTo
                            ? "brightness(.8)"
                            : "",
                      }}
                      aria-label={
                        !menu.label ? getAriaLabelByType(menu, key) : undefined
                      }
                      role={!menu.label ? "link" : ""}
                      onClick={() => {
                        if (
                          currentUrl !== menu.redirectTo.toLowerCase() &&
                          menu.redirectTo !== ""
                        ) {
                          redirectToPage(menu.redirectTo, menu.linkTo);
                        }
                      }}
                      alt={``}
                    />
                  )}
                  <span
                    tabIndex={menu.label ? 0 : -1}
                    role={"link"}
                    aria-label={
                      label ? getAriaLabelByType(menu, key) : undefined
                    }
                    onClick={() => {
                      if (
                        currentUrl !== menu.redirectTo.toLowerCase() &&
                        menu.redirectTo !== ""
                      ) {
                        redirectToPage(menu.redirectTo, menu.linkTo);
                      }
                    }}
                    onKeyPress={(e) => {
                      if (
                        currentUrl !== menu.redirectTo.toLowerCase() &&
                        menu.redirectTo !== ""
                      ) {
                        redirectToPage(menu.redirectTo, menu.linkTo);
                      }
                    }}
                  >
                    {menu.label}
                  </span>
                </span>
              </li>
            ))}
          </ul>
        </div>
      );
    default:
      return null;
  }
};

const styles: StyleSheet = {
  itemContainer: {
    display: "flex",
    flexDirection: "column",
    color: colors.white,
    gap: "1.5rem",
  },
  itemTitle: {
    fontSize: fontSize.Paragraph,
    lineHeight: "2rem",
    marginBlockStart: "0",
    marginBlockEnd: "1rem",
    maxWidth: "15rem",
    fontFamily: fonts.FbReformaMedium,
  },
  itemTitleContainer: {
    position: "relative",
  },
  subMenuItem: {
    display: "flex",
    gap: ".5rem",
    alignItems: "center",
    fontFamily: fonts.FbReformaRegular,
    fontSize: fontSize.FooterItem,
    lineHeight: "2rem",
    color: colors.white,
  },
};
export default FooterItem;
