import { t } from "i18next";
import React, { Fragment, useState, useRef } from "react";
import ReactModal from "react-modal";
import colors from "../../../Assets/Colors/Colors.json";
import fonts from "../../../Assets/Fonts/Fonts.json";
import Images from "../../../Data/Images/Images";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import { ITrain } from "../../../Interfaces/ITrain";
import {
  FormatHoursMinutes,
  GetDurationFormatted,
  GetDurationTimeMS,
} from "../../../Services/DateService";
import i18n from "../../../Services/i18n";
import { UmbracoStoreState } from "../../../Redux/Slices/umbracoSlice";
import { useSelector } from "react-redux";
import { ScheduleState } from "../../../Redux/Slices/scheduleSlice";
import { GetStationsDictionary } from "../../../Utils/helpers";
import { IStation } from "../../../Interfaces/IStation";
import store from "store2";
import Config from "../../../Configuration/server";
import fontSize from "../../../Assets/Fonts/FontsSizes.json";
import { IsMobile } from "../../../Services/DeviceService";
import { useLocation } from "react-router-dom";
import QueryString from "qs";
import TravelMessages from "../TravelMessages/TravelMessages";
import { SystemParamsState } from "../../../Redux/Slices/systemParamsSlice";

const TrainCardMobile: React.FC<ITrain> = (props) => {
  const {
    trainNumber,
    originPlatform,
    destPlatform,
    arrivalTime,
    departureTime,
    changes,
    routeStations,
    isSelected,
    index,
    totalTrains,
    lastDestanation,
    trainPosition,
    onPurchaseButtonClick,
    onNotificationButtonClick,
    isNotificationVisible,
    travelMessages,
    handleDelayInfoModal,
  } = props;

  const location = useLocation();

  const trainSharedNumber =
    QueryString.parse(location.search.split("?")[1]).trainNumber || "";

  const timeDifferent = trainPosition?.calcDiffMinutes;
  const durationInMs = GetDurationTimeMS(
    new Date(departureTime),
    new Date(arrivalTime)
  );
  const durationFormated = GetDurationFormatted(durationInMs);
  const trainCardRef = useRef<HTMLDivElement>(null);
  const purchaseButtonRef = useRef<HTMLButtonElement>(null);

  const [_isNotificationListVisible, _setIsNotificationListVisible] =
    useState(false);

  const totalTimeFormated = `${durationFormated} ${t(
    durationFormated.includes(":") ? "Hrs" : "Min"
  )}`;

  const redirectPurchaseOption = (link: string) => {
    window.open(link, "_blank");
  };

  const systemParams = (
    useSelector((state: any) => state.SystemParamsReducer) as SystemParamsState
  ).systemParams;
  const systemParamsDictionary = systemParams.reduce(
    (result: { [key: string]: string }, current) => {
      result[current.key] = current.value;
      return result;
    },
    {}
  );

  const umbracoStore = (
    useSelector((state: any) => state.UmbracoStoreReducer) as UmbracoStoreState
  ).umbracoStore;

  const stations: IStation[] = store.session.get(
    `${Config.BASE_LOCALSTORAGE_NAME}stations`
  )[i18n.language];

  const schedule = useSelector(
    (state: any) => state.ScheduleReducer
  ) as ScheduleState;

  const station = GetStationsDictionary(stations);

  const [_isPurchaseCardModalVisible, _setIsPurchaseCardModalVisible] =
    useState(false);

    const getIsLateIndicationBySystemParams = () => {
      if (
        trainPosition === undefined ||
        trainPosition === null ||
        trainPosition.calcDiffMinutes === undefined ||
        trainPosition.calcDiffMinutes === null
      ) {
        return false;
      }
  
      if (systemParamsDictionary["showTrainDelayInSearchResults"] === "false")
        return false;
  
      const minimalDelayMinute =
        systemParamsDictionary["minimalDelayMinute"] !== undefined
          ? +systemParamsDictionary["minimalDelayMinute"]
          : 0;
  
      if (trainPosition.calcDiffMinutes >= minimalDelayMinute) {
        if (trainPosition.calcDiffMinutes && trainPosition.calcDiffMinutes > 0) {
          return true;
        }
      }
      return false;
    };

  return (
    <Fragment>
      <div
        style={{
          ...styles.mainWrapper,
        }}
      >
        <div
          ref={trainCardRef}
          className="train_card"
          id={`train_card_${index}`}
          tabIndex={!IsMobile() ? 0 : -1}
          style={{
            border:
              (trainSharedNumber as string) !== trainNumber.toString()
                ? !isSelected
                  ? `.1rem solid ${colors.pastelGray}`
                  : `.1rem solid ${colors.pastelGray}`
                : `.22rem solid ${colors.black}`,
            boxShadow: !isSelected
              ? `0 2px 10px 0 rgba(0, 0, 0, 0.15)`
              : `0 2px 10px 0 rgba(0, 0, 0, 0.15)`,
            overflow: "hidden",
          }}
          onKeyPressCapture={() => {
            trainCardRef.current && trainCardRef.current.click();
          }}
          data-active={isSelected}
          aria-checked={isSelected}
          role="radio"
          aria-label={t("FullTrainDetails", {
            trainNumber,
            fromStation:
              schedule.fromStation && station[schedule.fromStation].stationName,
            toStation:
              schedule.toStation && station[schedule.toStation].stationName,
            departureTime: FormatHoursMinutes(departureTime),
            platform: originPlatform,
            changes,
          })}
          onKeyDown={(e) => {
            if (e.key === "ArrowDown") {
              if (index !== undefined) {
                const nextCard = document.getElementById(
                  `train_card_${index + 1}`
                );
                if (trainCardRef.current) {
                  trainCardRef.current.tabIndex = -1;
                }
                if (nextCard) {
                  nextCard.tabIndex = 0;
                  nextCard.focus();
                  if (purchaseButtonRef.current) {
                    purchaseButtonRef.current.tabIndex = -1;
                  }
                }
              }
            }
            if (e.key === "ArrowUp") {
              if (index !== undefined) {
                const previousCard = document.getElementById(
                  `train_card_${index - 1}`
                );
                if (trainCardRef.current) {
                  trainCardRef.current.tabIndex = -1;
                }
                if (previousCard) {
                  previousCard.tabIndex = 0;
                  previousCard.focus();
                  if (purchaseButtonRef.current) {
                    purchaseButtonRef.current.tabIndex = -1;
                  }
                }
              }
            }

            if (e.key === "Enter" || e.key === "Space") {
              const routeDetails = document.getElementById("route-details");
              if (routeDetails) {
                routeDetails.focus();
              }
            }
            if (e.key === "Tab" && !e.shiftKey) {
              if (purchaseButtonRef.current) {
                purchaseButtonRef.current.tabIndex = 0;
              }
              if (index !== undefined && totalTrains !== undefined) {
                if (index !== totalTrains - 1) {
                }
              }
            }
          }}
        >
          <div
            style={{
              ...styles.header,
              backgroundColor:
                (trainSharedNumber as string) !== trainNumber.toString()
                  ? colors.ghostWhite
                  : "#E2E2E2",
            }}
          >
            <div
              style={{
                ...styles.title,
                color:
                  (trainSharedNumber as string) !== trainNumber.toString()
                    ? colors.gray
                    : colors.grayDark,
              }}
            >
              <img
                src={Images.trainInCard}
                aria-label={`${t("Train-Number")} ,`}
                style={{
                  filter: "brightness(.2)",
                  transform: `${
                    i18n.dir() === "rtl" ? "scaleX(1)" : "scaleX(-1)"
                  }`,
                }}
              />{" "}
              {/* {` ${trainNumber}`} */}
              {` ${trainNumber}`}
            </div>
            <div style={styles.icons}>
              <img
                src={Images.handicap}
                style={{
                  filter: "brightness(.2)",
                }}
              />
            </div>
          </div>
          {getIsLateIndicationBySystemParams() ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: ".7rem",
              }}
            >
              <div style={styles.delToolTip}>
                <span style={styles.del}>
                  {`(${
                    i18n.language === `en` || i18n.language === `ru`
                      ? `${t(`display_minutes`, {
                          minute: `+${timeDifferent}`,
                        })}`
                      : ""
                  }${
                    i18n.language === `en` || i18n.language === `ru`
                      ? ``
                      : `${t(`display_minutes`, {
                          minute: `${timeDifferent}+`,
                        })}`
                  })`}
                </span>
              </div>
              <img
                src={Images.information}
                width={"17"}
                style={{
                  marginBottom: "-0.25rem",
                }}
                title={t("delayInfo")}
                onClick={(e) => {
                  e.stopPropagation();
                  handleDelayInfoModal && handleDelayInfoModal(true);
                }}
              />
            </div>
          ) : null}
          <div style={styles.body}>
            <div style={styles.timeWrapper}>
              <div
                style={{
                  ...styles.departureArrivalInfo,
                  // alignItems: "flex-start",
                }}
              >
                <div style={styles.departureArrivalTime}>
                  {FormatHoursMinutes(departureTime)}
                </div>
                <div style={styles.platform}>
                  {`${
                    originPlatform === 0
                      ? t(`NoSetPlatformNumber`)
                      : `${t("Platform")} ${originPlatform}`
                  }`}
                </div>
              </div>
              <div>
                <div style={styles.timeLengthInfo}>
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <div style={styles.totalTime}>
                      <div style={styles.linearGradient}></div>
                      {totalTimeFormated}
                      <div
                        style={{
                          ...styles.linearGradient,
                          backgroundImage:
                            "linear-gradient(90deg,hsla(0,0%,91%,0),#cdcdcd)",
                          right: i18n.dir() === "rtl" ? "100%" : "",
                          left: i18n.dir() === "ltr" ? "-50%" : "",
                        }}
                      ></div>
                    </div>
                  </div>
                  <div style={styles.totalChanges}>
                    <span
                      style={{
                        color: colors.grayDark,
                      }}
                    >
                      {t("Change", { count: changes })}
                    </span>
                    <img
                      src={Images.arrowDownGray}
                      style={{
                        transform: "scale(.9)",
                      }}
                    />
                  </div>
                </div>
              </div>
              <div
                style={{
                  ...styles.departureArrivalInfo,
                  // alignItems: "flex-end",
                }}
              >
                <div style={styles.departureArrivalTime}>
                  {FormatHoursMinutes(arrivalTime)}
                </div>
                <div style={styles.platform}>
                  {`${
                    destPlatform === 0
                      ? t(`NoSetPlatformNumber`)
                      : `${t("Platform")} ${destPlatform}`
                  }`}
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              padding: "1rem 0rem",
              alignItems: "center",
              borderTop: `1px solid ${colors.pastelGray}`,
            }}
          >
            <div
              style={styles.displayRoute}
              onClick={(e) => {
                e.stopPropagation();
                // _setIsNotificationListVisible(!_isNotificationListVisible);
                onNotificationButtonClick &&
                  onNotificationButtonClick(index!, props);
              }}
            >
              <img
                width={!isNotificationVisible ? "27" : "25"}
                height={!isNotificationVisible ? "19" : "25"}
                src={
                  !isNotificationVisible
                    ? Images.notificationsBell
                    : Images.closeButton
                }
              />

              <div style={styles.displayRouteTitle}>{t("Actions")}</div>
            </div>
            <span style={{ color: "#d2d2d2", fontSize: fontSize.SubTitle }}>
              |
            </span>
            <div
              style={styles.displayRoute}
              onClick={(e) => {
                e.stopPropagation();
                onPurchaseButtonClick && onPurchaseButtonClick();
              }}
              onKeyPressCapture={(e) => {
                e.stopPropagation();
                onPurchaseButtonClick && onPurchaseButtonClick();
              }}
            >
              <img
                width={!isNotificationVisible ? "27" : "25"}
                height={!isNotificationVisible ? "19" : "25"}
                src={Images.shekels}
              />
              <div style={styles.displayRouteTitle}>{t("prices")}</div>
            </div>
          </div>
        </div>
        {travelMessages && travelMessages.length > 0 && (
          <TravelMessages travelMessages={travelMessages} />
        )}
      </div>
    </Fragment>
  );
};

const styles: StyleSheet = {
  mainWrapper: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: colors.white,
    justifyContent: "center",
    maxWidth: "55rem",
    width: "90%",
    borderRadius: "0.625rem",
    // boxShadow: "0 2px 10px 0 rgba(0, 0, 0, 0.15)",
    minHeight: "11rem",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    padding: ".5rem",
    backgroundColor: colors.ghostWhite,
  },
  title: {
    color: colors.gray,
    fontFamily: fonts.FbReformaRegular,
    fontSize: fontSize.Text,
    display: "flex",
    alignItems: "center",
    gap: ".5rem",
  },
  body: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "1rem",
    gap: "6rem",
  },
  departureArrivalInfo: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    gap: "0.7rem",
  },
  departureArrivalTime: {
    fontSize: fontSize.UmbracoElementTitle,
    fontFamily: fonts.FbReformaMedium,
  },
  timeLengthInfo: {
    display: "flex",
    flexDirection: "column",
    gap: ".5rem",
  },
  totalTime: {
    border: `.1rem solid ${colors.pastelGray}`,
    borderRadius: "2rem",
    padding: "0 1rem",
    minWidth: "4rem",
    textAlign: "center",
    position: "relative",
  },
  displayRoute: {
    display: "flex",
    gap: ".25rem",
    color: colors.darkBlue,
    fontSize: fontSize.SubTitle,
    fontFamily: fonts.FbReformaRegular,
    minWidth: "7rem",
    justifyContent: "center",
    alignItems: "center",
    width: `100%`,
  },
  totalChanges: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  timeWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  purchaseTicket: {
    borderRadius: "1rem",
    backgroundColor: colors.darkBlue,
    border: ".1rem solid transparent",
    color: colors.white,
    fontSize: fontSize.Text,
    width: "10rem",
    height: "2.2rem",
    cursor: "pointer",
    fontFamily: fonts.FbReformaMedium,
  },
  platform: {
    color: colors.grayDark,
  },
  icons: {
    display: "flex",
    marginInlineStart: "1rem",
    marginInlineEnd: "1rem",
  },
  linearGradient: {
    height: "2px",
    width: "3rem",
    top: "50%",
    left: "100%",
    position: "absolute",
    backgroundImage: "linear-gradient(90deg, #cdcdcd, hsla(0,0%,91%,0))",
  },
  purchaseModalIconWrapper: {
    display: "flex",
    justifyContent: "center",
    position: "relative",
    top: "-2.5rem",
    zIndex: "9999999",
  },
  purchaseModalBody: {
    position: "absolute",
    top: "12%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    gap: "1.25rem",
    zIndex: "9999999",
  },
  exitIcon: {
    transform: "scale(1.3)",
  },
  purchaseModalIconContainer: {
    width: "4.3rem",
    height: "4.3rem",
    boxShadow: "0 5px 11px 0 rgba(0, 0, 0, 0.21)",
    borderRadius: "50%",
    backgroundColor: "white",
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transform: "translateY(5px)",
  } as React.CSSProperties,
  purchaseModalTitle: {
    fontSize: fontSize.UmbracoElementTitle,
    lineHeight: "1.5rem",
    maxWidth: "18rem",
    fontFamily: fonts.FbReformaMedium,
    textAlign: "center",
  },
  purchaseOptions: {
    display: "flex",
    justifyContent: "center",
    gap: "1rem",
    flexWrap: "wrap",
  },
  purchaseItem: {
    cursor: "pointer",
    scale: ".8",
  },
  purchaseModalCloseIcon: {
    position: "absolute",
    left: i18n.dir() === "rtl" ? "1rem" : "",
    right: i18n.dir() === "ltr" ? "1rem" : "",
    top: "-2rem",
    cursor: "pointer",
  },
  del: {
    color: colors.bittersweet,
    fontSize: fontSize.Text,
    marginInlineStart: `1rem`,
  },
  delToolTip: {
    display: "flex",
    alignItems: "baseline",
    gap: ".4rem",
  },
};

export default TrainCardMobile;
