import { useEffect, useRef, useState } from "react";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import colors from "../../../Assets/Colors/Colors.json";
import fonts from "../../../Assets/Fonts/Fonts.json";
import Images from "../../../Data/Images/Images";
import { IHomeTopContainer } from "../../../Interfaces/ISoftUpdate";
import { useNavigate } from "react-router-dom";
import useDeviceMode from "../../../Utils/useWindowDimensions";
import fontSize from "../../../Assets/Fonts/FontsSizes.json";

import {
  SoftUpdateInterval as SoftUpdateTimeout,
  StyleMode,
} from "../../../Utils/consts";
import { GetSystemParams } from "../../../Services/GlobalService";
import { t } from "i18next";
import i18n from "../../../Services/i18n";
import { Swiper as ReactSwiper, SwiperSlide, useSwiper } from "swiper/react";
import Swiper, { Autoplay, Pagination, EffectFade, A11y, Keyboard} from "swiper";
import { Swiper as SwiperCore } from 'swiper/types';
import "swiper/css";
import "swiper/css/effect-fade";
import { IsMobile } from "../../../Services/DeviceService";
import { useSelector } from "react-redux";
import { UmbracoStoreState } from "../../../Redux/Slices/umbracoSlice";
import HeaderMobile from "../../Header/Mobile/HeaderMobile";
import { GetLongTextWithDots } from "../../../Utils/utils";
import Config from "../../../Configuration/server";
import { AddToDataLayer } from "../../../Services/GoogleAnalyticsService";
import FocusTrapSlide from "../../FocusTrapSlide/FocusTrapSlide";
import store from "store2";

const HomeTopContainer: React.FC<IHomeTopContainer> = (props) => {
  const { updates, textAlign = "start", homeContent } = props;
  const [_currentItemIndex, _setCurrentItemIndex] = useState(0);
  const [_currentReplaceTime, _setCurrentReplaceTime] = useState(6);
  const [_mouseOverSearchBox, _setMouseOverSearchBox] = useState(false);
  const [_mouseOverLink, _setMouseOverLink] = useState(false);
  const [_isPaused, _setIsPaused] = useState(false);
  const [_swiper, _setSwiper] = useState<Swiper>();

  const headerRef = useRef<HTMLHeadingElement>(null);
  const headerBoldRef = useRef<HTMLHeadingElement>(null);
  const topContainerRef = useRef<HTMLDivElement>(null);
  const searchBoxRef = useRef<HTMLDivElement>(null);
  const isPressed = useRef(true);

  const navigate = useNavigate();
  const deviceMode = useDeviceMode();
  const isMounted = useRef(false);

  const redirectToPage = (page?: string) => {
    if(page?.includes('https://')){
      window.open(`${page}`,'_blank')
    }
    else
    page && navigate(`?page=${page}`);
  };

  const isSafariOs = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const isSafariAndWebMode = isSafariOs && deviceMode === StyleMode.desktop;
  const umbracoStore = (
    useSelector((state: any) => state.UmbracoStoreReducer) as UmbracoStoreState
  ).umbracoStore;

  const isIos =
    /^((?!chrome|android).)*safari/i.test(navigator.userAgent) &&
    deviceMode === StyleMode.mobile;

  useEffect(() => {
    const pagination = document.getElementById("topContainer");
    if (pagination) {
      const item = pagination.getElementsByClassName("swiper-pagination");
      if (item[0]) {
        switch (textAlign) {
          case "start":
            (item[0] as HTMLElement).style.width = "15%";
            break;
          case "center":
            (item[0] as HTMLElement).style.width = "57%";
            break;
          case "end":
            (item[0] as HTMLElement).style.width = "99%";
            break;
        }
      }
    }
  }, [textAlign]);

  useEffect(() => {
    // handleSwiperAttributes();
  }, [_swiper]);

  const handleSwiperAttributes = () => {
    const bullets = document.getElementsByClassName("swiper-pagination-bullet");
    if (bullets) {
      Array.from(bullets).forEach((bullet, index) => {
        bullet.setAttribute("role", "radio");
        bullet.setAttribute(
          "aria-label",
          t("GalleryItemNumberOutOfNumber", {
            currentIndex: index + 1,
            total: bullets.length,
          })
        );
        bullet.setAttribute("id", `slide_bullet_${index}`);
      });
    }
  }

  useEffect(() => {
    isMounted.current = true;

    (async () => {
      let systemParams = await GetSystemParams();
      let currentTime = 0;
      systemParams.map((item) => {
        if (item.key === SoftUpdateTimeout) {
          currentTime = +item.value;
        }
      });
      if (isMounted.current) {
        _setCurrentReplaceTime(currentTime);
        // startTimeout();
      }
    })();
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (headerRef.current && headerBoldRef.current) {
      headerRef.current.innerHTML = headerRef.current.innerHTML.replace(
        /&nbsp;/g,
        " "
      );
      headerBoldRef.current.innerHTML = headerBoldRef.current.innerHTML.replace(
        /&nbsp;/g,
        " "
      );
    }
  }, [_currentItemIndex, _currentReplaceTime]);

  const handleLastItemTab = (event: React.KeyboardEvent<HTMLDivElement>) => {

    if (event.key === 'Tab' && !event.shiftKey) {
      event.preventDefault();
      // Move focus to the first pagination bullet
      const paginationBullets = document.querySelectorAll('.swiper-pagination-bullet');
      if (paginationBullets.length > 0) {
        Array.from(paginationBullets).forEach((bullet, index) => {
        
          if(bullet.className === "swiper-pagination-bullet swiper-pagination-bullet-active") {
            (bullet as HTMLElement).focus();
          }

          const pageMainContent = document.getElementById("pageMainContent");
          pageMainContent?.focus();
          if(_swiper){
            _swiper.autoplay.start();
          }
        })}
    }
    if (event.key === 'Tab' && event.shiftKey) {
      event.preventDefault();
      // Move focus to the first pagination bullet
      const paginationBullets = document.querySelectorAll('.swiper-pagination-bullet');
      if (paginationBullets.length > 0) {
        Array.from(paginationBullets).forEach((bullet, index) => {
          if(bullet.className === "swiper-pagination-bullet swiper-pagination-bullet-active") {
            bullet.setAttribute("aria-checked", "true");
            (bullet as HTMLElement).focus();
            if(_swiper){
              _swiper.autoplay.stop();
            }
          } else {
            bullet.setAttribute("aria-checked", "false");
          }
        })} else {
          const pauseSoftUpdate = document.getElementById("pauseSoftUpdate") as HTMLElement;
          pauseSoftUpdate.focus();
          if(_swiper){
            _swiper.autoplay.start();
          }
        }
    }

    //handleSwiperAttributes();

  };

    // Function to move focus between bullets using arrow keys
    const handleBulletArrowNavigation = (event: React.KeyboardEvent<HTMLDivElement>) => {

      const paginationBullets = document.querySelectorAll('.swiper-pagination-bullet');
      
      if (paginationBullets.length > 0) {
        let focusedIndex = -1;
        // Find the currently focused bullet
        paginationBullets.forEach((bullet, index) => {
          if (bullet === document.activeElement) {
            focusedIndex = index;
          }
        });
        
        if (event.key === 'Tab' && !event.shiftKey) {
          event.preventDefault();
          (paginationBullets[focusedIndex] as HTMLElement).blur();

            // if(focusedIndex < paginationBullets.length - 1) {
              document.getElementById(`softUpdateLink_${focusedIndex}`)?.focus();
            // }
        }

        if (event.key === 'Tab' && event.shiftKey) {
          event.preventDefault();
          // if(focusedIndex > 0){
          //   (paginationBullets[focusedIndex] as HTMLElement).blur();
          //     document.getElementById(`softUpdateLink_${focusedIndex}`)?.focus();
          //   } else {

          //     const pauseSoftUpdate = document.getElementById("pauseSoftUpdate") as HTMLElement;
          //     pauseSoftUpdate.focus();
          //   }
            
          document.getElementById(`softUpdateLink_${focusedIndex}`)?.focus();
          const pauseSoftUpdate = document.getElementById("pauseSoftUpdate") as HTMLElement;
          pauseSoftUpdate.focus();

 
        }

        if (event.key === 'Enter') {
          (paginationBullets[focusedIndex] as HTMLElement).focus();
          
          Array.from(paginationBullets).forEach((bullet, index) => {
            bullet.setAttribute("tabIndex", "-1");
            bullet.setAttribute("aria-checked", "false");
          });
          (paginationBullets[focusedIndex] as HTMLElement).setAttribute("aria-checked", "true");
        }
        if (event.key === 'ArrowLeft' && focusedIndex < paginationBullets.length - 1) {
          (paginationBullets[focusedIndex + 1] as HTMLElement).focus();

        } else if (event.key === 'ArrowRight' && focusedIndex > 0) {
          (paginationBullets[focusedIndex - 1] as HTMLElement).focus();
        }
      }

      //handleSwiperAttributes();

    };

    useEffect(() => {
      const paginationBullets = document.querySelectorAll('.swiper-pagination-bullet');
      paginationBullets.forEach((bullet, index) => {
        // Disable focus on all bullets except the first one
        if (index !== 0) {
          (bullet as HTMLElement).setAttribute('tabindex', '-1');
        }
      });
  
      // Add arrow key navigation to each bullet
      paginationBullets.forEach((bullet) => {
      (bullet as any).addEventListener('keydown', handleBulletArrowNavigation);
      });
      paginationBullets.forEach((bullet) => {
        (bullet as any).addEventListener('keydowncapture', handleBulletArrowNavigation);
        });
      return () => {
        paginationBullets.forEach((bullet) => {
          (bullet as any).removeEventListener('keydown', handleBulletArrowNavigation);
        });
        paginationBullets.forEach((bullet) => {
          (bullet as any).removeEventListener('keydowncapture', handleBulletArrowNavigation);
        });
      };
    }, []);

  const handlePlayPause = (isPaused: boolean) => {
    if (!isPaused) {
      _swiper && _swiper.autoplay.stop();
    } else {
      _swiper && _swiper.autoplay.start();
    }
    _setIsPaused(!isPaused);
  };

              
  const handleFocus = () => {
    if (_swiper) {
      _swiper?.autoplay.stop();
      // console.log("Autoplay paused on focus");
    }
  };

  const handleBlur = () => {
    if (_swiper) {
      _swiper.autoplay.start();
      // console.log("Autoplay resumed on blur");
    }
  };


  return (
    <div
      style={{
        width: "100%",
      }}
    >
      {deviceMode !== StyleMode.desktop && Config.IS_TENDERS === "false" && (
        <HeaderMobile headerContent={umbracoStore?.headerContent} />
      )}
      <div
        ref={topContainerRef}
        id={"topContainer"}
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          position: "relative",
          justifyContent: "center",
          alignItems: "center",
        }}
        onFocusCapture={(e) => {
          // stopTimeout();
        }}
        onBlurCapture={(e) => {
          // if (topContainerRef.current) {
          //   if (topContainerRef.current.contains(e.relatedTarget)) {
          //     if (e.relatedTarget === searchBoxRef.current) {
          //       startTimeout();
          //     } else {
          //       stopTimeout();
          //     }
          //   } else {
          //     startTimeout();
          //   }
          // }
        }}
      >
        {updates.length > 1 && (
          <div
            style={{
              position: "absolute",
              left: i18n.dir() === "rtl" ? "2rem" : "",
              right: i18n.dir() === "ltr" ? "2rem" : "",
              top: deviceMode !== "MOBILE" ? "6rem" : "unset",
              backgroundColor: colors.white,
              borderRadius: "50%",
              padding: ".25%",
              boxSizing: "border-box",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: "10",
              width: "2rem",
              height: "2rem",
              cursor: "pointer",
            }}
            tabIndex={!IsMobile() ? 0 : -1}
            role={"button"}
            aria-label={t(
              _isPaused
                ? "PlayHomeTopContainerUpdates"
                : "PauseHomeTopContainerUpdates"
            )}
            id="pauseSoftUpdate"
            onClick={() => handlePlayPause(_isPaused)}
            onKeyPress={() => handlePlayPause(_isPaused)}
            aria-pressed={_isPaused}
          >
            <img
              src={!_isPaused ? Images.audioPauseIcon : Images.audioPlay}
              style={{
                scale: _isPaused ? ".7" : "",
                width: "100%",
                height: "100%",
              }}
              alt={``}
            />
          </div>
        )}
        <ReactSwiper
             onInit={(swiper) => {
              
              if(swiper && swiper.keyboard) {
                swiper.keyboard.disable();
              }
              // swiper.keyboard.disable(); // Ensure keyboard control is off
            }}
          onSwiper={(swiper) => {
            _setSwiper(swiper)
          }}
          effect="fade"
          className={"mySwiper"}
          slideToClickedSlide={true}
          role={"region"}
          aria-label={t("sliderUpdates")}
          slidesPerView={1}
          modules={[Pagination, Autoplay, EffectFade, A11y, Keyboard]}
          pagination={{ clickable: true,
            renderBullet: (index, className) => {
              const customLabel = t("GalleryItemNumberOutOfNumber", {
                currentIndex: index + 1,
                total: _swiper?.slides.length,
              })
              return `<span class="${className}" role="radio" aria-label="${customLabel}"></span>`;
            },

           }}
          loop={true}
          preventInteractionOnTransition={true}
          loopPreventsSlide={true}
          watchSlidesProgress={true}
          // autoplay={
          //   !_isPaused
          //     ? {
          //         delay: _currentReplaceTime * 1000,
          //         disableOnInteraction: false,
                  
          //       }
          //     : undefined
          // }
        >
          {updates.map((update, index) => (
            <SwiperSlide key={index}>
              {/* <FocusTrapSlide> */}
              <div
                onFocus={handleFocus}
                onBlur={handleBlur}
                className="cover-blur"
                style={{
                  background: `25% 50% / cover url(${update.coverImage._url}) no-repeat`,
                  alignItems: "center",
                  width: "100%",
                  position: "relative",
                  height: deviceMode === StyleMode.desktop ? "26rem" : "21rem",
                  zIndex: "1",
                  justifyContent: "center",
                  display: "flex",
                  marginTop: "-2rem",
                }}
              >
                {/* <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    backdropFilter: "brightness(.85)",
                    WebkitBackdropFilter: "brightness(.85)",
                  }}
                ></div> */}
                <div
                  style={{
                    width: "70%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: textAlign,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1rem",
                      position: "absolute",
                    }}
                  >
                    <div
                      style={{
                        ...styles.titleContainer,
                        height:
                          deviceMode === StyleMode.desktop ? "10rem" : "16rem",
                      }}
                    >
                      <h2
                        style={{
                          ...styles.titleContainer,
                          height:
                            deviceMode === StyleMode.desktop ? "10rem" : "",
                        }}
                        // tabIndex={!IsMobile() ? 0 : -1}
                      >
                        {update.header && (
                          <div
                            style={{
                              ...styles.title,
                              marginTop: "0",
                              height:
                                deviceMode === StyleMode.mobile
                                  ? "auto"
                                  : "auto",
                              fontSize:
                                deviceMode === StyleMode.desktop
                                  ? fontSize.PageTitle
                                  : fontSize.UmbracoElementTitle,
                            }}
                            ref={headerRef}
                          >
                            {deviceMode !== StyleMode.desktop &&
                            update.header.length > 24
                              ? GetLongTextWithDots(update.header, 24)
                              : update.header}
                          </div>
                        )}
                        {update.headerBold && (
                          <div
                            style={{
                              ...styles.title,
                              fontFamily: fonts.FbReformaMedium,
                              height: "",
                              marginTop:
                                deviceMode === StyleMode.mobile ? "auto" : "",
                              fontSize:
                                deviceMode === StyleMode.desktop
                                  ? fontSize.PageTitle
                                  : fontSize.SubTitle,
                            }}
                            ref={headerBoldRef}
                          >
                            {deviceMode !== StyleMode.desktop &&
                            update.headerBold.length > 30
                              ? GetLongTextWithDots(update.headerBold, 30)
                              : update.headerBold}
                          </div>
                        )}
                        <div
                          style={{
                            paddingTop: "1rem",
                          }}
                        ></div>
                      </h2>
                      {
                        update.link&&update.link.redirectTo&&
                          <div role={"link"} id={`softUpdateLink_${index}`} 
                          tabIndex={0} 
                          onKeyDown={handleLastItemTab}
                          onKeyDownCapture={(e) => {
                            handleLastItemTab(e);
                            if(e.key === "Enter") {
                              AddToDataLayer({
                                event_name: `element_click`,
                                type: "hero section banner",
                                description: update.link.text,
                              })
                              redirectToPage(update.link.redirectTo);
                            }
                          }}
                          >
                          <span
                            style={
                              !_mouseOverLink
                                ? { ...styles.headerLink }
                                : { ...styles.headerLinkOver }
                            }
                            onClick={() => {
                              AddToDataLayer({
                                event_name: `element_click`,
                                type: "hero section banner",
                                description: update.link.text,
                              })
                              redirectToPage(update.link.redirectTo);
                            }}
                            onMouseOver={() => _setMouseOverLink(true)}
                            onFocus={() => {
                              _setMouseOverLink(true);
                            }}
                            onMouseLeave={() => _setMouseOverLink(false)}
                            onBlur={() => {
                              _setMouseOverLink(false);
                            }}
                            onKeyPressCapture={(e) => {
                              if (e.key === "Tab") {
                                const radio = document.getElementsByClassName("swiper-pagination-bullet swiper-pagination-bullet-active")[0] as HTMLLegendElement;
                                e.stopPropagation();
                                radio.focus();
                              }
                            }}
                          >
                            {update.link.text}{" "}
                          </span>
                          <span
                            aria-hidden={"true"}
                            style={
                              !_mouseOverLink
                                ? { ...styles.headerLink }
                                : { ...styles.headerLinkOver }
                            }
                            onClick={() => {
                              redirectToPage(update.link.redirectTo);
                            }}
                            onMouseOver={() => _setMouseOverLink(true)}
                            onFocus={() => {
                              _setMouseOverLink(true);
                            }}
                            onMouseLeave={() => _setMouseOverLink(false)}
                            onBlur={() => {
                              _setMouseOverLink(false);
                            }}
                          >
                            {update.link.text && ">"}
                          </span>
                          </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
              {/* </FocusTrapSlide> */}
            </SwiperSlide>
          ))}
        </ReactSwiper>
        <div
          id="pageMainContent"
          ref={searchBoxRef}
          style={
            !_mouseOverSearchBox
              ? {
                  ...styles.searchBoxContainer,
                  justifyContent:
                    deviceMode === StyleMode.mobile && !isIos
                      ? "space-around"
                      : "space-around",
                }
              : {
                  ...styles.searchBoxContainerOver,
                  transform: deviceMode === StyleMode.desktop ? "30px" : "50px",
                  justifyContent:
                    deviceMode === StyleMode.mobile && !isIos
                      ? "space-around"
                      : "space-around",
                }
          }
          role={"link"}
          tabIndex={!IsMobile() ? 0 : -1}
          aria-label={`${t(`Jou​rney​-planner-link`)}`}
          onClick={() => {
            store.session("routePlanNavigatedFrom", "main search");
            navigate("?page=routePlan&step=origin");
          }}
          onKeyDownCapture={(e) => {
            if(e.key === "Enter"){
              store.session("routePlanNavigatedFrom", "main search");
              navigate("?page=routePlan&step=origin");
            }
          }}
          onMouseOver={() => _setMouseOverSearchBox(true)}
          onMouseLeave={() => _setMouseOverSearchBox(false)}
        >
          <img
            src={homeContent?.autocompleteIcon._url}
            style={{
              scale: "0.8",
            }}
          />
          <div
            style={{
              ...styles.inputText,
              marginInlineEnd: isIos ? "1rem" : "0rem",
            }}
          >
            {homeContent?.autocompletePlaceholderText}
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  currentDisplayed: {
    position: "relative",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: "85.5rem",
    height: "48rem",
  },
  arrows: {
    position: "absolute",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    top: "40%",
  },
  headerContainer: {
    position: "absolute",
    backgroundColor: colors.whiteTransparent,
    width: "100%",
    minHeight: "5rem",
    maxHeight: "8rem",
    display: "flex",
  },
  header: {
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaMedium,
    fontSize: fontSize.UmbracoElementTitle,
    position: "absolute",
    top: "35%",
  },
  galleryBullets: {
    display: "flex",
    gap: "1rem",
  },
  galleryItemBullet: {
    width: ".75rem",
    height: ".75rem",
    border: `1px solid ${colors.ghostWhite}`,
    borderRadius: "0.75rem",
    cursor: "pointer",
  },
  titleContainer: {
    zIndex: "1",
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontFamily: fonts.FbReformaRegular,
    fontSize: fontSize.PageTitle,
    color: colors.white,
    textShadow: "1px 1px 2px black",
    lineHeight: "3.5rem",
    whiteSpace: "nowrap",
    overflow: "hidden",
    letterSpacing: "1.34px",
  },
  headerLink: {
    fontFamily: fonts.FbReformaRegular,
    // textDecoration: "underline",
    letterSpacing: "0.038125rem",
    color: colors.white,
    fontSize: fontSize.Paragraph,
    cursor: "pointer",
  },
  headerLinkOver: {
    fontFamily: fonts.FbReformaRegular,
    textDecoration: "underline",
    letterSpacing: "0.038125rem",
    color: colors.white,
    fontSize: fontSize.Paragraph,
    cursor: "pointer",
  },
  cover: {
    width: "100%",
    position: "relative",
    height: "50%",
    zIndex: "1",
    justifyContent: "center",
    display: "flex",
    minHeight: "24.5rem",
    alignItems: "center",
  },
  /*searchBoxContainer: {
    display: "flex",
    height: "4.3rem",
    maxWidth: "45rem",
    width: "90%",
    borderRadius: "35px",
    border: "none",
    justifyContent: "space-between",
    alignItems: "center",
    boxShadow: "-1px 1px 33px 1px rgb(64 60 67 / 40%)",
    zIndex: "10",
    transform: "translateY(-30px)",
    backgroundColor: colors.white,
    position: "absolute",
    bottom: "-4rem",
    cursor: "pointer",
  },*/
  searchBoxContainer: {
    display: "flex",
    height: "4.3rem",
    maxWidth: "45rem",
    width: "90%",
    borderRadius: "55px",
    border: "none",
    justifyContent: "space-between",
    alignItems: "center",
    boxShadow: "-1px 1px 33px 1px rgb(64 60 67 / 40%)",
    zIndex: "10",
    transform: "translateY(50%)",
    backgroundColor: colors.white,
    position: "absolute",
    bottom: "0",
    cursor: "pointer",
  },
  /*searchBoxContainerOver: {
    display: "flex",
    height: "4.3rem",
    maxWidth: "45rem",
    width: "90%",
    borderRadius: "35px",
    border: "none",
    justifyContent: "space-between",
    alignItems: "center",
    boxShadow: "-1px 1px 33px 1px rgb(83 55 60)",
    zIndex: "10",
    transform: "translateY(-30px)",
    backgroundColor: colors.white,
    position: "absolute",
    bottom: "-4rem",
    cursor: "pointer",
  },*/
  searchBoxContainerOver: {
    display: "flex",
    height: "4.3rem",
    maxWidth: "45rem",
    width: "90%",
    borderRadius: "55x",
    border: "none",
    justifyContent: "space-between",
    alignItems: "center",
    boxShadow: "-1px 1px 33px 1px rgb(83 55 60)",
    zIndex: "10",
    //transform: "translateY(30px)",
    backgroundColor: colors.white,
    position: "absolute",
    bottom: "0",
    cursor: "pointer",
  },
  inputText: {
    fontSize: fontSize.UmbracoElementTitle,
    boxSizing: "border-box",
    textAlign: "center",
    fontFamily: fonts.FbReformaRegular,
    // marginInlineEnd: "3rem",
    // marginInlineStart: "-3rem",
  },
  icon: {
    backgroundRepeat: "no-repeat",
    width: "2.2rem",

    marginInlineStart: "3rem",
  },
};

export default HomeTopContainer;
