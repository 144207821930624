import { useState } from "react";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  IArticle,
  IArticleWithMedia,
  ISelectedArticles,
} from "../../../../Interfaces/IMagazinePage";
import { StyleMode } from "../../../../Utils/consts";
import useDeviceMode from "../../../../Utils/useWindowDimensions";
import LayoutMagazine from "../Components/LayoutMagazine";
import MediaAndTextArticle from "./MediaAndTextArticle";
import SelectedArticleGalleryItem from "./SelectedArticleGalleryItem";
import ArticleMedia from "../Components/ArticleMedia";

const SelectedArticles: React.FC<ISelectedArticles> = (props) => {
  const { articlesWithMedia, title, anchor } = props;
  const [_articleOnModal, _setArticleOnModal] = useState<
    IArticle | IArticleWithMedia
  >();
  const deviceMode = useDeviceMode();
  const articleMedia = articlesWithMedia[0];
  const articlesMedia = articlesWithMedia.slice(1);
  return (
    <LayoutMagazine
      title={title}
      anchor={anchor}
      modalData={_articleOnModal}
      onCloseModal={() => _setArticleOnModal(undefined)}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: "4rem",
        }}
      >
        <MediaAndTextArticle {...articleMedia} />
        {deviceMode === StyleMode.mobile ? (
          <div style={{ paddingInlineStart: "2rem" }}>
            <Swiper
              slidesPerView={deviceMode === StyleMode.desktop ? 3 : 1.5}
              spaceBetween={deviceMode === StyleMode.desktop ? 120 : 30}
              navigation={false}
              modules={[Navigation]}
              className="mySwiper articles-media"
              role={"region"}
              slideToClickedSlide={true}
            >
              {articlesMedia.map((article, index) => (
                <SwiperSlide key={index}>
                  <SelectedArticleGalleryItem
                    {...article}
                    containerStyles={{ height: "11rem" }}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        ) : (
          <div
            className="articles-media"
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              gap: "2rem",
            }}
          >
            {articlesMedia.map((article, index) => (
              <SelectedArticleGalleryItem
                key={index}
                {...article}
                containerStyles={{ height: "17rem", width: "100%" }}
                videoStyles={{
                  maxWidth: "30rem",
                }}
              />
            ))}
          </div>
        )}
      </div>
    </LayoutMagazine>
  );
};

export default SelectedArticles;
