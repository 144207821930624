import { t } from "i18next";
import { Fragment, useEffect, useRef, useState } from "react";
import ReactModal from "react-modal";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import colors from "../../../Assets/Colors/Colors.json";
import { GetSystemParams } from "../../../Services/GlobalService";
import i18n from "../../../Services/i18n";
import AutoCompleteForm from "../Components/Form/AutoCompleteForm/AutoCompleteForm";
import DatePickerForm from "../Components/Form/DatePickerForm/DatePickerForm";
import InputForm from "../Components/Form/InputForm/InputForm";
import InputTextSearchForm from "../Components/Form/InputTextSearchForm/InputTextSearchForm";
import Tabs from "../Components/Tabs/Tabs";
import AuctionCard from "../Components/UmbracoElements/Auctions/AuctionCards/AuctionCard/AuctionCard";
import AuctionResultCard from "../Components/UmbracoElements/Auctions/AuctionCards/AuctionResultCard/AuctionResultCard";
import CompletedAuctionCard from "../Components/UmbracoElements/Auctions/AuctionCards/CompletedAuctionCard/CompletedAuctionCard";
import ExemptionNoticesCard from "../Components/UmbracoElements/Auctions/AuctionCards/ExemptionNoticesCard/ExemptionNoticesCard";
import SingleSupplierCard from "../Components/UmbracoElements/Auctions/AuctionCards/SingleSupplierCard/SingleSupplierCard";
import SingleSupplierCardMobile from "../Components/UmbracoElements/Auctions/AuctionCards/SingleSupplierCard/SingleSupplierCardMobile";

import QueryString from "qs";
import fonts from "../../../Assets/Fonts/Fonts.json";
import Images from "../../../Data/Images/Images";
import { SearchAndGetAuctionsServer } from "../../../Services/AuctionsFiltersServiceServer";
import {
  FormatDateBasic,
  subtractMonthsFromToday,
} from "../../../Services/DateService";
import {
  GetAuctionsByType,
  GetExemptionNotices,
  GetSingleSupplier,
  PostEmailList,
} from "../../../Services/tenderServerServices";
import { AuctionSteps, StyleMode } from "../../../Utils/consts";
import { GetDynamicTextsDictionary } from "../../../Utils/helpers";
import useDeviceMode from "../../../Utils/useWindowDimensions";
import FilterAuction from "../Components/Auctions/FilterAuction";
import AutoCompleteFormTypes from "../Components/Form/AutoCompleteForm/AutoCompleteFormTypes";
import Loading from "../Components/Loading/Loading";
import PaginationDots from "../Components/Pagination/PaginationDots";
import AuctionCardMobile from "../Components/UmbracoElements/Auctions/AuctionCards/AuctionCard/AuctionCardMobile";
import AuctionResultCardMobile from "../Components/UmbracoElements/Auctions/AuctionCards/AuctionResultCard/AuctionResultCardMobile";
import CompletedAuctionCardMobile from "../Components/UmbracoElements/Auctions/AuctionCards/CompletedAuctionCard/CompletedAuctionCardMobile";
import ExemptionNoticesCardMobile from "../Components/UmbracoElements/Auctions/AuctionCards/ExemptionNoticesCard/ExemptionNoticesCardMobile";
import Title from "../Components/UmbracoElements/Title";
import {
  IAuctionSystem,
  IAuctionsSystemPage,
} from "../Interfaces_tenders/IAuctionsSystem";
import {
  IAuctionsSystemAuctions,
  IAuctionsSystemDomains,
  IAuctionsSystemExemptionNotices,
  IAuctionsSystemSingleSupplier,
  IAuctionsSystemTypes,
  IAuctionsSystemTypesAndDomains,
  IAuctionsSystemTypesAndTenderTypes,
  IAuctionsTenderTypes,
} from "../Interfaces_tenders/IAuctionsSystemDetails";
import { ITab } from "../Interfaces_tenders/ITabs";

const AuctionsSystem: React.FC<IAuctionsSystemPage> = (props) => {
  const location = useLocation();
  const deviceMode = useDeviceMode();
  const inputRef = useRef<HTMLInputElement>(null);
  const clearTextRef = useRef<any>(null);
  const containerAuctiondsRef = useRef<any>(null);
  const { step, auctionsExtrenal, auctionsSystem, auctionsSystemServer } =
    props;
  const currentPage = useSelector((state: any) => state.PageReducer).page;

  const [auctionSystemServerDetails, _setauctionSystemServerDetails] =
    useState(auctionsSystemServer);

  const [_availableDomains, _setAvailableDomains] =
    useState<IAuctionsSystemTypesAndDomains[]>();

  const [_availableTypes, _setAvailableTypes] =
    useState<IAuctionsSystemTypesAndTenderTypes[]>();

  const _currentTypes = useSelector((state: any) => state.TendersReducer)
    .tenderType as IAuctionsSystemTypes[];

  const errorDisplay = useSelector((state: any) => state.AuctionsErrorStatus);

  const errorDisplayUpdateMe = useSelector(
    (state: any) => state.AuctionsErrorStatusUpdateClientNewTenders
  );

  const auctionSystem = auctionsSystem.auctionsSystem[0] as IAuctionSystem;

  const [_loader, _setLoader] = useState(true);
  const [_fakeLoader, _setFakeLoader] = useState(true);

  const [_fakeLoaderFlag, _setFakeLoaderFlag] = useState(true);
  const [_emailAdressValue, _setEmailAdressValue] = useState<string>("");
  const [_filed, _setFiled] = useState<number>(0);
  const [_nameAdressValue, _setNameAdressValue] = useState<string>("");

  const [_placeHolderFrom, _setPlaceHolderFrom] = useState(true);
  const [_placeHolderTo, _setPlaceHolderTo] = useState(true);
  const HomeReducer = useSelector((state: any) => state.HomeReducer);
  const isIos =
    /^((?!chrome|android).)*safari/i.test(navigator.userAgent) &&
    deviceMode === "MOBILE";
  const validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  useEffect(() => {
    if (i18n.language === "he") {
      auctionsSystemServer &&
        _setauctionSystemServerDetails(
          auctionsSystemServer
            .filter(
              (auction) =>
                "" === auction.language || auction.language === i18n.language
            )
            .filter((x: any) =>
              x.category !== 0
                ? x.category ===
                _currentTypes.filter(
                  (x: any) =>
                    x.name.toLowerCase() === currentPage.toLowerCase()
                )[0].id
                : x.category === 0
            )
        );
    } else {
      auctionsSystemServer &&
        _setauctionSystemServerDetails(
          auctionsSystemServer
            .filter((auction) => auction.language === i18n.language)
            .filter((x: any) =>
              x.category !== 0
                ? x.category ===
                _currentTypes.filter(
                  (x: any) =>
                    x.name.toLowerCase() === currentPage.toLowerCase()
                )[0].id
                : x.category === 0
            )
        );
    }
  }, [_currentTypes, auctionsSystemServer]);

  const dynamicTextsDict = [
    GetDynamicTextsDictionary(auctionSystem.dynamicTexts),
  ];

  const [_defaultAuctionsServer, _setDefaultAuctionsServer] = useState<
    (
      | IAuctionsSystemAuctions
      | IAuctionsSystemSingleSupplier
      | IAuctionsSystemExemptionNotices
    )[]
  >([]);
  const [_selectedAuctionsServer, _setSelectedAuctionsServer] = useState<
    (
      | IAuctionsSystemAuctions
      | IAuctionsSystemSingleSupplier
      | IAuctionsSystemExemptionNotices
    )[]
  >([]);

  const [_fromDate, _setFromDate] = useState<Date>(new Date());
  const [_toDate, _setToDate] = useState<Date>(new Date());
  const [_domain, _setDomain] = useState<string>("");
  const [_type, _setType] = useState<string>("");
  const [_searchValue, _setSearchValue] = useState<string>("");
  const [_mobileFilterFields, _setMobileFilterFields] = useState(true);
  const [_currentPage, _setCurrentPage] = useState(1);
  const [_auctionsPerPage, _setAuctionsPerPage] = useState(12);
  const [_indexOfLastAuction, _setIndexOfLastAuction] = useState(
    _currentPage * _auctionsPerPage
  );
  const [_indexOfFirstAuction, _setIndexOfFirstAuction] = useState(
    _indexOfLastAuction - _auctionsPerPage
  );
  const [_currentAuctions, _setCurrentAuctions] = useState(
    _selectedAuctionsServer.slice(_indexOfFirstAuction, _indexOfLastAuction)
  );
  const [_isUpdateMeModalOpen, _setIsUpdateMeModalOpen] = useState(false);

  const [_auctionsStep, _setAuctionsStep] = useState("");
  const [_closeTenderRange, _setCloseTenderRange] = useState("");
  const [_keyDomains, _setKeyDomains] = useState(2);
  const [_keyTypes, _setKeyTypes] = useState(1);

  const [_mobileFilterIsOpen, _setMobileFilterIsOpen] = useState(false);
  const [_curDisplayContentGot, _setCurDisplayContentGot] = useState(false);

  const [_areAuctionsFiltered, _setAreAuctionsFiltered] = useState(false);

  const [_mailingFormDisplay, _setMailingFormDisplay] = useState(true);

  const [_currentDomains, _setCurrentDomains] = useState<
    IAuctionsSystemTypesAndDomains[]
  >([]);
  const [_allDomainsMerge, _setAllDomainsMerge] = useState<
    IAuctionsSystemTypesAndDomains[]
  >([]);

  const [_currentTypesDropDown, _setCurrentTypesDropDown] = useState<
    IAuctionsSystemTypesAndTenderTypes[]
  >([]);

  const [_defaultSelectedDomain, _setDefaultSelectedDomain] = useState<string>("");
  const [_domainDefaultIndicator, _setDomainDefaultIndicator] = useState<boolean>(false);

  const navigate = useNavigate();

  const [_tabs, _setTabs] = useState<ITab[]>([
    {
      name: dynamicTextsDict[0][AuctionSteps.openAuctions].text,
      id: AuctionSteps.openAuctions,
      isSelected: false,
      index: 0,
    },
    {
      name: dynamicTextsDict[0][AuctionSteps.singleSupplier].text,
      id: AuctionSteps.singleSupplier,
      isSelected: false,
      index: 1,
    },
    {
      name: dynamicTextsDict[0][AuctionSteps.exemptionsNotices].text,
      id: AuctionSteps.exemptionsNotices,
      isSelected: false,
      index: 2,
    },
    {
      name: dynamicTextsDict[0][AuctionSteps.closedAuctions].text,
      id: AuctionSteps.closedAuctions,
      isSelected: false,
      index: 3,
    },
    // {
    //   name: dynamicTextsDict[0][AuctionSteps.auctionResults].text,
    //   id: AuctionSteps.auctionResults,
    //   isSelected: false,
    //   index: 4,
    // },
  ]);

  const [_currentSingleSupplierServer, _setCurrentSingleSupplierServer] =
    useState<IAuctionsSystemSingleSupplier[]>();
  const [_currentExemptionNoticesServer, setCurrentExemptionNoticesServer] =
    useState<IAuctionsSystemExemptionNotices[]>();

  const [_currentOpenAuctionsServer, setCurrentOpenAuctionsServer] =
    useState<IAuctionsSystemAuctions[]>();

  const [_currentCloseAuctionsServer, setCurrentCloseAuctionsServer] =
    useState<IAuctionsSystemAuctions[]>();

  const [_currentResultAuctionsServer, setCurrentResultAuctionsServer] =
    useState<IAuctionsSystemAuctions[]>();

  useEffect(() => {
    if (_selectedAuctionsServer.length > 0) {
      _setCurDisplayContentGot(true);
      clearTimeout(timer);
      timer = null;
      _setLoader(false);
    }
  }, [_selectedAuctionsServer.length]);

  useEffect(() => {
    let isMounted = true;
    (async () => {
      const systemParams = await GetSystemParams();

      if (systemParams.length > 0) {
        const closeTenderRange = systemParams.find(
          (systemParam: { key: string }) =>
            systemParam.key === "CloseTenderRange"
        );

        // if (closeTenderRange !== undefined) {
        closeTenderRange && _setCloseTenderRange(closeTenderRange.value);
        // if (CloseTenderRange.value === "true") {
        //   if (isMounted) {
        //     _setIsMaintenance(true);
        //   }
        // }
        // }
      }
    })();
    return () => {
      isMounted = false;
    };
  }, []);

  /**open */
  useEffect(() => {
    if (
      step === "openauctions" &&
      !_currentOpenAuctionsServer &&
      _currentTypes.length > 0
    ) {
      (async () => {
        const openAuctionsMainDetails = await GetAuctionsByType(1);

        if (openAuctionsMainDetails) {
          const auctionsData: IAuctionsSystemAuctions[] =
            openAuctionsMainDetails;

          const currentOpenAuctionsMainDetails: IAuctionsSystemAuctions[] =
            auctionsData // nedd to change to ->>> auctionsData
              .filter((auction) =>
                i18n.language === "he"
                  ? "" === auction.language ||
                  auction.language === i18n.language
                  : auction.language === i18n.language
              )
              .filter(
                (auction) =>
                  FormatDateBasic(new Date()) >=
                  FormatDateBasic(auction.publishDate)
              )
              .map((auction) => ({
                ...auction,
                auctionSystem: auctionSystem,
                page: currentPage,
              }));

          const currentcategoryDetails: IAuctionsSystemAuctions[] =
            _currentTypes &&
            currentOpenAuctionsMainDetails.filter((x: any) =>
              x.category !== 0
                ? x.category ===
                _currentTypes.filter(
                  (x: any) =>
                    x.name.toLowerCase() === currentPage.toLowerCase()
                )[0].id
                : x.category === 0
            );

          setCurrentOpenAuctionsServer(currentcategoryDetails);
        }
      })();
    }
  }, [step, _currentTypes.length]);
  /**close */
  useEffect(() => {
    if (
      step === "closedauctions" &&
      !_currentCloseAuctionsServer &&
      _currentTypes.length > 0
    ) {
      (async () => {
        const closeAuctionsMainDetails = await GetAuctionsByType(2);

        if (closeAuctionsMainDetails) {
          const auctionsData: IAuctionsSystemAuctions[] =
            closeAuctionsMainDetails;

          const currentCloseAuctionsMainDetails: IAuctionsSystemAuctions[] =
            auctionsData // nedd to change to ->>> auctionsData
              .filter((auction) =>
                i18n.language === "he"
                  ? "" === auction.language ||
                  auction.language === i18n.language
                  : auction.language === i18n.language
              )
              .map((auction) => ({
                ...auction,
                auctionSystem: auctionSystem,
                page: currentPage,
              }));

          const currentcategoryDetails: IAuctionsSystemAuctions[] =
            _currentTypes &&
            currentCloseAuctionsMainDetails
              .filter(
                (closeTender: IAuctionsSystemAuctions) =>
                  new Date(closeTender.biddingDate) >
                  subtractMonthsFromToday(_closeTenderRange)
              )
              .filter((x: any) =>
                x.category !== 0
                  ? x.category ===
                  _currentTypes.filter(
                    (x: any) =>
                      x.name.toLowerCase() === currentPage.toLowerCase()
                  )[0].id
                  : x.category === 0
              );

          setCurrentCloseAuctionsServer(currentcategoryDetails);
        }
      })();
    }
  }, [step, _currentTypes.length]);
  /**result */
  useEffect(() => {
    if (
      step === "auctionresults" &&
      !_currentResultAuctionsServer &&
      _currentTypes.length > 0
    ) {
      (async () => {
        const resultAuctionsMainDetails = await GetAuctionsByType(3);

        if (resultAuctionsMainDetails) {
          const auctionsData: IAuctionsSystemAuctions[] =
            resultAuctionsMainDetails;

          const currentResultAuctionsMainDetails: IAuctionsSystemAuctions[] =
            auctionsData // nedd to change to ->>> auctionsData
              .filter((auction) =>
                i18n.language === "he"
                  ? "" === auction.language ||
                  auction.language === i18n.language
                  : auction.language === i18n.language
              )
              .map((auction) => ({
                ...auction,
                auctionSystem: auctionSystem,
                page: currentPage,
              }));

          const currentcategoryDetails: IAuctionsSystemAuctions[] =
            _currentTypes &&
            currentResultAuctionsMainDetails.filter((x: any) =>
              x.category !== 0
                ? x.category ===
                _currentTypes.filter(
                  (x: any) =>
                    x.name.toLowerCase() === currentPage.toLowerCase()
                )[0].id
                : x.category === 0
            );

          setCurrentResultAuctionsServer(currentcategoryDetails);
        }
      })();
    }
  }, [step, _currentTypes.length]);

  /**single */
  useEffect(() => {
    if (
      step === "singlesupplier" &&
      !_currentSingleSupplierServer &&
      _currentTypes.length > 0 //&&
    ) {
      (async () => {
        const singleSupplierMainDetails = await GetSingleSupplier();

        if (singleSupplierMainDetails) {
          const auctionsData: IAuctionsSystemSingleSupplier[] =
            singleSupplierMainDetails;

          const currentSingleSupplierMainDetails: IAuctionsSystemSingleSupplier[] =
            auctionsData
              .filter((auction) =>
                i18n.language === "he"
                  ? "" === auction.language ||
                  auction.language === i18n.language
                  : auction.language === i18n.language
              )

              .map((auction) => ({
                ...auction,
              }));

              
          const currentcategoryDetails: IAuctionsSystemSingleSupplier[] =
            _currentTypes &&
            currentSingleSupplierMainDetails.filter((x: any) =>
              x.category !== 0
                ? x.category ===
                _currentTypes.filter(
                  (x: any) =>
                    x.name.toLowerCase() === currentPage.toLowerCase()
                )[0].id
                : x.category === 0
            ); //.filter(tender=>IsDateD1BiggerThenDateD2(_fromDate,new Date(tender.publishDate)));

          _setCurrentSingleSupplierServer(currentcategoryDetails);
        }
      })();
    }
  }, [step, _currentTypes.length]);

  /**notices */
  useEffect(() => {
    if (
      step === "exemptionsnotices" &&
      !_currentExemptionNoticesServer &&
      _currentTypes.length > 0
    ) {
      (async () => {
        const exemptionNoticeMainDetails = await GetExemptionNotices();

        if (exemptionNoticeMainDetails) {
          const auctionsData: IAuctionsSystemExemptionNotices[] =
            exemptionNoticeMainDetails;

          const currentExemptionNoticesMainDetails: IAuctionsSystemExemptionNotices[] =
            auctionsData
              .filter((auction) =>
                i18n.language === "he"
                  ? "" === auction.language ||
                  auction.language === i18n.language
                  : auction.language === i18n.language
              )
              // .filter(
              //   (auction) =>
              //     FormatDateBasic(new Date()) >=
              //     FormatDateBasic(auction.publishDate)
              // )
              .map((auction) => ({
                ...auction,
              }));

              

          const currentcategoryDetails: IAuctionsSystemExemptionNotices[] =
            _currentTypes &&
            currentExemptionNoticesMainDetails.filter((x: any) =>
              x.category !== 0
                ? x.category ===
                _currentTypes.filter(
                  (x: any) =>
                    x.name.toLowerCase() === currentPage.toLowerCase()
                )[0].id
                : x.category === 0
            ); //.filter(tender=>IsDateD1BiggerThenDateD2(_fromDate,new Date(tender.publishDate)));
          setCurrentExemptionNoticesServer(currentcategoryDetails);
        }
      })();
    }
  }, [step, _currentTypes.length]);

  useEffect(() => {
    const urlParams = QueryString.parse(location.search.split("?")[1]);

    const currentUrl = {
      ...urlParams,
      isModalOpen: _isUpdateMeModalOpen.toString(),
    };

    // navigate("?" + QueryString.stringify(currentUrl), { replace: true });
  }, [_isUpdateMeModalOpen]);

  const singleSupplierSystemServerDetails =
    _currentSingleSupplierServer as IAuctionsSystemSingleSupplier[];

  const exemptionsnoticesSystemServerDetails =
    _currentExemptionNoticesServer as IAuctionsSystemExemptionNotices[];

  const openAuctionsSystemServerDetails =
    _currentOpenAuctionsServer as IAuctionsSystemAuctions[];

  const closeAuctionsSystemServerDetails =
    _currentCloseAuctionsServer as IAuctionsSystemAuctions[];

  const resultAuctionsSystemServerDetails =
    _currentResultAuctionsServer as IAuctionsSystemAuctions[];

  useEffect(() => {
    handleUrlParams();
  }, [
    step,
    auctionSystemServerDetails,
    _currentSingleSupplierServer,
    _currentExemptionNoticesServer,
    _currentOpenAuctionsServer,
    _currentCloseAuctionsServer,
    _currentResultAuctionsServer,
  ]);

  useEffect(() => {
    resetPagination();
  }, [_selectedAuctionsServer]);

  useEffect(() => {
    if (_mobileFilterIsOpen) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [_mobileFilterIsOpen]);

  const handleUrlParams = () => {
    handleReset();
    let auctionsServer: (
      | IAuctionsSystemAuctions
      | IAuctionsSystemSingleSupplier
      | IAuctionsSystemExemptionNotices
    )[] = [];
    let tabIndex = 0;
    let stepName = step ? step.toLowerCase() : AuctionSteps.openAuctions;
    switch (stepName) {
      case AuctionSteps.openAuctions:
        if (
          openAuctionsSystemServerDetails &&
          openAuctionsSystemServerDetails.length > 0
        ) {
          auctionsServer = openAuctionsSystemServerDetails.sort(
            (a, b) =>
              new Date(a.biddingDate).getTime() -
              new Date(b.biddingDate).getTime()
          );

          {
            const publishedDates = auctionsServer.map((auction) =>
              new Date(
                (auction as IAuctionsSystemAuctions).publishDate
              ).getTime()
            );

            const minDate =
              publishedDates.length > 0
                ? new Date(
                  Math.min(...publishedDates.filter((date) => date > 0))
                )
                : new Date();
            _setFromDate(minDate);
          }
        }
        tabIndex = 0;

        break;
      case AuctionSteps.singleSupplier:
        if (
          _currentSingleSupplierServer &&
          _currentSingleSupplierServer.length > 0
        ) {
          auctionsServer = singleSupplierSystemServerDetails.sort(
            (a, b) =>
              new Date(b.publishDate).getTime() -
              new Date(a.publishDate).getTime()
          );
          {
            const publishedDates = auctionsServer.map((auction) =>
              new Date(
                (auction as IAuctionsSystemSingleSupplier).publishDate
              ).getTime()
            );
            const minDate =
              publishedDates.length > 0
                ? new Date(
                  Math.min(...publishedDates.filter((date) => date > 0))
                )
                : new Date();
            _setFromDate(minDate);
          }
        }

        tabIndex = 1;
        break;

      case AuctionSteps.exemptionsNotices:
        if (
          _currentExemptionNoticesServer &&
          _currentExemptionNoticesServer.length > 0
        ) {
          auctionsServer = exemptionsnoticesSystemServerDetails.sort(
            (a, b) =>
              new Date(b.publishDate).getTime() -
              new Date(a.publishDate).getTime()
          );

          {
            const publishedDates = auctionsServer.map((auction) =>
              new Date(
                (auction as IAuctionsSystemExemptionNotices).publishDate
              ).getTime()
            );
            const minDate =
              publishedDates.length > 0
                ? new Date(
                  Math.min(...publishedDates.filter((date) => date > 0))
                )
                : new Date();
            _setFromDate(minDate);
          }
        }
        tabIndex = 2;
        break;
      case AuctionSteps.closedAuctions:
        if (
          closeAuctionsSystemServerDetails &&
          closeAuctionsSystemServerDetails.length > 0
        ) {
          auctionsServer = closeAuctionsSystemServerDetails.sort(
            (a, b) =>
              new Date(a.biddingDate).getTime() -
              new Date(b.biddingDate).getTime()
          );

          {
            const publishedDates = auctionsServer.map((auction) =>
              new Date(
                (auction as IAuctionsSystemAuctions).publishDate
              ).getTime()
            );
            const minDate =
              publishedDates.length > 0
                ? new Date(
                  Math.min(...publishedDates.filter((date) => date > 0))
                )
                : new Date();
            _setFromDate(minDate);
          }
        }
        tabIndex = 3;
        break;
      case AuctionSteps.auctionResults:
        if (
          resultAuctionsSystemServerDetails &&
          resultAuctionsSystemServerDetails.length > 0
        ) {
          auctionsServer = resultAuctionsSystemServerDetails.sort(
            (a, b) =>
              new Date(b.biddingDate).getTime() -
              new Date(a.biddingDate).getTime()
          );

          {
            const publishedDates = auctionsServer.map((auction) =>
              new Date(
                (auction as IAuctionsSystemAuctions).publishDate
              ).getTime()
            );
            const minDate =
              publishedDates.length > 0
                ? new Date(
                  Math.min(...publishedDates.filter((date) => date > 0))
                )
                : new Date();
            _setFromDate(minDate);
          }
        }
        tabIndex = 4;
        break;
      default:
        stepName = AuctionSteps.openAuctions;
        tabIndex = 0;
    }

    _setAuctionsStep(stepName);
    const allTabs = [
      ..._tabs.map((tab) => {
        return {
          ...tab,
          isSelected: _tabs[tabIndex].id === tab.id,
        };
      }),
    ];
    _setTabs(allTabs);
    _setAreAuctionsFiltered(false);

    _setDefaultAuctionsServer(auctionsServer);
    _setSelectedAuctionsServer(auctionsServer);
  };

  useEffect(() => {
    if (_currentTypes.length > 0 && auctionSystem) {
      const allDomains: IAuctionsSystemTypes[] = _currentTypes.filter(
        (curType: IAuctionsSystemTypes) => curType.type === 1
      );

      const umbracoDomains: IAuctionsSystemDomains[] =
        auctionSystem.domainOptions;

      let merged: IAuctionsSystemTypesAndDomains[] = [];

      for (let i = 0; i < allDomains.length; i++) {
        const curDomain = umbracoDomains.find(
          (umbracoDomain) => umbracoDomain.domainKey === allDomains[i].name
        );

        if (curDomain) {
          const mergedDomains = {
            domainKey: curDomain.domainKey,
            domainValue: curDomain.domainValue,
            id: allDomains[i].id,
            type: allDomains[i].type,
            name: allDomains[i].name,
          };

          merged.push(mergedDomains);
        }
      }
      const allDomainsMerge: any[] = allDomains.map((item) => {
        const curDomain = umbracoDomains.find(
          (umbracoDomain) => umbracoDomain.domainKey === item.name
        );
        return ({ ...curDomain, ...item })
      })
      _setAvailableDomains(merged);

      if (_availableDomains) {
        const mergedDomains: IAuctionsSystemTypesAndDomains[] =
          _availableDomains;
        let domainsArray: IAuctionsSystemTypesAndDomains[] = [];
        const currentDomains = _defaultAuctionsServer.map((x) => x.filed);

        for (let i = 0; i < mergedDomains.length; i++) {
          for (let j = 0; j < currentDomains.length; j++) {
            if (mergedDomains[i].id === currentDomains[j]) {
              if (!domainsArray.includes(mergedDomains[i])) {
                domainsArray.push(mergedDomains[i]);
              }
            }
          }
        }


        domainsArray.filter(
          (domain) =>
            domain.domainKey &&
            domain.domainValue &&
            domain.id &&
            domain.name &&
            domain.type
        );

        _setCurrentDomains(domainsArray);

        _setKeyDomains(Math.random() * (100 - 1) + 1);
      }
      _setAllDomainsMerge(allDomainsMerge);
    }
  }, [_currentTypes, _defaultAuctionsServer, auctionSystem]);


  useEffect(() => {

    const urlSearch = QueryString.parse(location.search.split("?")[1]);
    if (urlSearch.domain) {
      const currentSelectedDomain = [..._currentDomains].find(domain => domain.domainKey === urlSearch.domain);
      const domainsAreas = document.getElementById("domainsAreas") as HTMLInputElement;
      if (currentSelectedDomain && domainsAreas) {
        _setDefaultSelectedDomain(currentSelectedDomain.domainValue);
        _setDomain(currentSelectedDomain.domainValue);
        _setDomainDefaultIndicator(true);
      }
    } else {
      _setDomain("");
      _setDefaultSelectedDomain("");
    }

  }, [_currentDomains])

  useEffect(() => {
    if (_domain && _domainDefaultIndicator) {
      handleSearchButtonClickServer();
    }
  }, [_domainDefaultIndicator, _domain])

  useEffect(() => {
    if (_currentTypes.length > 0) {
      const allTypes: IAuctionsSystemTypes[] = _currentTypes.filter(
        (x: IAuctionsSystemTypes) => x.type === 2
      );

      const umbracoTypes: IAuctionsTenderTypes[] = auctionSystem.typeOptions;

      let merged: IAuctionsSystemTypesAndTenderTypes[] = [];

      for (let i = 0; i < allTypes.length; i++) {
        const curType = umbracoTypes.find(
          (umbracoType) => umbracoType.typeKey === allTypes[i].name
        );

        if (curType) {
          const mergedTypes = {
            typeKey: curType.typeKey,
            typeValue: curType.typeValue,
            id: allTypes[i].id,
            type: allTypes[i].type,
            name: allTypes[i].name,
          };

          merged.push(mergedTypes);
        }
      }
      _setAvailableTypes(merged);

      if (_availableTypes) {
        const mergedTypes: IAuctionsSystemTypesAndTenderTypes[] =
          _availableTypes;

        let typesArray: IAuctionsSystemTypesAndTenderTypes[] = [];
        const currentTypes = _defaultAuctionsServer.map((x) => x.type);

        for (let i = 0; i < mergedTypes.length; i++) {
          for (let j = 0; j < currentTypes.length; j++) {
            if (mergedTypes[i].id === currentTypes[j]) {
              if (!typesArray.includes(mergedTypes[i])) {
                typesArray.push(mergedTypes[i]);
              }
            }
          }
        }

        _setCurrentTypesDropDown(
          typesArray.filter(
            (type) =>
              type.typeKey &&
              type.typeValue &&
              type.id &&
              type.name &&
              type.type
          )
        );
        _setKeyTypes(Math.random() * -(200 - 101) + 101);
      }
    }
  }, [_currentTypes, _defaultAuctionsServer, auctionSystem]);

  const handleDateSelected = (selectedDate: Date, dateType: string) => {
    switch (dateType) {
      case "fromDate":
        _setFromDate(selectedDate);
        _setPlaceHolderFrom(false);
        break;
      case "toDate":
        _setToDate(selectedDate);
        _setPlaceHolderTo(false);
        break;
    }
  };

  const handleTabClick = (selectedTab: ITab) => {
    // Store.dispatch(setErrorStatus(false));
    navigate(`?page=${currentPage}&step=${selectedTab.id}`, { replace: true });
  };

  const handleSearchButtonClickServer = () => {
    _setFakeLoader(true);
    _setFakeLoaderFlag(!_fakeLoaderFlag);

    if (_currentTypes.length > 0 && auctionSystem) {
      const searchedAuctions = SearchAndGetAuctionsServer({
        currentTypes: _currentTypes,
        currentAuctions: auctionSystemServerDetails,
        currentOpenAuctions: _currentOpenAuctionsServer,
        currentCloseAuctions: _currentCloseAuctionsServer,
        currentResultAuctions: _currentResultAuctionsServer,
        currentSingleSuppliers: _currentSingleSupplierServer,
        currentExemptionNotices: _currentExemptionNoticesServer,
        searchValue: _searchValue,
        selectedDomain: _domain,
        selectedType: _type,
        fromDate: _fromDate,
        toDate: _toDate,
        typeOfContent: _auctionsStep,
      });
      searchedAuctions &&
        searchedAuctions.length < _selectedAuctionsServer.length
        ? _setAreAuctionsFiltered(true)
        : _setAreAuctionsFiltered(false);

      if (searchedAuctions) _setSelectedAuctionsServer(searchedAuctions);

      if (deviceMode === StyleMode.mobile) _setMobileFilterIsOpen(false);

      setTimeout(() => {
        _setDomainDefaultIndicator(false);
      }, 1000);
    }
  };

  const handleReset = () => {
    _setSearchValue("");
    _setDomain("");
    _setType("");

    if (deviceMode !== StyleMode.mobile) {
      clearTextRef.current && clearTextRef.current.clearText();

      const domainsAreasInput: HTMLInputElement = document.getElementById(
        "domainsAreas"
      ) as HTMLInputElement;

      if (domainsAreasInput) {
        domainsAreasInput.value = "";
      }

      const typeTenderAreasInput: HTMLInputElement = document.getElementById(
        "typeTenderAreas"
      ) as HTMLInputElement;

      if (typeTenderAreasInput) {
        typeTenderAreasInput.value = "";
      }
    } else {
      if (_mobileFilterIsOpen) {
        clearTextRef.current.clearText();
        const domainsAreasInput: HTMLInputElement = document.getElementById(
          "domainsAreas"
        ) as HTMLInputElement;

        if (domainsAreasInput) {
          domainsAreasInput.value = "";
        }

        const typeTenderAreasInput: HTMLInputElement = document.getElementById(
          "typeTenderAreas"
        ) as HTMLInputElement;

        if (typeTenderAreasInput) {
          typeTenderAreasInput.value = "";
        }
      }
    }
    _setToDate(new Date());
    _setPlaceHolderFrom(true);
    _setPlaceHolderTo(true);
  };

  const resetPagination = () => {
    const selectedPage = 1;
    const indexLastAuction = selectedPage * _auctionsPerPage;
    const indexFirstAuction = indexLastAuction - _auctionsPerPage;
    _setIndexOfLastAuction(indexLastAuction);
    _setIndexOfFirstAuction(indexFirstAuction);
    _setCurrentAuctions(
      _selectedAuctionsServer.slice(indexFirstAuction, indexLastAuction)
    );
    _setCurrentPage(selectedPage);
  };

  const handlePagination = (selectedPage: number) => {
    const indexLastAuction = selectedPage * _auctionsPerPage;
    const indexFirstAuction = indexLastAuction - _auctionsPerPage;

    _setIndexOfLastAuction(indexLastAuction);
    _setIndexOfFirstAuction(indexFirstAuction);
    _setCurrentAuctions(
      _selectedAuctionsServer.slice(indexFirstAuction, indexLastAuction)
    );
    _setCurrentPage(selectedPage);
    containerAuctiondsRef.current.focus();
    const mainScroll = document.getElementById('mainScroll');
    mainScroll && mainScroll.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleMobileFilterToggle = () => {
    _setMobileFilterIsOpen(!_mobileFilterIsOpen);
  };
  const [_isHamburgerOpen, _setIsHamburgerOpen] = useState(false);
  const [_menuClicked, _setMenuClicked] = useState(false);



  let timer: any = null;
  let timerFakeLoader: any = null;

  useEffect(() => {
    timer = setTimeout(function () {
      _setLoader(false);
    }, 8000);

    return () => {
      clearTimeout(timer);
    };
  }, [step, _selectedAuctionsServer.length]);

  useEffect(() => {
    timerFakeLoader = setTimeout(function () {
      _setFakeLoader(false);
    }, 1000);

    return () => {
      clearTimeout(timerFakeLoader);
    };
  }, [_fakeLoaderFlag]);

  useEffect(() => {
    if (_selectedAuctionsServer && _selectedAuctionsServer.length === 0) {
      _setLoader(true);
      //clearTimeout(timer);
    }
  }, [step, _selectedAuctionsServer]);

  const sendEmailToMailingListHandler = async () => {
    const pageInfo = _currentTypes.filter((item) => item.type === 3).find((item) =>
      item.name.toLowerCase() === currentPage?.toLowerCase());
    const stepInfo = _currentTypes.filter((item) => item.type === 4).find((item) =>
      item.name.toLowerCase() === step?.toLowerCase());
    const filed = (HomeReducer.systemParamsKey["hideSelectDomainMailing"] && HomeReducer.systemParamsKey["hideSelectDomainMailing"].value !== "true") ? _filed === 0 ? undefined : _filed : 0
    const postEmailAdress = await PostEmailList({
      mails: _emailAdressValue,
      name: _nameAdressValue,
      tendersType: true,
      domain: filed,
      page: pageInfo?.id,
      step: stepInfo?.id ,
    });

    if (postEmailAdress.status === 200) {
      _setMailingFormDisplay(false);
    }

  };

  return (
    <Fragment>
      <div style={styles.mainContainer}>
        {deviceMode !== StyleMode.mobile ? (
          <Tabs tabs={_tabs} onTabClick={handleTabClick} />
        ) : (
          <Tabs
            isMobile
            tabs={_tabs && _tabs}
            onTabClick={handleTabClick}
            containerStyle={{
              display: "flex",
              alignItems: "center",
              overflowX: "auto",
              overflowY: "hidden",

              height: "4rem",
              boxShadow:
                "0 0 1px 0 rgba(0, 0, 0, 0.2), 0 0 15px 0 rgba(0, 0, 0, 0.19)",
              gap: "2rem",
              paddingRight: "10%",
              paddingLeft: "10%",
            }}
            tabStyle={{
              cursor: "pointer",
              position: "relative",
              //width: "17rem",
              textAlign: "center",
              whiteSpace: "nowrap",
              //paddingInline:"40px"
            }}
            lineStyle={{
              position: "absolute",
              width: "100%",
              height: ".5rem",
              bottom: "-1.3rem",
              backgroundColor: colors.koromiko,
            }}
            conFixStyle={{
              position: "sticky",
              top: "0",
              zIndex: "1",
              backgroundColor: colors.white,
            }}
            step={step}
          />
        )}
        <div style={styles.body}>
          {errorDisplay && (
            <div style={styles.errorMessage}>{t("errorMessage")}</div>
          )}
          {deviceMode !== StyleMode.mobile && !errorDisplay && (
            <Fragment>
              <div style={styles.formElementsContainer}>
                <InputTextSearchForm
                  placeholder={auctionSystem.searchPart.searchPlaceholder}
                  onChange={(text) => _setSearchValue(text)}
                  inputRef={inputRef}
                  ref={clearTextRef}
                />
                {(_auctionsStep === AuctionSteps.openAuctions ||
                  _auctionsStep === AuctionSteps.closedAuctions) && (
                    <Fragment>
                      <AutoCompleteForm
                        placeholder={auctionSystem.searchPart.domainPlaceholder}
                        items={_currentDomains}
                        onItemClick={(selectedItem) => {
                          _setDomain(selectedItem);
                        }}
                        onChange={(e) => {
                          _setDomain(e.target.value);
                        }}
                        key={_keyDomains}
                        id={"domainsAreas"}
                        defaultSelection={_defaultSelectedDomain}
                      />

                      <AutoCompleteFormTypes
                        placeholder={dynamicTextsDict[0]["auctionType"].text}
                        items={_currentTypesDropDown}
                        onItemClick={(selectedItem) => {
                          _setType(selectedItem);
                        }}
                        onChange={(e) => {
                          _setType(e.target.value);
                        }}
                        key={_keyTypes}
                        id={"typeTenderAreas"}
                      />
                    </Fragment>
                  )}
                <DatePickerForm
                  icon={auctionSystem.searchPart.calenderIcon}
                  placeholder={t("From-post-date")}
                  date={_fromDate}
                  onDateSelected={(selectedDate) => {
                    handleDateSelected(selectedDate, "fromDate");
                  }}
                  key={_fromDate.toLocaleDateString() + "_from"}
                  showPlaceHolder={_placeHolderFrom}
                  maxDate={_toDate}
                />

                <DatePickerForm
                  icon={auctionSystem.searchPart.calenderIcon}
                  placeholder={t("To-post-date")}
                  date={_toDate}
                  onDateSelected={(selectedDate) => {
                    handleDateSelected(selectedDate, "toDate");
                  }}
                  key={_toDate.toLocaleDateString() + "_to"}
                  showPlaceHolder={_placeHolderTo}
                  minDate={_fromDate}
                />
                <div style={styles.searchButtonContainerMobile}>
                  <button
                    style={styles.searchButton}
                    onClick={handleSearchButtonClickServer}
                  >
                    {dynamicTextsDict[0]["search"].text}
                  </button>
                </div>
                <div
                  style={styles.clear}
                  tabIndex={0}
                  onClick={handleUrlParams}
                >
                  {dynamicTextsDict[0]["clear"].text}
                </div>
              </div>
              <div style={styles.infoUpdates}>
                <div style={styles.totalAuctions} tabIndex={0}>
                  {_selectedAuctionsServer.length}{" "}
                  {dynamicTextsDict[0]["auctions"].text}
                </div>
                {
                  <div style={styles.updateMeContainer}>
                    {_auctionsStep === AuctionSteps.openAuctions && (
                      <Fragment>
                        <div style={styles.bellIcon} />
                        <div style={styles.updateMe}>
                          <span
                            tabIndex={0}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              _setIsUpdateMeModalOpen(true);
                            }}
                          >
                            {auctionSystem.searchPart.updateMe}
                          </span>
                        </div>
                      </Fragment>
                    )}
                  </div>
                }
              </div>
            </Fragment>
          )}
          {deviceMode === StyleMode.mobile && !errorDisplay && (
            <Fragment>
              {_mobileFilterIsOpen && (
                <div style={styles.ModalOverlay}>
                  <div style={styles.innerModal}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row-reverse",
                        margin: "1rem",
                        boxSizing: "border-box",
                      }}
                    >
                      <img
                        src={Images.closeButtonMob}
                        onClick={() => {
                          handleMobileFilterToggle();
                        }}
                      />
                      <div
                        style={{
                          position: "absolute",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          top: "-.5rem",
                          left: "0",
                        }}
                      />
                    </div>

                    <Fragment>
                      <div style={styles.containerStyles}>
                        <div style={styles.formElementsContainerMobile}>
                          <div style={styles.mobileTitleAndClear}>
                            <div style={styles.mobileHeaderFIlter}>
                              <Title
                                text={dynamicTextsDict[0]["filterContent"].text}
                                titleStyles={{
                                  display: "flex",
                                  fontSize: "1.5625rem",
                                  fontFamily: fonts.FbReformaMedium,
                                  color: colors.darkBlue,
                                  borderRight: `1px solid ${colors.koromiko}`,
                                  borderRightWidth: ".2rem",
                                  paddingRight: ".3rem",
                                }}
                                isUnderline={false}
                              />
                              <div
                                style={styles.clearMobile}
                                onClick={handleUrlParams}
                              >
                                {dynamicTextsDict[0]["clear"].text}
                              </div>
                            </div>
                          </div>
                          <div style={styles.mobileFilterInputes}>
                            <InputTextSearchForm
                              placeholder={
                                auctionSystem.searchPart.searchPlaceholder
                              }
                              onChange={(text) => _setSearchValue(text)}
                              inputRef={inputRef}
                              ref={clearTextRef}
                            />

                            {(_auctionsStep === AuctionSteps.openAuctions ||
                              _auctionsStep ===
                              AuctionSteps.closedAuctions) && (
                                <Fragment>
                                  <AutoCompleteForm
                                    placeholder={
                                      auctionSystem.searchPart.domainPlaceholder
                                    }
                                    items={_currentDomains}
                                    onItemClick={(selectedItem) => {
                                      _setDomain(selectedItem);
                                    }}
                                    onChange={(e) => {
                                      _setDomain(e.target.value);
                                    }}
                                    key={_keyDomains}
                                    id={"domainsAreas"}
                                    customStyles={{ width: "100%", zIndex: "40" }}
                                  />
                                  <AutoCompleteFormTypes
                                    placeholder={
                                      dynamicTextsDict[0]["auctionType"].text
                                    }
                                    items={_currentTypesDropDown}
                                    onItemClick={(selectedItem) => {
                                      _setType(selectedItem);
                                    }}
                                    onChange={(e) => {
                                      _setType(e.target.value);
                                    }}
                                    key={_keyTypes}
                                    id={"typeTenderAreas"}
                                    customStyles={{ width: "100%" }}
                                  />
                                </Fragment>
                              )}

                            <DatePickerForm
                              icon={auctionSystem.searchPart.calenderIcon}
                              placeholder={t("From-post-date")}
                              date={_fromDate}
                              onDateSelected={(selectedDate) => {
                                handleDateSelected(selectedDate, "fromDate");
                              }}
                              key={_fromDate.toLocaleDateString() + "_from"}
                              noMinDate={true}
                              customStyles={{ width: "100%" }}
                              showPlaceHolder={_placeHolderFrom}
                              maxDate={_toDate}
                              step={step}
                            />
                            <DatePickerForm
                              icon={auctionSystem.searchPart.calenderIcon}
                              placeholder={t("To-post-date")}
                              date={_toDate}
                              onDateSelected={(selectedDate) => {
                                handleDateSelected(selectedDate, "toDate");
                              }}
                              key={_toDate.toLocaleDateString() + "_to"}
                              noMinDate={true}
                              customStyles={{ width: "100%" }}
                              showPlaceHolder={_placeHolderTo}
                              minDate={_fromDate}
                              step={step && step}
                            />
                          </div>
                          <div style={styles.searchButtonMobileContainer}>
                            <button
                              style={styles.searchButtonMobile}
                              onClick={handleSearchButtonClickServer}
                            >
                              {dynamicTextsDict[0]["filterContent"].text}
                            </button>
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  </div>
                </div>
              )}

              <div style={styles.topInfoMobileContainer}>
                {
                  <div style={styles.updateMeContainer}>
                    {_auctionsStep === AuctionSteps.openAuctions && (
                      <Fragment>
                        <img
                          src={Images.bellIcon}
                          style={{ height: isIos ? "1rem" : "1.3rem" }}
                        />
                        <div
                          style={{ ...styles.updateMe, fontSize: "1.525rem" }}
                        >
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              _setIsUpdateMeModalOpen(true);
                            }}
                          >
                            {auctionSystem.searchPart.updateMe}
                          </span>
                        </div>
                      </Fragment>
                    )}
                  </div>
                }
                <div style={styles.infoUpdates}>
                  <div style={styles.totalAuctions}>
                    {_selectedAuctionsServer.length}{" "}
                    {dynamicTextsDict[0]["auctions"].text}
                  </div>
                  {!_mobileFilterIsOpen && (
                    <FilterAuction
                      areAuctionsFiltered={_areAuctionsFiltered}
                      onMobileFilterToggle={handleMobileFilterToggle}
                      dynamicTextsDict={dynamicTextsDict}
                    />
                  )}
                </div>
              </div>
            </Fragment>
          )}

          {_selectedAuctionsServer && !errorDisplay && _loader && <Loading />}
          {_fakeLoader && !_loader && <Loading />}
          {!_loader &&
            !_fakeLoader &&
            !errorDisplay &&
            _selectedAuctionsServer &&
            _selectedAuctionsServer.length === 0 && (
              <div style={styles.auctionName}>
                {dynamicTextsDict[0]["auctionsNotFound"].text}
              </div>
            )}

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "3rem",
              width: "100%",
            }}
          >
            <div
              style={styles.auctionCards}
              ref={containerAuctiondsRef}
              tabIndex={0}
            >
              {_currentAuctions &&
                _selectedAuctionsServer.length > 0 &&
                _indexOfLastAuction &&
                _auctionsPerPage &&
                _currentAuctions.map((selectedAuction, index) => {
                  switch (_auctionsStep) {
                    case AuctionSteps.openAuctions:
                      return (
                        !_loader &&
                        !_fakeLoader &&
                        (deviceMode !== StyleMode.mobile ? (
                          <AuctionCard
                            key={index}
                            {...(selectedAuction as IAuctionsSystemAuctions)}
                            dynamicTextsDict={dynamicTextsDict}
                            auctionSystem={auctionSystem}
                            currentIndex={
                              _indexOfLastAuction - _auctionsPerPage + index + 1
                            }
                            numOfAuctions={_selectedAuctionsServer.length}
                            page={currentPage}
                          />
                        ) : (
                          <AuctionCardMobile
                            key={index}
                            {...(selectedAuction as IAuctionsSystemAuctions)}
                            dynamicTextsDict={dynamicTextsDict}
                            auctionSystem={auctionSystem}
                            currentIndex={
                              _indexOfLastAuction - _auctionsPerPage + index + 1
                            }
                            numOfAuctions={_selectedAuctionsServer.length}
                            page={currentPage}
                          />
                        ))
                      );

                    case AuctionSteps.closedAuctions:
                      return (
                        !_loader &&
                        !_fakeLoader &&
                        (deviceMode !== StyleMode.mobile ? (
                          <CompletedAuctionCard
                            key={index}
                            {...(selectedAuction as IAuctionsSystemAuctions)}
                            dynamicTextsDict={dynamicTextsDict}
                            currentIndex={
                              _indexOfLastAuction - _auctionsPerPage + index + 1
                            }
                            numOfAuctions={_selectedAuctionsServer.length}
                            page={currentPage}
                          />
                        ) : (
                          <CompletedAuctionCardMobile
                            key={index}
                            {...(selectedAuction as IAuctionsSystemAuctions)}
                            dynamicTextsDict={dynamicTextsDict}
                            currentIndex={
                              _indexOfLastAuction - _auctionsPerPage + index + 1
                            }
                            numOfAuctions={_selectedAuctionsServer.length}
                            page={currentPage}
                          />
                        ))
                      );
                    case AuctionSteps.auctionResults:
                      return (
                        !_loader &&
                        !_fakeLoader &&
                        (deviceMode !== StyleMode.mobile ? (
                          <AuctionResultCard
                            key={index}
                            {...(selectedAuction as IAuctionsSystemAuctions)}
                            dynamicTextsDict={dynamicTextsDict}
                            currentIndex={
                              _indexOfLastAuction - _auctionsPerPage + index + 1
                            }
                            numOfAuctions={_selectedAuctionsServer.length}
                            step={step}
                          />
                        ) : (
                          <AuctionResultCardMobile
                            key={index}
                            {...(selectedAuction as IAuctionsSystemAuctions)}
                            dynamicTextsDict={dynamicTextsDict}
                            currentIndex={
                              _indexOfLastAuction - _auctionsPerPage + index + 1
                            }
                            numOfAuctions={_selectedAuctionsServer.length}
                            step={step}
                          />
                        ))
                      );
                    default:
                      return null;
                  }
                })}

              {_currentAuctions &&
                !_loader &&
                !_fakeLoader &&
                _currentAuctions.map((selectedAuction, index) => {
                  switch (_auctionsStep) {
                    case AuctionSteps.exemptionsNotices:
                      return (
                        !_loader &&
                        !_fakeLoader &&
                        (deviceMode !== StyleMode.mobile ? (
                          <ExemptionNoticesCard
                            key={index}
                            {...(selectedAuction as IAuctionsSystemExemptionNotices)}
                            dynamicTextsDict={dynamicTextsDict}
                            currentIndex={
                              _indexOfLastAuction - _auctionsPerPage + index + 1
                            }
                            numOfAuctions={_selectedAuctionsServer.length}
                          />
                        ) : (
                          <ExemptionNoticesCardMobile
                            key={index}
                            {...(selectedAuction as IAuctionsSystemExemptionNotices)}
                            dynamicTextsDict={dynamicTextsDict}
                            currentIndex={
                              _indexOfLastAuction - _auctionsPerPage + index + 1
                            }
                            numOfAuctions={_selectedAuctionsServer.length}
                          />
                        ))
                      );
                    default:
                      return null;
                  }
                })}

              {_currentAuctions &&
                !_loader &&
                _currentAuctions.map((selectedAuction, index) => {
                  switch (_auctionsStep) {
                    case AuctionSteps.singleSupplier:
                      return (
                        !_loader &&
                        !_fakeLoader &&
                        (deviceMode !== StyleMode.mobile ? (
                          <SingleSupplierCard
                            key={index}
                            {...(selectedAuction as IAuctionsSystemSingleSupplier)}
                            dynamicTextsDict={dynamicTextsDict}
                            currentIndex={
                              _indexOfLastAuction - _auctionsPerPage + index + 1
                            }
                            numOfAuctions={_selectedAuctionsServer.length}
                          />
                        ) : (
                          <SingleSupplierCardMobile
                            key={index}
                            {...(selectedAuction as IAuctionsSystemSingleSupplier)}
                            dynamicTextsDict={dynamicTextsDict}
                            currentIndex={
                              _indexOfLastAuction - _auctionsPerPage + index + 1
                            }
                            numOfAuctions={_selectedAuctionsServer.length}
                          />
                        ))
                      );
                    default:
                      return null;
                  }
                })}
            </div>
            <div>
              {_selectedAuctionsServer.length > 0 &&
                !_loader &&
                !_fakeLoader && (
                  <PaginationDots
                    active={parseInt(_currentPage.toString())}
                    itemsPerPage={_auctionsPerPage}
                    totalItems={_selectedAuctionsServer.length}
                    step={2}
                    onClickHandler={handlePagination}
                  />
                )}
            </div>
          </div>
        </div>
      </div>
      <ReactModal
        isOpen={_isUpdateMeModalOpen}
        ariaHideApp={false}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            width: "85%",
            maxWidth: "42rem",
            bottom: "auto",
            height: "30rem",
            transform: "translate(-50%, -50%)",
            border: "none",
            padding: "none",
            position: "absolute",
            maxHeight: "100%",
            overflow: "visible",
            borderRadius: "13px 13px 288px 288px / 13px 13px 43px 43px",
          },
          overlay: {
            backgroundColor: colors.modal,
            zIndex: "99999",
            overflow: "hidden",
          },
        }}
        parentSelector={() => document.querySelector("#root") || document.body}
        preventScroll={true}
      >
        <div style={styles.updateMeModalIconWrapper}>
          <div style={styles.updateMeModalIconContainer}>
            <div style={{ ...styles.bellBigIcon }} />
          </div>
        </div>
        <img
          role={"button"}
          aria-label={t("closeButtonAria")}
          style={{ ...styles.closeUpdateMeIcon }}
          onClick={() => _setIsUpdateMeModalOpen(false)}
          src={Images.closeBG}
          tabIndex={0}
        />
        <div style={styles.updateMeModalBody}>
          <div style={styles.updateMeModalFormContainer}>
            <div
              style={styles.updateMeModalTitle}
              dangerouslySetInnerHTML={{
                __html: auctionSystem.searchPart.updateMeModalTitle,
              }}
            />
            {_mailingFormDisplay && (
              <InputForm
                placeholder={
                  auctionSystem.searchPart.updateMeModalFirstnamePlaceholder
                }
                onChange={(e) => {
                  _setNameAdressValue(e);
                }}
                type="Text"
              />
            )}
            {_mailingFormDisplay && (
              <InputForm
                placeholder={
                  auctionSystem.searchPart.updateMeModalEmailPlaceholder
                }
                onChange={(e) => {
                  _setEmailAdressValue(e);
                }}
                type="Email"
              />
            )}
            {(HomeReducer.systemParamsKey["hideSelectDomainMailing"] && HomeReducer.systemParamsKey["hideSelectDomainMailing"].value !== "true") && _mailingFormDisplay && (
              <AutoCompleteForm
                placeholder={auctionSystem.searchPart.domainPlaceholder}
                items={_allDomainsMerge}
                width={"100%"}
                onItemClick={(selectedItem) => {
                  const filedId = _allDomainsMerge.find(
                    (item) => item.domainKey === selectedItem
                  )?.id!;
                  _setFiled(filedId!);
                }}
                onChange={(e) => {
                  // _setFiled(e.target.value);
                }}
                key={_keyDomains}
                id={"domainsAreas"}
                isAllExists={true}
              />
            )}
            {_mailingFormDisplay && (
              <div
                style={
                  _emailAdressValue === "" ||
                    !_emailAdressValue.match(validRegex)
                    ? {
                      ...styles.updateMeButton,
                      pointerEvents: "none",
                      opacity: "0.4",
                    }
                    : {
                      ...styles.updateMeButton,
                    }
                }
                tabIndex={0}
                role={"button"}
                onClick={sendEmailToMailingListHandler}
              >
                {auctionSystem.searchPart.updateMeModalButtonText}
              </div>
            )}
            {!_mailingFormDisplay && !errorDisplayUpdateMe && (
              <div style={styles.updateMeModalTitle}>{t("mailingListAdd")}</div>
            )}
            {errorDisplayUpdateMe && (
              <p>{t("errorMessageUpdateClientNewTenders")}</p>
            )}
          </div>
        </div>
      </ReactModal>
    </Fragment>
  );
};

const styles: any = {
  mainContainer: {
    alignItems: "center",
    flexDirection: "column",
    zIndex: "10",
    minHeight: "100vh",
  } as React.CSSProperties,
  cover: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "40vh",
    position: "relative",
  } as React.CSSProperties,
  title: {
    color: colors.white,
    fontSize: "2.8125rem",
    lineHeight: "3rem",
    letterSpacing: "0.05625rem",
    textShadow: `2px 2px ${colors.black}`,
    fontFamily: fonts.FbReformaMedium,
  } as React.CSSProperties,
  breadcrumb: {
    display: "flex",
    color: colors.white,
    fontSize: "1rem",
    letterSpacing: "0.05625rem",
    fontFamily: fonts.FbReformaRegular,
    gap: ".5rem",
  } as React.CSSProperties,
  coverElements: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    right: "15%",
    top: "2rem",
    gap: "1.2rem",
  } as React.CSSProperties,
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "3rem",
    padding: "2rem 0",
    width: "100%",
  } as React.CSSProperties,
  formElementsContainer: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    alignItems: "center",
    width: "80%",
    gap: "2rem",
  } as React.CSSProperties,
  formElementsContainerMobile: {
    display: "flex",
    flexDirection: "column",
    /* justifyContent: "center",
    flexWrap: "wrap",
    alignItems: "center",*/
    height: "100%",
    /* overflowY: "auto",
    overflowX: "hidden",
    alignContent: "start",
    gap: "1.2rem",*/
  } as React.CSSProperties,
  infoUpdates: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    maxWidth: "85rem",
  } as React.CSSProperties,
  totalAuctions: {
    fontSize: "1.375rem",
    color: colors.pasterlGrayDark,
    fontFamily: fonts.FbReformaRegular,
  } as React.CSSProperties,
  updateMeContainer: {
    display: "flex",
    gap: ".5rem",
    alignItems: "center",
  } as React.CSSProperties,
  bellIcon: {
    backgroundImage: `url(${Images.bellIcon})`,
    width: "1.2rem",
    height: "1.2rem",
    backgroundRepeat: "no-repeat",
  } as React.CSSProperties,
  filterIcon: {
    backgroundImage: `url(${Images.closeBG})`,
    width: "1.2rem",
    height: "1.2rem",
    backgroundRepeat: "no-repeat",
  } as React.CSSProperties,
  filterCheckIcon: {
    backgroundImage: `url(${Images.bellIcon})`,
    width: "1.2rem",
    height: "1.2rem",
    backgroundRepeat: "no-repeat",
  } as React.CSSProperties,

  bellBigIcon: {
    backgroundImage: `url(${Images.bellBigIcon})`,
    width: "2rem",
    height: "2rem",
    backgroundRepeat: "round",
  },
  updateMe: {
    fontFamily: fonts.FbReformaRegular,
    //  fontSize: "1.525rem",
    color: "rgb(0, 69, 138)",
  } as React.CSSProperties,
  auctionCards: {
    display: "flex",
    flexWrap: "wrap",
    gap: "4.5rem",
    justifyContent: "center",
  } as React.CSSProperties,
  area: {
    display: "flex",
    alignItems: "center",
    border: `1px solid ${colors.pastelGray}`,
  } as React.CSSProperties,
  searchButton: {
    width: "10rem",
    height: "2.5rem",
    backgroundColor: colors.darkBlue,
    border: `1px solid ${colors.darkBlue}`,
    borderRadius: "1.25rem",
    color: colors.white,
    fontFamily: fonts.FbReformaMedium,
    fontSize: "1rem",
    letterSpacing: "0.029375rem",
    cursor: "pointer",
  } as React.CSSProperties,
  searchButtonMobile: {
    width: "100%",
    height: "2.5rem",
    backgroundColor: colors.darkBlue,
    border: `1px solid ${colors.darkBlue}`,
    borderRadius: "1.25rem",
    color: colors.white,
    fontFamily: fonts.FbReformaMedium,
    fontSize: "1rem",
    letterSpacing: "0.029375rem",
    cursor: "pointer",
  } as React.CSSProperties,

  clear: {
    fontFamily: fonts.FbReformaRegular,
    fontSize: "1rem",
    textDecoration: "underline",
    color: colors.darkCyan,
    cursor: "pointer",
  } as React.CSSProperties,
  clearMobile: {
    fontFamily: fonts.FbReformaRegular,
    fontSize: "1rem",
    textDecoration: "underline",
    color: colors.darkCyan,
    cursor: "pointer",
  } as React.CSSProperties,
  updateMeButton: {
    backgroundColor: colors.darkBlue,
    color: colors.white,
    fontFamily: fonts.FbReformaMedium,
    fontSize: "1.2rem",
    borderRadius: "16rem",
    width: "8rem",
    height: "1.8rem",
    fontWeight: "bold",
    letterSpacing: "0.029375rem",
    textAlign: "center",
    paddingTop: ".5rem",
    cursor: "pointer",
  } as React.CSSProperties,
  closeUpdateMeIcon: {
    position: "absolute",
    /* backgroundImage: `url(${Images.closeBG})`,
    backgroundRepeat: "no-repeat",
    width: "2rem",
    height: "1.5rem",*/
    left: "1rem",
    top: "1rem",
    cursor: "pointer",
    // transform: "scale(1.5)",
  } as React.CSSProperties,
  updateMeModalIconContainer: {
    width: "4rem",
    height: "4rem",
    boxShadow: "0px 0px 10px black",
    borderRadius: "50%",
    backgroundColor: "white",
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  } as React.CSSProperties,
  updateMeModalIconWrapper: {
    display: "flex",
    justifyContent: "center",
    position: "relative",
    top: "-2.5rem",
    zIndex: "9999999",
  } as React.CSSProperties,
  updateMeModalBody: {
    position: "absolute",
    top: "12%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  } as React.CSSProperties,
  updateMeModalFormContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "1.5rem",
  } as React.CSSProperties,
  updateMeModalTitle: {
    fontSize: "1.6rem",
    letterSpacing: "0.035625rem",
    lineHeight: "1.75rem",
    fontFamily: fonts.FbReformaRegular,
    color: colors.darkBlue,
    maxWidth: "22rem",
    textAlign: "center",
  } as React.CSSProperties,

  ModalOverlay: {
    backgroundColor: colors.modal,
    position: "fixed",
    width: "100%",
    height: "100%",
    top: "0",
    zIndex: "999",
  },

  innerModal: {
    position: "absolute",
    width: "100%",
    bottom: "0",
    height: "85%",
  },

  closeModalIcon: {
    backgroundImage: `url(${Images.closeButtonMob})`,
    backgroundRepeat: "no-repeat",
    width: "1.2rem",
    height: "1.2rem",
    cursor: "pointer",
    transform: "scale(1.5)",
    zIndex: "99999999",
  },

  containerStyles: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: colors.white,
    //  lineHeight: "19rem",
    width: "100%",
    // height: `${6 * languageSelection.length}`,
    padding: "2rem",
    boxSizing: "border-box",
    borderRadius: "2.6rem",
    //zoom: "140%",
    height: "100%",
  },
  mobileHeaderFIlter: {
    display: "flex",
    minHeight: "5vh",
    width: "100%",
    justifyContent: "center",
  } as React.CSSProperties,

  topInfoMobileContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    width: "80%",
  } as React.CSSProperties,

  filterIconContainer: {
    display: "flex",
    gap: "0.5rem",
    alignItems: "center",
    color: "rgb(0, 157, 214)",
    fontSize: "1.5rem",
  } as React.CSSProperties,
  auctionName: {
    color: colors.gray,
    fontSize: "1.75rem",
    letterSpacing: "0.039375rem",
    fontFamily: fonts.FbReformaMedium,
    maxWidth: "35rem",
    lineHeight: "2rem",
    padding: "1.45rem",
    overflow: "hidden",
    textOverflow: "ellipsis",
  } as React.CSSProperties,

  mobileTitleAndClear: {
    flex: "2",
  } as React.CSSProperties,

  mobileFilterInputes: {
    flex: "15",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "1rem",
  } as React.CSSProperties,

  searchButtonMobileContainer: {
    flex: "3",
    display: "flex",
    justifyContent: "center",
  } as React.CSSProperties,

  errorMessage: {
    color: colors.red,
    fontSize: "1.75rem",
    letterSpacing: "0.039375rem",
    fontFamily: fonts.FbReformaMedium,
    maxWidth: "35rem",
    lineHeight: "2rem",
    padding: "1.45rem",
  } as React.CSSProperties,
};

export default AuctionsSystem;
