import Tags from "./Tags";
import { StyleSheet } from "../../../../Interfaces/IStyleSheet";
import colors from ".././../../../Assets/Colors/Colors.json";
import fonts from ".././../../../Assets/Fonts/Fonts.json";
import fontSize from ".././../../../Assets/Fonts/FontsSizes.json";
import { ITitleAndDescription } from "../../../../Interfaces/IBaseElement";
import moment from "moment";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const ArticleMediaTitleDescription: React.FC<{
  titleAndDescription: ITitleAndDescription;
  author: string;
  creationDate: Date;
  readingTime: string;
  tags: string[];
  redirectTo?: string;
}> = (props) => {
  const {
    titleAndDescription,
    author,
    creationDate,
    readingTime,
    tags,
    redirectTo,
  } = props;

  // console.log("props", props);

  const [_isHover, _setIsHover] = useState(false);

  const navigate = useNavigate();

  const redirectToPage = (page?: string) => {
    page && navigate(`?page=${page}`);
  };

  return (
    <>
      <div style={styles.tags}>
        <Tags tags={tags} />
      </div>
      <div style={styles.bottomContainer}>
        <span
          onMouseMove={() => _setIsHover(true)}
          onMouseLeave={() => _setIsHover(false)}
          style={{
            ...styles.bottomTitle,
            textDecoration: _isHover ? "underline" : "none",
            cursor: _isHover ? "pointer" : "default",
          }}
          onClick={() => redirectTo && redirectToPage(redirectTo)}
        >
          {titleAndDescription.title}
        </span>
        <span style={styles.bottomText}>
          {author} | {moment(creationDate).format("DD.MM.YY")} | {readingTime}
        </span>
      </div>
    </>
  );
};

const styles: StyleSheet = {
  container: {
    position: "relative",
    width: "100%",
    height: "100%",
    borderRadius: "5px",
  },
  playPauseButton: {
    position: "absolute",
    top: "1rem",
    right: "1rem",
    cursor: "pointer",
  },
  bottomContainer: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    width: "100%",
    minHeight: "6rem",
    bottom: 0,
    left: 0,
    boxSizing: "border-box",
    padding: "1.25rem",
    gap: ".5rem",
  },
  bottomTitle: {
    fontSize: fontSize.SubTitle,
    fontFamily: fonts.FbReformaMedium,
    color: colors.darkBlue,
  },
  bottomText: {
    fontSize: fontSize.Text,
    fontFamily: fonts.FbReformaLight,
    color: colors.nero,
  },
  thumbnail: {
    position: "absolute",
    width: "100%",
    height: "100%",
    left: 0,
    bottom: 0,
    objectFit: "cover",
    borderRadius: "5px",
  },
  tags: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    bottom: "6.5rem",
    right: "1.5rem",
  },
};

export default ArticleMediaTitleDescription;
