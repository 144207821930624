import colors from "../../../Assets/Colors/Colors.json";
import fonts from "../../../Assets/Fonts/Fonts.json";
import fontSize from "../../../Assets/Fonts/FontsSizes.json";
import Images from "../../../Data/Images/Images";
import { IInfrastructureTitleDescriptionModal } from "../../../Interfaces/IInfrastructureTitleDescription";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import i18n from "../../../Services/i18n";
import { StyleMode } from "../../../Utils/consts";
import useDeviceMode from "../../../Utils/useWindowDimensions";
import ContentTabs from "../ContentTab/ContentTabs";

const InfrastructureTitleDescriptionModal: React.FC<
  IInfrastructureTitleDescriptionModal
> = (props) => {
  const { title, subTitle, additionalTitle, icon, contentTabs, onClose } =
    props;
  const deviceMode = useDeviceMode();
  return (
    <div style={styles.bluredBackground} onClick={onClose}>
      <div
        style={{
          ...styles.modalCard,
          width: deviceMode === StyleMode.desktop ? "80%" : "100%",
          borderRadius:
            deviceMode === StyleMode.desktop
              ? "20px 20px 288px 288px / 20px 20px 43px 43px"
              : "20px 20px 0px 0px",

          height: deviceMode === StyleMode.desktop ? "50rem" : "100%",

          position: deviceMode === StyleMode.desktop ? "relative" : "absolute",
          top: deviceMode === StyleMode.desktop ? "unset" : "20%",
          // bottom: "auto",
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <img
          tabIndex={0}
          role="button"
          src={
            deviceMode === StyleMode.desktop
              ? Images.close
              : Images.closeButtonMob
          }
          style={{
            ...styles.close,
            left: i18n.dir() === "rtl" ? "1rem" : "unset",
            right: i18n.dir() === "ltr" ? "1rem" : "unset",
            top: deviceMode === StyleMode.desktop ? "1rem" : "-3rem",
          }}
          onClick={onClose}
        />
        <div style={styles.circle}>
          <img src={icon._url} />
        </div>
        <h1 style={styles.title}>{title}</h1>
        <h2 style={styles.subTitle}>{subTitle}</h2>
        <h3 style={styles.additionalTitle}>{additionalTitle}</h3>
        <ContentTabs contentTabs={contentTabs} />
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  bluredBackground: {
    position: "fixed",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 15, 30, 0.8146)",
    top: 0,
    left: 0,
    zIndex: 999,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalCard: {
    backgroundColor: colors.white,
    maxWidth: "42.5rem",
    width: "80%",
    height: "50rem",
    position: "relative",
    zIndex: 1000,
    padding: "48px 24px 14px 24px",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    textAlign: "center",
    fontSize: fontSize.UmbracoElementTitle,
    fontWeight: 400,
    lineHeight: "normal",
    letterSpacing: "0.65px",
    fontFamily: fonts.FbReformaMedium,
    marginBlockEnd: 0,
  },
  subTitle: {
    textAlign: "center",
    fontSize: fontSize.Text,
    fontWeight: 400,
    lineHeight: "normal",
    fontFamily: fonts.FbReformaRegular,
    maxWidth: "80%",
  },
  additionalTitle: {
    textAlign: "center",
    fontSize: fontSize.Text,
    fontWeight: 400,
    lineHeight: "normal",
    fontFamily: fonts.FbReformaMedium,
    maxWidth: "80%",
  },
  contentTabs: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  close: {
    top: "1rem",
    position: "absolute",
    cursor: "pointer",
  },
  circle: {
    position: "absolute",
    top: "-2.1rem",
    backgroundColor: colors.white,
    width: "4.375rem",
    height: "4.375rem",
    borderRadius: "63.636px",
    boxShadow: "0px 2px 20px 0px rgba(0, 0, 0, 0.20)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

export default InfrastructureTitleDescriptionModal;
