import {
  ICustomerServiceItem,
  ICustomerServicesHeadline,
} from "../../../Interfaces/ICustomerServices";
import colors from "../../../Assets/Colors/Colors.json";
import CustomerServiceHeadlineItem from "./CustomerServiceHeadlineItem";
import { Fragment, useState } from "react";
import ReactModal from "react-modal";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import useDeviceMode from "../../../Utils/useWindowDimensions";
import { StyleMode } from "../../../Utils/consts";

const CustomerServicesHeadline: React.FC<ICustomerServicesHeadline> = (
  props
) => {
  const { customerServicesHeadLine, anchor } = props;
  const [_isModalOpened, _setIsModalOpened] = useState(false);
  const [
    _selectedCustomerServiceHeadlineItem,
    _setSelectedCustomerServiceHeadlineItem,
  ] = useState<ICustomerServiceItem>();

  const deviceMode = useDeviceMode();

  const handleCustomerServiceSelection = (
    customerServiceHeadlineItem: ICustomerServiceItem
  ) => {
    _setSelectedCustomerServiceHeadlineItem(customerServiceHeadlineItem);

    switch (customerServiceHeadlineItem.openMode) {
      case "Modal":
        _setIsModalOpened(true);
        break;
      case "New Window":
        window.open(customerServiceHeadlineItem.formUrl, "_blank");
        break;
    }
  };
  return (
    <Fragment>
      <div
        anchor-id={anchor}
        style={{
          ...styles.container,
          justifyContent:
            deviceMode === StyleMode.desktop ? props.rowPosition : "",
        }}
      >
        {customerServicesHeadLine.map((customerServiceHeadLine, index) => (
          <CustomerServiceHeadlineItem
            {...customerServiceHeadLine}
            key={index}
            onSelect={handleCustomerServiceSelection}
          />
        ))}
      </div>
      {_selectedCustomerServiceHeadlineItem && (
        <ReactModal
          isOpen={_isModalOpened}
          shouldCloseOnOverlayClick={true}
          onRequestClose={() => _setIsModalOpened(false)}
          style={{
            content: styles.modalContent,
            overlay: {
              backgroundColor: colors.modal,
              zIndex: "99999",
              overflow: "hidden",
            },
          }}
          parentSelector={() =>
            document.querySelector("#root") || document.body
          }
          appElement={document.getElementById("#root") || document.body}
          preventScroll={true}
        >
          <iframe
            src={_selectedCustomerServiceHeadlineItem.formUrl}
            width={"100%"}
            height={"100%"}
          ></iframe>
        </ReactModal>
      )}
    </Fragment>
  );
};

const styles: StyleSheet = {
  container: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    width: "80%",
    maxWidth: "76rem",
    gap: "1rem",
  },
  modalContent: {
    top: "50%",
    left: "50%",
    right: "auto",
    maxWidth: "90.625rem",
    width: "80%",
    bottom: "auto",
    height: "50rem",
    // height: deviceMode === StyleMode.desktop ? "40rem" : "80rem",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "none",
    position: "absolute",
    borderRadius: "0.625rem",
    maxHeight: "100%",
    overflow: "hidden",
  },
};

export default CustomerServicesHeadline;
