import { useEffect, useState } from "react";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import { IFilesElements } from "../../../Interfaces/IUmbraco";
import useDeviceMode from "../../../Utils/useWindowDimensions";
import FileElement from "./FileElement";
import { StyleMode } from "../../../Utils/consts";

const FilesElements: React.FC<IFilesElements> = (props) => {
  const { filesArray, maxElementsInRow, anchor } = props;
  const defaultMaxElementsInRow = 3;

  const [_numOfElements, _setNumOfElements] =
    useState<number>(maxElementsInRow || defaultMaxElementsInRow);
  const [_test, _setTest] = useState<any>();
  const deviceMode = useDeviceMode();
  useEffect(() => {
    deviceMode === StyleMode.mobile && _setNumOfElements(defaultMaxElementsInRow);
  }, [maxElementsInRow]);

  const FilesInRows = () => {
    let rowItems = [];
    for (let i = 0; i < filesArray.length; i += _numOfElements) {
      let row = [];
      row.push(
        filesArray.slice(i, i + _numOfElements).map((file) => {
          return (
            <FileElement
              // key={i + "_" + _numOfElements + "_" + file.openType + "_" + "_" + file.fileName}
              {...file}
            />
          );
        })
      );
      rowItems.push(
        row.map((item, index) => {
          return (
            <div
              key={index}
              style={{ display: "flex", gap: "2rem", flexWrap: "wrap" }}
            >
              {item}
            </div>
          );
        })
      );
    }
    return rowItems;
  };

  return (
    <div style={styles.container} anchor-id={anchor}>
      {FilesInRows()}
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    width: "80%",
    gap: "5rem",
    maxWidth: "76rem",
  },
};

export default FilesElements;
