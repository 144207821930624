import fonts from "../../../../Assets/Fonts/Fonts.json";
import { StyleSheet } from "../../../../Interfaces/IStyleSheet";
import { StyleMode } from "../../../../Utils/consts";
import { RemoveHtmlTags, StripHTML } from "../../../../Utils/helpers";
import useDeviceMode from "../../../../Utils/useWindowDimensions";
import {
  GetLongTextWithDots,
  replaceRichtextBoldWithFont,
} from "../../../../Utils/utils";

const RichDescription: React.FC<{ text: string }> = ({ text }) => {
  const deviceMode = useDeviceMode();
  return (
    <div
      style={{
        ...styles.description,
        maxWidth: deviceMode === StyleMode.desktop ? "20rem" : "",
      }}
      title={text.length > 395 ? StripHTML(text) : undefined}
    >
      <div
        dangerouslySetInnerHTML={{
          __html: GetLongTextWithDots(replaceRichtextBoldWithFont(text), 395),
        }}
        aria-label={RemoveHtmlTags(text)}
      />
    </div>
  );
};
const styles: StyleSheet = {
  description: {
    fontFamily: fonts.FbReformaLight,
    fontSize: "1.2rem",
    lineHeight: 1.5,
  },
};

export default RichDescription;
