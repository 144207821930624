import { useState } from "react";
import ReactModal from "react-modal";
import Images from "../../../../../../../Data/Images/Images";
import { StyleSheet } from "../../../../../Interfaces_tenders/IStyleSheet";
import colors from "../../../../../../../Assets/Colors/Colors.json";
import i18n from "../../../../../../../Services/i18n";
import { IAuctionsSystemWinSupplier } from "../../../../../Interfaces_tenders/IAuctionsSystemDetails";
import { t } from "i18next";
import fonts from "../../../../../../../Assets/Fonts/Fonts.json";

const AuctionSuppliers: React.FC<IAuctionsSystemWinSupplier> = (props) => {
  const { winningSupplierArr, waitingSupplierArr, dynamicTextsDict } = props;

  const waitingSupplierExist =
    waitingSupplierArr && waitingSupplierArr.length > 0;

  const dynamicTexts =
    dynamicTextsDict && dynamicTextsDict.length > 0
      ? dynamicTextsDict[0]
      : undefined;

  const [_isWinSupplierModalOpen, _setIsWinSupplierModalOpen] = useState(false);

  return (
    <div style={styles.suppliersContainer}>
      <div style={styles.referent}>
        {winningSupplierArr.length > 0 &&
          dynamicTexts &&
          dynamicTexts["winSupplierListTitile"].text}
      </div>
      <div style={styles.auctionsWinningContainer}>
        {!waitingSupplierExist &&
          winningSupplierArr.slice(0, 4).map((supplier, index) => (
            <div style={styles.referent} key={index}>
              <div style={styles.referentRow}>
              <img src={Images.person}></img>
                <div style={styles.winSupplierMulti} title={supplier}>
                  {supplier}
                </div>
              </div>
            </div>
          ))}
        {waitingSupplierExist && (
          <div style={{ width: "100%", display: "flex" }}>
            <div style={styles.referent}>
              <div style={styles.referentRow}>
              <img src={Images.person}></img>
                <div>
                  {dynamicTexts && dynamicTexts["winSupplier"].text}:{" "}
                  {winningSupplierArr[0]}
                </div>

                <div></div>
              </div>
            </div>
            <div style={styles.referent}>
              <div style={styles.referentRow}>
              <img src={Images.person}></img>
                <div>
                  {dynamicTexts && dynamicTexts["waitingSupplier"].text}:{" "}
                  {waitingSupplierArr[0]}
                </div>
              </div>
            </div>
          </div>
        )}

        {!waitingSupplierExist && winningSupplierArr.length > 4 && (
          <div style={styles.winSupplier} tabIndex={0}>
            <span
              onClick={() => {
                _setIsWinSupplierModalOpen(true);
              }}
            >
              {dynamicTexts && dynamicTexts["winSupplierList"].text}
            </span>
          </div>
        )}
      </div>

      <ReactModal
        isOpen={_isWinSupplierModalOpen}
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => {
          _setIsWinSupplierModalOpen(false);
        }}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            width: "85%",
            maxWidth: "45rem",
            bottom: "auto",
            transform: "translate(-50%, -50%)",
            border: "none",
            padding: "none",
            position: "absolute",

            overflow: "visible",
            borderRadius: "0.658rem",
          },
          overlay: {
            backgroundColor: colors.modal,
            zIndex: "99999",
            overflow: "hidden",
          },
        }}
        parentSelector={() => document.querySelector("#root") || document.body}
        preventScroll={true}
      >
        <div style={styles.auctionName}>
          {dynamicTexts && dynamicTexts["winSupplierListTitile"].text}
        </div>
        <img
          role={"button"}
          aria-label={t("closeButtonAria")}
          src={Images.closeBG}
          tabIndex={0}
          style={{
            ...styles.closeIcon,
            left: i18n.dir() === "rtl" ? " 0.5rem" : "",
            right: i18n.dir() === "ltr" ? "0.5rem" : "",
          }}
          onClick={() => _setIsWinSupplierModalOpen(false)}
        />
        <div
          style={{
            ...styles.auctionsWinningContainer,
            padding: "1.45rem",
            fontSize: "1.3rem",
          }}
        >
          {winningSupplierArr &&
            !waitingSupplierExist &&
            winningSupplierArr.map((auction, index) => (
              <div style={styles.referent} key={index}>
                <div style={styles.referentRow}>
                <img src={Images.person}></img>
                  <div>{auction}</div>

                  <div></div>
                </div>
              </div>
            ))}
        </div>
      </ReactModal>
    </div>
  );
};

const styles: StyleSheet = {
  referent: {
    display: "flex",
    gap: "1rem",
    width: "50%",
    paddingBottom: "0.5rem",
  },
  referentWaiting: {
    display: "flex",
    gap: "1rem",
    width: "100%",
  },
  referentRow: {
    display: "flex",
    justifyContent: "space-between",
    gap: "0.2rem",
  },
  icon: {
    backgroundRepeat: "no-repeat",
    width: "2rem",
    height: "1.5rem",
  },

  auctionsWinningContainer: {
    display: "flex",
    flexWrap: "wrap",
    // justifyContent: "right",
  } as React.CSSProperties,

  winSupplier: {
    fontFamily: fonts.FbReformaRegular,
    fontSize: "1.125rem",
    color: colors.cyanBold,
    cursor: "pointer",
  } as React.CSSProperties,

  suppliersContainer: {
    height: "5rem",
  },

  auctionName: {
    color: colors.darkBlue,
    fontSize: "1.6rem",
    letterSpacing: "0.039375rem",
    fontFamily: fonts.FbReformaMedium,
    maxWidth: "35rem",
    lineHeight: "2rem",
    padding: "1.45rem",
    overflow: "hidden",
    textOverflow: "ellipsis",

  } as React.CSSProperties,
  closeIcon: {
    position: "absolute",
    /* backgroundImage: `url(${Images.closeBG})`,
    backgroundRepeat: "no-repeat",
    width: "2rem",
    height: "1.5rem",*/
    left: "1rem",
    top: "1rem",
    cursor: "pointer",
    transform: "scale(1.5)",
  } as React.CSSProperties,

  winSupplierMulti: {
    width: "15rem",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  } as React.CSSProperties,
};

export default AuctionSuppliers;
