import colors from "../../Assets/Colors/Colors.json";
import fonts from "../../Assets/Fonts/Fonts.json";
import fontSize from "../../Assets/Fonts/FontsSizes.json";
import {
  IPopMessageBackend,
  TTitlePosition,
} from "../../Interfaces/IPopMessages";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import i18n from "../../Services/i18n";
import { removeAllTagHTML } from "../../Utils/Regex";
import { StyleMode } from "../../Utils/consts";
import useDeviceMode from "../../Utils/useWindowDimensions";

const PopMessageCard: React.FC<IPopMessageBackend> = (props) => {
  const {
    title,
    messageBody,
    supportRichText,
    titlePosition = "center",
  } = props;

  const deviceMode = useDeviceMode();

  const getFinalPosition = (): TTitlePosition => {
    if (!titlePosition) return "center";

    if (i18n.dir() === "rtl") {
      return titlePosition;
    } else if (i18n.dir() === "ltr") {
      if (titlePosition === "start") return "end";
      else if (titlePosition === "end") return "start";
      else return titlePosition;
    }

    return "center";
  };

  const finalPosition = getFinalPosition();

  return (
    <div style={styles.container}>
      <h1
        style={{
          ...styles.header,
          maxWidth:
            finalPosition === "center"
              ? "35rem"
              : finalPosition === "start"
              ? "47rem"
              : "42rem",
          textAlign: finalPosition,
          padding: "0 1rem",
          // justifyContent: finalPosition
        }}
        className="text-wrapper"
      >
        {title}
      </h1>
      <div
        tabIndex={0}
        style={{
          ...styles.contentMessage,
          // padding: deviceMode === StyleMode.desktop ? "0 10%" : "",
          margin: deviceMode !== StyleMode.desktop ? "0 3rem" : "",
          // alignItems: deviceMode === StyleMode.desktop ? "center" : "",
          gap: "2rem",
        }}
        dangerouslySetInnerHTML={{
          __html: supportRichText
            ? messageBody
            : messageBody.replace(removeAllTagHTML, ""),
        }}
      ></div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  header: {
    lineHeight: "2.5rem",
    maxWidth: "47rem",
  },
  titleContainer: {
    display: "flex",
    width: "90%",
  },
  contentMessage: {
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    maxHeight: "20rem",
    // minHeight: "10rem",
    boxSizing: "border-box",
    flex: 1,
    fontSize: "1.2rem",
    lineHeight: "1.8rem",
    width: "90%",
    // alignItems: "center",
    // textAlign: "center",
  },
  finishButton: {
    borderRadius: "3.75rem",
    backgroundColor: colors.darkBlue,
    border: "0.06rem solid",
    fontFamily: fonts.FbReformaMedium,
    fontSize: fontSize.UmbracoElementTitle,
    fontWeight: `${i18n.language === "ar" ? `600` : ``}`,
    textAlign: "center",
    minWidth: "5rem",
    color: colors.white,
    cursor: "pointer",
    flex: 1,
    // marginTop: "5%",
  },
};

export default PopMessageCard;
