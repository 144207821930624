import colors from "../../Assets/Colors/Colors.json";
import fonts from "../../Assets/Fonts/Fonts.json";
import fontSize from "../../Assets/Fonts/FontsSizes.json";
import Images from "../../Data/Images/Images";
import { IStationDetails } from "../../Interfaces/IStationInfo";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import i18n from "../../Services/i18n";

const StationInfoParking: React.FC<IStationDetails> = (props) => {
  const {
    type,
    carParking,
    bikeParking,
    bikeParkingCosts,
    parkingCosts,
    parkingContentTranslationKey,
    parkingTitleTranslationKey,
  } = props;
  let _container =
    type === "desktop" ? styles.container : styles.containerMobile;
  let _stationOptions = {
    ...styles.stationOptions,
    rowGap: type === "desktop" ? "3rem" : "2rem",
  };

  let _title = {
    ...styles.textColor,
    fontSize: type === "desktop" ? fontSize.SubTitle : fontSize.SubTitle,
  };
  let _text = {
    ...styles.text,
    lineHeight: type === "desktop" ? "1.75rem" : "1",
    fontSize: type === "desktop" ? fontSize.Text : fontSize.Text,
  };
  type ITDI = {
    image?: string;
    title: string;
    description: string;
  };
  const ParkingType = ({ image, title, description }: ITDI) => {
    return (
      <div className="row" style={styles.itemDescription}>
        {image && (
          <img
            src={image}
            style={{ width: type === "desktop" ? "3.2rem" : "2.5rem" }}
            alt={``}
            width={`auto`}
            height={`auto`}
          />
        )}
        <div style={{ display: "flex", flexDirection: "column", gap: ".25rem" }}>
          <div style={_title}>{title}</div>
          <div
            style={_text}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>
      </div>
    );
  };
  return (
    <div className="container over-hidden" style={_container}>
      <div style={_stationOptions}>
        {carParking && (
          <ParkingType
            image={Images.parking}
            title={carParking}
            description={parkingCosts}
          />
        )}
        {bikeParking && (
          <ParkingType
            image={Images.bicycle_facilities}
            title={bikeParking}
            description={bikeParkingCosts}
          />
        )}
      </div>
      <div style={{ paddingBlock: "2rem" }}>
        {parkingTitleTranslationKey && (
          <ParkingType
            title={parkingTitleTranslationKey}
            description={parkingContentTranslationKey}
          />
        )}
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  stationOptions: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    columnGap: "1rem",
  },

  itemDescription: {
    flex: "1 1 20rem",
    gap: "1rem",
    alignItems: "flex-start",
  },

  text: {
    fontFamily: fonts.FbReformaLight,
    fontSize: fontSize.FooterItem,
    lineHeight: "1.75rem",
  },

  textColor: {
    fontFamily: fonts.FbReformaMedium,
    fontWeight: `${i18n.language === "ar" ? `600` : ``}`,
    fontSize: fontSize.FooterItem,
    color: colors.darkBlue,
  },
  container: {
    padding: "2rem",
    boxSizing: "border-box",
    width: "100%",
    minHeight: "25rem",
    paddingTop: "3rem",
  },
  containerMobile: {
    padding: "2rem",
    boxSizing: "border-box",
    width: "100%",
  },
};

export default StationInfoParking;
