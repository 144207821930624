import { IArticleWithMedia } from "../../../../Interfaces/IMagazinePage";

const ArticleImage: React.FC<
  IArticleWithMedia & { styles?: React.CSSProperties }
> = (props) => {
  const { media, styles } = props;
  return (
    <img
      src={media._url}
      style={{ width: "100%", height: "100%", objectFit: "cover", ...styles }}
    />
  );
};

export default ArticleImage;
