import TagManager from "react-gtm-module";
import { IDataLayerObject } from "../Interfaces/IGoogleAnalytics";

const defaultDataLayer: IDataLayerObject = {
  event: `gtmEvent`,
  event_name: undefined,
  station_name: undefined,
  type: undefined,
  from_station: undefined,
  to_station: undefined,
  when: undefined,
  departure_time: undefined,
  description: undefined,
  description_2: undefined,
};

export const AddToDataLayer = (dataLayer: IDataLayerObject) => {
  const finalDataLayer = { ...defaultDataLayer, ...dataLayer };
  // console.log("dataLayer", finalDataLayer);
  TagManager.dataLayer({ dataLayer: finalDataLayer });
};
