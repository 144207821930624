import { useEffect, useState } from 'react';
import { GetDeviceMode } from './deviceMode';

export function GetWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export default function useDeviceMode() {
  const [_deviceMode, _setDeviceMode] = useState(GetDeviceMode(GetWindowDimensions().width));

  useEffect(() => {
    function handleResize(e: any) {
      const windowDimensions = GetWindowDimensions();
      const deviceMode = GetDeviceMode(windowDimensions.width);
      _setDeviceMode(deviceMode);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return _deviceMode;
}
