import colors from "../../../../../../Assets/Colors/Colors.json";
import Images from "../../../../../../Data/Images/Images";
import { StyleSheet } from "../../../../Interfaces_tenders/IStyleSheet";
import { FormatDateYYYYMMDD_Dots } from "../../../../../../Services/DateService";
import { IAuctionsSystemMeetings } from "../../../../Interfaces_tenders/IAuctionsSystemDetails";
import { t } from "i18next";
import fonts from "../../../../../../Assets/Fonts/Fonts.json";
import { StyleMode } from "../../../../../../Utils/consts";
import { FormatHoursMinutes } from "../../../../../../Services/DateService";

const MeetingCard: React.FC<IAuctionsSystemMeetings> = (props) => {
  const { meetingsId, meetingDate, registretionLink, location } = props;
  return (
    <div
      tabIndex={0}
      style={{
        ...styles.mainWrapper,
        border: `.1px solid ${colors.pastelGray}`,
        width: StyleMode.desktop ? "100%" : "90%",
      }}
    >
      <div style={styles.header}>
        {
          <div style={styles.title}>
            {t("MeetingOffer")} {meetingsId}
          </div>
        }
      </div>
      <div style={styles.body}>
        <div style={styles.timeWrapper}>
          <div style={styles.dateItem}>
            <img src={Images.datePicker}></img>
            <div style={styles.datePublishContainer}>
              <div>{t("MeetingDate")}</div>
              <div>{FormatDateYYYYMMDD_Dots(new Date(meetingDate))}</div>
              {` - `}
              <div>{FormatHoursMinutes(meetingDate?.toString())}</div>
            </div>
          </div>

          {location && (
            <div style={styles.meetingInfoCon}>
              <div style={styles.meetingInfoTitle}>{t("MeetingInfo")}</div>
              <div style={styles.meetingInfoDescription}>{location}</div>
            </div>
          )}

          {registretionLink && registretionLink.length > 0 ? (
            <div
              tabIndex={0}
              style={styles.requestClarification}
              onClick={() => {
                window.open(`${registretionLink}`, "_blank");
              }}
            >
              {t("MeetingLinkUrl")}
            </div>
          ) : (
            <div
              tabIndex={0}
              style={{ ...styles.meetingInfoTitle, fontWeight: `600` }}
            >
              {t("LinkNotRequierd")}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  mainWrapper: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: colors.white,
    maxWidth: "60rem",

    borderRadius: "0.625rem",
    boxShadow: "0 2px 10px 0 rgba(0, 0, 0, 0.15)",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: `.1rem solid ${colors.pastelGray}`,
    padding: ".5rem",
  },
  title: {
    color: colors.gray,
    fontFamily: fonts.FbReformaRegular,
    fontSize: "1rem",
  },
  body: {
    display: "flex",
    alignItems: "center",
    padding: "1rem",
    gap: "15rem",
  },
  departureArrivalInfo: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    minWidth: "5rem",
  },
  departureArrivalTime: {
    fontSize: "1.8rem",
    fontFamily: fonts.FbReformaMedium,
  },
  timeLengthInfo: {
    display: "flex",
    flexDirection: "column",
    gap: ".5rem",
  },
  totalTime: {
    border: `.1rem solid ${colors.pastelGray}`,
    borderRadius: "2rem",
    padding: "0 1rem",
    minWidth: "4rem",
    textAlign: "center",
    position: "relative",
  },
  displayRoute: {
    display: "flex",
    gap: "1rem",
    color: colors.darkCyan,
    fontWeight: "bold",
    minWidth: "7rem",
  },
  totalChanges: {
    color: colors.gray,
    display: "flex",
    justifyContent: "center",
  },
  timeWrapper: {
    display: "flex",
    gap: "1rem",
    flexDirection: "column",
    width: "100%",
  },
  purchaseTicket: {
    borderRadius: "1rem",
    backgroundColor: colors.darkBlue,
    border: ".1rem solid transparent",
    color: colors.white,
    fontSize: "1rem",
    width: "10rem",
    height: "2.2rem",
    cursor: "pointer",
    fontFamily: fonts.FbReformaMedium,
  },
  platform: {
    color: colors.gray,
    fontFamily: fonts.FbReformaRegular,
  },
  icons: {
    display: "flex",
    marginInlineStart: "1rem",
    marginInlineEnd: "1rem",
  },
  handicapIcon: {
    backgroundImage: `url(${Images.handicap})`,
    backgroundRepeat: "no-repeat",
    width: "1rem",
    height: "1rem",
    transform: "scale(1.5)",
  },
  linearGradient: {
    height: "2px",
    width: "4.5rem",
    top: "50%",
    left: "100%",
    position: "absolute",
    backgroundImage: "linear-gradient(90deg, #cdcdcd, hsla(0,0%,91%,0))",
  },
  purchaseModalIconWrapper: {
    display: "flex",
    justifyContent: "center",
    position: "relative",
    top: "-2.5rem",
    zIndex: "9999999",
  },
  purchaseModalBody: {
    position: "absolute",
    top: "12%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    gap: "1.25rem",
  },

  purchaseModalIconContainer: {
    width: "4.3rem",
    height: "4.3rem",
    boxShadow: "0px 0px 10px black",
    borderRadius: "50%",
    backgroundColor: "white",
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  } as React.CSSProperties,
  purchaseModalTitle: {
    fontSize: "1.5rem",
    lineHeight: "1.5rem",
    maxWidth: "18rem",
    fontFamily: fonts.FbReformaMedium,
    textAlign: "center",
  },
  purchaseOptions: {
    display: "flex",
    gap: "2rem",
  },
  purchaseItem: {
    cursor: "pointer",
  },
  dateItem: {
    display: "flex",
    alignItems: "center",
    /*justifyContent: "center",*/
    color: colors.darkBlue,
    fontSize: "1.2rem",
    gap: "1rem",
    /* flex: 1,*/
  },
  datePublishContainer: {
    display: "flex",
    //flexDirection: "column",
    alignItems: "center",
    gap: "1rem",
  },
  meetingInfo: {
    display: "flex",
    /* alignItems: "center",*/
    /*  justifyContent: "center",*/
    color: colors.darkBlue,
    fontSize: "1.2rem",
    flex: 1,
  },
  meetingInfoCon: {
    /* flex: 4,*/
    display: "flex",
    //flexDirection: "column",
    gap: "1rem",
  },
  icon: {
    backgroundRepeat: "no-repeat",
    width: "2.5rem",
    height: "2.5rem",
  },

  meetingInfoDescription: {
    flex: 3,
    maxWidth:"100%",
    overflow:"auto"
  },
  meetingInfoTitle: {
    color: colors.darkBlue,
    fontSize: "1.2rem",
    gap: "1rem",
  },
  requestClarification: {
    backgroundColor: colors.darkBlue,
    color: colors.white,
    fontSize: "1rem",
    borderRadius: "16rem",
    justifyContent: "center",
    paddingTop: ".5rem",
    alignItems: "center",
    width: "10rem",
    minHeight: "1.8rem",
    textAlign: "center",
    cursor: "pointer",
  },
};

export default MeetingCard;
