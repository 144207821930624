import axios from "axios";
import Config from "../Configuration/server";
import { ILinesMapResponse } from "../Interfaces/ILinesMap";
import { IStationInformation } from "../Interfaces/IStation";
import { setSomethingWentWrong } from "../Redux/Slices/somethingWentWrongSlice";
import Store from "../Redux/Store/Store";
import { headers } from "./GlobalService";
const COMMON = `${Config.BASE_URL}${Config.COMMON}`;

export const GetUpdatesBackend = async (language: string) => {
  try {
    const languages: { [locale: string]: string } = {
      he: "Hebrew",
      en: "English",
      ru: "Russian",
      ar: "Arabic",
    };

    const res = await axios(
      `${COMMON}RailUpdates?LanguageId=${languages[language]}&SystemType=${Config.SYSTEM_TYPE}`,
      { method: "GET", headers }
    );

    // Store.dispatch(setSomethingWentWrong(false));

    return res.data.result;
  } catch (error) {
    Store.dispatch(setSomethingWentWrong(true));
    // console.log(error);
  }
};

export const GetAccurecyIndex = async () => {
  try {
    const res = await axios(`${COMMON}AccurecyIndex/GetAccurecyIndexData`, {
      method: "GET",
      headers,
    });
    // Store.dispatch(setSomethingWentWrong(false));

    return res.data.result;
  } catch (error) {
    Store.dispatch(setSomethingWentWrong(true));
    // console.log(error);
  }
};

export const GetStationMapSVG = async () => {
  try {
    const res = await axios(
      // "https://app-rail-back-common-qa.azurewebsites.net/api/v1/StationImage/GetStationSVG",
      `${Config.BASE_URL}/common/api/v1/StationImage/GetStationSVGPerLanguage`,
      {
        method: "GET",
        headers,
      }
    );
    // Store.dispatch(setSomethingWentWrong(false));
    return res.data.result as ILinesMapResponse;
  } catch (error) {
    Store.dispatch(setSomethingWentWrong(true));
    // console.log(error);
  }
};

export const GetPlanningAndRates = async () => {
  try {
    const res = await axios(`${COMMON}Tenders/GetPlanningAndRates`, {
      method: "GET",
      headers,
    });
    // Store.dispatch(setSomethingWentWrong(false));
    return res.data.result;
  } catch (error) {
    Store.dispatch(setSomethingWentWrong(true));
    // console.log(error);
  }
};

export const GetPopUpMessagesWithStations = async (
  pageTypeId: "MainPage" | "RoutePlanning",

  languageId: string,
  stationId?: number
) => {
  const stationIdparams = stationId ? `stationId=${stationId}` : "";
  try {
    const res = await axios(
      `${COMMON}PopUpMessages/GetPopUpMessagesWithStation?PageTypeId=${pageTypeId}&LanguageId=${languageId}&SystemTypeId=Web&${stationIdparams}`,
      {
        method: "GET",
        headers,
      }
    );
    // Store.dispatch(setSomethingWentWrong(false));
    return res.data.result;
  } catch (error) {
    Store.dispatch(setSomethingWentWrong(true));
    // console.log(error);
  }
};
export const GetPopUpMessages = async (
  pageTypeId: "MainPage" | "RoutePlanning",
  languageId: string
) => {
  try {
    const res = await axios(
      `${COMMON}PopUpMessages?PageTypeId=${pageTypeId}&LanguageId=${languageId}&SystemTypeId=Web`,
      {
        method: "GET",
        headers,
      }
    );
    // Store.dispatch(setSomethingWentWrong(false));
    return res.data.result;
  } catch (error) {
    // Store.dispatch(setSomethingWentWrong(true));
    // console.log(error);
  }
};

export const GetStationInformation = async (
  StationInformation: IStationInformation
) => {
  const { language, systemType, StationId } = StationInformation;
  const languages: { [locale: string]: string } = {
    he: "Hebrew",
    en: "English",
    ru: "Russian",
    ar: "Arabic",
  };
  try {
    const res = await axios(
      `${COMMON}Stations/GetStationInformation?LanguageId=${languages[language]}&SystemType=${systemType}&StationId=${StationId}`,
      {
        method: "GET",
        headers,
      }
    );
    const res2 = await axios(
      `${COMMON}Stations/GetStationServices?LanguageId=${languages[language]}&SystemType=${systemType}&StationId=${StationId}`,
      {
        method: "GET",
        headers,
      }
    );
    res.data.result.stationServices = res2.data.result;
    return res.data.result;
  } catch (error) {
    // console.log(error);
  }
};

export const GetStationInformationPost = async (
  StationInformation: IStationInformation
) => {
  const { language, systemType, StationId } = StationInformation;
  const languages: { [locale: string]: string } = {
    he: "Hebrew",
    en: "English",
    ru: "Russian",
    ar: "Arabic",
  };
  try {
    const res = await axios(`${COMMON}Stations/GetStationInformationPost`, {
      method: "POST",
      headers,
      data: {
        LanguageId: languages[language],
        SystemType: systemType,
        StationId: StationId,
      },
    });
    const res2 = await axios(
      `${COMMON}Stations/GetStationServices?LanguageId=${languages[language]}&SystemType=${systemType}&StationId=${StationId}`,
      {
        method: "GET",
        headers,
      }
    );
    res.data.result.stationServices = res2.data.result;
    return res.data.result;
  } catch (error) {
    // console.log(error);
  }
};

export const PostForm = async (
  formId: number,
  address1_telephone1?: string,
  lastname?: string,
  firstname?: string,
  new_occasiondate?: Date,
  new_destination?: string,
  new_initialstation?: string,
  files?: File[],
  new_casesubject1?: string,
  new_casesubject2?: string,
  new_casesubject3?: string,
  new_customerid?: string,
  address1_city?: string,
  address1_line1?: string,
  address1_postalcode?: string,
  address1_postofficebox?: string,
  emailaddress1?: string,
  description?: string,
  ravKavCardNumber?: string
) => {
  try {
    const res = await axios(`${COMMON}FormsAPI/PostForms`, {
      method: "POST",
      headers,
      data: {
        address1_telephone1: address1_telephone1,
        lastname: lastname,
        firstname: firstname,
        new_occasiondate: new_occasiondate,
        new_destination: new_destination,
        new_initialstation: new_initialstation,
        files: files,
        new_casesubject1: new_casesubject1,
        new_casesubject2: new_casesubject2,
        new_casesubject3: new_casesubject3,
        new_customerid: new_customerid,
        address1_city: address1_city,
        address1_line1: address1_line1,
        address1_postalcode: address1_postalcode,
        address1_postofficebox: address1_postofficebox,
        emailaddress1: emailaddress1,
        description: description,
        new_ravkavnumber: ravKavCardNumber,
        formId: formId,
      },
    });
    return res;
  } catch (error) {
    // Store.dispatch(setErrorStatusUpdateClientNewTenders(true));
    return Promise.reject(error);
  }
};

export const PostComplimentaryTransport = async (
  stationId: number,
  startTime: string
) => {
  try {
    const res = await axios(
      `${COMMON}MotUpdates/GetMotUpdatesByTrainStation?stationId=${stationId}&startTime=${startTime}`,
      {
        method: "GET",
        headers,
      }
    );
    return res;
  } catch (error: any) {
    console.log(
      "error.response.data.statusCode:",
      error.response.data.statusCode
    );

    if (error.response.data.statusCode !== 200) {
      return error.response;
    } else
      return {
        data: {
          result: {
            motStations: [],
            motResponse: {
              siri: { serviceDelivery: { stopMonitoringDelivery: [] } },
            },
          },
        },
      };
  }
};
