import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import colors from "../../../Assets/Colors/Colors.json";
import fonts from "../../../Assets/Fonts/Fonts.json";
import Images from "../../../Data/Images/Images";
import { ILastTravel } from "../../../Interfaces/ILastTravel";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import { setPage } from "../../../Redux/Slices/pageSlice";
import {
  setDestinationStation,
  setOriginStation,
} from "../../../Redux/Slices/scheduleSlice";
import useDeviceMode from "../../../Utils/useWindowDimensions";
import fontSize from "../../../Assets/Fonts/FontsSizes.json";
import { SetTravelOnTopList } from "../../../Services/LastTravelsService";

const LastTravelMobile: React.FC<ILastTravel> = (props) => {
  const {
    fromStation,
    toStation,
    isFavorite,
    onClickFavorite,
    isFromStationClosed,
    isToStationClosed,
    onTravelClosed,
  } = props;
  const [_isOver, _setIsOver] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const deviceMode = useDeviceMode();

  const handleLastTravelClick = () => {
    SetTravelOnTopList(fromStation.stationId, toStation.stationId);
    dispatch(setOriginStation(fromStation.stationId));
    dispatch(setDestinationStation(toStation.stationId));
    dispatch(setPage("routePlan"));
    navigate(
      `?page=routePlan&step=when&fromStation=${fromStation.stationId}&toStation=${toStation.stationId}`
    );
  };

  const isSafariOs = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const isSafariAndWebMode = isSafariOs && deviceMode === "DESKTOP";

  const [_isIos, _setIsIos] = useState(isSafariOs && !isSafariAndWebMode);

  useEffect(() => {
    _setIsIos(isSafariOs && !isSafariAndWebMode);
  }, [isSafariAndWebMode]);

  return (
    <div
      id={`lastTravel-${fromStation.stationId}-${toStation.stationId}`}
      style={{
        ...styles.mainContainer,
        backgroundColor: _isOver
          ? isFromStationClosed || isToStationClosed
            ? colors.disabled
            : colors.pattensBlueLighter
          : colors.white,
      }}
      onMouseMove={() => _setIsOver(true)}
      onMouseLeave={() => _setIsOver(false)}
      onClick={() => {
        if (!isFromStationClosed && !isToStationClosed) {
          handleLastTravelClick();
        } else {
          onTravelClosed &&
            onTravelClosed(
              isFromStationClosed,
              isToStationClosed,
              fromStation,
              toStation
            );
        }
      }}
    >
      <div style={styles.column}>
        <div style={styles.row}>
          <img
            src={Images.origin}
            style={{
              filter: "brightness(.7)",
            }}
          ></img>
          <div style={styles.stationName}>{fromStation.stationName}</div>
        </div>
        <div style={styles.row}>
          <div
            style={{
              display: "flex",
            }}
          >
            <img src={Images.dotsNewHome} style={styles.dots} alt={``} />
          </div>
        </div>
        <div style={styles.row}>
          <img
            src={Images.destination}
            style={{
              filter: "brightness(.6)",
            }}
          ></img>
          <div style={styles.stationName}>{toStation.stationName}</div>
        </div>
      </div>
      <div
        style={styles.favoriteContainer}
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation();
          onClickFavorite &&
            onClickFavorite(fromStation.stationId, toStation.stationId);
        }}
      >
        <img
          src={!isFavorite ? Images.favorite : Images.favoriteFilled}
          style={{
            ...styles.favoriteIcon,
          }}
        ></img>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  mainContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
  },
  from: {
    fontFamily: fonts.FbReformaRegular,
    letterSpacing: "0.07rem",
    color: colors.gray,
  },
  to: {
    fontFamily: fonts.FbReformaRegular,
    marginInlineStart: "5rem",
    letterSpacing: "0.07rem",
    color: colors.gray,
  },
  favoriteContainer: {
    width: "3rem",
    height: "3rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  dots: {
    // lineHeight:".25rem"
  },
  stationName: {
    padding: "0px 10px",
    alignSelf: "start",
    position: "relative",
    top: "-2px",
    fontSize: fontSize.Paragraph,
    width: "15rem",
    color: colors.black,
    WebkitLineClamp: "2",
    WebkitBoxOrient: "vertical",
    lineClamp: "2",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    cursor: "pointer",
  },
  row: {
    display: "flex",
    alignItems: "center",
    gap: ".5rem",
  },

  line: {
    width: "100%",
    height: "2px",
    backgroundColor: colors.gray,
  },
};

export default LastTravelMobile;
