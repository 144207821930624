import { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import store from "store2";
import Loading from "../Components/Loading/Loading";
import Config from "../Configuration/server";
import Main from "../Screens/Main";
import { GetStations } from "../Services/GlobalService";
import { CheckDynamicParams, CheckWrongUrl, check301redirects } from "../Utils/redirects";
import Unsubscribe from "../Screens/Unsubscribe";

const Router: React.FC = () => {
  const [_isStations, _setIsStations] = useState(false);
  const [_previewActive] = useState(Config.PREVIEW_ACTIVE === `true`);
  const [_previewMode, _setPreviewMode] = useState(false);
  const [_loading, _setLoading] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  // const deeplinkAndroid = require("../Configuration/assetlinks.json")

  const handleStations = async () => {
    if (
      store.session.get(`${Config.BASE_LOCALSTORAGE_NAME}stations`) === null
    ) {
      const stations = await GetStations();

      if (stations) {
        store.session.set(`${Config.BASE_LOCALSTORAGE_NAME}stations`, stations);
        _setIsStations(true);
      } else {
        _setIsStations(false);
      }
    } else {
      _setIsStations(true);
    }
  };

  const handleLastTravels = () => {
    if (store.get(`${Config.BASE_LOCALSTORAGE_NAME}lastTravels`) === null) {
      store.set(`${Config.BASE_LOCALSTORAGE_NAME}lastTravels`, []);
    }
  };

  const getMode = () => {
    if (_previewActive) {
      // store.set(`isPreview`,`true`)
      let preview = store.get(`isPreview`);
      return preview === `true`;
    }
    return false;
  };

  const redirectToPage = (page?: string) => {
    page && navigate(`${page}`);
  };

  const GetLocationUrl = (PATHNAME: string, URL: string) => {
    if (PATHNAME.includes(`mldp`)) {
      URL += `&${
        PATHNAME.includes(`/ar/`)
          ? `lan=ar`
          : PATHNAME.includes(`/en/`)
          ? `lan=en`
          : PATHNAME.includes(`/ru/`)
          ? `lan=ru`
          : `lan=he`
      }`;
    }
    return URL;
  };
  useEffect(() => {
    let isMounted = true;
    (async () => {
      
      handleStations();
      handleLastTravels();
      _setLoading(true);
      
      const dynamicUrl = await CheckDynamicParams(location.search)
      
      const redirectUrl = check301redirects(
        location.pathname.includes(`mldp`)
          ? GetLocationUrl(location.pathname, location.search)
          : GetLocationUrl(location.pathname, location.pathname)
      );
      if (redirectUrl) {
        redirectToPage(redirectUrl);
      }
      else if(dynamicUrl&&dynamicUrl.length>0){
        redirectToPage(dynamicUrl)
      }
      else {
        const wrongUrl = CheckWrongUrl(location.pathname)
        if(wrongUrl&&wrongUrl.length>0){
          redirectToPage(wrongUrl)
        }
      }
      _setLoading(false); 

    })();
    return () => {
      isMounted = false;
    };
  }, []);

  if (getMode()) {
    return (
      <Routes>
        <Route
          path="/"
          element={<Main isStations={_isStations} isPreview={true} />}
        ></Route>
      </Routes>
    );
  } else {
    if (_loading) return <Loading message="" />;
    else
      return (
        <Routes>
          {/* <Route path="/.well-known/assetlinks" element={JSON.stringify(deeplinkAndroid)}></Route> */}
          <Route path="/" element={<Main isStations={_isStations} />}></Route>
          <Route path="/unsubscribe" element={<Unsubscribe />}></Route>
        </Routes>
      );
  }
};
export default Router;
