import { t } from "i18next";
import { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { useSelector } from "react-redux";
import store from "store2";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import colors from "../../Assets/Colors/Colors.json";
import fonts from "../../Assets/Fonts/Fonts.json";
import fontSize from "../../Assets/Fonts/FontsSizes.json";
import Config from "../../Configuration/server";
import Images from "../../Data/Images/Images";
import { IStation } from "../../Interfaces/IStation";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import { ITab } from "../../Interfaces/ITabs";
import { ISEOStation } from "../../Interfaces/IUmbraco";
import { UmbracoStoreState } from "../../Redux/Slices/umbracoSlice";
import { GetStationInformationPost } from "../../Services/CommonService";
import { IsMobile } from "../../Services/DeviceService";
import { Translations } from "../../Services/GlobalService";
import i18n from "../../Services/i18n";
import { StyleMode, UpdateSeo } from "../../Utils/consts";
import { GetStationsDictionary } from "../../Utils/helpers";
import useDeviceMode from "../../Utils/useWindowDimensions";
import { GetLongTextWithDots } from "../../Utils/utils";
import Loading from "../Loading/Loading";
import ToolTip from "../Modal/ToolTip";
import Breadcrumb from "../UmbracoElements/Breadcrumb";
import StationInfo from "./StationInfo";
import TabsHeader from "./TabsHeader";

const StationHeader: React.FC<any> = (props) => {
  const [_isSelected, _setIsSelected] = useState("info");
  const [_currentStation, _setCurrentStation] = useState<any>({});
  const [_currentStationSEO, _setCurrentStationSEO] = useState<ISEOStation>();
  const [_info, _setInfo] = useState<any>({});
  const [_tooltipMessage, __SeTooltipMessage] = useState<string>("");
  const [_tabs, _setTabs] = useState<ITab[]>([]);
  const [_isModalOpened, _setIsModalOpened] = useState<boolean>(false);
  const deviceMode = useDeviceMode();
  const [_isLoading, _setIsLoading] = useState(true);

  const umbracoStore = (
    useSelector((state: any) => state.UmbracoStoreReducer) as UmbracoStoreState
  ).umbracoStore;
  const convertUrlToObject = (url: string) => {
    const searchObejct: any = {};
    decodeURI(url)
      .replace("?", "")
      .split("&")
      .map((search, index) => {
        const sreachParams = search.split("=");
        searchObejct[sreachParams[0].toLowerCase()] = sreachParams[1];
      });
    return searchObejct;
  };

  const Translation = Translations[
    i18n.language as `he` | `en` | `ar` | `ru`
  ] as any;

  const init = async () => {
    const searchObejct: any = convertUrlToObject(window.location.search);
    if (searchObejct.stationname) {
      const stations: IStation[] = store.session.get(
        `${Config.BASE_LOCALSTORAGE_NAME}stations`
      )[i18n.language];
      let dictStations: any;
      let stationId: any = searchObejct.stationname;
      let station: any = stations.find((e) => {
        if (!/\d+/.test(searchObejct.stationname)) {
          if (e.stationName === searchObejct.stationname) {
            searchObejct.location = e.location;
            searchObejct.handicap = e.handicap;

            return e;
          }
        } else {
          if (e.stationId === Number(stationId)) {
            searchObejct.location = e.location;
            searchObejct.handicap = e.handicap;
            return e.stationId === Number(stationId);
          }
        }
      });
      stationId = station.stationId;
      dictStations = GetStationsDictionary(stations);
      dictStations[stationId] && _setCurrentStation(dictStations[stationId]);

      let info = await GetStationInformationPost({
        language: i18n.language,
        systemType: +Config.SYSTEM_TYPE!,
        StationId: stationId,
      });

      _setInfo({
        ...info,
        location: searchObejct.location,
        handicap: searchObejct.handicap,
      });
      _setIsLoading(false);
    }
  };
  useEffect(() => {
    let _tabs = [
      { name: "info", id: "1", isSelected: true },
      { name: "transit", id: "2", isSelected: false },
      {
        name: "parking",
        id: "2",
        isSelected: false,
        notShow:
          _info?.stationDetails?.carParking.length === 0 &&
          _info?.stationDetails?.bikeParking.length === 0,
      },
      { name: "environment", id: "4", isSelected: false },
    ];

    _setTabs(_tabs.filter((e) => !e.notShow));
    let currentStation: ISEOStation | any = umbracoStore?.StationsSeos
      ? umbracoStore?.StationsSeos[_info?.stationDetails?.stationId]
      : {};
    UpdateSeo(
      currentStation?.seoTitle,
      currentStation?.seo,
      currentStation?.seoTitle,
      currentStation?.seo,
      currentStation?.seoSocialNetworksImage,
      false
    );
    _setCurrentStationSEO(currentStation);
    // UpdateSeo(
    //   _info.stationDetails?.stationName,
    //   RemoveHtmlTags(
    //     _info.stationDetails?.aboutStationContent
    //       ? _info.stationDetails?.aboutStationContent
    //       : ""
    //   ),
    //   _info.stationDetails?.stationName,
    //   RemoveHtmlTags(
    //     _info.stationDetails?.aboutStationContent
    //       ? _info.stationDetails?.aboutStationContent
    //       : ""
    //   )
    // );
  }, [_info?.stationDetails?.stationId]);

  useEffect(() => {
    init();
  }, []);

  const setTabsCurrent = (_tab: ITab) => {
    const updatedTabs = _tabs.map((tab) => {
      return { ...tab, isSelected: tab.name === _tab.name };
    });
    _setIsSelected(_tab.name);
    _setTabs([...updatedTabs]);
    // navigate(
    //   `?page=stationinfo&step=${t(_tab.name)}&stationname=3500`,{replace:false}
    // );
  };
  return (
    <div
      className="station-info"
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{}}>
        <div style={styles.wapperHeader}>
          <div className="cover-color-blur">
            <div
              className="container"
              style={{
                display: "flex",
                height: "100%",
                alignItems: "center",
              }}
            >
              <div style={{ lineHeight: "3rem" }}>
                <div style={styles.breadcrumb}>
                  {_info.creationDate && (
                    <Breadcrumb
                      items={[
                        { text: t("LinesStations"), link: "linesmap" },
                        {
                          text: `${
                            IsMobile() &&
                            _info.stationDetails?.stationName.length >= 8
                              ? GetLongTextWithDots(
                                  _info.stationDetails?.stationName,
                                  8
                                )
                              : _info.stationDetails?.stationName
                          }`,
                          link: window.location.search,
                        },
                      ]}
                    />
                  )}
                </div>
                <div
                  style={{
                    ...styles.title,
                    fontSize: `${
                      deviceMode === StyleMode.desktop
                        ? fontSize.PageTitle
                        : fontSize.H1
                    }`,
                  }}
                >
                  {IsMobile() && _info.stationDetails?.stationName.length >= 30
                    ? GetLongTextWithDots(_info.stationDetails?.stationName, 30)
                    : _info.stationDetails?.stationName}
                  {_info.stationDetails?.airPollutionIcon && (
                    <div
                      className="image-wrapper-color"
                      style={styles.imageWrapperIcon}
                      title={`${Translation.StationAirPollutionWarning}`}
                      onClick={() => {
                        __SeTooltipMessage(
                          Translation.StationAirPollutionWarning
                        );
                        deviceMode === StyleMode.mobile &&
                          _setIsModalOpened(true);
                      }}
                    >
                      <img
                        src={Images.airPollution}
                        alt={`${Translation.StationAirPollutionWarning}`}
                        title={`${Translation.StationAirPollutionWarning}`}
                        style={{ width: `1.03rem`, cursor: "help" }}
                      />
                    </div>
                  )}
                  {_info.handicap && (
                    <div
                      className="image-wrapper-color"
                      style={{ ...styles.imageWrapperIcon, cursor: "help" }}
                      title={`${Translation.StationAccessibilityShort}`}
                      onClick={() => {
                        __SeTooltipMessage(
                          Translation.StationAccessibilityShort
                        );
                        deviceMode === StyleMode.mobile &&
                          _setIsModalOpened(true);
                      }}
                    >
                      {" "}
                      <img
                        src={Images.handicap2}
                        alt={`${Translation.StationAccessibilityShort}`}
                        title={`${Translation.StationAccessibilityShort}`}
                        style={{ width: `1.15rem`, cursor: "help" }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <TabsHeader
          data={_tabs}
          onSelect={(item: any) => setTabsCurrent(item)}
        />
      </div>

      {_isLoading ? (
        <Loading />
      ) : (
        <StationInfo
          info={_info}
          _isSelected={_isSelected}
          seoObject={_currentStationSEO}
        />
      )}

      <ReactModal
        shouldCloseOnOverlayClick={true}
        isOpen={_isModalOpened}
        onRequestClose={() => _setIsModalOpened(false)}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            textAlign: "center",
            maxWidth: "42rem",
            bottom: "auto",
            transform: "translate(-50%, -50%)",
            border: "none",
            padding: "none",
            position: "absolute",
            maxHeight: "100%",
            overflow: "visible",
          },
          overlay: {
            backgroundColor: colors.modal,
            zIndex: "99999",
            overflow: "hidden",
          },
        }}
        appElement={document.getElementById("#root") || document.body}
        parentSelector={() => document.querySelector("#root") || document.body}
        preventScroll={true}
      >
        <ToolTip title={_tooltipMessage} />
      </ReactModal>
    </div>
  );
};

const styles: StyleSheet = {
  imageWrapperIcon: {
    width: "2.375rem",
    padding: "0.5rem",
    height: "2.375rem",
    borderRadius: "50%",
    // background: "#ffffff42",
    background: `#adad9d`,
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: `32px`,
  },
  title: {
    display: "flex",
    flexDirection: "row",
    gap: 15,
    alignItems: "center",
    color: colors.white,
    lineHeight: "3rem",
    letterSpacing: "0.05625rem",
    textShadow: `2px 2px ${colors.black}`,
    fontFamily: fonts.FbReformaMedium,
  },
  breadcrumb: {
    display: "flex",
    color: colors.white,
    fontSize: fontSize.Text,
    letterSpacing: "0.05625rem",
    fontFamily: fonts.FbReformaRegular,
    cursor: "pointer",
    gap: ".5rem",
  },
  coverElements: {
    maxWidth: "85rem",
    margin: "auto",
    padding: "2rem",
    lineHeight: "2.5rem",
  },
  wapperHeader: {
    backgroundImage: `url(${Images.coverImage})`,
    minHeight: "13rem",
    height: "20.5vh",
    backgroundSize: "cover",
    backgroundPosition: "30% 30%",
  },
  tab: {
    cursor: "pointer",
    fontFamily: fonts.FbReformaMedium,
    fontSize: fontSize.Text,
    color: "#757575",
    lineHeight: "1rem",
    padding: "1rem 0.5rem",
  },
};

export default StationHeader;
