import FocusTrap from "focus-trap-react";
import { t } from "i18next";
import { Fragment, ReactNode, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import colors from "../../Assets/Colors/Colors.json";
import fonts from "../../Assets/Fonts/Fonts.json";
import fontSize from "../../Assets/Fonts/FontsSizes.json";
import Images from "../../Data/Images/Images";
import { IMedia } from "../../Interfaces/IBaseElement";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import { IsMobile } from "../../Services/DeviceService";
import i18n from "../../Services/i18n";
import { StyleMode } from "../../Utils/consts";
import useDeviceMode from "../../Utils/useWindowDimensions";
import TextAndBGModal from "../Modal/TextAndBGModal";
import TextAndDescriptionsVideoModal from "../Modal/TextAndDescriptionsVideoModal";
interface IWhatHappendedHere {
  contentTypeAlias: string;
  elementName: string;
  inTitle: string;
  title: string;
  description: string;
  happeneds: { section: IVideoAndText[] }[];
}

interface IVideoAndText {
  contentTypeAlias: string;
  cover: IMedia;
  title: string;
  description: string;
  thumbnail?: IMedia;
}
interface ITextAndMedia {
  section: IVideoAndText;
  i: number;
  index: number;
  children?: ReactNode;
  oneItem?: boolean;
}
const WhatHappendedHere: React.FC<IWhatHappendedHere> = (props) => {
  const [_sectionItemFocusIndex, _setSectionItemFocusIndex] = useState("");
  const deviceMode = useDeviceMode();
  const [_isModalVisible, _setIsModalVisible] = useState(false);
  const [_currentModalComponent, _setCurrentModalComponent] =
    useState<React.ReactNode>();

  const [_selectedPlayId, _setSelectedPlayId] = useState("");
  interface IMedia {
    _url: string;
    _id: string;
    umbracoExtension: string;
  }
  interface IVideoAndText {
    contentTypeAlias: string;
    cover: IMedia;
    title: string;
    description: string;
    thumbnail?: IMedia;
  }
  interface ITextAndMedia {
    section: IVideoAndText;
    i: number;
    index: number;
    children?: ReactNode;
    oneItem?: boolean;
  }
  const Video = (props: ITextAndMedia) => {
    const { section, index, i, children, oneItem } = props;
    let [play, setPlay] = useState(true);
    const playButtonRef = useRef<HTMLImageElement>(null);

    let thumbnail = section?.thumbnail?._url;

    const _onClick = (e?: any) => {
      _setIsModalVisible(true);
      _setCurrentModalComponent(
        <TextAndDescriptionsVideoModal
          key={i}
          source={section?.cover?._url}
          description={section.description}
          title={section.title}
        />
      );
    };

    return (
      <div
      key={i}
        // role={"button"}
        aria-label={t("PlayVideo")}
        onClick={_onClick}
        onKeyDown={(e) => e.key === "Enter" && _onClick(e)}
        className="video-list"
        onMouseLeave={(e: any) => {
          setPlay(true);
          let video: any = document.getElementById(`video-${index}-${i}`);
          video?.pause();
        }}
        onBlurCapture={() => {
          setPlay(true);
          const video = document.getElementById(
            `video-${index}-${i}`
          ) as HTMLVideoElement;
          video.pause();
        }}
        style={{
          cursor: "pointer",
          backgroundImage: `url(${section?.cover?._url})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          flex: "1 0 48%",
          boxSizing: "border-box",
          height: "15rem",

          display: "flex",
          overflow: "hidden",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <video
          onMouseOver={(e: any) => {
            setPlay(false);
            e.target.play();
          }}
          id={`video-${index}-${i}`}
          muted
          loop
          poster={thumbnail}
          width={"100%"}
        >
          <source src={section?.cover?._url} type="video/mp4"></source>
        </video>
        {play && (
          <img
            ref={playButtonRef}
            role={"button"}
            tabIndex={0}
            id={`video-${index}-${i}-play`}
            src={Images.play}
            aria-label={`${t("PlayVideo")} ${section.description}`}
            style={{ position: "absolute" }}
            onClick={(e) => {
              _onClick(e);
              _setSelectedPlayId(`video-${index}-${i}-play`);
            }}
            onKeyDown={(e) => {
              e.stopPropagation();
              if (e.key === "Enter") {
                _onClick();
              }
            }}
          />
        )}
        {children}
      </div>
    );
  };

  const textLongThreeDot = (text: string, number: number) => {
    return `${text.slice(0, number)}${text.length > number ? "..." : ""}`;
  };

  return (
    <>
      {_isModalVisible && (
        <FocusTrap>
          <div
            style={styles.modalOverlay}
            onClick={() => {
              _setIsModalVisible(false);
            }}
          >
            <div
              //  className="modal-wrapper"
              style={styles.modalCard}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <img
                tabIndex={0}
                role={"button"}
                aria-label={t("Close")}
                src={Images.closeButton}
                onClick={() => {
                  _setIsModalVisible(false);
                  const playButton = document.getElementById(_selectedPlayId);
                  if (playButton) {
                    let timeout = setTimeout(() => {
                      playButton.focus();
                      clearTimeout(timeout);
                    }, 0);
                  }
                }}
                onKeyDownCapture={(e) => {
                  if (e.key === "Escape") {
                    _setIsModalVisible(false);
                    const playButton = document.getElementById(_selectedPlayId);
                    if (playButton) {
                      let timeout = setTimeout(() => {
                        playButton.focus();
                        clearTimeout(timeout);
                      }, 0);
                    }
                  }
                }}
                style={{
                  position: "absolute",
                  cursor: "pointer",
                  left: i18n.dir() === "rtl" ? "0" : "",
                  right: i18n.dir() === "ltr" ? "0" : "",
                }}
              />
              {_currentModalComponent}
            </div>
          </div>
        </FocusTrap>
      )}
      <div
        style={{
          background: `linear-gradient(to bottom, ${colors.koromiko} 50%, #07070700 50%)`,
        }}
      >
        <div style={styles.maxWidth}>
          <div
            style={{
              ...styles.row,
              justifyContent: "center",
              alignItems: "center",
              paddingBottom: "2rem",
            }}
          >
            <div style={{ gap: "1rem" }}>
              <div
                style={{
                  flex: 1,
                  ...styles.column,
                  gap: "1rem",
                  textAlign: "center",
                }}
              >
                <h3 style={styles.title}>
                  <span style={styles.inTitle}>{props.inTitle} </span>
                  {props.title}
                </h3>
                <p style={styles.letsTalks}>{props.description}</p>
              </div>
            </div>
          </div>
        </div>
        <div style={{ ...styles.maxWidth, paddingTop: 0, maxWidth: "77rem" }}>
          <Swiper
            className={"mySwiper"}
            slideToClickedSlide={true}
            role={"region"}
            slidesPerView={"auto"}
            spaceBetween={30}
            navigation={true}
            modules={[Navigation]}
          >
            {props.happeneds.map((item: any, index: number) => (
              <SwiperSlide key={index}>
                <div
                  // tabIndex={!IsMobile() ? 0 : -1}
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "2rem",
                    paddingInline:
                      deviceMode === StyleMode.mobile ? "" : "5rem",
                  }}
                >
                  {item.section.map((section: IVideoAndText, i: number) => (
                    <Fragment key={i}>
                      {section.cover.umbracoExtension !== "mp4" ? (
                        <div
                          key={i}
                          tabIndex={!IsMobile() ? 0 : -1}
                          onClick={() => {
                            _setIsModalVisible(true);
                            _setCurrentModalComponent(
                              <TextAndBGModal
                                img={section?.cover?._url}
                                description={section.description}
                                title={section.title}
                              />
                            );
                          }}
                          style={{
                            backgroundImage: `url(${section?.cover?._url})`,
                            backgroundPositionX: "50%",
                            backgroundPositionY: "45%",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            flex: "1 0 48%",
                            height: "15rem",

                            padding: "2rem",
                            boxSizing: "border-box",
                            display: "flex",
                          }}
                          className="happened-box"
                          onFocusCapture={() => {
                            _setSectionItemFocusIndex(
                              index.toString() + i.toString()
                            );
                          }}
                        >
                          <div
                            className="happened-box-inner"
                            style={{
                              backgroundColor: "#ffffffde",
                              overflow: "hidden",
                              ...styles.column,
                              gap: "1rem",
                              width: "100%",
                              padding: "1rem",
                              opacity:
                                _sectionItemFocusIndex ===
                                index.toString() + i.toString()
                                  ? 1
                                  : _sectionItemFocusIndex === ""
                                  ? ""
                                  : "",
                            }}
                          >
                            <p
                              style={{
                                ...styles.textInSection,
                                fontFamily: fonts.FbReformaRegular,
                                color: colors.darkBlue,
                                overflow: "hidden",
                              }}
                              title={
                                section.title.length > 40 ? section.title : ""
                              }
                            >
                              {textLongThreeDot(section.title, 40)}
                            </p>
                            <p
                              style={{
                                ...styles.textInSection,
                                fontFamily: fonts.FbReformaLight,
                                color: "black",
                              }}
                              title={
                                section.description.length > 135
                                  ? section.description
                                  : ""
                              }
                            >
                              {textLongThreeDot(section.description, 135)}
                            </p>
                          </div>
                        </div>
                      ) : (
                        Video({
                          ...{
                            section,
                            i,
                            index,
                          },
                        })

                        // oneItem={item.section.length === 1}
                        // />
                      )}
                      {item.section.length === 1 && (
                        <div style={styles.boxHappended}></div>
                      )}
                    </Fragment>
                  ))}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </>
  );
};
const styles: StyleSheet = {
  wrap: {
    display: "flex",
    flexWrap: "wrap",
  },
  modalOverlay: {
    position: "fixed",
    backgroundColor: colors.modal,
    width: "100%",
    height: "100%",
    left: "0",
    top: "0",
    zIndex: "999999",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  modalCard: {
    backgroundColor: colors.white,
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    borderRadius: "1rem",
    overflow: "hidden",
    maxHeight: "90vh",
    maxWidth: "55rem",
    // width:"100%"
  },
  title: {
    fontSize: fontSize.PageTitle,
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaLight,
    lineHeight: "3rem",
    margin: 0,
  },

  inTitle: {
    fontSize: fontSize.PageTitle,
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaMedium,
  },
  maxWidth: {
    maxWidth: "68rem",
    margin: "auto",
    paddingBlock: "2rem",
    paddingInline: "1rem",
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  column: {
    display: "flex",
    flexDirection: "column",
  },
  boxHappended: {
    cursor: "pointer",
    flex: "1 0 48%",
    boxSizing: "border-box",

    display: "flex",
    overflow: "hidden",
    justifyContent: "center",
    alignItems: "center",
  },

  letsTalks: {
    color: colors.darkBlue,
    fontSize: fontSize.UmbracoElementTitle,
    fontFamily: fonts.FbReformaLight,
    letterSpacing: "0.44px",
    lineHeight: "1.87rem",
  },
  textInSection: {
    margin: 0,
    fontSize: fontSize.Paragraph,
    letterSpacing: "0.44px",
    lineHeight: "1.4rem",
  },
};

export default WhatHappendedHere;
