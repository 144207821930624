import colors from "../../Assets/Colors/Colors.json";
import fonts from "../../Assets/Fonts/Fonts.json";
import fontSize from "../../Assets/Fonts/FontsSizes.json";
import { StyleSheet } from "../../Interfaces/IStyleSheet";

export interface IToolTip {

  title: string;
}

const ToolTip: React.FC<IToolTip> = (props) => {
  const {title} = props;


  return (
    <div style={{padding:"2rem"}}>{title}</div>
  );
};

const styles: StyleSheet = {
  modalContainer: {
    padding: "2rem 3rem",
    maxHeight: "100%",
    overflow: "auto",
  },
  title: {
    color: colors.darkBlue,
    fontSize: fontSize.UmbracoElementTitle,
    fontFamily: fonts.FbReformaMedium,
  },
  propertyInfo: {
    fontSize: fontSize.Paragraph,
    fontFamily: fonts.FbReformaRegular,
  },
  propertyInfoContainer: {
    display: "flex",
    gap: "5rem",
  },
  propertyInfoBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    width: "14rem",
    paddingTop: "1rem",
    paddingBottom: "1rem",
  },
  description: {
    fontFamily: fonts.FbReformaRegular,
    fontSize: fontSize.FooterItem,
    lineHeight: "2rem",
    maxWidth: "80%",
  },
  button: {
    backgroundColor: colors.darkBlue,
    color: colors.white,
    fontSize: fontSize.Text,
    borderRadius: "16rem",
    borderColor: colors.darkBlue,
    minWidth: "8rem",
    height: "2rem",
    fontWeight: "bold",
    textAlign: "center",
    cursor: "pointer",
  },
  shareIcon: {
    cursor: "pointer",
  },
  shareModalCloseIconWrapper: {
    display: "flex",
    justifyContent: "center",
    position: "relative",
    top: "-2.5rem",
    zIndex: "9999999",
  },
  shareModalBody: {
    position: "absolute",
    top: "12%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    gap: "1.25rem",
  },

  shareModalCloseIconContainer: {
    width: "4.3rem",
    height: "4.3rem",
    boxShadow: "0 5px 11px 0 rgba(0, 0, 0, 0.21)",
    borderRadius: "50%",
    backgroundColor: "white",
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transform: "translateY(5px)",
  } as React.CSSProperties,
  shareModalTitle: {
    fontSize: fontSize.UmbracoElementTitle,
    lineHeight: "1.5rem",
    maxWidth: "22rem",
    fontFamily: fonts.FbReformaMedium,
    textAlign: "center",
  },
  shareOptions: {
    display: "flex",
    justifyContent: "center",
    gap: "3rem",
    flexWrap: "wrap",
    width: "70%",
  },
  shareItem: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  shareModalCloseIcon: {
    position: "absolute",
    top: "-2rem",
    cursor: "pointer",
  },
};

export default ToolTip;
