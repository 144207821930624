import { IButtonsFunctional } from "../../../Interfaces/IButtonFunctional";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import ButtonFunctional from "./ButtonFunctional";

const ButtonsFunctional: React.FC<IButtonsFunctional> = (props) => {
  const { buttons } = props;

  return (
    <div style={styles.buttons}>
      {buttons.map((button, index) => (
        <ButtonFunctional {...button} key={index} />
      ))}
    </div>
  );
};

const styles: StyleSheet = {
  buttons: {
    display: "flex",
    gap: "2rem",
    flexWrap: "wrap",
  },
};

export default ButtonsFunctional;
