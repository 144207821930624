import { t } from "i18next";
import { Fragment, useEffect, useRef, useState } from "react";
import ReactModal from "react-modal";
import colors from "../../../Assets/Colors/Colors.json";
import fonts from "../../../Assets/Fonts/Fonts.json";
import Images from "../../../Data/Images/Images";
import {
  IDirectorateMemberModal,
  IDirectorateMembers,
  IDirectorateMemberShort,
} from "../../../Interfaces/IDirectorateMember";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import i18n from "../../../Services/i18n";
import { StyleMode } from "../../../Utils/consts";
import useDeviceMode from "../../../Utils/useWindowDimensions";
import { replaceRichtextBoldWithFont } from "../../../Utils/utils";
import DirectorateMemberModal from "./DirectorateMemberModal/DirectorateMemberModal";
// import DirectorateMemberModalMobile from "./DirectorateMemberModal/Mobile/DirectorateMemberModalMobile";
import DirectorateMemberShort from "./DirectorateMemberShort";
import fontSize from "../../../Assets/Fonts/FontsSizes.json";
import { IsMobile } from "../../../Services/DeviceService";

const DirectorateMembers: React.FC<IDirectorateMembers> = (props) => {
  const { directorateMembers } = props;

  const deviceMode = useDeviceMode();
  const closeButtonRef = useRef<HTMLImageElement>(null);
  const fullNameRef = useRef<HTMLImageElement>(null);

  const [_isModalOpened, _setIsModalOpened] = useState(false);
  const [_selectedDirectorateMember, _setSelectedDirectorateMember] =
    useState<IDirectorateMemberModal>();

  const handleDirectorateMemberSelection = (
    directorateMember: IDirectorateMemberShort
  ) => {
    _setSelectedDirectorateMember(directorateMember);
    _setIsModalOpened(true);
  };

  const handleCloseModal = () => {
    _setIsModalOpened(false);
  };

  useEffect(() => {
    closeButtonRef.current?.focus();
  }, [closeButtonRef]);

  useEffect(() => {
    if (
      _isModalOpened &&
      _selectedDirectorateMember &&
      deviceMode !== StyleMode.desktop
    ) {
      fullNameRef.current?.focus();
    }
  }, [_isModalOpened, _selectedDirectorateMember, deviceMode]);

  return (
    <Fragment>
      <div style={styles.container}>
        {directorateMembers.map((directorateMember, index) => (
          <DirectorateMemberShort
            {...directorateMember}
            key={index}
            onSelect={handleDirectorateMemberSelection}
          />
        ))}
      </div>
      <ReactModal
        isOpen={_isModalOpened && deviceMode === StyleMode.desktop}
        onRequestClose={() => _setIsModalOpened(false)}
        ariaHideApp={false}
        style={{
          content: {
            top: "",
            left: "",
            right: "",
            bottom: "",
            maxWidth: "74.625rem",
            width: "50%",
            // transform: "translate(-50%, -50%)",
            border: "none",
            padding: "none",
            position: "absolute",
            borderRadius: "0.625rem",
            overflowY: "scroll",
            height: deviceMode === StyleMode.desktop ? "60%" : "",
          },
          overlay: {
            backgroundColor: `rgba(0, 0, 0, 0.8)`,
            backdropFilter: `blur(0.2rem)`,
            zIndex: "99999",
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
        parentSelector={() => document.querySelector("#root") || document.body}
        preventScroll={true}
      >
        <img
          role={"button"}
          aria-label={`${t(`Close`)}`}
          style={{
            ...styles.closeIconMemberModal,
            left: i18n.dir() === "rtl" ? "1rem" : "",
            right: i18n.dir() === "ltr" ? "1rem" : "",
            zIndex: "999999999999",
          }}
          onClick={() => _setIsModalOpened(false)}
          src={Images.closeButton}
          tabIndex={!IsMobile() ? 0 : -1}
        />
        {_selectedDirectorateMember && (
          <DirectorateMemberModal
            {..._selectedDirectorateMember}
            onClose={handleCloseModal}
          />
        )}
      </ReactModal>
      {_isModalOpened &&
        _selectedDirectorateMember &&
        deviceMode !== StyleMode.desktop && (
          <div
            style={styles.modalOverflow}
            onClick={() => {
              _setIsModalOpened(false);
            }}
          >
            <div
              style={styles.modalCard}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <img
                src={Images.closeButtonMob}
                ref={closeButtonRef}
                tabIndex={!IsMobile() ? 0 : -1}
                style={{
                  ...styles.closeIcon,
                  left: i18n.dir() === "rtl" ? "-1rem" : "",
                  right: i18n.dir() === "ltr" ? "-1rem" : "",
                }}
                onClick={() => {
                  _setIsModalOpened(false);
                }}
                onKeyPressCapture={() => {
                  _setIsModalOpened(false);
                }}
              ></img>
              <div style={styles.modalImageCircle}>
                <img
                  src={_selectedDirectorateMember.image._url}
                  style={{
                    ...styles.modalImage,
                  }}
                ></img>
              </div>
              <div style={styles.modalBody}>
                <div style={styles.modalBodyUpper}>
                  <div
                    style={styles.modalFullname}
                    ref={fullNameRef}
                    tabIndex={!IsMobile() ? 0 : -1}
                  >
                    {_selectedDirectorateMember.fullname}
                  </div>
                  <div
                    style={{
                      maxWidth: "70%",
                      fontSize: fontSize.UmbracoElementTitle,
                      fontFamily: fonts.FbReformaLight,
                      marginBottom: "0.25rem",
                      textAlign: "center",
                    }}
                  >
                    {_selectedDirectorateMember.role}
                  </div>
                </div>
                <div
                  style={{
                    paddingInlineStart: "3rem",
                    fontSize: fontSize.FooterItem,
                  }}
                >
                  <div
                    style={{
                      width: "90%",
                      fontFamily: fonts.FbReformaLight,
                      letterSpacing: "0.034375rem",
                      marginBottom: "0.8rem",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: replaceRichtextBoldWithFont(
                        _selectedDirectorateMember.description
                      ),
                    }}
                  ></div>
                  <div style={styles.modalInfoContainer}>
                    <div
                      style={{
                        marginBottom: "0.25rem",
                        fontFamily: fonts.FbReformaMedium,
                      }}
                    >
                      {_selectedDirectorateMember.birthYear.title}
                    </div>
                    <div
                      style={{
                        fontFamily: fonts.FbReformaLight,
                        letterSpacing: "0.034375rem",
                        marginBottom: "0.8rem",
                      }}
                      dangerouslySetInnerHTML={{
                        __html:
                          _selectedDirectorateMember.birthYear.description,
                      }}
                    ></div>
                  </div>
                  <div style={styles.modalInfoContainer}>
                    <div
                      style={{
                        marginBottom: "0.25rem",
                        fontFamily: fonts.FbReformaMedium,
                      }}
                    >
                      {_selectedDirectorateMember.education.title}
                    </div>
                    <div
                      style={{
                        fontFamily: fonts.FbReformaLight,
                        letterSpacing: "0.034375rem",
                        marginBottom: "0.8rem",
                      }}
                      dangerouslySetInnerHTML={{
                        __html:
                          _selectedDirectorateMember.education.description,
                      }}
                    ></div>
                  </div>
                  <div style={styles.modalInfoContainer}>
                    <div
                      style={{
                        fontFamily: fonts.FbReformaMedium,
                        marginBottom: "0.25rem",
                      }}
                    >
                      {_selectedDirectorateMember.workExperience.title}
                    </div>
                    <div
                      onKeyDownCapture={(e) => {
                        if (e.key === "Tab") {
                          if (closeButtonRef.current) {
                            e.stopPropagation();
                            e.preventDefault();
                            closeButtonRef.current.focus();
                          }
                        }
                      }}
                      style={{
                        fontFamily: fonts.FbReformaLight,
                        letterSpacing: "0.034375rem",
                        marginBottom: "0.8rem",
                      }}
                      dangerouslySetInnerHTML={{
                        __html:
                          _selectedDirectorateMember.workExperience.description,
                      }}
                    ></div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <span></span>
                  </div>
                </div>
              </div>
              {/* <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  position: "absolute",
                  bottom: "2rem",
                  fontSize: fontSize.Paragraph,
                  color: colors.cyanBold,
                }}
                onClick={() => {
                  window.location.href = `mailto:${_selectedDirectorateMember.mailTo.mailTo}`;
                }}
              >
                {_selectedDirectorateMember.mailTo.mailTo}
              </div> */}
            </div>
          </div>
        )}
    </Fragment>
  );
};

const styles: StyleSheet = {
  container: {
    display: "flex",
    flexWrap: "wrap",
    gap: "4rem",
    width: "80%",
    maxWidth: "76rem",
  },
  modalOverflow: {
    display: "flex",
    justifyContent: "center",
    top: "0",
    position: "fixed",
    width: "100%",
    height: "100%",
    backgroundColor: colors.modal,
    zIndex: "99999",
  },
  modalCard: {
    display: "flex",
    justifyContent: "center",
    position: "absolute",
    top: "8%",
    width: "85%",
    height: "88%",
    backgroundColor: colors.white,
    borderRadius: "13px 13px 288px 288px / 13px 13px 43px 43px",
  },
  modalImageCircle: {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "7rem",
    height: "7rem",
    backgroundColor: colors.white,
    top: "0",
    borderRadius: "50%",
    transform: "translateY(-3.5rem)",
    boxShadow: "-1px 1px 33px 1px rgb(64 60 67 / 40%)",
  },
  modalImage: {
    position: "absolute",
    backgroundRepeat: "no-repeat",
    width: "75%",
    height: "85%",
    borderRadius: "50%",
    backgroundPosition: `center`,
  },
  modalBody: {
    position: "absolute",
    top: "10%",
    maxHeight: "80%",
    overflow: "auto",
    display: "flex",
    gap: "1rem",
    flexDirection: "column",
  },
  modalFullname: {
    fontSize: fontSize.UmbracoElementTitle,
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaMedium,
    letterSpacing: "0.065rem",
    marginBottom: "0.25rem",
  },
  modalBodyUpper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: ".5rem",
  },
  modalInfoContainer: {
    display: "flex",
    flexDirection: "column",
    width: "90%",
  },
  closeIcon: {
    position: "absolute",
    backgroundRepeat: "no-repeat",
    cursor: "pointer",
    transform: "translateY(-3rem)",
  },
  closeIconMemberModal: {
    position: "absolute",
    backgroundRepeat: "no-repeat",
    width: "2rem",
    height: "2rem",
    top: "4rem",
    cursor: "pointer",
    transform: "translateY(-3rem) scale(1.4)",
  },
};

export default DirectorateMembers;
