import axios from "axios";
import Config from "../Configuration/server";
import {
  IFavoriteTravel,
  ITrainLuzRequestParams,
} from "../Interfaces/IRoutePlanSearchResults";
import { ISchedule } from "../Interfaces/ISchedule";
import { headers } from "./GlobalService";
import i18n from "./i18n";

export const RJPA = Config.RJPA_URL;

export const RjpaPostDetails = async (
  params: ISchedule,
  searchType:
    | "searchTrainLuzForDateTime"
    | "searchTrainLuzAfterDate"
    | "searchTrainLuzBeforeDate"
) => {
  const { fromStation, toStation, scheduleType, date, hours, minutes } = params;
  const railWebBlastTravels = localStorage.getItem("RAILWEBQAlastTravels");
  const railWebBlastTravelsParse =
    railWebBlastTravels && JSON.parse(railWebBlastTravels);

  function isFav(): boolean {
    if (railWebBlastTravelsParse && railWebBlastTravelsParse.length > 0) {
      return railWebBlastTravelsParse.some(
        (item: IFavoriteTravel) =>
          item.from === fromStation &&
          item.to === toStation &&
          item.isFavorite === true
      );
    } else console.error("We dont have data");
    return false;
  }

  const languages: { [locale: string]: string } = {
    he: "Hebrew",
    en: "English",
    ru: "Russian",
    ar: "Arabic",
  };

  let requestedDay = searchType;

  const language = languages[i18n.language];

  async function fetchGeoLocation() {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const requestLocation = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          };
          resolve(requestLocation);
        },
        (geoError) => {
          console.warn("Geolocation failed:", geoError);
          resolve({ latitude: "0.0", longitude: "0.0" });
        }
      );
    });
  }

  async function fetchIP() {
    try {
      const ipResponse = await axios.get("https://api.ipify.org?format=json");
      return ipResponse.data.ip;
    } catch (ipError) {
      console.warn("IP fetching failed:", ipError);
      return "0.0.0.0";
    }
  }

  function getScreenResolution() {
    return {
      height: window.screen.height,
      width: window.screen.width,
    };
  }

  async function sendRequest(trainLuzrequestParams: ITrainLuzRequestParams) {
    try {
      await axios.post(
        `${Config.BASE_URL}/${RJPA}/timetable/searchTrain`,
        trainLuzrequestParams,
        { headers }
      );
      console.log("Train request sent successfully.");
    } catch (requestError) {
      console.error("Error making train request:", requestError);
    }
  }


  async function gatherDetailsAndSendRequest() {
    const requestLocation = await fetchGeoLocation();
    const requestIP = await fetchIP();
    const screenResolution = getScreenResolution();

    const trainLuzrequestParams: ITrainLuzRequestParams = {
      methodName: requestedDay,
      fromStation: fromStation,
      toStation: toStation,
      date: date,
      hour: `${hours}:${minutes}`,
      systemType: `${Config.SYSTEM_TYPE}`,
      scheduleType: scheduleType === 1 ? "ByDeparture" : "ByArrival",
      languageId: language,
      requestLocation: JSON.stringify(requestLocation),
      requestIP: requestIP,
      userAgent: navigator.userAgent,
      screenResolution: JSON.stringify(screenResolution),
      searchFromFavorites: isFav(),
    };

    await sendRequest(trainLuzrequestParams);
  }

  const result = gatherDetailsAndSendRequest();

  return result;
};
