import { Fragment, useEffect, useRef, useState } from "react";
import { IGallery, IGalleryYoutube } from "../../../../../Interfaces/IGallery";
import { StyleSheet } from "../../../../../Interfaces/IStyleSheet";
import { IVideo } from "../../../../../Interfaces/IVideo";
import GalleryImage from "../../GalleryItems/GalleryImage";
import GalleryVideo from "../../GalleryItems/GalleryVideo";
import colors from "../../../../../Assets/Colors/Colors.json";
import fonts from "../../../../../Assets/Fonts/Fonts.json";
import Images from "../../../../../Data/Images/Images";
import GalleryYoutube from "../../GalleryItems/GalleryYoutube";
import { t } from "i18next";
import fontSize from "../../../../../Assets/Fonts/FontsSizes.json";
import { GetLongTextWithDots } from "../../../../../Utils/utils";
import { useSelector } from "react-redux";
import useDeviceMode from "../../../../../Utils/useWindowDimensions";
import { CookiesSettings } from "../../../../../Redux/Slices/cookiesSettingsSlice";
import { StyleMode } from "../../../../../Utils/consts";
import Swiper, { Autoplay, Navigation, Pagination } from "swiper";
import { Swiper as ReactSwiper, SwiperSlide } from "swiper/react";
import i18n from "../../../../../Services/i18n";
const DefaultGalleryWhatIsNewMobile: React.FC<IGallery> = (props) => {
  const { replaceTimeInSecond, onClick } = props;

  const [_gallery, _setGallery] = useState(props.gallery);
  const headingRef = useRef<HTMLImageElement>(null);
  const [_swiper, _setSwiper] = useState<Swiper>();
  const [_currentSlideIndex, _setCurrentSlideIndex] = useState(0);
  const galleryContainerRef = useRef<HTMLDivElement>(null);

  const deviceMode = useDeviceMode();
  const isCookiesModalVisible = (
    useSelector((state: any) => state.CookiesSettingsSlice) as CookiesSettings
  ).isCookiesModalVisible;

  useEffect(() => {
    !isCookiesModalVisible && headingRef.current?.focus();
  }, [isCookiesModalVisible]);

  const handleHeaderText = (text: string) => {
    if (deviceMode === StyleMode.desktop) {
      if (text.length < 158) {
        return text;
      } else {
        return GetLongTextWithDots(text, 158);
      }
    }
    return text;
  };

  useEffect(() => {
    if (galleryContainerRef.current) {
      galleryContainerRef.current.focus();
    }
  }, []);

  useEffect(() => {
    const bullets = galleryContainerRef.current?.getElementsByClassName(
      "swiper-pagination-bullet"
    );

    if (bullets) {
      Array.from(bullets).forEach((bullet, index) => {
        bullet.setAttribute("tabIndex", "0");
        bullet.setAttribute("role", "button");
        bullet.setAttribute(
          "aria-label",
          t("GalleryItemNumberOutOfNumber", {
            currentIndex: index + 1,
            total: bullets.length,
          })
        );
      });
    }

    const prevButton =
      galleryContainerRef.current?.getElementsByClassName("swiper-button-prev");

    if (prevButton && prevButton.length > 0) {
      prevButton[0].setAttribute("tabIndex", "0");
      prevButton[0].setAttribute("role", "button");
      prevButton[0].setAttribute("aria-label", t("PreviousGalleryItem"));
    }

    const nextButton =
      galleryContainerRef.current?.getElementsByClassName("swiper-button-next");

    if (nextButton && nextButton.length > 0) {
      nextButton[0].setAttribute("tabIndex", "0");
      nextButton[0].setAttribute("role", "button");
      nextButton[0].setAttribute("aria-label", t("NextGalleryItem"));
    }
  }, []);

  return (
    <div
      ref={galleryContainerRef}
      style={styles.galleryContainer}
      id={"defaultGalleryMobile"}
    >
      <img
        style={{
          ...styles.closeButton,
          left: i18n.dir() === "rtl" ? "1rem" : "",
          right: i18n.dir() === "ltr" ? "1rem" : "",
        }}
        src={Images.closeButtonMob}
        onClick={onClick}
      />
      <div>
        <ReactSwiper
          tabIndex={0}
          onSwiper={(swiper) => {
            _setSwiper(swiper);
          }}
          onSlideChange={(swiper) => {
            _setCurrentSlideIndex(swiper.activeIndex);
          }}
          className={"mySwiper"}
          // slideToClickedSlide={true}
          role={"region"}
          slidesPerView={1}
          navigation={true}
          keyboard={{ enabled: true }}
          modules={[Pagination, Navigation, Autoplay]}
          pagination={{ clickable: true }}
          loop={true}
          autoplay={
            replaceTimeInSecond !== 0
              ? {
                  delay: replaceTimeInSecond * 1000,
                  disableOnInteraction: false,
                }
              : false
          }
        >
          {_gallery.map((gallery, index) => (
            <SwiperSlide key={index}>
              {gallery.contentTypeAlias === "imageGalleryItem" && (
                <GalleryImage
                  {...gallery}
                  imageStyles={{
                    backgroundRepeat: "no-repeat",
                    height: "28rem",
                    width: "100%",
                    objectFit: "contain",
                  }}
                />
              )}
              {gallery.contentTypeAlias === "videoGalleryItem" && (
                <GalleryVideo
                  media={gallery.media as IVideo}
                  header={gallery.header}
                  videoStyles={{
                    backgroundRepeat: "no-repeat",
                    height: "28rem",
                    width: "100%",
                  }}
                  thumbnail={gallery.thumbnail}
                  isPlayButtonEnabled={true}
                />
              )}
              {gallery.contentTypeAlias === "youtubeGalleryItem" && (
                <GalleryYoutube {...(gallery as IGalleryYoutube)} />
              )}
              {gallery.contentTypeAlias !== "youtubeGalleryItem" && (
                <div
                  style={
                    gallery.contentTypeAlias === "imageGalleryItem"
                      ? {
                          ...styles.headerContainer,
                          bottom: "0",
                          justifyContent: "center",
                        }
                      : {
                          ...styles.headerContainer,
                          bottom: "0",
                          justifyContent: "center",
                        }
                  }
                >
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span
                      aria-level={3}
                      style={{
                        ...styles.header,
                        right: "2rem",
                      }}
                      aria-label={t("CurrentGalleryItem", {
                        currentGalleryTitle: gallery.header.text,
                      })}
                      title={gallery.header.text}
                    >
                      {handleHeaderText(gallery.header.text)}
                    </span>
                  </div>
                  {_currentSlideIndex === _gallery.length && (
                    <div
                      style={{
                        paddingInlineStart: "1rem",
                        paddingInlineEnd: "1rem",
                        display:"flex"              ,
                        alignItems:"center"
                      }}
                    >
                      <button
                        tabIndex={0}
                        style={styles.finishButton}
                        role={"button"}
                        aria-label={t(`Finish`)}
                        onKeyPressCapture={() => {
                          props.onClick && props.onClick();
                          window.location.reload();
                        }}
                        onClick={() => {
                          props.onClick && props.onClick();
                          window.location.reload();
                        }}
                      >
                        {t(`Finish`)}
                      </button>
                    </div>
                  )}
                </div>
              )}
            </SwiperSlide>
          ))}
        </ReactSwiper>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  galleryContainer: {
    position: "relative",
    width: "100%",
    height: "100%",
    cursor: "pointer",
  },
  currentDisplayed: {
    position: "relative",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: "85.5rem",
    height: "48rem",
  },
  arrows: {
    display: "flex",
    justifyContent: "space-between",
  },
  headerContainer: {
    position: "absolute",
    backgroundColor: colors.whiteTransparent,
    width: "100%",
    minHeight: "5rem",
    maxHeight: "8rem",
    display: "flex",
  },
  header: {
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaMedium,
    fontSize: fontSize.UmbracoElementTitle,
    position: "absolute",
    top: "20%",
    width: "80%",
  },
  galleryBullets: {
    display: "flex",
    gap: "1rem",
  },
  galleryItemBullet: {
    width: ".75rem",
    height: ".75rem",
    border: `.1rem solid ${colors.darkBlue}`,
    borderRadius: "0.75rem",
    cursor: "pointer",
  },
  finishButton: {
    borderRadius: "3.75rem",
    backgroundColor: colors.white,
    border: "0.06rem solid",
    fontFamily: fonts.FbReformaMedium,
    fontSize: fontSize.UmbracoElementTitle,
    fontWeight: `${i18n.language === "ar" ? `600` : ``}`,
    textAlign: "center",
    minWidth: "5rem",
    color: "rgb(0, 69, 138)",
    cursor: "pointer",
  },
  closeButton: {
    position: "absolute",
    zIndex: "2",
    top: "-2rem",
    cursor: "pointer",
  },
};

export default DefaultGalleryWhatIsNewMobile;
