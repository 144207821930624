import i18next from "i18next";
import QueryString from "qs";
import React, { Fragment, useEffect, useRef, useState } from "react";
import ReactGA from "react-ga";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import "./App.css";
import fontSize from "./Assets/Fonts/FontsSizes.json";
import CookiesManager from "./Components/Cookies/CookiesManager";
import Footer from "./Components/Footer/Footer";
import Header from "./Components/Header/Header";
import Modal from "./Components/Modal/Modal";
import SharePage from "./Components/SharePage/SharePage";
import TickerMain from "./Components/Ticker/TickerMain";
import FileElementPage from "./Components/UmbracoElements/FileElement/FileElementPage";
import { StyleSheet } from "./Interfaces/IStyleSheet";
import {
  HomeState,
  setSystemParamsKey,
  setWebsiteVersion,
} from "./Redux/Slices/homeSlice";
import { ModalState } from "./Redux/Slices/modalSlice";
import { PageState } from "./Redux/Slices/pageSlice";
import { SomethingWentWrongState } from "./Redux/Slices/somethingWentWrongSlice";
import { setSystemParams } from "./Redux/Slices/systemParamsSlice";
import { setTranslations } from "./Redux/Slices/translationsSlice";
import { UmbracoStoreState } from "./Redux/Slices/umbracoSlice";
import { setUrlTranslations } from "./Redux/Slices/urlTranslationsSlice";
import Router from "./Routes/Router";
import ErrorPageMain from "./Screens/ErrorPageMain";
import {
  GetSystemParams,
  GetTranslations,
  GetURLTranslations,
  GetUmbracoTranslations,
} from "./Services/GlobalService";
import i18n from "./Services/i18n";
import { StyleMode } from "./Utils/consts";
import { Scale } from "./Utils/scale";
import useDeviceMode from "./Utils/useWindowDimensions";
import Config from "./Configuration/server";
import TagManager from "react-gtm-module";

const App: React.FC = () => {
  const deviceMode = useDeviceMode();
  const location = useLocation();
  const dispatch = useDispatch();

  ReactGA.initialize(Config.GA_ID!);

  const initializeGTM = () => {
    const gtmId = Config.GTM_ID;

    if (gtmId) {
      TagManager.initialize({ gtmId });
      // Adding GTM script to <head>
      // const script = document.createElement('script');
      // script.async = true;
      // script.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`;
      // document.head.appendChild(script);
  
      // Adding GTM iframe to <body>
      const iframe = document.createElement('iframe');
      iframe.src = `https://www.googletagmanager.com/ns.html?id=${gtmId}`;
      iframe.height = '0';
      iframe.width = '0';
      iframe.style.display = 'none';
      iframe.style.visibility = 'hidden';
      document.body.insertAdjacentElement('afterbegin', iframe);
    } else {
      console.warn('GTM ID is not defined in environment variables');
    }
  }

  const InitializeGlassbox  = () => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = '_cls_detector';
    script.async = true;
    script.src = Config.GLASSBOX_CLS_SCRIPT_SRC || '';
    script.setAttribute('data-clsconfig', Config.GLASSBOX_CLS_CONFIG || '');
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  };

  useEffect(() => {
    initializeGTM();
    // InitializeGlassbox();
  }, []);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + "" + window.location.search);
  }, [window.location.pathname, window.location.search]);

  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const currentPage = (
    useSelector((state: any) => state.PageReducer) as PageState
  ).page;
  const umbracoStore = (
    useSelector((state: any) => state.UmbracoStoreReducer) as UmbracoStoreState
  ).umbracoStore;

  useEffect(() => {
    let t = umbracoStore?.treeData?.categories.find((item: any) => {
      if (item.id === "translations") {
        let ob = { ...item.pages[0].elements[0].translates };
        return ob;
      }
    });
  }, [umbracoStore]);

  const somethingWentWrong = (
    useSelector(
      (state: any) => state.SomethingWentWrongReducer
    ) as SomethingWentWrongState
  ).somethingWentWrong;

  const curWebsiteVersion = (
    useSelector((state: any) => state.HomeReducer) as HomeState
  ).websiteVersion;

  const modal = (useSelector((state: any) => state.ModalReducer) as ModalState)
    .modalBottom;
  const [_isHeaderFooterVisible, _setIsHeaderFooterVisible] = useState(false);
  const [_isMaintenance, _setIsMaintenance] = useState(false);
  const [_isUpdatesTickerVisible, _setIsUpdatesTickerVisible] = useState(false);
  const [_isFilePreviewPage, _setIsFilePreviewPage] = useState(false);
  const [_forceRenderKey, _setForceRenderKey] = useState(0);
  const [_checkForCookies, _setCheckForCookies] = useState("");
  const [random, setRandom] = useState(1);

  useEffect(() => {
    function handleResize() {
      if (deviceMode === StyleMode.desktop) {
        // setRandom(Math.random());
      }
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    // global.directionWeb = i18n.dir() === "ltr" ? "right" : "left";
  }, [i18n.dir()]);

  const changeRootFontOnRussianLanguage = () => {
    let CompressionRatio = 1;
    // if (i18n.language === "ru") {
    //   CompressionRatio = 0.75;
    //   document.documentElement.style.setProperty("--fontSize", "12.8px");
    // }
    //  else {
    document.documentElement.style.setProperty("--fontSize", "14.8px");
    // }
    if (deviceMode === StyleMode.mobile) {
      const baseSize = Scale(14.8, window.innerWidth);
      const aq = Scale(14.8 / 2, window.innerWidth);
      document.documentElement.style.setProperty(
        "--fontSize",
        `${baseSize * CompressionRatio}px`
      );
    }
  };

  // const modal = useSelector((state: any) => state.ModalReducer) as IModal;
  // Render all components on language change by changing key (forcing components render) :
  i18next.on("languageChanged", async () => {
    _setForceRenderKey(Math.floor(Math.random() * 1000));
    document.documentElement.dir = i18next.dir(i18next.language);
    let translation = await GetUmbracoTranslations(i18n.language);
    i18next.addResourceBundle(
      i18n.language,
      "translation",
      translation,
      true,
      true
    );
    changeRootFontOnRussianLanguage();
  });

  useEffect(() => {
    window.addEventListener("resize", () => {
      // changeRootFontOnRussianLanguage();
    });
    return () => {
      window.removeEventListener("resize", () => {});
    };
  }, []);
  useEffect(() => {
    let isMounted = true;
    (async () => {
      const urlTranslations = await GetURLTranslations();

      if (urlTranslations) {
        dispatch(setUrlTranslations(urlTranslations));
      }

      const systemParams = await GetSystemParams();
      let systemParamsKey = systemParams.reduce((prev, current) => {
        prev = {
          ...prev,
          [current.key]: current,
        };

        return prev;
      }, {});

      dispatch(setSystemParams(systemParams));

      const translations = await GetTranslations();
      dispatch(setTranslations(translations));

      dispatch(setSystemParamsKey(systemParamsKey));
      if (systemParams.length > 0) {
        const siteMaintenance = systemParams.find(
          (systemParam) => systemParam.key === "siteMaintenance"
        );

        const websiteVersion = systemParams.find(
          (systemParam) => systemParam.key === "websiteVersion"
        );

        const checkForCookies = systemParams.find(
          (systemParam) => systemParam.key === "CheckForCookies"
        );
        if (checkForCookies) {
          _setCheckForCookies(checkForCookies.value);
        }

        if (websiteVersion !== undefined) {
          if (isMounted) {
            dispatch(setWebsiteVersion(websiteVersion.value));
          }
        }

        if (siteMaintenance !== undefined) {
          if (siteMaintenance.value === "true") {
            if (isMounted) {
              _setIsMaintenance(true);
            }
          } else if (siteMaintenance.value === "false") {
            if (isMounted) {
              _setIsMaintenance(false);
            }
          }
        }
      }
    })();
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    document.documentElement.dir = i18next.dir(i18next.language);
    changeRootFontOnRussianLanguage();
  }, []);

  useEffect(() => {
    const isAnchor = location.search.includes("?") ? location.search.split("?")[1].includes("&anchor=") : false;
    let timeout = setTimeout(() => {
      if(!isAnchor) {
        scrollContainerRef.current?.scrollTo(0, 0);
      }
    }, 250);
    let isMounted = true;
    if (
      currentPage.toLowerCase() === "" ||
      currentPage.toLowerCase() === "homepage"
    ) {
      if (isMounted) {
        _setIsUpdatesTickerVisible(true);
      }
    } else {
      if (isMounted) {
        _setIsUpdatesTickerVisible(false);
      }
    }    

    changeRootFontOnRussianLanguage();


    return () => {
      isMounted = false;
      clearTimeout(timeout);
    };
  }, [currentPage, location]);



  useEffect(() => {
    // fix for history back button hides header + footer:
    let timeout = setTimeout(() => {
      const urlParams = QueryString.parse(location.search.split("?")[1]);
      const url = location.pathname;
      if (urlParams && urlParams.page) {
        const page = (urlParams.page as string).toLowerCase();
        if (deviceMode === StyleMode.desktop) {
          if (page === "routeplan".toLowerCase()) {
            _setIsHeaderFooterVisible(false);
          } else if (page === "unsubscribe".toLowerCase()) {
            _setIsHeaderFooterVisible(false);
          } else {
            _setIsHeaderFooterVisible(true);
          }
        } else {
          if (
            page === "routeplan".toLowerCase() ||
            page === "routePlanSearchResults".toLowerCase()
          ) {
            _setIsHeaderFooterVisible(false);
          } else {
            if (url && url.includes(`assetlinks`)) {
              _setIsHeaderFooterVisible(false);
            } else if (page === "unsubscribe".toLowerCase()) {
              _setIsHeaderFooterVisible(false);
            } else {
              _setIsHeaderFooterVisible(true);
            }
          }
        }
        if (page === "unsubscribe".toLowerCase()) {
          _setIsHeaderFooterVisible(false);
        }

        clearTimeout(timeout);
      }
      if (url && url.includes(`assetlinks`)) {
        _setIsHeaderFooterVisible(false);
      }
    }, 0);
  }, [location]);

  useEffect(() => {
    let isMounted = true;
    const html = document.documentElement;
    if (html) {
      html.style.overflow = "";
    }

    if (isMounted) {
      _setIsFilePreviewPage(
        currentPage.toLowerCase().includes("filePreview".toLowerCase())
      );
    }
    switch (currentPage.toLowerCase()) {
      case "filePreview_external".toLowerCase():
        if (isMounted) {
          _setIsHeaderFooterVisible(false);
        }
        break;
      case "filePreview_internal".toLowerCase():
        if (isMounted) {
          _setIsHeaderFooterVisible(true);
        }
        break;
      case "routePlan".toLowerCase():
        if (isMounted) {
          _setIsHeaderFooterVisible(false);
        }
        break;
      case "routePlanSearchResults".toLowerCase():
        if (!somethingWentWrong) {
          if (isMounted) {
            _setIsHeaderFooterVisible(deviceMode === StyleMode.desktop);
          }
        } else {
          if (isMounted) {
            _setIsHeaderFooterVisible(true);
          }
        }
        break;
      case "routePlanSearchResultsMulti".toLowerCase():
        if (!somethingWentWrong) {
          if (isMounted) {
            _setIsHeaderFooterVisible(deviceMode === StyleMode.desktop);
          }
        } else {
          if (isMounted) {
            _setIsHeaderFooterVisible(true);
          }
        }
        break;
      case "unsubscribe":
        if (isMounted) {
          _setIsHeaderFooterVisible(false);
        }
        break;
      case "":
        if (isMounted) {
          _setIsHeaderFooterVisible(true);
        }
        break;
    }

    return () => {
      isMounted = false;
    };
  }, [currentPage, location, i18n.language, somethingWentWrong]);

  const RenderHeader: React.FC = () => {
    switch (deviceMode) {
      case StyleMode.desktop:
        return _isUpdatesTickerVisible ? (
          <>
            {umbracoStore?.headerContent && (
              <div style={styles.topContainer}>
                <TickerMain />
                <Header headerContent={umbracoStore?.headerContent} />
              </div>
            )}
          </>
        ) : (
          <>
            {umbracoStore?.headerContent && (
              <header style={styles.header}>
                <Header headerContent={umbracoStore?.headerContent} />
              </header>
            )}
          </>
        );
      case StyleMode.mobile:
      case StyleMode.tablet:
        return _isUpdatesTickerVisible ? (
          <>
            <div
              style={{
                background: "black",
              }}
            >
              <TickerMain />
            </div>
            {/* <HeaderMobile headerContent={umbracoStore?.headerContent} /> */}
          </>
        ) : (
          // <HeaderMobile headerContent={umbracoStore?.headerContent} />
          <></>
        );
      default:
        return <></>; //<HeaderMobile headerContent={umbracoStore?.headerContent} />;
    }
  };

  return (
    <>
      <CookiesManager
        checkForCookies={_checkForCookies}
        cookies={umbracoStore.cookies}
      />
      {!_isMaintenance ? (
        <Fragment key={_forceRenderKey}>
          <Modal />
          <div style={styles.body} className="body">
            {!_isFilePreviewPage && _isHeaderFooterVisible && <RenderHeader />}
            <div
              className="mainScroll"
              id={"mainScroll"}
              style={{ overflowY: "auto", overflowX: "hidden" }}
              ref={scrollContainerRef}
            >
              <div
                style={{
                  paddingTop:
                    _isUpdatesTickerVisible && deviceMode === StyleMode.desktop
                      ? "3rem"
                      : "",
                }}
              >
                <Router />
                {_isFilePreviewPage && <FileElementPage />}
                {!_isFilePreviewPage &&
                  _isHeaderFooterVisible &&
                  Config.IS_TENDERS === "false" && (
                    <>
                      {umbracoStore.footer && (
                        <Footer
                          leftFooter={umbracoStore?.footer?.leftFooter}
                          upperRightFooter={
                            umbracoStore?.footer?.upperRightFooter
                          }
                          bottomRightFooter={
                            umbracoStore?.footer?.bottomRightFooter
                          }
                          websiteVersion={curWebsiteVersion}
                        />
                      )}
                    </>
                  )}
              </div>
            </div>
          </div>
        </Fragment>
      ) : (
        <ErrorPageMain errorName="Maintenance" />
      )}
      {Config.IS_TENDERS === "false" &&
        currentPage !== "" &&
        currentPage !== "routePlanSearchResults".toLowerCase() &&
        currentPage !== "homepage" &&
        currentPage !== "routeplan" && <SharePage />}
    </>
  );
};

const styles: StyleSheet = {
  body: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
  },
  topContainer: {
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    zIndex: "99999",
    display: "flex",
    flexDirection: "column",
  },
  header: {
    // position: "fixed",
    // zIndex: "99999",
    width: "100%",
  },
  skipBtn: {
    position: "absolute",
    zIndex: "9",
    backgroundColor: "rgb(238, 238, 238)",
    left: "0.25rem",
    top: "10%",
    minWidth: "15%",
    height: "auto",
    overflow: "auto",
    margin: "auto",
    padding: "5px",
    border: "0.3rem dashed",
    textAlign: "center",
    fontSize: fontSize.FooterItem,
  },
};

export default App;
