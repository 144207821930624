import { useEffect, useState } from "react";
import colors from "../../../Assets/Colors/Colors.json";
import fonts from "../../../Assets/Fonts/Fonts.json";
import fontSize from "../../../Assets/Fonts/FontsSizes.json";
import { ISectionTabs } from "../../../Interfaces/ISectionTab";
import { IAnyElement } from "../../../Interfaces/IStaticPage";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import i18n from "../../../Services/i18n";
import useDeviceMode from "../../../Utils/useWindowDimensions";
import AllElements from "../AllElements";
import RichTextEditor from "../RichTextEditor";
import SectionTab from "./SectionTab";
import { StyleMode } from "../../../Utils/consts";
import FileElement from "../FileElement/FileElement";

const SectionTabs: React.FC<ISectionTabs> = (props) => {
  const { tabs } = props;
  const [_selectedCapsule, _setSelectedCapsule] = useState(0);
  const [_currentElements, _setCurrentElements] = useState<
    (IAnyElement & any)[]
  >([]);

  const totalPercent = 100 / tabs.length;

  useEffect(() => {
    if (tabs.length > 0) handleTabClick(0, tabs[0].elements);
  }, []);

  const handleTabClick = (tabIndex: number, elements: IAnyElement[]) => {
    _setSelectedCapsule(tabIndex);
    _setCurrentElements(elements);
  };
  const deviceMode = useDeviceMode();
  return (
    <div style={styles.container}>
      <div
        style={{
          ...styles.capsuleWrapper,
          width: deviceMode === StyleMode.desktop ? "80%" : "100%",
          // overflowX: "auto",
          height: deviceMode === StyleMode.desktop ? "3.2rem" : "",
          display: "flex",
          gap: "2rem",
          alignItems: "center",
          padding: "1.2rem",
          boxSizing: "border-box",
        }}
        className="modalScrollX"
      >
        <div
          style={{
            ...styles.capsuleCalculations,
            width: `${totalPercent}%`,
            left:
              i18n.dir() === "rtl"
                ? 100 - totalPercent * (_selectedCapsule + 1) + "%"
                : "",
            right:
              i18n.dir() === "ltr"
                ? 100 - totalPercent * (_selectedCapsule + 1) + "%"
                : "",
            transition: `${i18n.dir() === "rtl" ? "left" : "right"} 120ms`,
          }}
        ></div>
        <div
          style={{
            ...styles.capsuleCalculations,
            width: "100%",
            borderBottom: "5px solid rgba(249, 186, 77, 0.20",
          }}
        ></div>
        {tabs.map((tab, index) => (
          <SectionTab
            {...tab}
            key={index}
            isSelected={index === _selectedCapsule}
            index={index}
            handleTabClick={handleTabClick}
          />
        ))}
      </div>
      <div style={styles.elementsContainer} className="customScrollbarBlue">
        {_currentElements.map((element, index) =>
          element.contentTypeAlias === "richTextEditor" ? (
            <RichTextEditor
              key={index}
              {...element}
              customStyles={{
                fontSize: fontSize.Text,
              }}
            />
          ) : element.contentTypeAlias === "fileElement" ? (
            <FileElement
              key={index}
              {...element}
              containerStyles={{
                fontSize: fontSize.Text,
                alignSelf: "start",
                marginInlineStart: "5rem",
                marginTop: "1.5rem",
              }}
            />
          ) : (
            <AllElements
              key={index}
              elementName={element.contentTypeAlias}
              {...element}
            />
          )
        )}
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    marginTop: "3rem",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    textAlign: "center",
    fontSize: fontSize.UmbracoElementTitle,
    fontWeight: 400,
    lineHeight: "normal",
    letterSpacing: "0.65px",
    fontFamily: fonts.FbReformaMedium,
    marginBlockEnd: 0,
  },
  capsuleWrapper: {
    zIndex: "3",
    display: "flex",
    justifyContent: "center",
    backgroundColor: colors.white,
    width: "80%",
    height: "3.2rem",
    textAlign: "center",
    transform: "translateY(-2.2rem)",
    position: "relative",
  },
  capsuleCalculations: {
    position: "absolute",
    bottom: "0",
    cursor: "pointer",
    whiteSpace: "nowrap",
    borderBottom: `5px solid ${colors.koromiko}`,
  },
  capsuleButtonSelected: {
    color: colors.white,
  },
  elementsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
};

export default SectionTabs;
