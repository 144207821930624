import { t } from "i18next";
import { Fragment, useEffect, useState } from "react";
import ReactModal from "react-modal";
import { useNavigate } from "react-router-dom";
import colors from "../../../Assets/Colors/Colors.json";
import Images from "../../../Data/Images/Images";
import {
  ICustomerServiceItem,
  ICustomerServices,
} from "../../../Interfaces/ICustomerServices";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import i18n from "../../../Services/i18n";
import { StyleMode } from "../../../Utils/consts";
import useDeviceMode from "../../../Utils/useWindowDimensions";
import CustomerServiceItem from "./CustomerServiceItem";
import fontSize from "../../../Assets/Fonts/FontsSizes.json";
import { IsMobile } from "../../../Services/DeviceService";
import FormsContainer from "../../Forms/FormsContainer";
import { useDispatch } from "react-redux";
import { setCurrentService } from "../../../Redux/Slices/formsSlice";
import { GetSystemParams } from "../../../Services/GlobalService";
import SharePage from "../../SharePage/SharePage";

const CustomerServices: React.FC<ICustomerServices> = (props) => {
  const { servicesList, rowPosition, anchor } = props;
  const [_isModalOpened, _setIsModalOpened] = useState(false);
  const [_isYesNoModal, _setIsYesNoModal] = useState(false);
  const [_displayModernform, _setDisplayModernform] = useState("false");
  const [_selectedCustomerServiceItem, _setSelectedCustomerServiceItem] =
    useState<ICustomerServiceItem>();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const deviceMode = useDeviceMode();

  const handleOpenYesNoModal = () => {
    _setIsYesNoModal(true);
  };

  const redirectToPage = (page?: string) => {
    page && navigate(`?page=${page}`);
  };

  const handleCustomerServiceSelection = (
    customerServiceItem: ICustomerServiceItem
  ) => {
    _setSelectedCustomerServiceItem(customerServiceItem);

    switch (customerServiceItem.openMode) {
      case "Modal":
        _setIsModalOpened(true);
        break;
      case "New Window":
        if (customerServiceItem.openType === "External") {
          window.open(customerServiceItem.formUrl, "_blank");
        } else if (customerServiceItem.openType === "Internal") {
          redirectToPage(customerServiceItem.formUrl);
        }
        break;
    }
  };

  const handleCloseModal = () => {
    _setIsModalOpened(false);
    dispatch(setCurrentService(""));
  };

  useEffect(() => {
    // isMounted.current = true;

    (async () => {
      let systemParams = await GetSystemParams();

      const formDisplay = systemParams.find(
        (item) => item.key === "displayModernForm"
      )?.value;

      formDisplay && _setDisplayModernform(formDisplay);
    })();
  }, []);


  return (
    <Fragment>
      <div
        style={{
          ...styles.container,
          justifyContent:
            deviceMode === StyleMode.desktop ? rowPosition : "center",
        }}
        anchor-id={anchor}
      >
        {servicesList.map((service, index) => (
          <CustomerServiceItem
            {...service}
            key={index}
            onSelect={handleCustomerServiceSelection}
          />
        ))}
      </div>
      {_selectedCustomerServiceItem && (
        <ReactModal
          // shouldCloseOnOverlayClick={true}
          isOpen={_isModalOpened}
          onRequestClose={handleCloseModal}
          style={{
            content: styles.modalContent,
            overlay: {
              backgroundColor: colors.modal,
              zIndex: "99999",
              overflow: "hidden",
            },
          }}
          appElement={document.getElementById("#root") || document.body}
          parentSelector={() =>
            document.querySelector("#root") || document.body
          }
          preventScroll={true}
        >
          <img
            src={Images.closeButton}
            style={{
              ...styles.closeMainModalIcon,
              left: i18n.dir() === "rtl" ? "-1rem" : "",
              right: i18n.dir() === "ltr" ? "1rem" : "",
            }}
            onClick={handleOpenYesNoModal}
            onKeyPressCapture={handleOpenYesNoModal}
            tabIndex={!IsMobile() ? 0 : -1}
            width={`auto`}
            height={`auto`}
            alt={``}
          />

          {_displayModernform === "true" &&
          (_selectedCustomerServiceItem.serviceName ===
            "טופס פנייה לשירות לקוחות" ||
            _selectedCustomerServiceItem.serviceName ===
              "בקשה לצילומים ברכבת") ? (
            <FormsContainer type={_selectedCustomerServiceItem.serviceName} />
          ) : (
            <div style={{ height: "100%" }}>
              <iframe
                src={_selectedCustomerServiceItem.formUrl}
                width={"100%"}
                height={"100%"}
                tabIndex={!IsMobile() ? 0 : -1}
                aria-label={`${_selectedCustomerServiceItem.serviceName} iframe`}
              >
                
              </iframe>
              <SharePage shareUrl={_selectedCustomerServiceItem.formUrl} />
            </div>
          )}
        </ReactModal>
      )}
      {_isYesNoModal && (
        <ReactModal
          isOpen={_isModalOpened}
          style={{
            content: styles.yesNoModalContent,
            overlay: {
              backgroundColor: colors.modal,
              zIndex: "999999",
              overflow: "hidden",
            },
          }}
          appElement={document.getElementById("#root") || document.body}
          parentSelector={() =>
            document.querySelector("#root") || document.body
          }
          preventScroll={true}
        >
          <div style={styles.yesNoModalContainer}>
            <div
              style={styles.yesNoModalTitle}
              tabIndex={!IsMobile() ? 0 : -1}
              aria-label={`${t("Close-window-question")}`}
            >
              {t("Close-window-question")}
            </div>
            <div
              style={{
                display: "flex",
                gap: "1rem",
              }}
            >
              <div
                style={styles.yesButton}
                onClick={() => {
                  _setIsYesNoModal(false);
                  _setIsModalOpened(false);
                }}
                onKeyPressCapture={() => {
                  _setIsYesNoModal(false);
                  _setIsModalOpened(false);
                }}
                role={`button`}
                tabIndex={!IsMobile() ? 0 : -1}
              >
                {t("Yes")}
              </div>
              <div
                style={styles.noButton}
                onClick={() => {
                  _setIsYesNoModal(false);
                }}
                onKeyPressCapture={() => {
                  _setIsYesNoModal(false);
                }}
                role={`button`}
                tabIndex={!IsMobile() ? 0 : -1}
              >
                {t("No")}
              </div>
            </div>
          </div>
        </ReactModal>
      )}
    </Fragment>
  );
};

const styles: StyleSheet = {
  container: {
    gap: "3rem",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    width: "80%",
    maxWidth: "76rem",
  },
  modalContent: {
    display: "flex",
    flexDirection: "column",
    top: "50%",
    left: "50%",
    right: "auto",
    maxWidth: "90.625rem",
    width: "90%",
    bottom: "auto",
    height: "50rem",
    // height: deviceMode === StyleMode.desktop ? "40rem" : "80rem",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "none",
    position: "absolute",
    borderRadius: "0.625rem",
    maxHeight: "100%",
    overflow: "unset",
  },
  yesNoModalContent: {
    display: "flex",
    flexDirection: "column",
    top: "50%",
    left: "50%",
    right: "auto",
    maxWidth: "35rem",
    width: "80%",
    bottom: "auto",
    height: "15rem",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "none",
    position: "absolute",
    borderRadius: "0.625rem",
    maxHeight: "100%",
    overflow: "hidden",
    justifyContent: "center",
  },
  yesNoModalContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "2rem",
  },
  yesNoModalTitle: {
    fontSize: fontSize.UmbracoElementTitle,
  },
  yesButton: {
    backgroundColor: colors.darkBlue,
    color: colors.white,
    fontSize: fontSize.Text,
    borderRadius: "16rem",
    width: "8rem",
    height: "1.8rem",
    fontWeight: "bold",
    textAlign: "center",
    paddingTop: ".5rem",
    cursor: "pointer",
  },
  noButton: {
    backgroundColor: colors.white,
    color: colors.darkBlue,
    fontSize: fontSize.Text,
    borderRadius: "16rem",
    width: "8rem",
    height: "1.8rem",
    fontWeight: "bold",
    textAlign: "center",
    paddingTop: ".5rem",
    cursor: "pointer",
    border: `.1rem solid ${colors.darkBlue}`,
  },
  closeMainModalIcon: {
    position: "absolute",
    top: "-.5rem",
    cursor: "pointer",
    borderRadius: "50%",
    backgroundColor: colors.white,
  },
};

export default CustomerServices;
