import { useState } from "react";
import colors from "../../../Assets/Colors/Colors.json";
import fonts from "../../../Assets/Fonts/Fonts.json";
import fontSize from "../../../Assets/Fonts/FontsSizes.json";
import Images from "../../../Data/Images/Images";
import { IInfrastructureTitleDescription } from "../../../Interfaces/IInfrastructureTitleDescription";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import { StyleMode } from "../../../Utils/consts";
import useDeviceMode from "../../../Utils/useWindowDimensions";
import InfrastructureTitleDescriptionModal from "./InfrastructureTitleDescriptionModal";
import { useNavigate } from "react-router-dom";
import TitleSplitted from "../TitleSplitted/TitleSplitted";
import MainModal from "../../Main Modal/MainModal";

const InfrastructureTitleDescription: React.FC<
  IInfrastructureTitleDescription
> = (props) => {
  const {
    firstTitle,
    secondTitle,
    body,
    modalOpenText,
    button,
    skipperText,
    image,
    modal,
    buttonLink,
    skipperLink,
  } = props;

  const [_isModalOpen, _setIsModalOpen] = useState(false);
  const deviceMode = useDeviceMode();
  const navigate = useNavigate();

  const closeModal = () => {
    _setIsModalOpen(false);
  };

  return (
    <>
      {_isModalOpen && (
        <MainModal onClose={closeModal}>
          <InfrastructureTitleDescriptionModal
            {...modal}
            onClose={closeModal}
          />
        </MainModal>
      )}
      <div style={styles.mainContainer}>
        <div
          style={{
            ...styles.container,
            flexDirection:
              deviceMode === StyleMode.desktop ? "row-reverse" : "column",
          }}
        >
          <img src={image._url} />
          <div
            style={{
              ...styles.infoContainer,
              alignItems: deviceMode === StyleMode.desktop ? "start" : "center",
            }}
          >
            <TitleSplitted
              firstTitle={firstTitle}
              secondTitle={secondTitle}
              customH1Styles={{
                ...styles.title,
                color: colors.darkBlue,
                padding: deviceMode !== StyleMode.desktop ? "0 2rem" : "",
                marginBlockEnd: 0,
                textAlign:
                  deviceMode === StyleMode.desktop ? "start" : "center",
                width: "",
              }}
              customFirstTitleStyles={{
                fontFamily:
                  deviceMode === StyleMode.desktop
                    ? fonts.FbReformaLight
                    : fonts.FbReformaMedium,
                fontSize: "2.7rem",
              }}
              customSecondTitleStyles={{
                fontFamily: fonts.FbReformaMedium,
              }}
              isMobileOneLine={false}
            />
            <div
              style={{
                ...styles.body,
                textAlign:
                  deviceMode === StyleMode.desktop ? "start" : "center",
                padding: deviceMode !== StyleMode.desktop ? "2rem" : "",
              }}
              dangerouslySetInnerHTML={{ __html: body }}
            ></div>
            <div style={styles.linkContainer}>
              <span
                role={"button"}
                tabIndex={0}
                onClick={() => _setIsModalOpen(true)}
              >
                {modalOpenText}
              </span>
              <span>{">"}</span>
            </div>
            <button
              tabIndex={0}
              role={"link"}
              style={styles.button}
              onClick={() => {
                if (buttonLink) window.open(buttonLink, "_blank");
              }}
            >
              {button}
            </button>
          </div>
        </div>
        <div style={styles.skipperContainer}>
          <span style={styles.skipperText}>{skipperText}</span>
          <img
            src={Images.galleryArrowDown}
            style={styles.arrowImage}
            onClick={() => {
              navigate(`?page=${skipperLink}`);
            }}
          />
        </div>
      </div>
    </>
  );
};

const styles: StyleSheet = {
  mainContainer: {
    display: "flex",
    flexDirection: "column",
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
  },
  infoContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "1.75rem",
  },
  title: {
    fontSize: fontSize.PageTitle,
    lineHeight: "3rem",
    color: colors.darkBlue,
    zIndex: 1,
    position: "relative",
  },
  body: {
    fontWeight: 400,
    lineHeight: "1.75rem",
    letterSpacing: "0.66px",
    // maxHeight: "8.375rem",
    maxWidth: "47.1875rem",
  },
  linkContainer: {
    display: "flex",
    gap: ".5rem",
    color: colors.darkBlue,
    fontWeight: 400,
    letterSpacing: "0.55px",
    fontFamily: fonts.FbReformaMedium,
    cursor: "pointer",
    zIndex: 1,
  },
  button: {
    backgroundColor: colors.koromiko,
    maxWidth: "24.0625rem",
    minHeight: "3.4375rem",
    borderRadius: "1.75rem",
    cursor: "pointer",
    zIndex: 1,
    border: "none",
    color: colors.black,
    fontSize: fontSize.Text,
    letterSpacing: "0.5px",
    fontWeight: 400,
    fontFamily: fonts.FbReformaMedium,
    padding: "1rem",
  },
  skipperContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    alignItems: "center",
    justifyContent: "center",
    padding: "1rem",
  },
  skipperText: {
    color: colors.pasterlGrayDark,
    fontWeight: 400,
    fontFamily: fonts.FbReformaRegular,
    letterSpacing: "0.48px",
    fontSize: fontSize.Text,
  },
  arrowImage: {
    animationName: "moveUpDown",
    animationIterationCount: "6",
    animationDirection: "alternate",
    animationDuration: ".7s",
    cursor: "pointer",
  },
};

export default InfrastructureTitleDescription;
