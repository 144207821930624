import Images from "../../../../../../../Data/Images/Images";
import { StyleSheet } from "../../../../../Interfaces_tenders/IStyleSheet";
import { FormatDateYYYYMMDD_Dots } from "../../../../../../../Services/DateService";
import colors from "../../../../../../../Assets/Colors/Colors.json";
import i18n from "../../../../../../../Services/i18n";
import { Fragment, useEffect, useState } from "react";
import ReactModal from "react-modal";
import useDeviceMode from "../../../../../../../Utils/useWindowDimensions";
import { StyleMode } from "../../../../../../../Utils/consts";
import ServerFile from "../../../File/ServerFile";
import { IAuctionsSystemExemptionNotices } from "../../../../../Interfaces_tenders/IAuctionsSystemDetails";
import { FilesToZipServiceServer } from "../../../../../../../Services/FilesToZipServiceServer";
import FileViewerServer from "../../../../FileViewer/FileViewerServer";
import { IServerFile } from "../../../../../Interfaces_tenders/IUmbraco";
import { t } from "i18next";
import QueryString from "qs";
import { useLocation, useNavigate } from "react-router-dom";
import { truncateText } from "../../../../../../../Utils/helpers";
import fonts from "../../../../../../../Assets/Fonts/Fonts.json";

const ExemptionNoticesCard: React.FC<IAuctionsSystemExemptionNotices> = (
  props
) => {
  const {
    publishDate,
    subject,
    supplierName,
    documentation,
    dynamicTextsDict,
    currentIndex,
    numOfAuctions,
  } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const dynamicTexts =
    dynamicTextsDict && dynamicTextsDict.length > 0
      ? dynamicTextsDict[0]
      : undefined;
  const [_isModalOpen, _setIsModalOpen] = useState(false);
  const deviceMode = useDeviceMode();
  const [_currentFileName, _setCurrentFileName] = useState<string>("");

  const opinionFile =
    documentation && documentation.filter((file) => file.docType === 5)[0];

  useEffect(() => {
    const urlParams = QueryString.parse(location.search.split("?")[1]);

    const currentUrl = {
      ...urlParams,
      isModalOpen: _isModalOpen.toString(),
    };

   // navigate("?" + QueryString.stringify(currentUrl), { replace: true });
  }, [_isModalOpen]);

  const opinionFilesMessage: IServerFile[] =
    opinionFile &&
    documentation &&
    documentation
      .filter((file) => file.docType === opinionFile.docType)
      .map((file) => {
        return {
          document: file.docName,
          filename: file.docDisplay ? file.docDisplay : t("defaultFileNeme"),
          displayName: opinionFile.docDisplay,
        };
      });

  return (
    <div
      style={styles.exemptionNoticesCardContainer}
      role={"main"}
      tabIndex={0}
      aria-label={
        t("tenderIndexNumber") +
        (currentIndex && currentIndex) +
        t("fromTotal") +
        (numOfAuctions && numOfAuctions)
      }
    >
      <div style={styles.exemptionNoticesHeader}>
        <div style={styles.subjectName} title={subject}>
          {subject && truncateText(subject, 40)}
        </div>
        <div style={styles.supplierRow}>
        <img src={Images.person}></img>
          <div>
            {" "}
            {dynamicTexts && dynamicTexts["supplier"].text}: {supplierName}
          </div>
        </div>
      </div>
      <div style={styles.itemsSideLineContainer}>
        {publishDate &&
          publishDate.toString() !== "0001-01-01T00:00:00" &&
          publishDate.toString() !== "1900-01-01T00:00:00" &&
          publishDate.toString() !== "8900-12-31T00:00:00" &&  (
            <div style={{
              ...styles.itemSideLine,
              borderRight:
                i18n.dir() === "rtl" ? `.1rem solid ${colors.black}` : "",
              borderLeft:
                i18n.dir() === "ltr" ? `.1rem solid ${colors.black}` : "",
            }}>
              <div style={styles.dateTitle}>
                {" "}
                {dynamicTexts && dynamicTexts["publishDate"].text}
              </div>
              <div style={styles.dateFormat}>
                {FormatDateYYYYMMDD_Dots(new Date(publishDate))}
              </div>
            </div>
          )}
        <div style={{
                  ...styles.itemSideLine,
                  borderRight:
                    i18n.dir() === "rtl" ? `.1rem solid ${colors.black}` : "",
                  borderLeft:
                    i18n.dir() === "ltr" ? `.1rem solid ${colors.black}` : "",
                }}>
          {documentation && opinionFile && (
            <Fragment>
              <div style={styles.dateTitle}>
                {" "}
                {dynamicTexts && dynamicTexts["document"].text}
              </div>

              <ServerFile
                filename={
                  opinionFile.docDisplay
                    ? opinionFile.docDisplay
                    : t("defaultFileNeme")
                }
                document={opinionFile.docName}
                onClick={() => {
                  if (deviceMode !== StyleMode.mobile) {
                    _setIsModalOpen(true);
                  } else {
                    const filesToDownload = [
                      ...opinionFilesMessage.filter(
                        (file) => file.document !== null
                      ),
                    ];
                    FilesToZipServiceServer(
                      filesToDownload,
                      opinionFile.docDisplay
                        ? opinionFile.docDisplay
                        : t("defaultFileNeme")
                    );
                  }
                }}
                onChangeFileName={(text) => _setCurrentFileName(text)}
                filenameStyles={{ textDecoration: "underline" }}
                displayName={opinionFile.docDisplay}
              />
            </Fragment>
          )}
        </div>
      </div>
      {documentation && (
        <ReactModal
          isOpen={_isModalOpen}
          ariaHideApp={false}
          style={{
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              width: "85%",
              bottom: "auto",
              height: "50rem",
              transform: "translate(-50%, -50%)",
              border: "none",
              padding: "none",
              position: "absolute",
              maxHeight: "100%",
              overflow: "visible",
            },
            overlay: {
              backgroundColor: colors.modal,
              zIndex: "99999",
              overflow: "hidden",
            },
          }}
          parentSelector={() =>
            document.querySelector("#root") || document.body
          }
          preventScroll={true}
        >
          <div
            style={{
              ...styles.closeMainModalIcon,
              right: i18n.dir() === "rtl" ? "-0.1rem" : "",
              left: i18n.dir() === "ltr" ? "1.1rem" : "",
            }}
            onClick={() => {
              _setIsModalOpen(false);
            }}
          ></div>
          <FileViewerServer
            files={opinionFilesMessage}
            title={subject && truncateText(subject, 40)}
            curFileName={_currentFileName}
            key={new Date().toDateString()}
          />
        </ReactModal>
      )}
    </div>
  );
};

const styles: StyleSheet = {
  exemptionNoticesCardContainer: {
    display: "flex",
    flexDirection: "column",
    height: "16rem",
    maxWidth: "40.625rem",
    minWidth: "25rem",
    width: "80%",
    // gap: ".5rem",
    boxShadow: "0 0 1px 0 rgba(0, 0, 0, 0.2), 0 0 15px 0 rgba(0, 0, 0, 0.19)",
    borderRadius: "0.625rem",
  },
  exemptionNoticesHeader: {
    display: "flex",
    flexDirection: "column",
    paddingInline: "1.45rem",
    paddingTop: "1.45rem",
    gap: "2rem",
    flex: 1,
  },
  subjectName: {
    color: colors.darkBlue,
    fontSize: "1.6rem",
    letterSpacing: "0.039375rem",
    fontFamily: fonts.FbReformaMedium,
    maxWidth: "95%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineHeight:"2rem"
  },
  supplierRow: {
    display: "flex",
    gap: "0.5rem",
  },
  icon: {
    backgroundRepeat: "no-repeat",
    width: "1.25rem",
    height: "1.5rem",
  },
  itemsSideLineContainer: {
    display: "flex",
    alignItems: "center",
    paddingInline: "2rem",
    gap: "5rem",
    flex: 1,
  },
  itemSideLine: {
    // borderRight: i18n.dir() === "rtl" ? `.1rem solid ${colors.black}` : "",
    // borderLeft: i18n.dir() === "ltr" ? `.1rem solid ${colors.black}` : "",
    padding: "0 1rem",
  },
  dateTitle: {
    fontFamily: fonts.FbReformaRegular,
    color: colors.pasterlGrayDark,
  },
  closeMainModalIcon: {
    position: "absolute",
    backgroundImage: `url(${Images.close})`,
    backgroundRepeat: "no-repeat",
    width: "3rem",
    height: "2.5rem",
    top: "0",
    cursor: "pointer",
    transform: "scale(1.5)",
  },
};

export default ExemptionNoticesCard;
