import { ISecondaryTitle } from "../../Interfaces/ISecondaryTitle";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import colors from "../../Assets/Colors/Colors.json";
import fonts from "../../Assets/Fonts/Fonts.json";

const SecondaryTitle: React.FC<ISecondaryTitle> = (props) => {
  const { text } = props;
  return (
    <div style={styles.container}>
      <h3 style={styles.title}>{text}</h3>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    maxWidth: "76rem",
    width: "80%",
  },
  title: {
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaMedium,
    letterSpacing: "0.75px",
    fontSize: "1.5625rem",
  },
};

export default SecondaryTitle;
