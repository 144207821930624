import { useSelector } from "react-redux";
import { IImageAndLink } from "../../Interfaces/IUmbraco";
import { Translations } from "../../Services/GlobalService";
import i18n from "../../Services/i18n";
import { StyleMode } from "../../Utils/consts";
import useDeviceMode from "../../Utils/useWindowDimensions";
import { ScheduleState } from "../../Redux/Slices/scheduleSlice";
import Config from "../../Configuration/server";
import { IStation } from "../../Interfaces/IStation";
import store from "store2";
import { GetStationsDictionary } from "../../Utils/helpers";
import { IsToday, IsTommorow } from "../../Services/DateService";
import { t } from "i18next";
import { AddToDataLayer } from "../../Services/GoogleAnalyticsService";
interface IPaymentOptions {
  paymentOptions?: IImageAndLink[],
  chargingOptions?:IImageAndLink[],
  currentProfileName?: string;
}
const PaymentOptions: React.FC<IPaymentOptions> = (props) => {
  const deviceMode = useDeviceMode();

  const schedule = useSelector(
    (state: any) => state.ScheduleReducer
  ) as ScheduleState;

  const Translation = Translations[
    i18n.language as `he` | `en` | `ar` | `ru`
  ] as any;
  return (
    <div
      className="row-around"
      style={{
        flexDirection: deviceMode === StyleMode.mobile  ? "column": "row",
        gap: "2rem",
        paddingTop:"0.5rem",
        alignItems:"center"
      }}
    >
         <div
        style={{
          textAlign: "center",
          gap: "1rem",
          display: "flex",
          flexDirection: "column",
          maxWidth:"25rem"
        }}
      >
        <p style={{fontWeight:"500"}}>{Translation["PaymentTravel"]}</p>

        <div className="row" style={{ gap: "2rem", justifyContent: "center",flexWrap:"wrap" }}>
          {props?.paymentOptions?.map((item,index) =>
          <div onClick={() => {            
            const allStations = store.session.get(
              `${Config.BASE_LOCALSTORAGE_NAME}stations`
            );
            const stations: IStation[] = allStations && allStations[i18n.language];
            const dictStations = stations && GetStationsDictionary(stations);

            let routePlanNavigatedFrom = store.session.get("routePlanNavigatedFrom");
            if(routePlanNavigatedFrom === null)
              routePlanNavigatedFrom = "main search";

            if(schedule.fromStation && schedule.toStation) {
              AddToDataLayer({
                event_name: `pay_platform_click`,
                from_station: dictStations[schedule.fromStation].stationName,
                to_station: dictStations[schedule.toStation].stationName,
                when: IsToday(new Date(schedule.date)) ? "today" : IsTommorow(new Date(schedule.date)) ? "tomorrow" : "custom date",
                departure_time: `${schedule.hours}:${schedule.minutes}` ,
                type: routePlanNavigatedFrom,
                description: item.imageAlt,
                description_2: props.currentProfileName || "",
              })
            }
          }}>
            <a href={item.link.redirectTo} key={index} target={"_blank"}>
              <img src={item.image._url} alt={item.imageAlt} style={{ width: "4rem" }} />
            </a>
          </div>
          )}
         
        </div>
      </div>
      <div
        style={{
          textAlign: "center",
          gap: "1rem",
          display: "flex",
          flexDirection: "column",
          maxWidth:"15rem"
        }}
      >
        <p style={{fontWeight:"500"}}>{Translation["PriceRavKavChrge"]}</p>
        <div className="row" style={{ gap: "2rem", justifyContent: "center",flexWrap:"wrap" }}>
        {props?.chargingOptions?.map((item,index) => 
          <div onClick={() => {            
            const allStations = store.session.get(
              `${Config.BASE_LOCALSTORAGE_NAME}stations`
            );
            const stations: IStation[] = allStations && allStations[i18n.language];
            const dictStations = stations && GetStationsDictionary(stations);

            let routePlanNavigatedFrom = store.session.get("routePlanNavigatedFrom");
            if(routePlanNavigatedFrom === null)
              routePlanNavigatedFrom = "main search";

            if(schedule.fromStation && schedule.toStation) {
              AddToDataLayer({
                event_name: `charge_platform_click`,
                from_station: dictStations[schedule.fromStation].stationName,
                to_station: dictStations[schedule.toStation].stationName,
                when: IsToday(new Date(schedule.date)) ? "today" : IsTommorow(new Date(schedule.date)) ? "tomorrow" : "custom date",
                departure_time: `${schedule.hours}:${schedule.minutes}` ,
                type: routePlanNavigatedFrom,
                description: item.imageAlt,
                description_2: props.currentProfileName || "",
              })
            }
          }}>
            <a href={item.link.redirectTo} key={index} target={"_blank"}>
              <img src={item.image._url} alt={item.imageAlt} style={{ width: "4rem" }} />
            </a>
          </div>
        )}
        </div>
      </div>
    </div>
  );
};
export default PaymentOptions;
