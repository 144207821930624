import { useEffect, useState } from "react";
import colors from "../../../Assets/Colors/Colors.json";
import fonts from "../../../Assets/Fonts/Fonts.json";
import fontSize from "../../../Assets/Fonts/FontsSizes.json";
import { IContentTabs } from "../../../Interfaces/IContentTab";
import { IAnyElement } from "../../../Interfaces/IStaticPage";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import i18n from "../../../Services/i18n";
import { StyleMode } from "../../../Utils/consts";
import useDeviceMode from "../../../Utils/useWindowDimensions";
import AllElements from "../AllElements";
import FileElement from "../FileElement/FileElement";
import RichTextEditor from "../RichTextEditor";
import ContentTab from "./ContentTab";

const ContentTabs: React.FC<IContentTabs> = (props) => {
  const { contentTabs } = props;
  const [_selectedCapsule, _setSelectedCapsule] = useState(0);
  const [_currentElements, _setCurrentElements] = useState<
    (IAnyElement & any)[]
  >([]);
  const totalPercent = 100 / contentTabs.length;

  useEffect(() => {
    if (contentTabs.length > 0) handleTabClick(0, contentTabs[0].elements);
  }, []);

  const handleTabClick = (tabIndex: number, elements: IAnyElement[]) => {
    _setSelectedCapsule(tabIndex);
    _setCurrentElements(elements);
  };
  const deviceMode = useDeviceMode();
  return (
    <div style={styles.container}>
      <div
        style={{
          ...styles.capsuleWrapper,
          width: deviceMode === StyleMode.desktop ? "80%" : "100%",
          height: deviceMode === StyleMode.desktop ? "3.2rem" : "4rem",
        }}
      >
        <div
          style={{
            ...styles.capsuleCalculations,
            width: `${totalPercent}%`,
            left:
              i18n.dir() === "rtl"
                ? 100 - totalPercent * (_selectedCapsule + 1) + "%"
                : "",
            right:
              i18n.dir() === "ltr"
                ? 100 - totalPercent * (_selectedCapsule + 1) + "%"
                : "",
            transition: `${i18n.dir() === "rtl" ? "left" : "right"} 120ms`,
          }}
        ></div>
        {contentTabs.map((contentTab, index) => (
          <ContentTab
            {...contentTab}
            key={index}
            isSelected={index === _selectedCapsule}
            index={index}
            handleTabClick={handleTabClick}
          />
        ))}
      </div>
      <div
        style={{
          ...styles.elementsContainer,
          maxHeight: deviceMode === StyleMode.desktop ? "26rem" : "22rem",
          maskImage: `linear-gradient(to bottom, black calc(100% - 48px), transparent 100%)`,
          paddingBottom: "3rem",
          // boxSizing: "border-box",
        }}
        className="customScrollbarBlue"
      >
        {_currentElements.map((element, index) =>
          element.contentTypeAlias === "richTextEditor" ? (
            <RichTextEditor
              key={index}
              {...element}
              customStyles={{
                fontSize: fontSize.Text,
              }}
            />
          ) : element.contentTypeAlias === "fileElement" ? (
            <FileElement
              key={index}
              {...element}
              containerStyles={{
                fontSize: fontSize.Text,
                alignSelf: "start",
                marginInlineStart: "5rem",
                marginTop: "1.5rem",
              }}
            />
          ) : (
            <AllElements
              key={index}
              elementName={element.contentTypeAlias}
              {...element}
            />
          )
        )}
        <div style={styles.fade}></div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    marginTop: "3rem",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    textAlign: "center",
    fontSize: fontSize.UmbracoElementTitle,
    fontWeight: 400,
    lineHeight: "normal",
    letterSpacing: "0.65px",
    fontFamily: fonts.FbReformaMedium,
    marginBlockEnd: 0,
  },
  capsuleWrapper: {
    zIndex: "3",
    display: "flex",
    justifyContent: "center",
    backgroundColor: colors.white,
    width: "80%",
    height: "3.2rem",
    textAlign: "center",
    borderRadius: "3rem",
    boxShadow: "0px 2px 20px 0px rgba(0, 0, 0, 0.20)",
    transform: "translateY(-2.2rem)",
    position: "relative",
  },
  capsuleCalculations: {
    position: "absolute",
    top: ".08rem",
    backgroundColor: colors.chooseTimeCapsula,
    height: "85%",
    border: `3px solid ${colors.white}`,
    borderRadius: "3rem",
    borderColor: colors.white,
    cursor: "pointer",
    fontSize: fontSize.H1,
    whiteSpace: "nowrap",
    zIndex: "-1",
  },
  capsuleButtonSelected: {
    color: colors.white,
  },
  elementsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    overflow: "auto",
    maxHeight: "26rem",
  },
};

export default ContentTabs;
