import useDeviceMode from "../../Utils/useWindowDimensions";

const InfoPayment: React.FC<any> = (props) => {
  const {} = props;
  const deviceMode = useDeviceMode();

  return (
    <div className="modal-content">
      <pre>{JSON.stringify(props)}</pre>
    </div>
  );
};

export default InfoPayment;
