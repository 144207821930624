import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import colors from "../../Assets/Colors/Colors.json";
import fonts from "../../Assets/Fonts/Fonts.json";
import fontSize from "../../Assets/Fonts/FontsSizes.json";
import { IWorkersInTrain } from "../../Interfaces/ICareers";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import { IsMobile } from "../../Services/DeviceService";
import { StyleMode } from "../../Utils/consts";
import useDeviceMode from "../../Utils/useWindowDimensions";
import Button from "./Careers/Button/Button";
import Worker from "./Worker";
import WorkerMobile from "./WorkerMobile";

const WorkersInTrain: React.FC<IWorkersInTrain> = (props) => {
  const { title, inTitle, buttons, workers } = props;

  const deviceMode = useDeviceMode();
  const warrperStyle: `center` | `unset` = `${IsMobile() ? `center` : `unset`}`;
  return (
    <div>
      <div
        style={{
          ...styles.maxWidth,
          display: "flex",
          justifyContent: deviceMode !== StyleMode.desktop ? "center" : "unset",
        }}
      >
        <div
          style={{
            ...styles.row,
            paddingBottom: "2rem",
            justifyContent: warrperStyle,
          }}
        >
          <div style={{ gap: "1rem" }}
                id="pageMainContent"
                tabIndex={!IsMobile() ? 0 : -1}>
            <div
              style={{
                flex: 1,
                ...styles.column,
                gap: "2rem",
              }}
            >
              <h2
                style={{
                  ...styles.title,
                  flexDirection: "column",
                  justifyContent:
                    deviceMode !== StyleMode.desktop ? "center" : "",
                  alignItems: deviceMode !== StyleMode.desktop ? "center" : "",
                }}
              >
                <span>{title}</span>
                <span style={styles.inTitle}>{inTitle}</span>
              </h2>
              {/* <p
                style={styles.subtitle}
              >{`${workers.length} ${props.jobsOpen} | ${props.locations}`}</p> */}
              <div
                style={{
                  ...styles.column,
                  gap: "1rem",
                  flexDirection:
                    deviceMode === StyleMode.desktop ? "row" : "column",
                    justifyContent:
                    deviceMode !== StyleMode.desktop ? "center" : "",
                  alignItems: deviceMode !== StyleMode.desktop ? "center" : "",
                }}
              >
                {buttons.map((item, index) => {
                  var style =
                    item.backgroundColor.length > 0
                      ? styles.btn
                      : {
                          ...styles.btn,
                          ...styles.btnOutline,
                          fontSize: fontSize.Text,
                        };
                  return <Button {...item} key={index} style={style} />;
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Swiper
        navigation={true}
        modules={[Navigation]}
        spaceBetween={15}
        // slidesPerGroup={1}
        breakpoints={{
          0: {
            slidesPerView: 2,
            // slidesPerGroup
          },
          450: {
            slidesPerView: 4,
            // slidesPerGroup
          },
          768: {
            slidesPerView: 5,
          },
          920: {
            slidesPerView: 6,
          },
          1024: {
            slidesPerView: 8,
          },
        }}
        style={{
          paddingTop: "2rem",
          overflow: "unset",
          // alignItems: "end",
          // justifyItems: "center",
          marginBottom: "0.5rem",
        }}
        loop={workers.length > 7 && deviceMode === StyleMode.desktop}
        className={`worker-swiper ${
          deviceMode === StyleMode.desktop && workers.length < 8
            ? "swiperCenter"
            : ""
        }`}
        // slideToClickedSlide={true}
        role={"region"}
      >
        {workers.map((item, index) => (
          <SwiperSlide
            key={index}
            className="my-SwiperSlide"
            // style={{ alignItems: "end", width: "12rem" }}
          >
            {deviceMode === StyleMode.desktop ? (
              <Worker
                {...item}
                index={index}
                // onClick={(worker) => {
                //   dispatch(
                //     openModal({
                //       visible: true,
                //       closeButtonType: "internal",
                //       childrenComponent: (
                //         <TextAndBGModalExtended
                //           img={worker.profile?._url}
                //           descriptionTitle={worker.descriptionTitle}
                //           description={worker.description}
                //           note={worker.note}
                //           title={`${worker.firstName} ${worker.lastName}`}
                //           noteTitle={worker.noteTitle}
                //         />
                //       ),
                //     })
                //   );
                // }}
              />
            ) : (
              <WorkerMobile {...item} index={index} />
            )}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
const styles: StyleSheet = {
  wrap: {
    display: "flex",
    flexWrap: "wrap",
  },
  bgGray: {
    backgroundColor: "#F9F9F9",
  },
  title: {
    fontSize: fontSize.PageTitle,
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaLight,
    lineHeight: "4rem",
    margin: 0,
    display: "flex",
  },
  inTitle: {
    fontFamily: fonts.FbReformaMedium,
  },
  subtitle: {
    fontSize: fontSize.H1,
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaLight,
    margin: 0,
  },
  info: {
    fontSize: fontSize.Paragraph,
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaLight,
    margin: 0,
  },

  mainHeader: {
    minHeight: "15.6rem",
    display: "flex",
    height: "100vh",
    maxHeight: "28rem",
  },
  cover: {
    backgroundColor: "rgba(0, 70, 143, 0.5)",
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  maxWidth: {
    maxWidth: "68rem",
    margin: "auto",
    paddingBlock: "2rem",
    paddingInline: "1rem",
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  column: {
    display: "flex",
    flexDirection: "column",
  },
  btn: {
    borderRadius: "3.75rem",
    backgroundColor: "rgb(249, 186, 77)",
    border: 0,
    fontSize: fontSize.Text,
    padding: "1rem",
    fontFamily: fonts.FbReformaMedium,

    width: "16rem",
  },
  btnOutline: {
    backgroundColor: "transparent",
    color: colors.darkBlue,
    border: "0.06rem solid",
  },
  wrapperInfo: {
    flex: "1.5 1 0%",
    flexDirection: "column",
    gap: "1rem",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    textAlign: "center",
  },
  infoBlue: {
    fontSize: fontSize.CareerElements,
    fontFamily: fonts.FbReformaMedium,
    lineHeight: "normal",
    color: colors.darkBlue,
  },
  name: {
    fontSize: fontSize.H1,
    color: "rgb(249, 186, 77)",
  },
  lineDivider: {
    width: 1,

    backgroundColor: colors.darkBlue,
  },
  description: {
    fontFamily: fonts.FbReformaLight,
    color: colors.darkBlue,
    fontSize: fontSize.UmbracoElementTitle,
    letterSpacing: "0.023rem",
    lineHeight: "2rem",
  },
  cardJob: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0 2px 23px 0 rgb(0 0 0 / 8%)",

    padding: "1rem",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    flex: 1,
  },
  tags: {
    display: "flex",
    flexWrap: "wrap",
  },
  tag: {
    border: "0.06rem solid #00458a",
    borderRadius: "3.1rem",
    minWidth: "4.4rem",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#D8F2FC",
    textAlign: "center",
    display: "inline-flex",
  },
  linkMoreInfo: {
    color: "#009DD6",
    fontSize: fontSize.Paragraph,
    letterSpacing: "0.03rem",
    lineHeight: "1.5rem",
  },
  whyToWork: {
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
  },
  whyTitle: {
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaRegular,
    fontSize: fontSize.H1,
  },
  infoBlack: {
    color: "#282828",
    fontSize: fontSize.UmbracoElementTitle,
    letterSpacing: "0.042rem",
    fontFamily: fonts.FbReformaLight,

    lineHeight: "2rem",
  },
  // ".boxss": { backgroundColor: "#D8F3FE" },
  topBox: {
    backgroundColor: "#D8F3FE",
    padding: "1rem",
    flexDirection: "column",
    display: "flex",
    gap: "0.47rem",
  },
  letsTalks: {
    color: colors.darkBlue,
    fontSize: fontSize.UmbracoElementTitle,
    fontFamily: fonts.FbReformaLight,
    letterSpacing: "0.44px",
    lineHeight: "1.87rem",
  },
  letsNote: {
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaMedium,
    fontSize: fontSize.UmbracoElementTitle,
    letterSpacing: "0.047rem",
    lineHeight: "1.68rem",
  },
  letsDescription: {
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaRegular,
    fontSize: fontSize.Text,
    letterSpacing: "0.57px",
    lineHeight: "1.125rem",
  },
};

export default WorkersInTrain;
