import { Fragment, useEffect, useRef, useState } from "react";
import { StyleSheet } from "../../../../Interfaces/IStyleSheet";
import { IPodcast, IPodcasts } from "../../../../Interfaces/IUmbraco";
import InputTextSearchForm from "../../../Form/InputTextSearchForm/InputTextSearchForm";
import Title from "../../Title";
import PodcastMobile from "./PodcastMobile";
import colors from "../../../../Assets/Colors/Colors.json";
import { t } from "i18next";
import Images from "../../../../Data/Images/Images";
import i18n from "../../../../Services/i18n";
import ShareModal from "../../../Modal/ShareModal";
import ReactModal from "react-modal";

const PodcastsMobile: React.FC<IPodcasts> = (props) => {
  const { title, podcastsList, searchPlaceholder } = props;
  const [_filteredPodcasts, _setFilteredPodcasts] = useState<IPodcast[]>([
    ...podcastsList,
  ]);
  const [_selectedPodcast, _setSelectedPodcast] = useState<IPodcast>();
  const [_currentPodcastIndex, _setCurrentPodcastIndex] = useState(0);
  const [_isShareModalVisible, _setIsShareModalVisible] = useState(false);
  const [_podcastShareLink, _setPodcastShareLink] = useState("");
  const selectedPodcastRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (selectedPodcastRef.current) {
      selectedPodcastRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [_selectedPodcast]);

  const handleSearchPodcasts = (text: string) => {
    const filteredPodcasts = [
      ...podcastsList.filter(
        (podcast) =>
          podcast.titleAndDescription.description.includes(text) ||
          podcast.titleAndDescription.title.includes(text)
      ),
    ];
    _setFilteredPodcasts(filteredPodcasts);
  };

  const handleLoadPoscast = (podcast: IPodcast) => {
    _setSelectedPodcast(podcast);
  };

  const handlePreviousPodcast = () => {
    const previousPodcastIndex =
      _currentPodcastIndex - 1 >= 0
        ? _currentPodcastIndex - 1
        : podcastsList.length - 1;
    _setCurrentPodcastIndex(previousPodcastIndex);
    handleLoadPoscast(podcastsList[previousPodcastIndex]);
  };
  const handleNextPodcast = () => {
    const nextPodcastIndex =
      _currentPodcastIndex + 1 <= podcastsList.length - 1
        ? _currentPodcastIndex + 1
        : 0;
    _setCurrentPodcastIndex(nextPodcastIndex);
    handleLoadPoscast(podcastsList[nextPodcastIndex]);
  };

  return (
    <Fragment>
      <div style={styles.container}>
        {/* <Title {...title} /> */}
        <InputTextSearchForm
          placeholder={searchPlaceholder}
          onChange={handleSearchPodcasts}
        />
        <div style={styles.podcasts}>
          {_filteredPodcasts.map((podcast, index) => (
            <Fragment key={index}>
              <PodcastMobile
                {...podcast}
                onClick={() => {
                  _setSelectedPodcast(podcast);
                  _setCurrentPodcastIndex(index);
                }}
                onShareClick={(podcastLink) => {
                  _setPodcastShareLink(podcastLink);
                  _setIsShareModalVisible(true);
                }}
                isSelected={
                  JSON.stringify(podcast) === JSON.stringify(_selectedPodcast)
                }
              />
              {index < _filteredPodcasts.length - 1 && (
                <div style={styles.line}></div>
              )}
            </Fragment>
          ))}
        </div>
      </div>
      <ReactModal
        isOpen={_isShareModalVisible}
        ariaHideApp={false}
        style={{
          content: {
            width: "100%",
            maxWidth: "42rem",
            border: "none",
            padding: "none",
            position: "absolute",
            maxHeight: "100%",
            overflow: "visible",
            backgroundColor: "transparent",
          },
          overlay: {
            backgroundColor: "transparent",
            zIndex: "9999999999999999",
            overflow: "hidden",
          },
        }}
        parentSelector={() => document.querySelector("#root") || document.body}
        preventScroll={true}
      >
        <ShareModal
          title={t("Share")}
          icon={Images.share}
          onClose={() => _setIsShareModalVisible(false)}
          items={[
            {
              name: t("Facebook"),
              icon: Images.facebookHeader,
              link: decodeURI(
                `https://www.facebook.com/sharer/sharer.php?u=${_podcastShareLink}`
              ),
              ariaLabel: t("ShareFacebook"),
            },
            {
              name: t("WhatsApp"),
              icon: Images.whatsapp,
              link: decodeURI(`https://wa.me/?text=${_podcastShareLink}`),
              ariaLabel: t("ShareWhatsapp"),
            },
            {
              name: t("Mail"),
              icon: Images.shareMail,
              link: decodeURI(
                `mailto:?subject=${t("SharePodcast")}&body=${_podcastShareLink}`
              ),
              ariaLabel: t("ShareEmail"),
            },
          ]}
        />
      </ReactModal>
    </Fragment>
  );
};

const styles: StyleSheet = {
  container: {
    display: "flex",
    flexDirection: "column",
    width: "80%",
    maxWidth: "76rem",
    gap: "5rem",
  },
  podcasts: {
    display: "flex",
    flexDirection: "column",
    gap: "3rem",
  },
  line: {
    width: "100%",
    height: ".01rem",
    backgroundColor: colors.pastelGray,
  },
  sharePodcastModalOverlay: {
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    backgroundColor: colors.modal,
    zIndex: "99999",
  },
  sharePodcastModal: {
    backgroundColor: colors.white,
    position: "absolute",
    left: "0",
    bottom: "0",
    maxHeight: "80%",
    width: "100%",
    minHeight: "40%",
    borderTopLeftRadius: "2rem",
    borderTopRightRadius: "2rem",
    padding: "2rem",
    boxSizing: "border-box",
  },
  sharePodcastModalContainer: {
    display: "flex",
    justifyContent: "center",
    gap: "3rem",
    alignItems: "center",
    height: "100%",
    paddingTop: "3rem",
  },
  closeButton: {
    position: "absolute",
    zIndex: "2",
    top: "-3rem",
    cursor: "pointer",
  },
};

export default PodcastsMobile;
