import { IButtonFuncional } from "../../../Interfaces/IButtonFunctional";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import colors from "../../../Assets/Colors/Colors.json";
import fonts from "../../../Assets/Fonts/Fonts.json";
import fontSize from "../../../Assets/Fonts/FontsSizes.json";
import { useState } from "react";
import InfrastructureTitleDescriptionModal from "../InfrastructureTitleDescription/InfrastructureTitleDescriptionModal";

const ButtonFunctional: React.FC<IButtonFuncional> = (props) => {
  const {
    text,
    textColor,
    backgroundColor,
    borderColor,
    buttonType,
    modal,
    link,
  } = props;

  const [_isModalOpen, _setIsModalOpen] = useState(false);

  const closeModal = () => {
    _setIsModalOpen(false);
  };

  return (
    <>
      {modal && _isModalOpen && (
        <InfrastructureTitleDescriptionModal {...modal} onClose={closeModal} />
      )}
      <button
        tabIndex={0}
        role={"link"}
        style={{
          ...styles.button,
          color: `#${textColor}`,
          backgroundColor: `#${backgroundColor}`,
          borderColor: `#${borderColor}`,
        }}
        onClick={() => {
          if (buttonType === "Link") {
            window.open(link, "_blank");
          } else {
            _setIsModalOpen(true);
          }
        }}
      >
        {text}
      </button>
    </>
  );
};

const styles: StyleSheet = {
  button: {
    backgroundColor: colors.koromiko,
    borderRadius: "1.75rem",
    cursor: "pointer",
    zIndex: 1,
    color: colors.black,
    fontSize: fontSize.Text,
    letterSpacing: "0.5px",
    fontWeight: 400,
    fontFamily: fonts.FbReformaMedium,
    padding: "10px 20px 10px 20px",
    minWidth: "11.6875rem",
  },
};

export default ButtonFunctional;
