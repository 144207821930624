import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import Images from "../../Data/Images/Images";
import { UmbracoStoreState } from "../../Redux/Slices/umbracoSlice";
import { GetDateAsTextFormat } from "../../Services/DateService";
import { GetStationsDictionary } from "../../Utils/helpers";
import { IStation } from "../../Interfaces/IStation";
import Config from "../../Configuration/server";
import store from "store2";
import i18n from "../../Services/i18n";

const PrintControl = React.forwardRef((props: any, ref) => {
  const { _routes, fromStation, toStation } = props;

  let departureTime = _routes[0]?.stations[0]?.departureTime;
  const umbracoStore = (
    useSelector((state: any) => state.UmbracoStoreReducer) as UmbracoStoreState
  ).umbracoStore;

  const allStations = store.session.get(
    `${Config.BASE_LOCALSTORAGE_NAME}stations`
  );
  const stations: IStation[] = allStations && allStations[i18n.language];
  const dictStations = stations && GetStationsDictionary(stations);

  return (
    <div
      style={{
        direction: i18n.dir(),
      }}
    >
      <div className="top-banner">
        <div className="logo">
          <img
            src={umbracoStore.headerContent?.logo._url}
            width={`auto`}
            height={`auto`}
            alt={``}
          />
        </div>
        <div className="header-print">
          {_routes.length > 0 && (
            <>
              <div>
                {GetDateAsTextFormat(new Date(departureTime))}{" "}
                {moment(departureTime).format("HH:mm")}
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span>{dictStations[fromStation].stationName}</span>
                <img
                  src={Images.switch}
                  style={{ paddingInline: ".5rem" }}
                  width={`20`}
                  alt={``}
                />{" "}
                <span>{dictStations[toStation].stationName}</span>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
});

export default PrintControl;
