import { ICookiesSettingsModal } from "../../../Interfaces/ICookiesModal";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import colors from "../../../Assets/Colors/Colors.json";
import fonts from "../../../Assets/Fonts/Fonts.json";
import Images from "../../../Data/Images/Images";
import i18n from "../../../Services/i18n";
import { t } from "i18next";
import { useState, useEffect, useRef } from "react";
import Config from "../../../Configuration/server";
import store from "store2";
import fontSize from "../../../Assets/Fonts/FontsSizes.json";
import { IsMobile } from "../../../Services/DeviceService";

const CookiesSettingsModal: React.FC<ICookiesSettingsModal> = (props) => {
  const { onClose, onButtonClick, cookies } = props;
  const [_cookiesToggleValue, _setCookiesToggleValue] = useState(false);
  const [_googleAnalyticsValue, _setGoogleAnalyticsValue] = useState(false);
  const [_previewValue, _setPreviewValue] = useState(false);
  const closeButtonRef = useRef<HTMLImageElement>(null);
  const settingsModalRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    const cookieString = document.cookie;
    const cookieName = `${Config.BASE_LOCALSTORAGE_NAME}_Cookies_Approve`;
    const cookie = cookieString
      .split("; ")
      .find((cookie) => cookie.includes(cookieName));
    if (cookie) {
      const result = cookie.split("=")[1];
      if (result === "true") {
        _setCookiesToggleValue(true);
      } else if (result === "false") {
        _setCookiesToggleValue(false);
      }
    } else {
      _setCookiesToggleValue(false);
    }

    const googleAnalyticsCookieName = `${Config.BASE_LOCALSTORAGE_NAME}_Google_Analytics_Enabled`;
    const googleAnalytics = cookieString
      .split("; ")
      .find((cookie) => cookie.includes(googleAnalyticsCookieName));
    if (googleAnalytics) {
      const result = googleAnalytics.split("=")[1];
      if (result === "true") {
        _setGoogleAnalyticsValue(true);
      } else if (result === "false") {
        _setGoogleAnalyticsValue(false);
      }
    } else {
      _setGoogleAnalyticsValue(false);
    }

    if (Config.PREVIEW_ACTIVE === `true`) {
      if (store.get(`isPreview`) === `true`) {
        _setPreviewValue(true);
      } else {
        _setPreviewValue(false);
      }
    }
  }, []);

  const handleConfirm = () => {
    onButtonClick({
      cookiesToggleValue: _cookiesToggleValue,
      googleAnalyticsValue: _googleAnalyticsValue,
      previewValue: _previewValue,
    });
  };

  useEffect(() => {
    closeButtonRef.current?.focus();
  }, [closeButtonRef]);

  return (
    <div style={styles.container} onClick={onClose}>
      <div
        style={styles.modal}
        role="dialog"
        aria-modal="true"
        tabIndex={!IsMobile() ? 0 : -1}
        ref={settingsModalRef}
        onClick={(e) => e.stopPropagation()}
      >
        <img
          ref={closeButtonRef}
          id={"cookiesSettingsClose"}
          role={"button"}
          aria-label={t("Close")}
          alt={``}
          tabIndex={!IsMobile() ? 0 : -1}
          src={Images.closeButton}
          style={{
            ...styles.closeButton,
            left: i18n.dir() === "rtl" ? 0 : "",
            right: i18n.dir() === "ltr" ? 0 : "",
          }}
          onClick={() => onClose()}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onClose();
            }
          }}
        />
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <div style={styles.information}>
            <img src={Images.cookie} alt={``} width={"90"} />
            <h1 style={styles.title}>{cookies?.settingsTitle}</h1>
            <div style={styles.settings}>
              <div style={styles.settingsRow}>
                <div>{cookies?.settingsEnableCookies}</div>
                <label className="switch">
                  <input
                    tabIndex={!IsMobile() ? 0 : -1}
                    type="checkbox"
                    checked={_cookiesToggleValue}
                    onChange={(e) => {
                      _setCookiesToggleValue(e.target.checked);
                    }}
                  />
                  <span className="slider round" />
                </label>
              </div>
              <div style={styles.settingsRow}>
                <div>{cookies?.settingsEnableGoogleAnalytics}</div>
                <label className="switch">
                  <input
                    tabIndex={!IsMobile() ? 0 : -1}
                    type="checkbox"
                    checked={_googleAnalyticsValue}
                    onChange={(e) => {
                      _setGoogleAnalyticsValue(e.target.checked);
                    }}
                  />
                  <span className="slider round" />
                </label>
              </div>
              {Config.PREVIEW_ACTIVE === `true` && (
                <div style={styles.settingsRow}>
                  <div>{`תצוגה מקדימה פעילה`}</div>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={_previewValue}
                      onChange={(e) => {
                        _setPreviewValue(e.target.checked);
                      }}
                    />
                    <span className="slider round" />
                  </label>
                </div>
              )}
            </div>
          </div>
          <div style={styles.buttons}>
            <button style={styles.acceptButton} onClick={handleConfirm}>
              {t("Confirm")}
            </button>
            <button
              style={styles.refuseButton}
              onClick={() => onClose()}
              onKeyDown={(e) => {
                if (e.key === "Tab") {
                  if (closeButtonRef.current) {
                    e.stopPropagation();
                    e.preventDefault();
                    closeButtonRef.current.focus();
                  }
                }
              }}
            >
              {t("Cancel")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    width: "100%",
    height: "100%",
    position: "fixed",
    left: "0",
    top: "0",
    backgroundColor: colors.modal,
    zIndex: "999999",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  modal: {
    position: "absolute",
    height: "32rem",
    width: "100%",
    maxWidth: "45rem",
    backgroundColor: colors.white,
    display: "flex",
    justifySelf: "center",
    margin: "auto",
    borderRadius: "0.625rem",
    boxSizing: "border-box",
    // padding: "5rem",
  },
  title: {
    fontFamily: fonts.FbReformaMedium,
    fontSize: fontSize.UmbracoElementTitle,
    letterSpacing: "0.045rem",
  },
  information: {
    fontFamily: fonts.FbReformaRegular,
    fontSize: fontSize.Paragraph,
    lineHeight: "1.75rem",
    letterSpacing: "0.03875rem",
    maxWidth: "60.5rem",
    color: colors.darkBlue,
    display: "flex",
    flexDirection: "column",
    padding: "3rem",
  },
  acceptButton: {
    borderRadius: "1rem",
    backgroundColor: colors.darkBlue,
    border: ".1rem solid transparent",
    color: colors.white,
    fontSize: fontSize.Paragraph,
    minWidth: "10rem",
    height: "2.2rem",
    cursor: "pointer",
    fontFamily: fonts.FbReformaMedium,
  },
  refuseButton: {
    borderRadius: "1rem",
    backgroundColor: colors.white,
    border: `.1rem solid ${colors.darkBlue}`,
    color: colors.darkBlue,
    fontSize: fontSize.Paragraph,
    minWidth: "10rem",
    height: "2.2rem",
    cursor: "pointer",
    fontFamily: fonts.FbReformaMedium,
  },
  buttons: {
    display: "flex",
    gap: "2rem",
    justifyContent: "center",
  },
  closeButton: {
    position: "absolute",
    top: 0,
    cursor: "pointer",
  },
  link: {
    color: colors.oldLavender,
    cursor: "pointer",
    textDecoration: "underline",
  },
  settings: {
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    gap: "1rem",
  },
  settingsRow: {
    display: "flex",
    justifyContent: "space-between",
  },
};

export default CookiesSettingsModal;
