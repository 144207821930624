import { t } from "i18next";
import { Fragment, useEffect, useRef, useState } from "react";
import store from "store2";
import colors from "../../Assets/Colors/Colors.json";
import fonts from "../../Assets/Fonts/Fonts.json";
import Config from "../../Configuration/server";
import {
  ILastTravel,
  ILastTravelLocalStorage,
  ILastTravelsMain,
} from "../../Interfaces/ILastTravel";
import { IStation } from "../../Interfaces/IStation";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import i18n from "../../Services/i18n";
import { StyleMode } from "../../Utils/consts";
import { GetStationsDictionary } from "../../Utils/helpers";
import useDeviceMode from "../../Utils/useWindowDimensions";
import LastTravelsList from "./LastTravelsList";
import fontSize from "../../Assets/Fonts/FontsSizes.json";
import { IsMobile } from "../../Services/DeviceService";
import { SystemParamsState } from "../../Redux/Slices/systemParamsSlice";
import { useSelector } from "react-redux";

const LastTravelsMain: React.FC<ILastTravelsMain> = (props) => {
  const {
    myTravelsText,
    myTravelsResetText,
    noTravelsImage,
    noTravelsText,
    noTravelsOptionalText,
    hideTravelsHistory,
  } = props;

  const [_lastTravels, _setLastTravels] = useState<ILastTravel[]>([]);
  const deviceMode = useDeviceMode();
  const lastTravelsContainerRef = useRef<HTMLDivElement>(null);
  const [_lastFavoriteKey, _setLastFavoriteKey] = useState("");
  const [_isResetOver, _setIsResetOver] = useState(false);
  const [_isHistoryDisplay, _setIsHistoryDisplay] = useState(true);
  const checkboxRef = useRef<HTMLInputElement>(null);

  const systemParams = (
    useSelector((state: any) => state.SystemParamsReducer) as SystemParamsState
  ).systemParams;

  const systemParamsDictionary = systemParams.reduce(
    (result: { [key: string]: string }, current) => {
      result[current.key] = current.value;
      return result;
    },
    {}
  );
  const fromStationsClosed = systemParamsDictionary["FilterFromStations"]
    ? systemParamsDictionary["FilterFromStations"].split(",")
    : [];
  const toStationsClosed = systemParamsDictionary["FilterToStations"]
    ? systemParamsDictionary["FilterToStations"].split(",")
    : [];

  useEffect(() => {
    handleInitLastTravels();
    handleInitHistoryCheck();
  }, [i18n.language]);

  const handleInitHistoryCheck = () => {
    const isTravelsHistorySaved = store.get("isTravelsHistorySaved");
    if (isTravelsHistorySaved === null) {
      store.set("isTravelsHistorySaved", true);
      _setIsHistoryDisplay(true);
      if (checkboxRef.current) {
        checkboxRef.current.checked = false;
      }
    } else {
      _setIsHistoryDisplay(isTravelsHistorySaved);
      if (checkboxRef.current) {
        checkboxRef.current.checked = !isTravelsHistorySaved;
      }
    }
  };

  const handleInitLastTravels = () => {
    const lastTravelsLocalStorage: ILastTravelLocalStorage[] = store.get(
      `${Config.BASE_LOCALSTORAGE_NAME}lastTravels`
    );
    if (lastTravelsLocalStorage) {
      if (store.session.get(`${Config.BASE_LOCALSTORAGE_NAME}stations`)) {
        const stations: IStation[] = store.session.get(
          `${Config.BASE_LOCALSTORAGE_NAME}stations`
        )[i18n.language];

        if (stations.length === 0) {
          return;
        }

        const dictStations = GetStationsDictionary(stations);
        const lastTravels: ILastTravel[] = [];

        for (let i = 0; i < lastTravelsLocalStorage.length; i++) {
          const fromStation = dictStations[lastTravelsLocalStorage[i].from];
          const toStation = dictStations[lastTravelsLocalStorage[i].to];
          lastTravels.push({
            fromStation,
            toStation,
            isFavorite: lastTravelsLocalStorage[i].isFavorite,
            isFromStationClosed: fromStationsClosed.includes(
              fromStation.stationId.toString()
            ),
            isToStationClosed: toStationsClosed.includes(
              toStation.stationId.toString()
            ),
          });
        }
        _setLastTravels(lastTravels);
      }
    }
  };

  const handleRestLastTravels = () => {
    _setLastTravels([]);
    store.set(`${Config.BASE_LOCALSTORAGE_NAME}lastTravels`, []);
  };

  const handleToggleHistory = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.currentTarget.checked;
    store.set("isTravelsHistorySaved", !isChecked);
    _setIsHistoryDisplay(!isChecked);
  };

  const handleFavorite = (fromStationId: number, toStationId: number) => {
    const fromIndex = _lastTravels.findIndex(
      (lastTravel) =>
        lastTravel.fromStation.stationId === fromStationId &&
        lastTravel.toStation.stationId === toStationId
    );
    const isFavorite = _lastTravels[fromIndex].isFavorite;
    let totalFavorites = 0;
    for (let i = 0; i < _lastTravels.length; i++) {
      if (_lastTravels[i].isFavorite) {
        totalFavorites++;
      }
    }

    let toIndex = !isFavorite ? 0 : totalFavorites - 1;

    let updatedLastTravels = _lastTravels.slice(0);

    updatedLastTravels.splice(
      toIndex,
      0,
      updatedLastTravels.splice(fromIndex, 1)[0]
    );
    if (!isFavorite) {
      updatedLastTravels[0].isFavorite = true;
    } else {
      updatedLastTravels[toIndex].isFavorite = false;
    }
    _setLastTravels(updatedLastTravels);

    store.set(
      `${Config.BASE_LOCALSTORAGE_NAME}lastTravels`,
      updatedLastTravels.map((lastTravel) => {
        return {
          from: lastTravel.fromStation.stationId,
          to: lastTravel.toStation.stationId,
          isFavorite: lastTravel.isFavorite,
        };
      })
    );

    if (lastTravelsContainerRef.current) {
      if (!isFavorite) {
        lastTravelsContainerRef.current.scrollTo(0, 0);
      }
    }
  };

  return (
    <div
      style={{
        ...styles.lastTravelsWrapper,
        paddingTop: deviceMode === StyleMode.desktop ? "5rem" : "4rem",
      }}
    >
      {_lastTravels.length > 0 && _isHistoryDisplay && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: deviceMode === StyleMode.desktop ? "40rem" : "",
          }}
        >
          <div style={styles.lastTravelsHeader}>
            <div>
              <h2 style={styles.lastTravelsTitle}>
                {myTravelsText}
              </h2>
            </div>
            <div
              onMouseOver={() => {
                _setIsResetOver(true);
              }}
              onMouseLeave={() => {
                _setIsResetOver(false);
              }}
              style={{
                ...styles.lastTravelsReset,
                textDecoration: _isResetOver ? "underline" : "",
              }}
              role={"button"}
              tabIndex={!IsMobile() ? 0 : -1}
              aria-label={`${myTravelsResetText} ${myTravelsText}`}
              onClick={handleRestLastTravels}
            >
              {myTravelsResetText}
            </div>
          </div>
          <div
            id={"lastTravelsContainer"}
            ref={lastTravelsContainerRef}
            style={{
              ...styles.lastTravels,
              maxHeight: deviceMode === StyleMode.desktop ? "14rem" : "18rem",
              width: deviceMode !== StyleMode.desktop ? "100%" : "",
              gap: deviceMode !== StyleMode.desktop ? "" : "",
              paddingLeft: i18n.dir() === "rtl" ? "2rem" : "0rem",
              paddingRight: i18n.dir() === "ltr" ? "2rem" : "0rem",
            }}
          >
            <LastTravelsList
              travels={_lastTravels}
              handleFavorite={handleFavorite}
              deviceMode={deviceMode}
              fromStationsClosed={fromStationsClosed}
              toStationsClosed={toStationsClosed}
            />
          </div>
        </div>
      )}
      {(_lastTravels.length === 0 || !_isHistoryDisplay) && (
        <Fragment>
          <div style={styles.noLastTravels}>
            <img
              src={noTravelsImage?._url}
              width={deviceMode === StyleMode.desktop ? "200" : "120"}
              max-height={`100%`}
              alt={``}
            />
            <div
              style={{
                ...styles.noLastTravelsTitle,
                fontSize: fontSize.UmbracoElementTitle,
                maxWidth: i18n.language === "ru" ? "70%" : "",
              }}
            >
              {noTravelsText}
            </div>
            <div style={styles.noLastTravelsText}>{noTravelsOptionalText}</div>
          </div>
        </Fragment>
      )}
      <div
        style={{
          ...styles.line,
          width: deviceMode === StyleMode.desktop ? "40rem" : "100%",
        }}
      ></div>
      <div style={styles.historyContainer}>
        <input
          type={"checkbox"}
          ref={checkboxRef}
          style={{
            height: "17px",
            width: "18px",
          }}
          onChange={handleToggleHistory}
        />
        <span style={styles.toggleHistoryText}>{hideTravelsHistory}</span>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  lastTravelsWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    alignItems: "center",
  },
  lastTravelsHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  lastTravelsTitle: {
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaMedium,
    fontSize: fontSize.UmbracoElementTitle,
  },
  lastTravelsReset: {
    color: colors.darkBlue,
    fontSize: fontSize.Paragraph,
    fontFamily: fonts.FbReformaRegular,
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  lastTravels: {
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    paddingTop: "1rem",
    scrollBehavior: "smooth",
    overflowX: "hidden",
    // paddingLeft: "2rem",
    // WebkitMaskImage: "linear-gradient(to bottom, black 50%, transparent 100%)",
  },
  noLastTravels: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: ".8rem",
  },
  noLastTravelsTitle: {
    color: colors.darkBlue,
    fontSize: fontSize.UmbracoElementTitle,
    fontFamily: fonts.FbReformaMedium,
    lineHeight: "1.5rem",
    textAlign: "center",
  },
  noLastTravelsText: {
    color: "#3C73BB",
    fontSize: fontSize.UmbracoElementTitle,
    fontFamily: fonts.FbReformaRegular,
  },
  line: {
    height: "1px",
    backgroundColor: colors.pastelGray,
  },
  historyContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "1rem",
  },
  toggleHistoryText: {
    fontSize: fontSize.FooterItem,
    lineHeight: "1.25rem",
    color: colors.darkBlue,
    fontFamily: fonts.FbReformaRegular,
    fontWeight: "400",
  },
};

export default LastTravelsMain;
