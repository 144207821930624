import { Fragment, useEffect, useRef, useState } from "react";
import { IPodcast } from "../../../../Interfaces/IUmbraco";
import colors from "../../../../Assets/Colors/Colors.json";
import {
  FormatDateYYYYMMDD_Dots,
  SecondsToDurationFormat,
} from "../../../../Services/DateService";
import { StyleSheet } from "../../../../Interfaces/IStyleSheet";
import PodcastControls from "./PodcastControls";
import Images from "../../../../Data/Images/Images";
import { t } from "i18next";
import {
  GetLongTextWithDots,
  replaceRichtextBoldWithFont,
} from "../../../../Utils/utils";
import fonts from "../../../../Assets/Fonts/Fonts.json";
import fontSize from "../../../../Assets/Fonts/FontsSizes.json";
import { IsMobile } from "../../../../Services/DeviceService";
import FileElement from "../../FileElement/FileElement";

const PodcastMobile: React.FC<IPodcast> = (props) => {
  const {
    titleAndDescription,
    thumbnail,
    date,
    onClick,
    isSelected,
    media,
    onShareClick,
    transcribedEpisode,
    length,
  } = props;
  const [_duration, _setDuration] = useState<number>(0);
  const [_isPodcastOpen, _setIsPodcastOpen] = useState(false);
  const [_mediaType, _setMediaType] = useState<"Audio" | "Video">("Audio");

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (media && media._url) {
      const lastSplitted =
        media._url.split("/")[media._url.split("/").length - 1];
      const mediaType = lastSplitted.split(".")[1];
      switch (mediaType) {
        case "mp3":
          _setMediaType("Audio");
          break;
        default:
          _setMediaType("Video");
          break;
      }
    }
  }, [media]);

  useEffect(() => {
    _setIsPodcastOpen(isSelected);
    if (isSelected) {
      if (containerRef.current) {
        containerRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
  }, [isSelected]);

  return (
    <Fragment>
      <div style={styles.container} onClick={onClick} ref={containerRef}>
        <div style={styles.headerContainer}>
          <img
            src={thumbnail && thumbnail._url ? thumbnail._url : Images.podcast}
            style={styles.thumbnail}
            width={`auto`}
            height={`auto`}
            alt={``}
          />
          <div style={styles.headerInfoContainer}>
            <div style={styles.title}>{titleAndDescription.title}</div>
            <div
              style={{
                display: "flex",
                gap: ".5rem",
                fontSize: fontSize.SubTitle,
              }}
            >
              <span style={{ fontFamily: fonts.FbReformaMedium }}>
                {length && length} |
              </span>
              <span style={styles.date}>
                {FormatDateYYYYMMDD_Dots(new Date(date))}|
              </span>
              <span
                style={{
                  textDecoration: "underline",
                }}
                onClick={() => {
                  onShareClick && onShareClick(media._url);
                  // window.open(media._url, "_blank");
                }}
              >
                {t("Share")}
              </span>
            </div>
          </div>
        </div>
        <div
          style={styles.description}
          dangerouslySetInnerHTML={{
            __html: replaceRichtextBoldWithFont(
              titleAndDescription.description.length > 121
                ? GetLongTextWithDots(titleAndDescription.description, 121)
                : titleAndDescription.description
            ),
          }}
        ></div>
        <div>
          {!_isPodcastOpen ? (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  fontSize: fontSize.UmbracoElementTitle,
                  color: colors.darkBlue,
                }}
              >
                {length}
              </div>
              <img
                src={Images.playWhite}
                onClick={() => {
                  _setIsPodcastOpen(true);
                }}
                width={`auto`}
                height={`auto`}
                alt={``}
                role={`button`}
                tabIndex={!IsMobile() ? 0 : -1}
                aria-label={t("PlayVideo")}
              />
            </div>
          ) : (
            <Fragment>
              {_mediaType === "Audio" ? (
                <PodcastControls
                  {...props}
                  onClickNext={() => {}}
                  onClickPrevious={() => {}}
                  length={length}
                />
              ) : (
                <video
                  width="320"
                  height="240"
                  controls
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                >
                  {media && media._url && (
                    <source src={media._url} type="video/mp4"></source>
                  )}
                </video>
              )}
            </Fragment>
          )}
        </div>
        {transcribedEpisode._url && (
          <FileElement
            file={transcribedEpisode}
            fileName={titleAndDescription.title}
            openType="preview"
            containerStyles={{
              paddingTop: "1rem",
              alignItems: "start",
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
        )}
      </div>
    </Fragment>
  );
};

const styles: StyleSheet = {
  container: {
    display: "flex",
    gap: "1rem",
    flexDirection: "column",
  },
  thumbnail: {
    width: "7rem",
    height: "4rem",
    borderRadius: "5%",
  },
  titleAndDescriptionContainer: {
    display: "flex",
    flexDirection: "column",
    paddingTop: "1.5rem",
    flex: "2",
  },
  title: {
    color: colors.darkBlue,
    fontSize: fontSize.UmbracoElementTitle,
    letterSpacing: "0.039375rem",
    fontFamily: fonts.FbReformaMedium,
    lineHeight: "1.8rem",
  },
  description: {
    color: colors.nero,
    fontFamily: fonts.FbReformaRegular,
    letterSpacing: "0.038125rem",
    fontSize: fontSize.SubTitle,
    maxWidth: "29.75rem",
  },
  information: {
    display: "flex",
    flexDirection: "column",
    gap: ".5rem",
    alignItems: "center",
    paddingBottom: "4rem",
    flex: "1",
  },
  length: {
    color: colors.darkBlue,
    letterSpacing: "0.038125rem",
    fontFamily: fonts.FbReformaRegular,
    fontSize: fontSize.Paragraph,
  },
  date: {
    color: colors.nero,
    fontFamily: fonts.FbReformaLight,
    letterSpacing: "0.0275rem",
  },
  headerContainer: {
    display: "flex",
    gap: "1rem",
  },
  headerInfoContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    gap: ".5rem",
  },
};

export default PodcastMobile;
