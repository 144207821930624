import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Images from "../../Data/Images/Images";
import { INotePrice, TPriceNoteLang } from "../../Interfaces/IFaresCalculation";
import { UmbracoStoreState } from "../../Redux/Slices/umbracoSlice";
import { Translations } from "../../Services/GlobalService";
import i18n from "../../Services/i18n";
import { StyleMode } from "../../Utils/consts";
import { GetNotePrices, getTravelPrice } from "../../Utils/helpers";
import useDeviceMode from "../../Utils/useWindowDimensions";
import Loading from "../Loading/Loading";
import { LinePrice } from "./CardPrice";
import { ICalcResult, IFromTOProfile, ITrainPricies } from "./FromToStation";
import PaymentOptions from "./PaymentOptions";
import { ProfilesCapsule } from "./Profiles";
import ReadMore from "./ReadMore";


type NotePriceExtend = INotePrice & TPriceNoteLang
const Fare: React.FC<IFromTOProfile> = (props) => {
  const Translation = Translations[
    i18n.language as `he` | `en` | `ar` | `ru`
  ] as any;

  const { fromStation, toStation, hidePayment = false, title } = props;
  const [_data, _setData] = useState<ICalcResult>({ data: [] });
  const [_currentProfile, _setCurrentProfile] = useState<ITrainPricies>();
  let [_selected, _setSelected] = useState<number>(0);
  let [_notePrices, _setNotePrices] = useState<{ [key: string | number]: NotePriceExtend }>({});
  const [_isLoading, _setIsLoading] = useState(true);
  const [_currentProfileName, _setCurrentProfileName] = useState<string>("");

  const umbracoStore = (
    useSelector((state: any) => state.UmbracoStoreReducer) as UmbracoStoreState
  ).umbracoStore;
  const [_statusFree, _setStatusFree] = useState<boolean>(false);
  const deviceMode = useDeviceMode();
  const navigate = useNavigate();

  useEffect(() => {
    init();
  }, []);

  const init = async () => {

    let res: ICalcResult = await getTravelPrice({
      fromStation,
      toStation,
    });
    const resPrices = await GetNotePrices()
    const notesProfiles = resPrices.reduce((prev, current) => {
      if (current.singleNote || current.dayNote || current.monthNote) {
        const ticketTypes = [current.singleNote, current.dayNote, current.monthNote].map((value, index) => value ? index + 1 : undefined).filter(value => value !== undefined);
        prev = {
          ...prev,
          [current.profile_Id]: {
            ...current,
            ticketTypes: ticketTypes,
            priceNote: {
              ar: current.priceNoteAr,
              en: current.priceNoteEn,
              he: current.priceNoteHe,
              ru: current.priceNoteRu
            }
          }
        }
      }
      return prev
    }
      , {})
    _setNotePrices(notesProfiles)
    onSetValue(res);
    _setIsLoading(false);
  };

  const onSetValue = (data: ICalcResult, profile: number = 0) => {
    let currentProfile = data?.data?.find(
      (item: ITrainPricies) => item.profileId === profile
    );
    _setCurrentProfile(currentProfile);
    _setSelected(profile);
    _setData(data);
    if(currentProfile)
      _setCurrentProfileName(Translation[`PassengerProfile${currentProfile.profileId}`]);
  };

  return _isLoading ? (
    <div
      style={{
        paddingTop: deviceMode === StyleMode.mobile ? "4rem" : "unset",
      }}
    >
      <Loading
        containerStyles={{
          minHeight: "13rem",
          height: "13rem",
          minWidth: deviceMode === StyleMode.mobile ? "unset" : "620px",
        }}
      />
    </div>
  ) : (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "0.5rem",
        minWidth: deviceMode === StyleMode.mobile ? "unset" : "620px",
        paddingTop: deviceMode === StyleMode.mobile ? "4rem" : "unset",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
          paddingBottom: "2rem",
        }}
      >
        <h1
          style={{
            fontSize: deviceMode === StyleMode.mobile ? "1.8rem" : "2rem",
          }}
        >
          {title ? title : Translation["PriceTitle"]}
        </h1>
        <p>
          {`${Translation["PriceSubTitle1"].replace(
            /\d+/,
            _data.search?.maxRange?.split("-").pop()
          )}`}
        </p>
      </div>
      <div
        style={{
          backgroundColor: "#ECF9FF",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          width: "100%",
        }}
      >
        <div
          style={{
            minHeight: "13rem",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            minWidth: "16rem",
            margin: "auto",
            // paddingBlock: "3rem",
            position: "relative",
          }}
        >
          <div style={{ position: "absolute", top: "0", translate: "0 -50%" }}>
            <ProfilesCapsule
              onChange={(item) => {
                onSetValue(_data, item.profileId);
              }}
              data={_data.data}
            />
          </div>
          {!_currentProfile?.statusFree ? (
            _currentProfile?.trainPricies.map((item, index) => (
              <Fragment key={index}>
                <LinePrice
                  title={Translation[`TicketType${item.ticketType}`]}
                  price={item.finalPrice}
                  disclaimer={_notePrices[item.profile_ID]?.ticketTypes?.includes(item.ticketType)}
                />
                {_currentProfile?.trainPricies.length !== index + 1 && (
                  <div style={{ height: "1px", backgroundColor: "#D7D7D7" }} />
                )}
              </Fragment>
            ))
          ) : (
            <p style={{ textAlign: "center" }}>
              {" "}
              {Translation["RideIsFree1"]}
              <br />
              <b>{Translation["RideIsFree2"]}</b>
            </p>
          )}
        </div>
      </div>

      <div
        style={{
          backgroundColor: "#F9F9F9",
          paddingBlock: "0.7rem",
          textAlign: "center",
        }}
      >
        <div style={{ maxWidth: "30rem", margin: "auto" }}>
          {_notePrices[_selected]?.priceNote[i18n.language]}{" "}
          <span
            style={{
              color: "#00458A",
              display: "inline-flex",
              alignItems: "center",
              cursor: "pointer",
              justifyContent: "center",
            }}
            onClick={() => navigate(`?page=travel-arrangements`)}
          >
            {" "}
            {Translation["fullInformationCardTypes"]}
            <img
              src={Images.arrow}
              style={{ rotate: i18n.dir() === "rtl" ? "90deg" : "-90deg" }}
            />
          </span>
        </div>
      </div>
      <div
        style={{
          backgroundColor: "#F9F9F9",
          paddingBlock: "0.7rem",
          textAlign: "center",
        }}
      >
        <div style={{ maxWidth: "30rem", margin: "auto" }}>
          {/* <ReadMoreText
            text={Translation["FareEngineFullDisclaimer"]}
            maxRows={2}
          /> */}
          {/* <section> */}
          <ReadMore
            limit={89}
            more={Translation["FareEngineOpenDisclaimer"]}
            less={Translation["FareEngineCloseDisclaimer"]}
            content={Translation["FareEngineFullDisclaimer"]}
          />
          {/* </section> */}
        </div>
      </div>

      {!hidePayment && (
        <PaymentOptions
          chargingOptions={umbracoStore.popUpFares?.chargingOptions}
          paymentOptions={umbracoStore.popUpFares?.paymentOptions}
          currentProfileName={_currentProfileName}
        />
      )}
    </div>
  );
};
export default Fare;
