import { configureStore } from "@reduxjs/toolkit";
import pageReducer from "../Slices/pageSlice";
import scheduleReducer from "../Slices/scheduleSlice";
import footerReducer from "../Slices/footerSlice";
import headerReducer from "../Slices/headerSlice";
import umbracoStoreReducer from "../Slices/umbracoSlice";
import modalReducer from "../Slices/modalSlice";
import somethingWentWrongReducer from "../Slices/somethingWentWrongSlice";
import homeReducer from "../Slices/homeSlice";
import cookiesSettingsSlice from "../Slices/cookiesSettingsSlice";
import tendersReducer from "../Slices/tendersSlice";
import formReducer from "../Slices/formsSlice";
import urlTranslationsReducer from "../Slices/urlTranslationsSlice";
import systemParamsReducer from "../Slices/systemParamsSlice";
import translationsReducer from "../Slices/translationsSlice";

export default configureStore({
  reducer: {
    PageReducer: pageReducer,
    ScheduleReducer: scheduleReducer,
    HeaderReducer: headerReducer,
    FooterReducer: footerReducer,
    UmbracoStoreReducer: umbracoStoreReducer,
    ModalReducer: modalReducer,
    SomethingWentWrongReducer: somethingWentWrongReducer,
    HomeReducer: homeReducer,
    CookiesSettingsSlice: cookiesSettingsSlice,
    TendersReducer: tendersReducer,
    FormReducer: formReducer,
    UrlTranslationsReducer: urlTranslationsReducer,
    SystemParamsReducer: systemParamsReducer,
    TranslationsReducer: translationsReducer,
  },
});
