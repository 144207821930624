import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import InfoPayment from "../Components/Modal/InfoPayment";
import { openModal } from "../Redux/Slices/modalSlice";
import colors from "../Assets/Colors/Colors.json";
import { t } from "i18next";

interface PreviewInterface {
  id?: number | null;
  passport?: number | null;
  payment?: number | null;
}
const star = {
  title: "כדי לשלם את החיוב, יש להזדהות באמצעות הפרטים הבאים:",
  tabs: [
    {
      text: "ID",
      type: "id",
    },
    {
      text: "passport",
      type: "passport",
    },
  ],
  inputs: [
    {
      placeholder: "ID",
      type: "text",
    },
    {
      placeholder: "מספר דרישה לתשלום",
      type: "text",
    },
  ],
};
const noData = {
  title: "לא מצאנו נתונים לתשלום, לפי המספר שהזדהית אתו",
  description:
    "ייתכן שמדובר בחיוב שלא ניתן לשלם באתר. לבירורים יש לפנות לשירות לקוחות רכבת ישראל בטל': 5770*",
};
const d = [
  {
    FineDate: "05/10/2020 13:42",
    TotalAmount: 160.5,
    FirstName: "ע",
    LastName: "ל",
    Address: "0 יבנאל",
    RequestNumber: "6257",
    FineID: 800100028,
    ProfileCode: "0",
    clsResult: null,
  },
  {
    FineDate: "14/10/2020 10:10",
    TotalAmount: 57.0,
    FirstName: "א",
    LastName: "פ",
    Address: "0 קריית ענבים",
    RequestNumber: "6328",
    FineID: 800100040,
    ProfileCode: "0",
    clsResult: null,
  },
];

const Fines: React.FC<any> = (props) => {
  const { dynamicElements } = props;
  const dispatch = useDispatch();
  let [_type, _setType] = useState("id");
  let [_disable, _setDisable] = useState(false);
  let [_translate, _setTranslate] = useState<any>({});
  let [_finesInput, _setFinesInput] = useState<any>({
    id: "",
    passport: "",
    payment: "",
    error: {
      id: "",
      passport: "",
      payment: "",
    },
  });

  useEffect(() => {
    var Translate = {};
    dynamicElements.map((item: any) => {
      Translate = {
        ...Translate,
        [item.textID]: item.text,
      };
    });
    _setTranslate(Translate);
  }, []);

  const onSwitch = (item: any) => {
    _setFinesInput({
      ..._finesInput,
      passport: "",
      id: "",
    });
    _setType(item.type);
  };

  const onSubmit = async () => {
    var formObject = { ..._finesInput };
    if (formObject[_type].length < 3) {
      formObject.error[_type] = _translate[`error${_type}`];
      _setFinesInput({ ...formObject });
      return;
    }
    if (_type === "id") {
      let checkSum = 0;
      let key = 1;
      const tzTemp = ("000000000" + _finesInput.id).slice(-9);
      for (let i: number = 0; i < tzTemp.length; i++) {
        const res = parseInt(tzTemp.substring(i, i + 1)) * key;
        if (res > 9) checkSum += (res % 10) + 1;
        else checkSum += res;
        key = key === 1 ? 2 : 1;
      }
      // var res = await axios('https://172.21.99.199:40532/api/GetFineDetails/?IdentificationCardTypes=1&PersonID=320469984&FineID=800100040&StationID=47&MachineTypeID=10&MachineNumber=1', { method: "GET", headers })
      if (checkSum % 10 !== 0) {
        return false;
      }
    } else if (_type === "passport") {
    }

    if (_finesInput.payment.length < 3) {
      formObject.error.payment = _translate.Payment;
      _setFinesInput({ ...formObject });
    }
    _setDisable(true);
  };

  const onChange = (name: string, event: any) => {
    var formObject = { ..._finesInput };

    var value =
      name !== "passport"
        ? event.target.value.replace(/\D/g, "")
        : event.target.value;

    if (name === "id") {
      let checkSum = 0;
      let key = 1;
      const tzTemp = ("000000000" + value).slice(-9);
      for (let i: number = 0; i < tzTemp.length; i++) {
        const res = parseInt(tzTemp.substring(i, i + 1)) * key;
        if (res > 9) checkSum += (res % 10) + 1;
        else checkSum += res;
        key = key === 1 ? 2 : 1;
      }
      if (checkSum % 10 !== 0) {
        formObject.error.id = _translate.errorID;
      } else {
        formObject.error.id = "";
      }
    } else if (name === "passport") {
      let regex = /([a-z]{3})([0-9]{9})/;
      let valid = regex.test(value);
      if (value.length < 3 || !valid) {
        formObject.error.passport = _translate.errorPassport;
      } else {
        formObject.error.passport = "";
      }
    }

    if (name === "payment") {
      if (value.length < 3) {
        formObject.error.payment = _translate.errorPayment;
      } else {
        formObject.error.payment = "";
      }
    }
    _setFinesInput({
      ...formObject,
      [name]: value,
    });
  };

  return (
    <>
      <div>
        <div className="form-switch">
          <div className="input-switch">
            <div className="row choose-types">
              {star.tabs.map((item, index) => (
                <div
                  className={`choose-type ${
                    _type === item.type ? "active" : ""
                  }`}
                  key={index}
                  onClick={() => onSwitch(item)}
                >
                  {t(item.text)}
                </div>
              ))}
            </div>
          </div>
          {/* {star.inputs.map((item) => 
          <div className="box-input">
          <input type={item.type} disabled={disable} onChange={(e) => {onChange(type, e)}}
            value={finesInput[type]} placeholder={item.placeholder} className={`input`} />
            {finesInput.error[type] && <div className="error">{finesInput.error[type]}</div>}
            </div>
          )} */}
          <div className="box-input">
            <input
              type={"text"}
              disabled={_disable}
              onChange={(e) => {
                onChange(_type, e);
              }}
              style={{
                border: `1px solid ${colors.gray}`,
              }}
              value={_finesInput[_type]}
              placeholder={_translate[`${_type}Placeholder`]}
              className={`input`}
            />
            {_finesInput.error[_type] && (
              <div className="error">{_finesInput.error[_type]}</div>
            )}
          </div>
          <div className="box-input">
            <input
              type={"text"}
              value={_finesInput.payment}
              disabled={_disable}
              onChange={(e) => onChange("payment", e)}
              placeholder={_translate.paymentDemandNumber}
              className={`input`}
              style={{
                border: `1px solid ${colors.gray}`,
              }}
            />
            {_finesInput.error.payment && (
              <div className="error">{_finesInput.error.payment}</div>
            )}
          </div>
          <button
            className="btn"
            disable-click={_disable.toString()}
            onClick={onSubmit}
            style={{
              boxSizing: "content-box",
              maxWidth: "16rem",
              borderRadius: "2rem",
              backgroundColor: "var(--koromiko)",
            }}
          >
            {_translate.btn}
          </button>
        </div>
        {_disable && (
          <div className="row-between wrap">
            {d.length > 1 ? (
              d.map((item: any, index) => (
                <div
                  className="fine-box"
                  key={index}
                  style={{ textAlign: "center" }}
                >
                  <div className="padding-wapper">
                    <h3>
                      {_translate.serialNumber} - {item.FineID}
                    </h3>
                    <div className="row-around">
                      <div className="name">
                        <b>{_translate.FineID}</b>
                        <div>{item.FineID}</div>
                      </div>
                      <div className="name">
                        <b>{_translate.Address}</b>
                        <div>{item.Address}</div>
                      </div>
                      <div className="name">
                        <b>{_translate.destination}</b>
                        <div></div>
                      </div>
                    </div>
                    <div className="row-around">
                      <div className="name">
                        <b>{_translate.FineID}</b>
                        <div>{item.FineID}</div>
                      </div>
                      <div className="name">
                        <b>{_translate.payUntil}</b>
                        <div>{moment(item.FineDate).format("L")}</div>
                      </div>
                      <div className="name">
                        <b>{_translate.TotalAmount}</b>
                        <div>{item.TotalAmount}</div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="btn-full-width"
                    onClick={() => {
                      dispatch(
                        openModal({
                          visible: true,
                          closeButtonType: "internal",
                          childrenComponent: <InfoPayment />,
                        })
                      );
                    }}
                  >
                    {_translate.payment}
                  </div>
                </div>
              ))
            ) : (
              <div className="fine-box">
                <div className="padding-wapper">
                  <h3>{noData.title}</h3>
                  <div className="row-around">
                    <p>{noData.description}</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Fines;
