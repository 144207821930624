import { t } from "i18next";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import { ITrain } from "../../Interfaces/ITrain";
import colors from "../../Assets/Colors/Colors.json";
import fonts from "../../Assets/Fonts/Fonts.json";
import fontSize from "../../Assets/Fonts/FontsSizes.json";
import { useEffect, useState } from "react";
import {
  GetCalendarInfo,
  GetCalenderDataForAPI,
} from "../../Services/CalendarService";
import { ICalendarStructure } from "../../Interfaces/ICalendar";
import { useSelector } from "react-redux";
import { SystemParamsState } from "../../Redux/Slices/systemParamsSlice";
import { SendCalendarMail } from "../../Services/SmsService";
import InputForm from "../../Components/Form/InputForm/InputForm";

const RouteToCalendar: React.FC<
  ITrain & { fromStation?: number; toStation?: number }
> = (props) => {
  const [_emailAddress, _setEmailAddress] = useState("");
  const [_trainDataToSend, _setTrainDataToSend] =
    useState<ICalendarStructure>();
  const [_emailValidationMessage, _setEmailValidationMessage] = useState("");
  const [_isAddToCalendarSucceed, _setIsAddToCalendarSucceed] = useState(false);

  const systemParams = (
    useSelector((state: any) => state.SystemParamsReducer) as SystemParamsState
  ).systemParams;

  const systemParamsDictionary = systemParams.reduce(
    (result: { [key: string]: string }, current) => {
      result[current.key] = current.value;
      return result;
    },
    {}
  );

  useEffect(() => {
    handleCalendarStructure();
  }, []);

  const handleCalendarStructure = () => {
    const calenderInfo = GetCalendarInfo({ ...props }, _emailAddress);
    if (calenderInfo) {
      _setTrainDataToSend(calenderInfo);
    }
    return calenderInfo;
  };

  const handleSubmit = async () => {
    const data = handleCalendarStructure();
    if (data !== undefined) {
      if (!data.email) {
        _setEmailValidationMessage(t("EmailIsEmpty"));
      } else {
        _setEmailValidationMessage("");

        const calenderDataForAPI = GetCalenderDataForAPI(
          { ...props },
          _emailAddress,
          systemParamsDictionary
        );

        if (calenderDataForAPI !== undefined) {
          const response = await SendCalendarMail(calenderDataForAPI);
          _setIsAddToCalendarSucceed(response?.status === 200);
        }
      }
    }
  };

  return (
    <div style={styles.mainContainer}>
      <h1>{t("AddToCalendarTitle")}</h1>
      {_trainDataToSend && (
        <div style={styles.travelInfo}>
          <ul
            style={{
              display: "flex",
              flexDirection: "column",
              gap: ".8rem",
            }}
          >
            <li style={styles.infoField}>{`${_trainDataToSend.eventName}`}</li>
            <li style={styles.infoField}>
              {t("date")} {_trainDataToSend.eventDate}|
              {t("Hour", {
                time: _trainDataToSend?.eventTime,
              })}
            </li>
            <li style={styles.infoField}>
              {_trainDataToSend.eventAdditionalInfo}
            </li>
          </ul>
        </div>
      )}
      {!_isAddToCalendarSucceed ? (
        <div>
          <span style={{ maxWidth: "50%" }}>
            {t("AddCalendarSecondaryLabel")}
          </span>
          <form
            style={styles.form}
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <div style={styles.inputContainer}>
              <InputForm
                placeholder={t("AddToCalendarInputPlaceholder")}
                type="Email"
                onChange={(text) => {
                  _setEmailAddress(text);
                }}
              />
            </div>
            {_emailValidationMessage.length > 0 && (
              <span style={styles.validation}>{_emailValidationMessage}</span>
            )}
            <button style={styles.sendButton}>
              {t("AddToCalendarButton")}
            </button>
          </form>
        </div>
      ) : (
        <span style={styles.succeed}>{t("AddToCalendarSucceed")}</span>
      )}
    </div>
  );
};

const styles: StyleSheet = {
  mainContainer: {
    marginTop: "1rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%",
  },
  inputContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "2.5rem",
    borderRadius: "1.25rem",
    gap: ".3rem",
    padding: "0 .5rem",
    position: "relative",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "1rem",
    paddingTop: "1rem",
  },
  sendButton: {
    backgroundColor: colors.koromiko,
    fontFamily: fonts.FbReformaMedium,
    width: "16rem",
    height: "2.5rem",
    borderRadius: "24rem",
    border: "none",
    fontSize: fontSize.Text,
    color: colors.nero,
    cursor: "pointer",
  },
  input: {
    width: "80%",
    fontSize: fontSize.Text,
    color: colors.pasterlGrayDark,
    letterSpacing: "0.043125rem",
    fontFamily: fonts.FbReformaRegular,
    borderColor: colors.darkBlue,
    border: `1px solid ${colors.black}`,
    borderRadius: ".5rem",
    height: "1.7rem",
  },
  travelInfo: {
    backgroundColor: colors.pattensBlueLighter,
    borderRadius: "1rem",
    boxSizing: "border-box",
    padding: "1.5rem",
  },
  infoField: {
    fontFamily: fonts.FbReformaLight,
    fontSize: fontSize.Paragraph,
  },
  validation: {
    color: colors.red,
    fontFamily: fonts.FbReformaMedium,
  },
  succeed: {
    paddingTop: "2rem",
    color: "#008958",
    fontFamily: fonts.FbReformaMedium,
    fontSize: fontSize.H1,
  },
};

export default RouteToCalendar;
