import { useState } from "react";
import { IGallery } from "../../../../Interfaces/IGallery";
import { StyleSheet } from "../../../../Interfaces/IStyleSheet";
import { StyleMode } from "../../../../Utils/consts";
import useDeviceMode from "../../../../Utils/useWindowDimensions";
import ModalGallery from "../GalleryTypes/ModalGallery";
import DefaultGalleryWhatIsNew from "./DefaultGalleryWhatIsNew";
import DefaultGalleryWhatIsNewMobile from "./Mobile/DefaultGalleryWhatIsNewMobile";
import colors from "../../../../Assets/Colors/Colors.json";

const GalleryWhatIsNew: React.FC<IGallery> = (props) => {
  const { galleryType, onClick } = props;
  const [_currentItemIndex, _setCurrentItemIndex] = useState(0);

  const deviceMode = useDeviceMode();
  const GalleryDisplayedByType: React.FC = () => {
    switch (galleryType) {
      case "Default":
        if (deviceMode === StyleMode.desktop) {
          return <DefaultGalleryWhatIsNew {...props} />;
        } else {
          return <DefaultGalleryWhatIsNewMobile {...props} />;
        }
      case "Modal":
        return <ModalGallery {...props} />;
      default:
        return null;
    }
  };

  return (
    <div style={styles.overlay} onClick={onClick}>
      <div
        onClick={(e) => e.stopPropagation()}
        style={{
          width: deviceMode === StyleMode.desktop ? "50%" : "100%",
          height: "50%",
          maxHeight: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            ...styles.galleryContainer,
            // height:
            //   galleryType === "Default"
            //     ? deviceMode === StyleMode.desktop
            //       ? "42rem"
            //       : ""
            //     : "",
            // maxWidth: deviceMode !== StyleMode.desktop ? "33rem" : "76rem",
          }}
        >
          <GalleryDisplayedByType />
        </div>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  overlay: {
    position: "fixed",
    left: "0",
    top: "0",
    width: "100%",
    height: "100%",
    backgroundColor: colors.modal,
    zIndex: "9999999",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  galleryContainer: {
    width: "100%",
    position: "relative",
  },
};

export default GalleryWhatIsNew;
