import { ICookiesModal } from "../../../Interfaces/ICookiesModal";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import colors from "../../../Assets/Colors/Colors.json";
import fonts from "../../../Assets/Fonts/Fonts.json";
import Images from "../../../Data/Images/Images";
import i18n from "../../../Services/i18n";
import { t } from "i18next";
import { Fragment, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import fontSize from "../../../Assets/Fonts/FontsSizes.json";
import { IsMobile } from "../../../Services/DeviceService";
import useDeviceMode from "../../../Utils/useWindowDimensions";

const CookiesModalMobile: React.FC<ICookiesModal> = (props) => {
  const { onClick, cookies, onOpenSettings } = props;
  const navigate = useNavigate();
  const cookiesModalRef = useRef<HTMLImageElement>(null);
  const redirectToPage = (page?: string) => {
    onClick("Skip");
    page && navigate(`?page=${page}`);
  };

  const deviceMode = useDeviceMode();
  const isIos =
    /^((?!chrome|android).)*safari/i.test(navigator.userAgent) &&
    deviceMode === "MOBILE";

  useEffect(() => {
    const timer = setTimeout(() => {
      cookiesModalRef.current?.focus();
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Fragment>
      <div style={styles.modalOverflow} onClick={() => onClick("Skip")}>
        <div style={styles.modalCard} onClick={(e) => e.stopPropagation()}>
          <img
            src={Images.closeButton}
            role={"button"}
            aria-label={t("Close")}
            tabIndex={!IsMobile() ? 0 : -1}
            style={{
              ...styles.closeIcon,
              left: i18n.dir() === "rtl" ? ".25rem" : "",
              right: i18n.dir() === "ltr" ? ".25rem" : "",
            }}
            onClick={() => {
              onClick("Skip");
            }}
            onKeyPressCapture={() => {
              onClick("Skip");
            }}
            onKeyDown={(e) => {
              if (e.key === "Tab") {
                if (cookiesModalRef.current) {
                  e.stopPropagation();
                  e.preventDefault();
                  cookiesModalRef.current.focus();
                }
              }
            }}
          ></img>
          <div style={styles.modalImageCircle}>
            <img
              src={Images.cookie}
              style={{
                ...styles.modalImage,
              }}
              alt={``}
            />
          </div>
          <div style={styles.modalBody}>
            <div style={styles.modalInfoContainer}>
              <div
                style={styles.information}
                ref={cookiesModalRef}
                role={"textbox"}
                tabIndex={!IsMobile() ? 0 : -1}
              >
                <div>
                  {cookies?.firstText}
                  <span
                    style={styles.link}
                    role={"link"}
                    tabIndex={!IsMobile() ? 0 : -1}
                    onClick={() =>
                      redirectToPage(cookies?.firstLink?.redirectTo)
                    }
                    onKeyPressCapture={() =>
                      redirectToPage(cookies?.firstLink?.redirectTo)
                    }
                  >
                    {" "}
                    {cookies?.firstLink?.text}
                  </span>
                </div>
                <div>
                  {cookies?.secondText}
                  <span
                    style={styles.link}
                    role={"link"}
                    tabIndex={!IsMobile() ? 0 : -1}
                    onClick={() => {
                      onOpenSettings && onOpenSettings();
                    }}
                    onKeyPressCapture={() => {
                      onOpenSettings && onOpenSettings();
                    }}
                  >
                    {" "}
                    {cookies?.secondLink?.text}
                  </span>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "1rem",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <button
                  style={styles.acceptButton}
                  onClick={() => onClick("Accept")}
                  onKeyPressCapture={() => onClick("Accept")}
                >
                  {t("Agree")}
                </button>
                <button
                  style={styles.refuseButton}
                  onClick={() => onClick("Refuse")}
                  onKeyPressCapture={() => onClick("Refuse")}
                >
                  {t("Refuse")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const styles: StyleSheet = {
  container: {
    display: "flex",
    flexWrap: "wrap",
    gap: "4rem",
    width: "80%",
    maxWidth: "76rem",
  },
  modalOverflow: {
    display: "flex",
    justifyContent: "center",
    top: "0",
    position: "fixed",
    width: "100%",
    height: "100%",
    backgroundColor: colors.modal,
    zIndex: "9999999999",
  },
  modalCard: {
    display: "flex",
    justifyContent: "center",
    position: "absolute",
    top: "8%",
    width: "85%",
    height: "75%",
    backgroundColor: colors.white,
    borderRadius: "13px 13px 288px 288px / 13px 13px 100px 100px",
  },
  information: {
    fontFamily: fonts.FbReformaRegular,
    fontSize: fontSize.Paragraph,
    lineHeight: "1.75rem",
    letterSpacing: "0.03875rem",
    padding: "2rem",
    color: colors.darkBlue,
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
    outline: "none"
  },
  acceptButton: {
    borderRadius: "2rem",
    backgroundColor: colors.darkBlue,
    border: ".1rem solid transparent",
    color: colors.white,
    fontSize: fontSize.Paragraph,
    minWidth: "10rem",
    minHeight: "3.2rem",
    cursor: "pointer",
    fontFamily: fonts.FbReformaMedium,
  },
  refuseButton: {
    borderRadius: "2rem",
    backgroundColor: colors.white,
    border: `.1rem solid ${colors.darkBlue}`,
    color: colors.darkBlue,
    fontSize: fontSize.Paragraph,
    minWidth: "10rem",
    minHeight: "3.2rem",
    cursor: "pointer",
    fontFamily: fonts.FbReformaMedium,
  },
  modalImageCircle: {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "5rem",
    height: "5rem",
    backgroundColor: colors.white,
    top: "1rem",
    borderRadius: "50%",
    transform: "translateY(-3.5rem)",
    boxShadow: "-1px 1px 33px 1px rgb(64 60 67 / 40%)",
  },
  modalImage: {
    position: "absolute",
    maxWidth: "50%",
  },
  modalBody: {
    position: "absolute",
    top: "10%",
    maxHeight: "80%",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  modalInfoContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  closeIcon: {
    position: "absolute",
    top: "0",
    cursor: "pointer",
  },
  link: {
    color: colors.oldLavender,
    cursor: "pointer",
    textDecoration: "underline",
  },
};
export default CookiesModalMobile;
