import { t } from "i18next";
import moment from "moment";
import { Fragment, useEffect, useRef, useState } from "react";
import ReactModal from "react-modal";
import { useSelector } from "react-redux";
import colors from "../../Assets/Colors/Colors.json";
import fonts from "../../Assets/Fonts/Fonts.json";
import fontSize from "../../Assets/Fonts/FontsSizes.json";
import Images from "../../Data/Images/Images";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import { IDynamicText } from "../../Interfaces/IUmbraco";
import { PageState } from "../../Redux/Slices/pageSlice";
import { IsMobile } from "../../Services/DeviceService";
import { replaceRichtextBoldWithFont } from "../../Utils/utils";
import ShareModal from "./ShareModal";
import { linkBuilder, linkShareFormat } from "../SharePage/ShareOptions";
import { useReactToPrint } from "react-to-print";
import useDeviceMode from "../../Utils/useWindowDimensions";
import { StyleMode } from "../../Utils/consts";

export interface IInfoCareer {
  email: string;
  endPublishDate: Date;
  jobComments: string;
  jobDescription: string;
  jobNumber: number | string;
  jobRequirements: string;
  location: string;
  publishDate: Date;
  careersText: IDynamicText[];
  title: string;
  customStyles?: React.CSSProperties;
}

const InfoCareer: React.FC<IInfoCareer> = (props) => {
  const {
    // contentTypeAlias: "career"
    email,
    endPublishDate,
    jobComments,
    jobDescription,
    jobNumber,
    jobRequirements,
    location,
    publishDate,
    careersText,
    // submissionDate: "2022-05-29T12:00:00",
    title,
    customStyles,
  } = props;
  const contentToPrint = useRef<any>();
  const handlePrint = useReactToPrint({
    content: () => contentToPrint.current,
  });

  const deviceMode = useDeviceMode();

  const [_translate, _setTranslate] = useState<any>({});
  const [_isShareModalVisible, _setIsShareModalVisible] = useState(false);
  const currentPage = (
    useSelector((state: any) => state.PageReducer) as PageState
  ).page;

  const closeButtonRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    var Translate = {};
    careersText?.map((item: any) => {
      Translate = {
        ...Translate,
        [item.textID]: item.text,
      };
    });
    _setTranslate(Translate);
  }, []);

  const PropertyInfo = (
    title: string,
    text: string | number,
    isBox: boolean,
    icon?: any
  ) => {
    return (
      <div
        style={{
          ...styles.propertyInfoBox,
          flexDirection: icon ? "row" : "column",
          boxShadow: isBox ? "0 2px 6px 0 rgba(0, 0, 0, 0.11)" : "",
          gap: icon ? "1rem" : "",
        }}
      >
        {icon && <img src={icon} width={`auto`} height={`auto`} alt={``} />}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <span style={{ ...styles.title, fontSize: fontSize.FooterItem }}>
            {title}
          </span>
          <span>{text}</span>
        </div>
      </div>
    );
  };
  const getShareUrl = (url: string) => {
    let link = `${window.location.href}&step=openauctions`;
    let title = t("ShareCareerNumber", {
      jobNumber: jobNumber,
    });
    linkShareFormat.title = title;
    linkShareFormat.subject = title;
    linkShareFormat.body = encodeURIComponent(title + "%0A" + link);
    linkShareFormat.summary = encodeURIComponent(title + "%0A" + link);
    linkShareFormat.text = encodeURIComponent(title + "%0A" + link);
    linkShareFormat.quote = title;
    linkShareFormat.url = encodeURIComponent(link);
    linkShareFormat.u = encodeURIComponent(link);
    // sharePagetext
    let newLink = linkBuilder(url, linkShareFormat);

    return decodeURI(newLink);
  };
  return (
    <Fragment>
      <div
        id="pageMainContent"
        // tabIndex={!IsMobile() ? 0 : -1}
        style={{
          ...styles.modalContainer,
          width: !currentPage.includes("career_") ? "100%" : "",
          ...customStyles,
        }}
      >
        <div
          id="career-info"
          style={{
            direction: "rtl",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            padding: "2rem 10%",
            paddingTop: deviceMode !== StyleMode.desktop ? "2rem" : "",
          }}
          ref={contentToPrint}
          className="print-container"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "2rem",
            }}
          >
            <div style={styles.propertyInfoContainer}>
              {PropertyInfo(_translate.jobNumber, jobNumber, true, false)}
              {/* {PropertyInfo(_translate.jobName, jobNumber, true, false)} */}
            </div>
            <div style={styles.propertyInfoContainer}>
              {PropertyInfo(
                _translate.publishDate,
                moment(publishDate).format("L"),
                false,
                Images.datePicker
              )}
              {PropertyInfo(
                _translate.jobEndPublishDate,
                moment(endPublishDate).format("L"),
                false,
                Images.datePicker
              )}
            </div>
            <div style={styles.propertyInfoContainer}>
              {PropertyInfo(
                _translate.jobLocation,
                location.replace(/.*\^/gm, ""),
                false,
                Images.location
              )}
            </div>
          </div>
          <h3 style={{ ...styles.title }}>{_translate.jobDescription}:</h3>
          <div
            style={{ ...styles.description, maxWidth: "80%" }}
            dangerouslySetInnerHTML={{
              __html: replaceRichtextBoldWithFont(jobDescription),
            }}
            // aria-label={RemoveHtmlTags(jobDescription)}
          ></div>
          <h3 style={styles.title}>{_translate.jobRequirements}:</h3>
          <div
            style={styles.description}
            dangerouslySetInnerHTML={{
              __html: replaceRichtextBoldWithFont(jobRequirements),
            }}
            // aria-label={RemoveHtmlTags(jobDescription)}
          ></div>
          {jobComments && (
            <>
              <h3 style={{ ...styles.title, paddingBottom: "1rem" }}>
                {_translate.jobComments}:
              </h3>
            </>
          )}
          <div
            style={styles.description}
            dangerouslySetInnerHTML={{
              __html: replaceRichtextBoldWithFont(jobComments),
            }}
            // aria-label={RemoveHtmlTags(jobDescription)}
          ></div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingTop: "1rem",
            }}
          ></div>
        </div>
        <div
          style={{
            ...styles.buttonsContainer,
            justifyContent:
              deviceMode === StyleMode.desktop
                ? "space-between"
                : "space-around",
            padding: deviceMode === StyleMode.desktop ? "2rem 10%" : "",
          }}
        >
          <div style={styles.buttons}>
            <button
              style={styles.button}
              onClick={() => {
                window.location.href = `mailto:${email}`;
              }}
            >
              {_translate.jobEmail}
            </button>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "1rem",
            }}
          >
            <img
              style={styles.shareIcon}
              src={Images.sharePng}
              width={"30"}
              height={"30"}
              role={"button"}
              aria-label={t("ShareCareer")}
              tabIndex={!IsMobile() ? 0 : -1}
              onClick={() => {
                _setIsShareModalVisible(true);
              }}
              onKeyPressCapture={() => {
                _setIsShareModalVisible(true);
              }}
              onKeyDown={(e) => {
                if (e.key === "Tab") {
                  if (closeButtonRef.current) {
                    e.stopPropagation();
                    e.preventDefault();
                    closeButtonRef.current.focus();
                  }
                }
              }}
            />
            <button
              style={{
                ...styles.button,
                backgroundColor: colors.white,
                border: "0",
                minWidth: "",
                height: "",
              }}
              onClick={handlePrint}
            >
              <img src={Images.printerBlack} width={"40"} height={"40"} />
              {/* {t("Printing")} */}
            </button>
          </div>
        </div>
      </div>
      <ReactModal
        isOpen={_isShareModalVisible}
        ariaHideApp={false}
        style={{
          content: {
            width: "100%",
            maxWidth: "42rem",
            border: "none",
            padding: "none",
            position: "absolute",
            maxHeight: "100%",
            overflow: "visible",
            backgroundColor: "transparent",
          },
          overlay: {
            backgroundColor: "transparent",
            zIndex: "9999999999999999",
            overflow: "hidden",
          },
        }}
        parentSelector={() => document.querySelector("#root") || document.body}
        preventScroll={true}
      >
        <ShareModal
          title={t("Share")}
          icon={Images.share}
          onClose={() => _setIsShareModalVisible(false)}
          items={[
            {
              name: t("Facebook"),
              icon: Images.facebookHeader,
              link: getShareUrl(
                `https://www.facebook.com/sharer/sharer.php?u=`
              ),
              ariaLabel: t("ShareFacebook"),
            },
            {
              name: t("WhatsApp"),
              icon: Images.whatsapp,
              link: getShareUrl(`https://wa.me/?text=`),
              ariaLabel: t("ShareWhatsapp"),
            },
            {
              name: t("Mail"),
              icon: Images.shareMail,
              link: getShareUrl(`mailto:?subject=&body=`),
              ariaLabel: t("ShareEmail"),
            },
            {
              name: t("Linkedin"),
              icon: Images.linkedinHeader,
              link: getShareUrl(
                `https://www.linkedin.com/sharing/share-offsite/?url=`
              ),
              ariaLabel: t("ShareLinkedin"),
            },
          ]}
        />
      </ReactModal>
    </Fragment>
  );
};

const styles: StyleSheet = {
  modalContainer: {
    padding: "2rem 10%",
    // maxHeight: "100%",
    overflow: "auto",
  },
  title: {
    color: colors.darkBlue,
    fontSize: fontSize.UmbracoElementTitle,
    fontFamily: fonts.FbReformaMedium,
  },
  propertyInfo: {
    fontSize: fontSize.Paragraph,
    fontFamily: fonts.FbReformaRegular,
  },
  propertyInfoContainer: {
    display: "flex",
    gap: "5rem",
    flexWrap: "wrap",
  },
  propertyInfoBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    minWidth: "10rem",
    paddingTop: "1rem",
    paddingBottom: "1rem",
  },
  description: {
    fontFamily: fonts.FbReformaRegular,
    fontSize: fontSize.FooterItem,
    lineHeight: "2rem",
  },
  button: {
    backgroundColor: colors.darkBlue,
    color: colors.white,
    fontSize: fontSize.Text,
    borderRadius: "16rem",
    borderColor: colors.darkBlue,
    minWidth: "8rem",
    height: "2rem",
    fontWeight: "bold",
    textAlign: "center",
    cursor: "pointer",
  },
  shareIcon: {
    cursor: "pointer",
  },
  shareModalCloseIconWrapper: {
    display: "flex",
    justifyContent: "center",
    position: "relative",
    top: "-2.5rem",
    zIndex: "9999999",
  },
  shareModalBody: {
    position: "absolute",
    top: "12%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    gap: "1.25rem",
  },

  shareModalCloseIconContainer: {
    width: "4.3rem",
    height: "4.3rem",
    boxShadow: "0 5px 11px 0 rgba(0, 0, 0, 0.21)",
    borderRadius: "50%",
    backgroundColor: "white",
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transform: "translateY(5px)",
  } as React.CSSProperties,
  shareModalTitle: {
    fontSize: fontSize.UmbracoElementTitle,
    lineHeight: "1.5rem",
    maxWidth: "22rem",
    fontFamily: fonts.FbReformaMedium,
    textAlign: "center",
  },
  shareOptions: {
    display: "flex",
    justifyContent: "center",
    gap: "3rem",
    flexWrap: "wrap",
    width: "70%",
  },
  shareItem: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  shareModalCloseIcon: {
    position: "absolute",
    top: "-2rem",
    cursor: "pointer",
  },
  buttonsContainer: {
    display: "flex",
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",
    gap: "1rem",
    maxWidth: "10rem",
    alignItems: "center",
    // padding: "2rem 10%",
  },
};

export default InfoCareer;
