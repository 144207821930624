import { useEffect, useState } from "react";
import colors from "../../Assets/Colors/Colors.json";
import { StyleSheet } from "../../Interfaces/IStyleSheet";
import {
  ILangObject,
  ILangRows,
  IMainBusStationsDetails,
  IOperatorDetails,
  ITransitInformation,
  ITransitTransportPage,
} from "../../Interfaces/ITransitTransport";
import { PostComplimentaryTransport } from "../../Services/CommonService";
import {
  calendar,
  calendarString,
  FormatDateYYYYMMDDTHHMM,
  FormatStringYYYYMMDDTHHMM,
  GetDurationTimeMSStringType,
  GetFormatedTimeFromString,
} from "../../Services/DateService";
import { Translations } from "../../Services/GlobalService";
import i18n from "../../Services/i18n";
import Images from "../../Data/Images/Images";
import db from "./db.json";
import fontSize from "../../Assets/Fonts/FontsSizes.json";

import TransitStation from "./TransitStation";
import Config from "../../Configuration/server";
import { IStation } from "../../Interfaces/IStation";
import store from "store2";
import font from "../../Assets/Fonts/Fonts.json";
import useDeviceMode from "../../Utils/useWindowDimensions";
import { StyleMode } from "../../Utils/consts";
import { t } from "i18next";
import Loading from "../Loading/Loading";
import ReactModal from "react-modal";
import ToolTip from "../Modal/ToolTip";

const TransitTransportPage: React.FC<ITransitTransportPage> = (props) => {
  const EARLY_QUERY = -29;
  const LATE_QUERY = 240;
  const deviceMode = useDeviceMode();

  const { stationId, typeOfPage, startTime, onClick } = props;
  const [_isLoading, _setIsLoading] = useState(true);

  const allStations = store.session.get(
    `${Config.BASE_LOCALSTORAGE_NAME}stations`
  );

  const stations: IStation[] = allStations && allStations[i18n.language];

  const stationDetails = stations.filter(
    (station) => station.stationId === stationId
  );

  const [_earlyQuery, _setEarlyQuery] = useState(false);

  const [_lateQuery, _setLateQuery] = useState(false);
  const [_detailsError, _setDetailsError] = useState(false);
  const [_isToolTip, _setIsToolTip] = useState("");

  const [_currentStationNameLanguage, _setCurrentStationNameLanguage] =
    useState<string>(
      `name${i18n.language.charAt(0).toUpperCase()}${i18n.language.slice(1)}`
    );

  const [_currentLan, _setCurrentLan] = useState<string>("");

  useEffect(() => {
    const curLanguage = `name${i18n.language
      .charAt(0)
      .toUpperCase()}${i18n.language.slice(1)}`;
    _setCurrentStationNameLanguage(curLanguage);
  }, [i18n.language]);

  const [_mainBusStationsDetails, _setMainBusStationsDetails] =
    useState<IMainBusStationsDetails[]>();

  const [_mainStationsLinesDetails, _setMainStationsLinesDetails] =
    useState<ITransitInformation[]>();

  const [_currentRailStation, _setCurrenrRailStation] = useState(
    stationDetails[0].stationName
  );

  const durationEarlyTime =
    startTime && GetDurationTimeMSStringType(startTime, new Date());

  const durationLateTime =
    startTime && GetDurationTimeMSStringType(startTime, new Date());

  const getTranstAgencyDetails = (
    db: {
      transportOperators: {
        opertaor: string;
        transitAgencyColor: string;
        transitAgencyIcon: string;
      }[];
    },
    item: IOperatorDetails,
    typeOfData: string
  ) => {
    const transitdetails = db.transportOperators.filter(
      (agent) =>
        agent.opertaor ===
        "transportOperators" + item.monitoredVehicleJourney.operatorRef
    );

    if (typeOfData === "color") {
      return transitdetails.length > 0
        ? transitdetails[0].transitAgencyColor
        : "black";
    } else {
      return transitdetails.length > 0
        ? transitdetails[0].transitAgencyIcon
        : "bus";
    }
  };

  useEffect(() => {
    if (
      (durationLateTime && durationLateTime > LATE_QUERY) ||
      (durationEarlyTime && durationEarlyTime < EARLY_QUERY)
    ) {
      if (durationLateTime && durationLateTime > LATE_QUERY) {
        _setLateQuery(true);
        _setEarlyQuery(false);
        _setIsLoading(false);
      }
      if (durationEarlyTime && durationEarlyTime < EARLY_QUERY) {
        _setLateQuery(false);
        _setEarlyQuery(true);
        _setIsLoading(false);
      }
    } else {
      (async () => {
        _setLateQuery(false);
        _setEarlyQuery(false);

        const mainBusStationsDetails =
          stationId &&
          (await PostComplimentaryTransport(
            stationId,
            startTime
              ? FormatStringYYYYMMDDTHHMM(startTime)
              : FormatDateYYYYMMDDTHHMM(new Date())
          ));
        if (mainBusStationsDetails && mainBusStationsDetails.status !== 200) {
          _setDetailsError(true);
        } else if (mainBusStationsDetails) {
          const mainBusStationsDetailsData: IMainBusStationsDetails[] =
            mainBusStationsDetails.data.result.motStations.filter(
              (
                value: IMainBusStationsDetails,
                index: number,
                self: IMainBusStationsDetails[]
              ) => {
                return (
                  self.findIndex(
                    (v: { code: number | null }) => v.code === value.code
                  ) === index
                );
              }
            );

          mainBusStationsDetailsData.map(
            (station: IMainBusStationsDetails) => ({
              city: station.city,
              code: station.code,
              id: station.id,
              latitude: station.latitude,
              locationDescriptionAr: station.locationDescriptionAr,
              locationDescriptionEn: station.locationDescriptionEn,
              locationDescriptionHe: station.locationDescriptionHe,
              locationDescriptionRu: station.locationDescriptionHe,
              longitude: station.longitude,
              nameAr: station.nameAr,
              nameEn: station.nameEn,
              nameHe: station.nameHe,
              nameRu: station.nameHe,
              type_id: station.type_id,
              uid: station.uid,
            })
          );
          _setMainBusStationsDetails(mainBusStationsDetailsData);
          _setIsLoading(false);

          const tranistLinesStationsDetails: ITransitInformation[] =
            mainBusStationsDetails.data.result.motResponse.siri.serviceDelivery.stopMonitoringDelivery
              .map(
                (station: { monitoredStopVisit: any }) =>
                  station.monitoredStopVisit
              )
              .map((tranistInfo: IOperatorDetails[]) => {
                return {
                  code: tranistInfo.length > 0 && tranistInfo[0].monitoringRef,
                  transitLines: tranistInfo
                    .map((item: IOperatorDetails) => {
                      return {
                        line: item.monitoredVehicleJourney.publishedLineName,
                        destinationRef:
                          mainBusStationsDetailsData &&
                          mainBusStationsDetailsData.filter(
                            (bus) =>
                              bus.code + "" ===
                              item.monitoredVehicleJourney.destinationRef
                          )[0]?.[
                            `${
                              _currentStationNameLanguage !== "nameRu"
                                ? _currentStationNameLanguage
                                : "nameEn"
                            }` as `nameEn` | `nameHe` | `nameAr` | `nameRu`
                          ],
                        destinationRefNumber:
                          item.monitoredVehicleJourney.destinationRef,
                        monitoringRef: item.monitoringRef,
                        order: item.monitoredVehicleJourney.monitoredCall.order,
                        transitAgencyColor: getTranstAgencyDetails(
                          db,
                          item,
                          "color"
                        ),
                        transitAgencyIcon: getTranstAgencyDetails(
                          db,
                          item,
                          "icon"
                        ),
                        opertaorRef:
                          operatorTranslates[i18n.language][
                            "transportOperators" +
                              item.monitoredVehicleJourney.operatorRef
                          ],
                        expectedArrivalTime:
                          item.monitoredVehicleJourney.monitoredCall
                            .expectedArrivalTime,
                      };
                    })
                    .reduce((acc: any, obj: any) => {
                      const key = "_" + obj.line;
                      if (!acc[key]) {
                        acc[key] = [];
                      }
                      acc[key].push(obj);
                      return acc;
                    }, {}),
                };
              });

          const removeFinalLines = (transit: IOperatorDetails[]) => {
            const filteredTransit: IOperatorDetails[] = [];
            for (const key in transit) {
              filteredTransit[key] = transit[key].filter(
                (item: IOperatorDetails) =>
                  !(
                    item.destinationRefNumber === item.monitoringRef &&
                    item.order !== "1"
                  )
              );
            }
            return filteredTransit;
          };

          const currentTranistLinesStationsDetails: ITransitInformation[] =
            tranistLinesStationsDetails.map((transit: ITransitInformation) => ({
              code: transit.code,

              transitLines: sortLinesByExpectedArrivalTime(
                removeFinalLines(transit.transitLines)
              ),
            }));

          _setMainStationsLinesDetails(currentTranistLinesStationsDetails);
        }
      })();
    }
  }, [startTime]);

  const translates = Translations as unknown as ILangObject;

  const operatorTranslates: ILangObject = Object.keys(translates).reduce(
    (acc: ILangObject, lang: string) => {
      const langRows: string[] = Object.keys(translates[lang]).filter((key) =>
        key.startsWith("transportOperators")
      );
      acc[lang] = langRows.reduce((langAcc: ILangRows, rowKey: string) => {
        langAcc[rowKey] = translates[lang][rowKey];
        return langAcc;
      }, {});
      return acc;
    },
    {}
  );

  const sortLinesByExpectedArrivalTime = (transit: IOperatorDetails[]) => {
    const transitlines: IOperatorDetails[] = transit;

    const lines = Object.fromEntries(
      Object.entries(transitlines).filter(([key, value]) => value.length > 0)
    );

    for (const key in lines) {
      lines[key].sort(
        (
          a: { expectedArrivalTime: string | number | Date },
          b: { expectedArrivalTime: string | number | Date }
        ) => {
          const timeA = new Date(a.expectedArrivalTime).getTime();
          const timeB = new Date(b.expectedArrivalTime).getTime();
          return timeA - timeB;
        }
      );
    }

    const sortedLinesByFirstItem = Object.values(lines)
      .map((x) => x[0])
      .sort((a, b) => {
        const timeA = new Date(a.expectedArrivalTime).getTime();
        const timeB = new Date(b.expectedArrivalTime).getTime();
        return timeA - timeB;
      })
      .map((line) => "_" + line.line);

    const sortedLines: IOperatorDetails[] = [];

    sortedLinesByFirstItem.forEach((key: any) => {
      sortedLines.push(lines[key]);
    });

    return sortedLines;
  };

  const expandedTransitInformation =
    _mainBusStationsDetails &&
    _mainStationsLinesDetails &&
    _mainStationsLinesDetails?.map((transit: ITransitInformation) => {
      const station = _mainBusStationsDetails?.find(
        (station: IMainBusStationsDetails) =>
          parseInt(transit.code) === station.code
      );

      return { ...station, ...transit };
    });

  return (
    <>
      {_isToolTip.length > 0 && (
        <div
          style={{
            position: "fixed",
            width: "100%",
            height: "100%",
            backgroundColor: colors.modal,
            zIndex: "99999999999",
            top: "0",
            display: "flex",
            alignItems: "center",
          }}
          onClick={() => {
            _setIsToolTip("");
          }}
        >
          <div
            style={{
              backgroundColor: colors.white,
              bottom: "50%",
              width: "100%",
              minHeight: "30%",
              position: "absolute",
              borderTopLeftRadius: "2rem",
              borderTopRightRadius: "2rem",
              borderRadius: "13px 13px 288px 288px / 13px 13px 43px 43px",
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div
              style={{
                position: "absolute",
                width: "5rem",
                height: "5rem",
                backgroundColor: colors.white,
                borderRadius: "50%",
                left: "calc(50% - 2.5rem)",
                top: "-2.5rem",
                boxShadow: "0 5px 11px 0 rgba(0, 0, 0, 0.21)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={Images.info_mobile} style={{ scale: "1.5" }} />
            </div>
            <img
              src={Images.closeButtonMob}
              style={{
                ...styles.closeMainModalIcon,
                left: i18n.dir() === "rtl" ? "1rem" : "",
                right: i18n.dir() === "ltr" ? "1rem" : "",
              }}
              onClick={() => {
                _setIsToolTip("");
              }}
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%",
                position: "absolute",
              }}
            >
              <h3
                style={{
                  textAlign: "center",
                  lineHeight: "1.5rem",
                  color: colors.darkBlue,
                }}
              >
                {_isToolTip}
              </h3>
            </div>
          </div>
        </div>
      )}

      {_isLoading && !_detailsError ? (
        <Loading />
      ) : (
        <div style={styles.container}>
          {expandedTransitInformation &&
            expandedTransitInformation.filter((x) => x.transitLines.length > 0)
              .length > 0 &&
            (typeOfPage === "stationInfo" ? (
              <div style={styles.pageHeader}>
                <div style={styles.pageIcon}>
                  <img src={Images.bus} alt="bus" width="50" height="50" />
                </div>
                <div style={styles.pageTitle}>
                  <div style={styles.pageMainTitle}>{t("buses")}</div>
                  <div style={styles.pageSubTitle}>
                    {t("transitDistanceExplain")}
                  </div>
                </div>
              </div>
            ) : (
              <div
                style={{
                  ...styles.transitContainer,
                  alignItems: deviceMode === StyleMode.desktop ? "" : "center",
                }}
              >
                <div style={styles.headerMobileContainer}>
                  <div
                    style={{
                      ...styles.mobileReload,
                      display:
                        deviceMode === StyleMode.desktop ? "none" : "flex",
                    }}
                  >
                    <img src={Images.reload} alt="reload" onClick={onClick} />
                  </div>
                  <div
                    style={{
                      ...styles.transitStationName,
                      justifyContent:
                        deviceMode === StyleMode.desktop ? "" : "center",
                    }}
                  >
                    {t("stationTransit")} {_currentRailStation}
                  </div>
                </div>

                <div
                  style={{
                    ...styles.scheduleTransitContainer,
                    flexDirection:
                      deviceMode === StyleMode.desktop ? "row" : "column",
                    alignItems:
                      deviceMode === StyleMode.desktop ? "unset" : "center",
                    gap: deviceMode === StyleMode.desktop ? "unset" : ".5rem",
                  }}
                >
                  <div style={styles.scheduleTransitContainerHeader}>
                    <div style={styles.scheduleTransitContainerHeaderText}>
                      {t("BusDepartureTimes")}
                    </div>
                    <img
                      title={t("ministryData")}
                      src={Images.information}
                      onClick={() =>
                        deviceMode === StyleMode.mobile &&
                        _setIsToolTip(t("ministryData"))
                      }
                      alt="information"
                      width="20"
                      height="20"
                    />
                  </div>
                  <div style={styles.scheduleTransitContainerTime}>
                    <div style={styles.scheduleTransitContainerTimeText}>
                      {t("todayAround")}

                      {startTime && " " + GetFormatedTimeFromString(startTime)}
                    </div>
                    <div style={styles.scheduleTransitContainerTimeImg}>
                      {deviceMode === StyleMode.desktop && (
                        <img
                          src={Images.reload}
                          alt="reload"
                          onClick={onClick}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          <div
            style={{
              ...styles.transitStations,
              maxHeight: deviceMode === StyleMode.desktop ? "unset" : "32rem",
              paddingTop: deviceMode === StyleMode.desktop ? "unset" : "1.6rem",
              paddingBottom:
                deviceMode === StyleMode.desktop ? "unset" : "1.6rem",

              overflow: deviceMode === StyleMode.desktop ? "unset" : "auto",
              width: deviceMode === StyleMode.desktop ? "90%" : "100%",
            }}
          >
            {expandedTransitInformation &&
              stationId &&
              !_earlyQuery &&
              !_lateQuery &&
              expandedTransitInformation
                .filter((x) => x.transitLines.length > 0)
                .map(
                  (station, index: number) =>
                    station.code && (
                      <TransitStation
                        key={index}
                        stationId={station.code}
                        stationName={
                          station[
                            `${
                              _currentStationNameLanguage !== "nameRu"
                                ? _currentStationNameLanguage
                                : "nameEn"
                            }` as `nameEn` | `nameHe` | `nameAr` | `nameRu`
                          ]
                        }
                        latitude={station.latitude}
                        longitude={station.longitude}
                        transitLines={station.transitLines}
                        locationDescriptionHe={
                          station[
                            `${_currentStationNameLanguage}` as
                              | `nameEn`
                              | `nameHe`
                              | `nameAr`
                              | `nameRu`
                          ]
                        }
                        stationRailId={stationId}
                        stations={stations}
                        typeOfPage={typeOfPage}
                      />
                    )
                )}
            {expandedTransitInformation &&
              !_earlyQuery &&
              !_lateQuery &&
              expandedTransitInformation.filter(
                (x) => x.transitLines.length > 0
              ).length < 1 && (
                <div style={styles.noBusContainer}>
                  <div style={styles.noBusIcon}>
                    <img
                      src={Images.noBusIcon}
                      alt="bus"
                      width="200"
                      height="200"
                    />
                  </div>
                  <div style={styles.noBusHeader}>{t("busErrorDepart")}</div>
                  <div style={styles.noBusSuHeader}>
                    (
                    {typeOfPage === "stationInfo"
                      ? calendar(new Date())
                      : startTime && calendarString(startTime)}
                    )
                  </div>
                </div>
              )}

            {_earlyQuery && !_lateQuery && (
              <div style={styles.noBusContainer}>
                <div style={styles.noBusIcon}>
                  <img
                    src={Images.noBusIcon}
                    alt="bus"
                    width="200"
                    height="200"
                  />
                </div>
                <div style={styles.noBusHeader}>{t("rideOver")}</div>
                <div style={styles.noBusSuHeader}>{t("pastBus")}</div>
              </div>
            )}
            {!_earlyQuery && _lateQuery && (
              <div style={styles.noBusContainer}>
                <div style={styles.noBusIcon}>
                  <img
                    src={Images.noBusIcon}
                    alt="bus"
                    width="200"
                    height="200"
                  />
                </div>
                <div style={styles.noBusHeader}>{t("rideNotStat")}</div>
                <div style={styles.noBusSuHeader}>{t("futureBus")}</div>
              </div>
            )}
            {_detailsError && (
              <div style={styles.noBusContainer}>
                <div style={styles.noBusIcon}>
                  <img
                    src={Images.noBusIcon}
                    alt="bus"
                    width="200"
                    height="200"
                  />
                </div>
                <div style={styles.noBusHeader}>
                  {/* {t("TheOperationFailed")} */}

                  {/* {startTime && GetFormatedTimeFromString(startTime)} */}
                </div>
                <div style={styles.noBusSuHeader}>
                  {t("errorMinistryQuery")}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

const styles: StyleSheet = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: ".8rem",
    gap: "2rem",
  },
  pageHeader: {
    display: "flex",
    gap: "2rem",
  },
  pageIcon: {},
  pageTitle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  pageMainTitle: {
    color: colors.darkBlue,
    fontFamily: font.FbReformaMedium,

    fontSize: fontSize.UmbracoElementTitle,
  },
  pageSubTitle: {},
  transitStations: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "3rem",
    width: "90%",
  },
  noBusContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  noBusHeader: {
    fontSize: fontSize.UmbracoElementTitle,

    lineHeight: "2rem",
    textAlign: "center",
    fontFamily: font.FbReformaMedium,

    color: colors.black,
  },
  noBusIcon: {
    display: "flex",
    justifyContent: "center",
  },
  noBusSuHeader: {
    textAlign: "center",
    fontSize: fontSize.SubTitle,

    color: colors.pasterlGrayDark,
  },
  closeMainModalIcon: {
    position: "absolute",
    cursor: "pointer",
    scale: "1.25",
    transform: "translateY(-1.8rem)",
  },
  transitContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: "1.5rem",
  },
  scheduleTransitContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  scheduleTransitContainerTime: {
    display: "flex",
    gap: "0.5rem",
    alignItems: "center",
  },
  scheduleTransitContainerHeader: {
    display: "flex",
    gap: "0.5rem",
  },
  scheduleTransitContainerTimeImg: {
    display: "flex",
    alignSelf: "center",
    cursor: "pointer",
  },
  scheduleTransitContainerTimeText: { fontWeight: "500", color: colors.nero },
  scheduleTransitContainerHeaderText: {
    fontSize: fontSize.UmbracoElementTitle,
    fontFamily: font.FbReformaMedium,
    color: colors.nero,
  },
  transitStationName: {
    fontFamily: font.FbReformaMedium,
    display: "flex",
    justifyContent: "center",
  },
  headerMobileContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
};

export default TransitTransportPage;
