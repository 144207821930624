import { Fragment, useState } from "react";
import ReactModal from "react-modal";
import colors from "../../../../Assets/Colors/Colors.json";
import Images from "../../../../Data/Images/Images";
import { IGallery, IGalleryYoutube } from "../../../../Interfaces/IGallery";
import { StyleSheet } from "../../../../Interfaces/IStyleSheet";
import { IVideo } from "../../../../Interfaces/IVideo";
import { StyleMode } from "../../../../Utils/consts";
import useDeviceMode from "../../../../Utils/useWindowDimensions";
import GalleryVideo from "../GalleryItems/GalleryVideo";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import GalleryYoutube from "../GalleryItems/GalleryYoutube";
import i18n from "../../../../Services/i18n";
import { t } from "i18next";
import fontSize from "../../../../Assets/Fonts/FontsSizes.json";
import { IsMobile } from "../../../../Services/DeviceService";

const ModalGallery: React.FC<IGallery> = (props) => {
  const [_gallery, _setGallery] = useState(props.gallery);
  const [_selectedItemIndex, _setSelectedItemIndex] = useState(0);
  const [_currentItemIndex, _setCurrentitemIndex] = useState(0);
  const [_isModalOpen, _setIsModalOpen] = useState(false);
  const [_maxItemsInRow, _setmaxItemsInRow] = useState(
    props.maxItemsInRow ? props.maxItemsInRow : 4
  );
  const deviceMode = useDeviceMode();

  const handleGalleryItemSelect = (galleryItemIndex: number) => {
    _setSelectedItemIndex(galleryItemIndex);
    _setIsModalOpen(true);
  };

  return (
    <Fragment>
      <Swiper
        // loopFillGroupWithBlank={true}
        slidesPerView={
          deviceMode === StyleMode.desktop
            ? _maxItemsInRow
            : deviceMode === StyleMode.mobile
            ? 1
            : 3
        }
        // centeredSlides={true}
        spaceBetween={30}
        navigation={true}
        modules={[Navigation]}
        className="mySwiper"
        role={"region"}
        slideToClickedSlide={true}
        aria-label={t("VideoImageSlider")}
      >
        {_gallery.map((galleryItem, index) => {
          return galleryItem.contentTypeAlias === "imageGalleryItem" ? (
            <SwiperSlide
              key={index}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <img
                // alt={}
                tabIndex={!IsMobile() ? 0 : -1}
                src={galleryItem.media._url}
                style={{
                  ...styles.galleryItem,
                  maxWidth: "14rem",
                }}
                role={"button"}
                aria-label={t("CurrentGalleryItem", {
                  currentGalleryTitle: _gallery[index].header.text,
                })}
                onClick={() => handleGalleryItemSelect(index)}
                width={`auto`}
                height={`auto`}
                alt={``}
              />
            </SwiperSlide>
          ) : galleryItem.contentTypeAlias === "videoGalleryItem" ? (
            <SwiperSlide
              key={index}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <GalleryVideo
                videoStyles={{
                  ...styles.galleryItem,
                  maxWidth: "14rem",
                  margin: "auto",
                }}
                media={galleryItem.media as IVideo}
                header={galleryItem.header}
                thumbnail={galleryItem.thumbnail}
                isPlayButtonEnabled={false}
                onClick={() => handleGalleryItemSelect(index)}
              />
            </SwiperSlide>
          ) : (
            <SwiperSlide
              key={index}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div
                tabIndex={!IsMobile() ? 0 : -1}
                role={"button"}
                aria-label={t("CurrentGalleryItem", {
                  currentGalleryTitle: _gallery[index].header.text,
                })}
                style={{
                  position: "absolute",
                  width: "16rem",
                  height: "14rem",
                  backgroundColor: "transparent",
                  cursor: "pointer",
                }}
                onClick={() => handleGalleryItemSelect(index)}
              ></div>
              <GalleryYoutube
                {...(galleryItem as IGalleryYoutube)}
                customStyles={{
                  width: "18rem",
                  height: "14rem",
                }}
              />
            </SwiperSlide>
          );
        })}

        <div
          className="arrows-main prev"
          style={{ backgroundImage: `url(${Images.directorateArrow})` }}
        />
      </Swiper>
      <ReactModal
        isOpen={_isModalOpen}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => _setIsModalOpen(false)}
        style={{
          content: {
            ...styles.content,
            height: deviceMode === StyleMode.desktop ? "100%" : "21rem",
          },
          overlay: {
            backgroundColor: colors.modal,
            zIndex: "99999",
            overflow: "hidden",
          },
        }}
        ariaHideApp={false}
        appElement={document.getElementById("#root") || document.body}
        preventScroll={true}
      >
        {/* <div
          style={{
            ...styles.close,
            right: i18n.dir() === "ltr" ? "1rem" : "",
            left: i18n.dir() === "rtl" ? "1rem" : "",
          }}
        >
          <img
            tabIndex={!IsMobile() ? 0 : -1}
            src={Images.close}
            role={"button"}
            aria-label={t(`Close-modal-btn`)}
            onClick={() => _setIsModalOpen(false)}
            alt={``}
          />
        </div> */}
        {_gallery[_selectedItemIndex].contentTypeAlias ===
          "imageGalleryItem" && (
          <img
            src={_gallery[_selectedItemIndex].media._url}
            width={"100%"}
            height={"100%"}
            alt={``}
          />
        )}
        {_gallery[_selectedItemIndex].contentTypeAlias ===
          "videoGalleryItem" && (
          <GalleryVideo
            videoStyles={{
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              width: "100%",
              height: "100%",
            }}
            media={_gallery[_selectedItemIndex].media as IVideo}
            header={_gallery[_selectedItemIndex].header}
            thumbnail={_gallery[_selectedItemIndex].thumbnail}
            isPlayButtonEnabled={true}
          />
        )}
        {_gallery[_selectedItemIndex].contentTypeAlias ===
          "youtubeGalleryItem" && (
          <GalleryYoutube
            {...(_gallery[_selectedItemIndex] as IGalleryYoutube)}
            customStyles={{
              width: "100%",
              height: "100%",
            }}
          />
        )}
        <div
          style={{
            position: "absolute",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            top: "calc(50% - 3rem)",
            left: "0",
          }}
        >
          <div>
            {_selectedItemIndex > 0 && (
              <img
                src={Images.galleryArrow}
                tabIndex={!IsMobile() ? 0 : -1}
                aria-label={t("PreviousGalleryItem")}
                onClick={() => {
                  const previousIndex = _selectedItemIndex - 1;
                  _setSelectedItemIndex(previousIndex);
                }}
                style={{
                  cursor: "pointer",
                }}
              />
            )}
          </div>
          <div>
            {_selectedItemIndex < _gallery.length - 1 && (
              <img
                src={Images.galleryArrowLeft}
                tabIndex={!IsMobile() ? 0 : -1}
                aria-label={t("NextGalleryItem")}
                onClick={() => {
                  const nextIndex = _selectedItemIndex + 1;
                  _setSelectedItemIndex(nextIndex);
                }}
                style={{
                  cursor: "pointer",
                }}
              />
            )}
          </div>
        </div>
      </ReactModal>
    </Fragment>
  );
};

const styles: StyleSheet = {
  galleryItem: {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    objectFit: "contain",
    width: "18rem",
    height: "14rem",
    cursor: "pointer",
  },
  galleryItemModal: {
    backgroundRepeat: "no-repeat",
    width: "100%",
    height: "100%",
    cursor: "pointer",
  },
  arrowIcon: {
    backgroundImage: `url(${Images.galleryArrow})`,
    backgroundRepeat: "no-repeat",
    width: "4.1rem",
    height: "4.3rem",
    cursor: "pointer",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    width: "80%",
    bottom: "auto",
    background: "hidden",
    border: "0",
    transform: "translate(-50%, -50%)",
    overflow: "hidden",
  } as React.CSSProperties,
  close: {
    display: "flex",
    justifyContent: "end",
    position: "absolute",
    zIndex: "1",
    fontSize: fontSize.UmbracoElementTitle,
    lineHeight: "normal",
    padding: "15px",
    color: "#81d4fa",
    cursor: "pointer",
  },
};

export default ModalGallery;
