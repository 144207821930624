
import { useEffect } from "react";
import colors from "../Assets/Colors/Colors.json";
import fonts from "../Assets/Fonts/Fonts.json";
import { StyleSheet } from "../Interfaces/IStyleSheet";
import { StyleMode } from "../Utils/consts";
import {  xmlSiteMap } from "../Utils/sitemap";
import useDeviceMode from "../Utils/useWindowDimensions";
import fontSize from "../Assets/Fonts/FontsSizes.json";



const XmlSiteMap: React.FC = () => {
  const deviceMode = useDeviceMode();

  const SiteMapXml = () => {
    const line = `${xmlSiteMap}`;
    const xmlBlob = new Blob([line], {type: 'text/plain'});
    const filename = "siteMap.xml";
   
    useEffect(() => {
      let a = document.createElement('a');

      a.setAttribute('href', window.URL.createObjectURL(xmlBlob));
      a.click();
    }, [])
  
   
    return (
      <>
        <div style={{...styles.container,direction:`ltr`}}>
        </div>
      </>
    );
  };

  return (
    <>
      <div
        style={{
          ...styles.mainContainer,
          paddingTop: deviceMode === StyleMode.desktop ? "3rem" : "",
        }}
      >
        <div style={styles.bodyBorderMobile}>
          <div style={styles.mainCardMobile}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "3rem",
                padding: "5rem 0",
                alignItems: `flex-start`,
              }}
            >
              <SiteMapXml />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const styles: StyleSheet = {
  container: {
    display: "",
    flexDirection: "column",
    alignItems: "center",
  },
  categoryContainer: {
    maxWidth: `76rem`,
    width: `100y%`,
  },
  icon: {
    backgroundRepeat: "no-repeat",
    width: "1rem",
    height: "1rem",
  },
  mainContainer: {
    alignItems: "center",
    flexDirection: "column",
    zIndex: "10",
  },
  cover: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "40vh",
    minHeight: "17.18rem",
    position: "relative",
  },
  title: {
    color: colors.white,
    fontSize: fontSize.PageTitle,
    lineHeight: "3rem",
    letterSpacing: "0.05625rem",
    textShadow: `2px 2px ${colors.black}`,
    fontFamily: fonts.FbReformaMedium,
    paddingTop: "3.25rem",
  },
  breadcrumb: {
    display: "flex",
    color: colors.white,
    fontSize: fontSize.Text,
    letterSpacing: "0.05625rem",
    fontFamily: fonts.FbReformaRegular,
    position: "absolute",
    paddingTop: "2rem",
  },
  coverElements: {
    gap: "3rem",
    width: "80%",
    maxWidth: "76rem",
    margin: "auto",
    lineHeight: "2.5rem",
    padding: "2rem",
  },
  bodyBorder: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transform: "translateY(-7.8rem)",
  },
  bodyBorderMobile: {
    display: `grid`,
    justifyContent: `left`,
    marginLeft: `6rem`,
  },
  mainCard: {
    width: "80%",
    backgroundColor: colors.white,
    borderRadius: "0.3125rem",
    boxShadow: "0 2px 10px 0 rgba(0, 0, 0, 0.15)",
    minHeight: "40rem",
  },
  mainCardMobile: {
    width: "80%",
    marginTop: "1%",
    minHeight: "40rem",
  },
  modalContent: {
    top: "50%",
    left: "50%",
    right: "auto",
    maxWidth: "90.625rem",
    width: "80%",
    bottom: "auto",
    height: "50rem",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "none",
    position: "absolute",
    borderRadius: "0.625rem",
    maxHeight: "100%",
    overflow: "visible",
  } as React.CSSProperties,
};

export default XmlSiteMap;



