import { t } from "i18next";
import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import colors from "../../../Assets/Colors/Colors.json";
import fonts from "../../../Assets/Fonts/Fonts.json";
import Images from "../../../Data/Images/Images";
import { StyleSheet } from "../../../Interfaces/IStyleSheet";
import { setPage } from "../../../Redux/Slices/pageSlice";
import { ScheduleState } from "../../../Redux/Slices/scheduleSlice";
import {
  FormatDateShort,
  FormatMissingDigit,
  GetDateAsTextFormat,
  IsTommorow,
} from "../../../Services/DateService";
import fontSize from "../../../Assets/Fonts/FontsSizes.json";
import { isToday } from "date-fns/esm";

const TimeCapsuleMobile: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const schedule = useSelector(
    (state: any) => state.ScheduleReducer
  ) as ScheduleState;
  const scheduleTypeFormatted = `${t(
    schedule.scheduleType === 1 ? "Departure" : "Arrival"
  )} ${t("At")}`;
  const hourFormatted = `${FormatMissingDigit(
    parseInt(schedule.hours)
  )}:${FormatMissingDigit(parseInt(schedule.minutes))}`;

  const handleClick = () => {
    dispatch(setPage("routePlan"));
    navigate(
      `?page=routePlan&step=when&fromStation=${schedule.fromStation}&toStation=${schedule.toStation}&date=${schedule.date}&hours=${schedule.hours}&minutes=${schedule.minutes}&scheduleType=${schedule.scheduleType}`
    );
  };

  return (
    <div style={styles.timeCapsule} onClick={handleClick}>
      <div style={styles.timeLabel}>
        {schedule.date && (
          <span>
            {`${
              isToday(new Date(schedule.date)) ||
              IsTommorow(new Date(schedule.date))
                ? GetDateAsTextFormat(new Date(schedule.date))
                : FormatDateShort(new Date(schedule.date))
            }, ${scheduleTypeFormatted} ${hourFormatted}`}
          </span>
        )}
        <img src={Images.arrowDown} width={`auto`} height={`auto`} alt={``} />
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  timeCapsule: {
    width: "90%",
    height: "4rem",
    backgroundColor: colors.white,
    position: "absolute",
    border: "none",
    borderRadius: "5rem",
    boxShadow: "-1px 1px 33px 1px rgb(64 60 67 / 40%)",
    cursor: "pointer",
    transform: "translateY(-2rem)",
    left: "5%",
    zIndex: "5",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  timeLabel: {
    fontSize: fontSize.FooterItem,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: ".5rem",
    fontFamily: fonts.FbReformaRegular,
  },
};

export default TimeCapsuleMobile;
